import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCampaignOverviewAction } from '../../redux/actions'

import CampaignOverviewLayout from '../CampaignOverviewLayout'
import moment from 'moment'
import debounce from 'lodash.debounce';

class CampaignOverviewPage extends Component {
  // handleFilterChange = filter => {
  //   let { getCampaignOverview } = this.props;
  //   if (filter.id) {
  //     let { history } = this.props;
  //     history.push(`/user/campaigns/reports/${filter.id}`)
  //     return;
  //   }
  //   getCampaignOverview(filter)
  // }
  handleFilterChange = debounce(filter => {
    let { getCampaignOverview, history } = this.props;
    const currentPath = history.location.pathname;
  
    // Check if the filter is new and only then update history or make API call
    if (filter.id && currentPath !== `/user/campaigns/reports/${filter.id}`) {
      history.push(`/user/campaigns/reports/${filter.id}`);
    } else if (filter !== this.props.campaignOverview.currentFilter) {
      // Call API if filter has changed
      getCampaignOverview(filter);
    }
  }, 300);
  componentDidMount() {
    let { getCampaignOverview } = this.props;
    getCampaignOverview({
      end_date: moment().startOf('month').toISOString()
    })
  }
  render() {
    let { campaignOverview } = this.props;
    return (
      <CampaignOverviewLayout
        campaignOverview={campaignOverview}
        title={'Campaign Overview'}
        handleFilterChange={this.handleFilterChange}
        displayFilter={true}
      />
    );
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview: bindActionCreators(getCampaignOverviewAction, dispatch),
  })
)(CampaignOverviewPage)




