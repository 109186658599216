
import { combineReducers } from 'redux';
const initialValues = {
  sms: {
    validFor: 'one',
    'Landing Page': '',
    'Valid For': 'one'
  },
  email: {
    validFor: 'one',
    'Landing Page': '',
    'Valid For': 'one'
  },
  app: {
    validFor: 'one',
    'Landing Page': '',
    'Valid For': 'one'
  },
  // promocodes: "C:\fakepath\merchant.5d35da03.csv"
}
const banks = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BANKS_DATA`:
      return action.payload;
    default:
      return state;
  }
}

const brands = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BRANDS_DATA`:
      return action.payload;
    default:
      return state;
  }
}
const branches = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BRANCHES_DATA`:
      return action.payload;
    default:
      return state;
  }
}

const projections = (state = [], action) => {
  switch (action.type) {
    case 'SET_PROJECTIONS_DATA':
      return action.payload;
    default:
      return state;
  }
}

const campaignTemplates = (state = [], action) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_TEMPLATE_DATA':
      return action.payload;
    default:
      return state;
  }
}
const previewData = (state = [], action) => {
  switch (action.type) {
    case 'SET_PREVIEW_DATA':
      return action.payload;
    default:
      return state;
  }
}
const promocodesCSV = (state = [], action) => {
  switch (action.type) {
    case 'SET_CSV_DATA':
      return action.payload;
    default:
      return state;
  }
}
const promocodesCSVMULTIPLE = (state = [], action) => {
  switch (action.type) {
    case 'SET_CSV_DATA':
      return action.payload;
    default:
      return state;
  }
}
const settingsData = (state = [], action) => {
  switch (action.type) {
    case 'SET_SETTING_DATA':
      return action.payload;
    default:
      return state;
  }
}
const initialData = (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_INITIAL_DATA':
      return action.payload;
    default:
      return state;
  }
}
const campaignResponseData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_RESPONSE_DATA':
      return action.payload;
    default:
      return state;
  }
}
const tabsData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TAB_DATA':
      return action.payload;
    default:
      return state;
  }
}
const ingestionLogs = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_INGESTION_LOGS_DATA`:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  banks,
  brands,
  branches,
  projections,
  campaignTemplates,
  previewData,
  promocodesCSV,
  settingsData,
  initialData,
  campaignResponseData,
  tabsData,
  promocodesCSVMULTIPLE,
  ingestionLogs
});