import React, { Component } from "react";

import { connect } from 'react-redux'
import EntityList from '../../pages/TrainComponentEntityList'
import { BranchComponentForm } from '../Forms/BranchComponentForm'
import { Badge } from "react-bootstrap";
import { updateUserProfileActionWithoutBranch } from '../../redux/actions';
import { bindActionCreators } from 'redux'
import { MerchantCommentsForm } from "../Forms/MerchantComments";
import { GetSessionValue } from "../../utils/sessionStorage";

class BranchComponent extends Component {
    constructor(props) {
        super(props);

        let columns = [
            // {
            //     dataField: 'checkBox',
            //     isDummyField: true,
            //     text: this.props && this.props.languageData && this.props.languageData.select ? this.props.languageData.select : 'Select',
            //     // style: { whiteSpace: 'nowrap' ,textAlign:'center',paddingTop:12}
            //     classes: 'vhalign checkBox'
            // },
            // {
            //     dataField: 'actions',
            //     isDummyField: true,
            //     text: 'Actions',
            //     style: { whiteSpace: 'nowrap', textAlign: 'center' },
            //     classes: 'vhalign actions'
            // },                
            {
                dataField: 'name',
                // sort: true,
                text: this.props && this.props.languageData && this.props.languageData.branchName ? this.props.languageData.branchName : 'Branch Name',
                classes: 'vhalign'
            },

            {
                dataField: 'mastercardMerchantID',
                isDummyField: true,
                sort: true,
                // text: languageData.mastercardMerchantID?languageData.mastercardMerchantID:'Merchant ID',
                text: 'Merchant ID',
                classes: 'vhalign merchantId'
            },
            // {
            //     dataField: 'tripadvisorUrl',
            //     sort: true,
            //     text: this.props && this.props.languageData && this.props.languageData.branchName ? this.props.languageData.branchName : 'TripAdvisor Link',
            //     classes: 'vhalign tripadvisorWidth'
            // },
            {
                dataField: 'ContactDetails',
                text: "Address Details",
                classes: 'vhalign ContactDetails'
            },
            // {
            //   dataField: 'userName',
            //   sort: true,
            //   text: 'User Name',
            //   classes: 'vhalign'
            // },
            // {
            //   dataField: 'contactName',
            //   sort: true,
            //   text: languageData.contactName?languageData.contactName:'Contact Name',
            //   classes: 'vhalign'
            // },
            // {
            //   dataField: 'activatedDate',
            //   sort: true,
            //   text: languageData.activatedDate?languageData.activatedDate:'Activated Date',
            //   classes: 'vhalign',
            //   formatter: (col, row) => {
            //    if(col){
            //     let date = new Date(col);
            //     // adjust 0 before single digit date
            //     let dd = ("0" + date.getDate()).slice(-2);
            //     // current month
            //     let mm = ("0" + (date.getMonth() + 1)).slice(-2); 
            //     // current year
            //     let yy = date.getFullYear();
            //    return `${dd}/${mm}/${yy}`
            //    }
            //   }
            // },
        ];

        let userDetails = GetSessionValue('loginCredentials');    
        if(userDetails.role === 'groupAdmin' || userDetails.role === 'groupUser') {
            columns.splice(0, 0, {
                dataField: 'brandName',
                sort: true,
                text: this.props.languageData.brandName ? this.props.languageData.brandName : 'Brand Name',
                classes: 'vhalign'
            });
        }
        
        this.state = {
            tableData: [],
            // Branch Name, Brand Name, Merchant Id, Status
            columns: columns,
            viewFields: [
                { name: 'name', label: 'Branch Name' },
                { name: 'merchantId', label: 'Merchant Id' },
                { name: 'block', label: 'Block Name' },
                { name: 'contactName', label: 'Contact Name' },
                { name: 'contactPhone', label: 'Contact Phone' },
                { name: 'contactEmail', label: 'Contact Email' },
                { name: 'address', label: 'Address' },
                { name: 'brandName', label: 'Brand Name' },
                { name: 'brandId', label: 'Brand Id' },
                { name: 'created', label: 'Created' },
                { name: 'activatedDate', label: 'Activated Date' },
                { name: 'comments', label: 'Comments' },
                { name: 'approvedBy', label: 'Approved By' },
                { name: 'status', label: 'Status' },
            ]
        }
    }


    backActionToPayment = () => {
        let { user } = this.props
        this.props.updateUserWithoutBranch({
            stepThree: false,
            brandId: user.brandId
        })
        this.props.backAction();
    }
    nextActionSetPassword = async () => {
        let { user } = this.props
        this.props.updateUserWithoutBranch({
            stepThree: true,
            brandId: user.brandId,
            step:'branch'
        })
        this.props.nextAction();
    }
    componentDidMount = () => {
        // this.updateUserProfile()
        // this.getDataOfUser();

    }
    getDataOfUser = async () => {

        let { user } = this.props
        if (user && user['stepThree'] == true && user['stepTwo'] == true) {
            this.props.nextAction();
        }
    }
    render() {
        return <EntityList
            onRef={(ref) => this.usersRef = ref}
            entityType="user"
            preset={{ type: 'brand' }}
            setAllBranchValue={this.props.setAllBranchValue}
            trainComponentMerchantIds={this.props.trainComponentMerchantIds}
            handleTrainComponentMerchantIds={this.props.handleTrainComponentMerchantIds}
            disableSelect={true}
            allBranch={this.props.allBranch}
            // prefix="Merchants"
            heading="branch"
            backAction={this.backActionToPayment}
            nextAction={this.nextActionSetPassword}
            icon="domain"
            EntityForm={BranchComponentForm}
            tableData={this.state.tableData}
            apiUrl="merchants"
            // CommentsForm={MerchantCommentsForm}
            // globalSearchPlaceHolder={'Search Brand / Branch Name / Branch Id'}
            // onSubmit={this.submit}
            columns={this.state.columns}
            viewFields={this.state.viewFields}
        />
    }
}

export default connect(
    state => ({
        languageData: state.languages,
        user: state.user
    }),
    dispatch => ({
        // getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
        updateUserWithoutBranch: bindActionCreators(updateUserProfileActionWithoutBranch, dispatch),

    })
)(BranchComponent);