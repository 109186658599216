import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCampaignOverviewAction } from '../../redux/actions'

import CampaignOverviewLayout from '../CampaignOverviewLayout'
import moment from 'moment'
import { withRouter } from "react-router-dom";

class Home extends Component {
  handleFilterChange = filter => {
    console.log(filter, 'filter')
    let { history, getCampaignOverview } = this.props;
    if (filter.id) {
      history.push(`/user/campaigns/reports/${filter.id}`)
      return
    }
    getCampaignOverview(filter)
  }
  componentDidMount() {
    let { getCampaignOverview } = this.props;
    if (this.props.match.params.id) {
      let flterSelect = {
        campaign_id: this.props.match.params.id,
      }
      getCampaignOverview(flterSelect)
    }
    // else {
    //   getCampaignOverview({
    //     start_date: moment().startOf('month').toISOString(),
    //     end_date: moment().startOf('month').toISOString() /* server will make sure it is correct date */
    //   })
    // }
  }
  render() {
    let { campaignOverview } = this.props;
    return (
      <CampaignOverviewLayout
        campaignOverview={campaignOverview}
        title={'Dashboard'}
        handleFilterChange={this.handleFilterChange}
        selectedCampaign_id={this.props.match.params.id}
      />
    );
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview: bindActionCreators(getCampaignOverviewAction, dispatch),
  })
)(withRouter((Home)))

