import axiosMethodRequest from '../config/service';
import { GET_REVIEW_DATA, GET_SCATTER_DATA } from '../redux/insights/actionTypes'
export const getScatterChartDataAPICall = (brandId, datePayload, tagType, branchId) => {
    // branchId = 'KMTA004Ba';
    return dispatch => {
        branchId = branchId == 'all' ? '' : branchId;
        dispatch({
            type: GET_SCATTER_DATA,
            payload: { data: null, loader: true }
        })
        const url = `reviewsdata/getscatterchart?brandId=${brandId}&tagType=${tagType}&datePayload=${JSON.stringify(datePayload)}&kmid=${branchId}`
        return axiosMethodRequest('GET', url).then((response) => {
            if (response && response.data && response.data.respCode) {// 
                dispatch({
                    type: GET_SCATTER_DATA,
                    payload: { data: response.data, loader: false }
                })
            }
        })
    }
}
