import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserInfo from './UserInfo.component';
import Nav from './Nav.component';
import Settings from './Settings.component'
import { setMobileNavVisibility, toggleSidebarVisibility } from '../../redux/reducers/layout';
import Icon from '@material-ui/core/Icon'
import KrowdLogo from '../../assets/images/logo-krowd-white.svg';
import { GetSessionValue } from '../../utils/sessionStorage';
const imgStyle = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
}
const imgStyleForTrain = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
  "marginTop": '10px'
}
class SideBar extends Component {
  state = {
    userDetails: '',
    user: ''
  }
  componentDidMount = () => {
    this.updateUserProfile()
  }
  updateUserProfile = async () => {
    let userDetails = GetSessionValue('user')
    await this.setState({ userDetails: userDetails })
  }
  render() {
    let {
      backgroundColor,
      backgroundImage,
      user,
      hideMobileMenu,
      mobileNavVisibility,
      toggleSidebarMenu,
      sidebarNavVisibility
    } = this.props;

    return (
      <div className="sidebar" data-color={backgroundColor} data-image={backgroundImage}>
        <div className="sidebar-wrapper">
          <div
            className="desktop-sidebar-close"
          >
            {
              sidebarNavVisibility ?
                <p className='d-flex'>
                  <span className='px-2 pt-2 w-50'>
                    <a href='https://krowd.info' target='_blank'>
                      <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
                    </a>
                  </span>
                  <span onClick={toggleSidebarMenu} className='float-right w-50 pt-2' >
                    <i><Icon className="left-circle-icon" style={{ fontSize: "30px" }}>remove_circle_outline</Icon></i>
                  </span>
                </p> :
                <i onClick={toggleSidebarMenu}><Icon className="menu-icon" style={{ fontSize: "30px" }}>reorder</Icon></i>
            }
          </div>
          {mobileNavVisibility && <div className="mobile-sidebar-close text-right mr-2" >
            <p className='d-flex'>
              <span className='px-2 pt-2 w-50'>
                <a href='https://krowd.info' target='_blank'>
                  <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
                </a>
              </span>
              <span onClick={hideMobileMenu} className='float-right w-50 ' >
                <i><Icon className="menu-icon" style={{ fontSize: "30px", transform: 'rotate(180deg)' }}>play_circle_outline</Icon></i>
              </span>
            </p>


          </div>}

          {/* {!mobileNavVisibility && <div className="user-wrapper">
            <div className="user my-0">

              <div className={`photo-container pb-0`} >
                <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
              </div>
            </div>
          </div>} */}
          <UserInfo />
          <Nav user={user} />
          <Settings user={user} sidebarNavVisibility={sidebarNavVisibility} hideMobileMenu={hideMobileMenu} />
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
      backgroundColor: state.ThemeOptions.backgroundColor,
      backgroundImage: state.ThemeOptions.backgroundImage,
      user: state.user,
      mobileNavVisibility: state.Layout.mobileNavVisibility,
      sidebarNavVisibility: state.Layout.sidebarNavVisibility
    }),
    (dispatch, ownProps) => ({
      hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
      toggleSidebarMenu: () => dispatch(toggleSidebarVisibility())
    })
  )(SideBar)
);