import React, { PureComponent, useState } from 'react';
import {
    Row, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearStoreData } from '../redux/actions';
import { Link } from 'react-router-dom';

class ChangePasswordModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    //to clear the data from store
    sessionExpired = () => {
        let { sessionExpired } = this.props;
        sessionExpired()
    }

    render() {
        const { isOpen } = this.props;
        return (
            <div>
                <Modal isOpen={isOpen} centered={true} size='lg'>
                    <ModalHeader className="bold-text" style={{ backgroundColor: '#323657', color: 'white', textAlign: 'center' }}  >
                        Change Password
                    </ModalHeader>
                    <ModalBody className='content__modal'>
                        <h5 className="py-3">Password changed successfully.</h5>

                        <Row className="justify-content-center">
                            <button className="btn btn-primary btn-lg" onClick={this.sessionExpired} style={{ backgroundColor: '#323657', border: 'none' }}>Login again</button>
                        </Row>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
export default connect(
    null,
    (dispatch => ({
        sessionExpired: bindActionCreators(clearStoreData, dispatch)
    }))
)(ChangePasswordModal);