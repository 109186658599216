import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { insightsSelectors } from "../../../redux/insights";
import { useSelector } from "react-redux";
import { Radar } from 'react-chartjs-2';
import FilterAdditionalData from './FilterAdditionalData';

const BigContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-left: 10px;
  }
`;

const temp_chart_data = {
    total: {},
    objectwithambiance: {},
    objectwithfood: {},
    objectwithprice: {},
    objectwithservice: {}
};
const SimilarityComponent = () => {
    console.log("SimilarityComponent")
    const [chartsTotalData, setChartsTotalData] = useState(temp_chart_data);
    const radarDataState = useSelector(insightsSelectors.getSimilarityData);
    const loader = useSelector(insightsSelectors.getRadarChartloaderState);

    useEffect(() => {
        if (radarDataState && radarDataState.data.respCode == 200 && radarDataState.data != undefined) {

            let data1 = radarDataState.data;
            /* New chart - start */
            let new_chart_var = {
                labels: [],
                datasets: [
                ]
            };

            let arr1 = [];
            /* Convert Object to array */
            arr1 = Object.keys(data1).map((key) => [(key), data1[key]]);
            let datas = chartsTotalData;
            arr1.forEach((data, index_) => {
                let [key, value] = data;
                let label = key.replace('objectwith', '')
                if (key != 'respCode' && key != 'brandList') {
                    let randCol = randomColor(label);
                    const sets_val = {      // new
                        label: label,
                        data: [],
                        fill: true,
                        backgroundColor: `rgb(${randCol}, 0.2)`,
                        borderColor: `rgb(${randCol})`,
                        pointBackgroundColor: `rgb(${randCol})`,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: `rgb(${randCol})`
                    }
                    new_chart_var.labels = [];
                    Object.keys(value).forEach((element, _value) => {
                        new_chart_var.labels.push(element);        // new
                    });
                    let result_ = Object.keys(value).map((key) => value[key]);
                    sets_val.data = [...result_];
                    // new
                    new_chart_var.datasets.push(sets_val);      // new
                    // datas[key] = { ...new_chart_var }     // new
                }
            });
            datas['total'] = { ...new_chart_var }     // new
            setChartsTotalData(datas);
            /* For 4 data seperate graphs */
            datas['total']['datasets'].map((ele) => {
                switch (ele.label) {
                    case "ambiance": {
                        datas.objectwithambiance = { ...datas.total };
                        datas.objectwithambiance.datasets = [];
                        datas.objectwithambiance.datasets.push(ele);
                    }
                        break;
                    case "food": {
                        datas.objectwithfood = { ...datas.total };
                        datas.objectwithfood.datasets = [];
                        datas.objectwithfood.datasets.push(ele);
                    }
                        break;
                    case "price": {
                        datas.objectwithprice = { ...datas.total };
                        datas.objectwithprice.datasets = [];
                        datas.objectwithprice.datasets.push(ele);
                    }
                        break;
                    case "service": {
                        datas.objectwithservice = { ...datas.total };
                        datas.objectwithservice.datasets = [];
                        datas.objectwithservice.datasets.push(ele);
                    }
                        break;
                    default:
                        break;
                }
            });
            setChartsTotalData(datas);
        }
    }, [radarDataState]);


    function randomColor(label) {
        let color = {
            ambiance: "182, 140, 184",
            food: "100, 97, 160",
            price: "167, 198, 218",
            service: "40, 75, 99"
        }
        return color[label];
    }

    const chartList = [
        {
            label: "Food",
            value: chartsTotalData.objectwithfood,
        },
        {
            label: "Price",
            value: chartsTotalData.objectwithprice,
        },
        {
            label: "Ambiance",
            value: chartsTotalData.objectwithambiance,
        },
        {
            label: "Service",
            value: chartsTotalData.objectwithservice,
        }];

    return (
        <BigContainer>
            <div className="row col-12 no-gutters">
                {/* <div className="col-12 text-right">
                    <FilterAdditionalData></FilterAdditionalData>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12"> */}
                    {/* <h4 className="p-l-10 pb-4">Distance Similarity</h4> */}
                {/* </div> */}
                {/* 
                    Old code
                */}
                {/* // && chartsTotalData != undefined && chartsTotalData.data && chartsTotalData.data != undefined */}
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-r-0">
                    {!loader ?
                        (radarDataState && radarDataState.data && chartsTotalData
                            ?
                            <div className='radar-example'>
                                <div className='multi-chart similarityCharts pb-3'>
                                    {chartList.map(res => {
                                        return <div key={res.label} className='col-12 col-lg-6 col-md-12 col-sm-12 pb-3'>
                                            <h4 className='py-3' style={{ fontSize: "1rem", fontWeight: 800 }} >{res.label}</h4>
                                            <Radar
                                                key={`chart ${res.label}`}
                                                id={`chart ${res.label}`}
                                                className='radar-example'
                                                data={res.value}
                                                options={{
                                                    legend: {
                                                        display: false
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    elements: {
                                                        line: {
                                                            borderWidth: 2
                                                        },
                                                    },
                                                }} />
                                        </div>
                                    })}
                                </div>
                            </div>
                            :
                            <p className="pt-3 text-center" > No data available</p>)
                        :
                        <div className="d-flex justify-content-center spinner-block">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                </div>

            </div>

        </BigContainer>

    );
};


export default SimilarityComponent;

