export const getInsightstate = (state) => {
  return state.insights.insightsData
};

export const getReviewstate = (state) => {
  return state.insights.reviewData
};

export const getChartDataState = (state) => {
  return state.insights.reviewData.data.chartDetails
};
export const getFactorChartDataState = (state) => {
  return state.insights.reviewFactorsData.data
};

export const getFactorChartloaderState = (state) => {
  return state.insights.reviewFactorsData.loader
};
export const getRadarChartloaderState = (state) => {
  return state.insights.similarityData.loader
};

export const getTrendsChartstate = (state) => {
  return state.insights.reviewTrendsData.data
};
export const getTrendsChartLoaderstate = (state) => {
  return state.insights.reviewTrendsData.loader
};
export const getLinesChartstate = (state) => {
  return state.insights.reviewData.data.lineschartDetails
};
export const scatterchartDetails = (state) => {
  return state.insights.reviewScatterData.data.scatterchartDetails
};
export const insightsDates = (state) => {
  return state.insights.insightSelectedDate
};
export const getBranchesData = (state) => {
  return state.insights.branchData
};
export const getSimilarityData = (state) => {
  return state.insights.similarityData
};

export const getCustomerReviewDataState = (state) => {
  return state.insights.customerReview
};

export const getUserdetailsState = (state) => {
  return state.user
};

export const scatterchartDetailsLoader = (state) => {
  return state.insights.reviewScatterData.loader
};

export const getSelectedTrendsChartstate = (state) => {
  return state.insights.selectedReviewTrendsData.data
};

export const getSelectedTrends = (state) => {
  return state.insights.selectedTrends
};

export const getBranchDataFromNode = (state) => {
  return state.insights.brandListFromNode.data
};

export const getFilterData = (state) => {
  return state.insights.filterModalData.data
};