import axiosMethodRequest from "../config/service"
import { campaignSPActionTypes } from '../redux/campaignStartPause';

export const getCampaignApiCall = () => {
    return dispatch => {
        const url = `campaigns`
        return axiosMethodRequest('GET', url).then((response) => {
            if (response && response.data && response.data.respCode) {
                var respdata = {}
                if (response.data && response.data.campaigns.length > 0) {
                    // callback(response.data.campaigns)
                    dispatch({
                        type: campaignSPActionTypes.GET_CAMPAIGN_LIST,
                        payload: { data: response.data.campaigns, loader: false },
                    })
                }

            }
        })
    }
}
