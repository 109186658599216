import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import Loader from '../components/Loader'
import { Link } from 'react-router-dom';
const Modal = ({
  close,
  show,
  title,
  message,
  action,
  callback,
  actionType = "success",
  size = "md",
  cancelText = "Cancel",
  hideCancelBtn = false,
  centered = false,
  isLoading
}) => {
  return (
    <ModalComponent show={show} onHide={close} size={size} centered={true}>
      <ModalComponent.Header closeButton className='p-1'>
        <ModalComponent.Title>{title != 'mc' ? title : ''}</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body className='p-0'>
        <Loader loader={isLoading} />
        {message}</ModalComponent.Body>
    </ModalComponent>
  )
}

export default Modal

