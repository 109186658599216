import React, { PureComponent } from 'react';
import {
    Row, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
// import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import Smstemplate from './SmsTemplate'
import { CampaignTemplateTabComponent } from './tabcomponent'
import Icon from '@material-ui/core/Icon'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import {
    getCampaignTemplateData,
    createCampaignAction,
    editCampaignAction,
    setPreviewDataAction,
    saveTabData,
    updateUserProfileAction
} from '../../redux/actions'
import { notificationOpts } from '../../config'
import { Redirect } from 'react-router-dom';
import fetchMethodRequest from './../../config/service';
class CampaignPreview extends PureComponent {
    constructor() {
        super();
        this.state = {
            activeTab: '1',
            type: 'sms',
            isLoading: false,
            fields: {
                sms: {
                    // spend:10
                },
                app: {
                    // spend:10
                },
                email: {
                    // spend:10
                }
            },
            dynamicFields: {
                sms: {
                    list: []
                },
                app: {
                    list: []
                },
                email: {
                    list: []
                }
            },
            successCampaign: false,
            spend: 10,
            isDiscountPercentFieldFound: false,
            isDiscountValueFieldFound: false,
            templates: [],
            validCampaignCreation: null,
            promocodeType: 'single'
        };
    }

    // Toggling Tabs
    toggle = async (tab) => {
        const { activeTab } = this.state;
        let { getCampaignTemplateData, previewData, setTabs } = this.props
        let type = this.state.type
        this.formLoad()
        if (activeTab !== tab) {
            if (tab == '1') {
                type = 'sms'
            } else if (tab == '2') {
                type = 'email'
            }
            else if (tab == '3') {
                type = 'app'
            }
            await this.setState({
                activeTab: tab,
                type: type
            });
            this.fetchData()
            setTabs({
                type: type,
                tab: tab
            })
        }
    };

    componentDidMount = async () => {
        let { previewData, tabsData } = this.props
        //to display based on history
        if (tabsData && tabsData.type) {
            await this.setState({ activeTab: tabsData.tab, type: tabsData.type })
        }
        this.fetchData()
        this.formLoad()
        //to filter campaign text based on context
        if (previewData && !previewData.discountValue) {
            this.setState({ isDiscountPercentFieldFound: true })
        } else if (previewData && previewData.discountValue) {
            this.setState({ isDiscountValueFieldFound: true })
        }
    }

    // get dfata from server
    fetchData = () => {
        let { getCampaignTemplateData } = this.props
        let { type } = this.state
        this.setState({ isLoading: true })
        getCampaignTemplateData(type, async (res) => {
            await this.setState({ templates: res })
            let filteredTemplates = await this.handleTemplateListData()
            if (this.tabComponentRef && this.tabComponentRef.dynamicFields) {
                this.tabComponentRef.dynamicFields(filteredTemplates[0], 0)
            }
            if (this.tabComponentRef && this.tabComponentRef.setInitialPreviewText) {
                this.tabComponentRef.setInitialPreviewText(filteredTemplates[0])
            }
            if (this.tabComponentRef && this.tabComponentRef.handlePreviewText) {
                this.tabComponentRef.handlePreviewText(filteredTemplates[0], 0)
            }
            this.setState({ isLoading: false })
        })

    }

    // setting template fielf values from respective tabs
    templateFieldValues = (values, type) => {
        let fieldValues = this.state.fields
        fieldValues['sms'] = values[type]
        fieldValues['email'] = values[type]
        fieldValues['app'] = values[type]
        this.setState({ fields: fieldValues })
    }

    //Campaign submit
    campaignSubmit = (data) => {
        let templatesAdded = this.templateDataAdded(data);
        let isValid = false;
        let imageCount = 0
        if (templatesAdded) {
            templatesAdded.forEach((item, index) => {
                if (item && item.type && item.type == 'email') {
                    let fields = item.fields;
                    Object.keys(fields).forEach((_item, _index) => {
                        if (_item) {
                            let lowerCaseItem = _item.toLowerCase();
                            if (lowerCaseItem && lowerCaseItem.includes('image') && fields[_item]) {
                                imageCount++;
                            }
                        }
                    })

                }
            })
            data.templates = templatesAdded;
            data.promocode = this.props.promoCodes;
        }
        if (imageCount >= 2) {
            this.setState({ validCampaignCreation: true });
        } else {
            this.setState({ validCampaignCreation: false });
            return
        }
        let {
            createCampaign,
            successNotification,
            errorNotification,
            editCampaign, user } = this.props;
        let executeAction = data._id ? editCampaign : createCampaign;
        executeAction(data, async (res, type) => {
            if (type == 'success') {
                if (!user.isTAndCAccepted) {
                    this.props.updateUser({
                        isTAndCAccepted: true,
                        brandId: user.brandId
                    })
                }
                await this.sendCsvToserver(res);
                this.setState({ successCampaign: true })
                setTimeout(() => {
                    let notification = { ...notificationOpts }
                    notification.message = this.getNotificationMessage(data)
                    successNotification(notification)
                })
                return;
            }
            let error = res ? res : ''
            setTimeout(() => {
                let notification = { ...notificationOpts }
                notification.message = `Error : ${error}`
                errorNotification(notification)
            })
        })
    }

    getNotificationMessage = data => {
        let { campaignForEdit } = this.props;
        if (data._id) {
            return `Campaign "${data.name}" updated successfully`
        } else {
            return `Campaign "${data.name}" has been created successfully`
        }
    }

    //handling selected template data
    templateDataAdded = () => {
        let templates = []
        let { selectedAppTemplate, selectedEmailTemplate, selectedSmsTemplate, fields, type } = this.state
        let { promoCodes, promoCodesMultiple } = this.props
        if (selectedAppTemplate && fields && fields['app']) {
            fields['app'].promocodes = promoCodes;
            fields['app'].promoCodesMultiple = promoCodesMultiple
            delete fields['app']['undefined']
            selectedAppTemplate.fields = fields.app
            templates.push(selectedAppTemplate)
        }
        if (selectedEmailTemplate && fields && fields['email']) {
            fields['email'].promocodes = promoCodes
            fields['email'].promoCodesMultiple = promoCodesMultiple
            delete fields['email']['undefined']
            selectedEmailTemplate.fields = fields.email
            templates.push(selectedEmailTemplate)
        }
        if (selectedSmsTemplate && fields && fields['sms']) {
            fields['sms'].promocodes = promoCodes
            delete fields['sms']['undefined']
            selectedSmsTemplate.fields = fields.sms
            templates.push(selectedSmsTemplate)
        }
        return templates
    }

    //setting coreesponding template data
    selectedTemplateData = (type, data) => {
        this.state[type] = data
        if (type) {
            // this.setState({ [type]: data })
        }

    }

    //Dynamic field setting
    dynamicFieldHandling = async (type, fields, index) => {
        let dynamicFields = this.state.dynamicFields
        dynamicFields[type]['list'] = fields
        await this.setState({ dynamicFields: dynamicFields })
    }

    //formLoad
    formLoad = () => {
        if (this && this.tabComponentRef && this.tabComponentRef.campaignTemplateFormRef && this.tabComponentRef.campaignTemplateFormRef.formLoad) {
            this.tabComponentRef.campaignTemplateFormRef.formLoad()
        }
    }

    //handle template list data
    handleTemplateListData = () => {
        // let {templates} = this.props;
        let { isDiscountPercentFieldFound, isDiscountValueFieldFound, templates } = this.state;
        let filteredTemplates = []
        templates.forEach((item, index) => {
            let campaignTemplateText = (item && item.campaignTemplateText ? item.campaignTemplateText : '').toLowerCase()
            if (isDiscountValueFieldFound) {
                if (campaignTemplateText.includes('discount (aed)') || campaignTemplateText.includes('discount(aed)')) {
                    filteredTemplates.push(item)
                }
            } else if (isDiscountPercentFieldFound) {
                if (campaignTemplateText.includes('discount %') || campaignTemplateText.includes('discount%')) {
                    filteredTemplates.push(item)
                }
            }
        })
        return filteredTemplates
    }

    //send promocodes csv to particular campaign id
    sendCsvToserver(data) {
        let { promoCodes, promoCodesMultiple } = this.props;

        let url = `campaigns/upload?uploadPath=promocode&campaign_id=${data && data.details && data.details.campaign_id ? data.details.campaign_id : ''}`
        if (promoCodesMultiple) {
            fetchMethodRequest('POST', url, promoCodesMultiple, 'upload').then((response) => {

            })
        }


    }
    render() {
        const { activeTab, isLoading, successCampaign, spend, fields } = this.state;
        const { templateData } = this.props
        return (
            <Col md={12} lg={12} xl={12}>
                {successCampaign ? <Redirect to='/user/campaigns/future' /> : null}
                <div className="tabs tabs--justify">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        this.toggle('1');
                                    }}
                                >SMS
                    </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >Email
                    </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => {
                                        this.toggle('3');
                                    }}
                                >App
                    </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}  >
                            {/* sms */}
                            <TabPane tabId="1" className='template__card'>
                                <CampaignTemplateTabComponent
                                    templateData={templateData}
                                    type={this.state.type}
                                    fetchData={this.fetchData}
                                    isLoading={isLoading}
                                    onRef={ref => this.tabComponentRef = (ref)}
                                    campaignSubmit={this.campaignSubmit}
                                    selectedTemplateData={this.selectedTemplateData}
                                    templateFieldValues={this.templateFieldValues}
                                    dynamicFields={this.state.dynamicFields}
                                    dynamicFieldHandling={this.dynamicFieldHandling}
                                    fields={this.state.fields}
                                    discountValueFound={this.state.isDiscountValueFieldFound}
                                    discountPercentageFound={this.state.isDiscountPercentFieldFound}
                                    validCampaignCreation={this.state.validCampaignCreation}
                                    promocodeType={this.state.promocodeType}
                                    handlePromoCode={(value) => {
                                        this.setState({ promocodeType: value })
                                    }}
                                />
                            </TabPane>
                            {/* sms */}
                            {/* email */}
                            <TabPane tabId="2" className='template__card'>
                                <CampaignTemplateTabComponent
                                    templateData={templateData}
                                    type={this.state.type}
                                    fetchData={this.fetchData}
                                    isLoading={isLoading}
                                    onRef={ref => this.tabComponentRef = (ref)}
                                    campaignSubmit={this.campaignSubmit}
                                    selectedTemplateData={this.selectedTemplateData}
                                    templateFieldValues={this.templateFieldValues}
                                    dynamicFields={this.state.dynamicFields}
                                    dynamicFieldHandling={this.dynamicFieldHandling}
                                    fields={this.state.fields}
                                    discountValueFound={this.state.isDiscountValueFieldFound}
                                    discountPercentageFound={this.state.isDiscountPercentFieldFound}
                                    validCampaignCreation={this.state.validCampaignCreation}
                                    promocodeType={this.state.promocodeType}
                                    handlePromoCode={(value) => {
                                        this.setState({ promocodeType: value })
                                    }}
                                />
                            </TabPane>
                            {/* email */}
                            {/* App */}
                            <TabPane tabId="3" className='template__card'>
                                <div className='comingsoon_div'>
                                    Coming soon
                                </div>
                            </TabPane>
                            {/* App */}
                        </TabContent>
                    </div>
                </div>
            </Col>
        );
    }
}

export default connect(
    state => ({
        sample: state,
        previewData: state.selectEntities.previewData,
        templates: state.selectEntities.campaignTemplates,
        promoCodes: state.selectEntities.promocodesCSV,
        promoCodesMultiple: state.selectEntities.promocodesCSVMULTIPLE,
        tabsData: state.selectEntities.tabsData,
        promoCodesMultiple: state.selectEntities.promocodesCSVMULTIPLE,
        promoCodes: state.selectEntities.promocodesCSV,
        user: state.user
    }),
    dispatch => ({
        getCampaignTemplateData: bindActionCreators(getCampaignTemplateData, dispatch),
        createCampaign: bindActionCreators(createCampaignAction, dispatch),
        editCampaign: bindActionCreators(editCampaignAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        errorNotification: bindActionCreators(errorNotificationAction, dispatch),
        setPreview: bindActionCreators(setPreviewDataAction, dispatch),
        setTabs: bindActionCreators(saveTabData, dispatch),
        updateUser: bindActionCreators(updateUserProfileAction, dispatch),
    })
)(CampaignPreview)