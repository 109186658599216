import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef } from 'yup';

class ForgotPasswordForm extends Component {

  initialValues = {
    email: '',
  }

  validationSchema = YupObject({
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
  })

  getForm = (props, options = { isDisabled: false, btnLabel: 'Submit' }) => {
    let { status, isSubmitting } = props;
    let { isDisabled, btnLabel } = options
    return (
      <Form className="form">
        <div className="form-group">
          <label>Email</label>
          <Field name="email" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting || isDisabled}>{btnLabel}</button>
        </div>
      </Form>
    )
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, options)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required
export default ForgotPasswordForm










