import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import AmexDiningSignup from "./AmexDiningSignup";

class AMEXDiningSignUpPublicPages extends Component {

    render() {
        let { notifications, match } = this.props; 
        return (
            <div className="container-fluid overflow-auto h-100 amex-dining-signupBg p-0">
                <Switch>
                    <Route path="/amexdiningsignup" component={AmexDiningSignup} />
                </Switch>
                <Notifications
                    notifications={notifications}
                />
            </div>
        );
    }
}

const mapStateToProp = state => ({
    user: state.user,
    notifications: state.notifications
});

export default connect(mapStateToProp)(AMEXDiningSignUpPublicPages)

