import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames'

import Icon from '@material-ui/core/Icon'

class Nav extends Component {

  state = {};
  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  //change here campaigns->insights
  componentDidMount() {
    if (this.isPathActive('/user/campaigns'))
      this.setState({ campaignMenuOpen: true })

    console.log('this.props.user.brandId', this.props.user);
  }
  getNavForCardProvider = () => {
    return (

      <ul className="nav flex-column">
        <li
          className={classNames({
            active: this.isPathActive('/user/dashboard')
          })}
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>Assessment</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/overview')
            })
          }
        >
          <Link to="/user/campaigns/overview">
            <i><Icon style={{ fontSize: "20px" }}>bar_chart</Icon></i>
            <p>Campaign Overview</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/create')
            })
          }
        >
          <Link to="/user/campaigns/create">
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Create Campaign</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/past') || this.isPathActive('/user/campaigns/future') || this.state.campaignsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ campaignsMenuOpen: !this.state.campaignsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>
              Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/campaigns/past')
                    })
                  }
                >
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/campaigns/current')
                    })
                  }
                >
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Current Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }
  getNavForBank = () => {
    return (

      <ul className="nav flex-column">
        <li
          className={classNames({
            active: this.isPathActive('/user/dashboard')
          })}
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/overview')
            })
          }
        >
          <Link to="/user/campaigns/overview">
            <i><Icon style={{ fontSize: "20px" }}>bar_chart</Icon></i>
            <p>Campaign Overview</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/past') || this.isPathActive('/user/campaigns/future') || this.state.campaignsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ campaignsMenuOpen: !this.state.campaignsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>
              Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/campaigns/past')
                    })
                  }
                >
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/campaigns/current')
                    })
                  }
                >
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Current Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/brands') || this.state.brandsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ brandsMenuOpen: !this.state.brandsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
            <p>
              Brands
              <b
                className={
                  classNames('caret', {
                    open: this.state.brandsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.brandsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/brands')
                    })
                  }
                >
                  <Link to="/user/brands">
                    <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                    <p>List</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/brands/users')
                    })
                  }
                >
                  <Link to="/user/brands/users">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }
  getNavForUser = () => {
    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        {/* <li 
          className={
            classNames({
              active : this.isPathActive('/user/merchants')
            })
          }
        >
          <Link to="/user/merchants">
            <i><Icon style={{fontSize: "20px"}}>shop</Icon></i>
            <p>Merchants</p>
          </Link>
        </li> */}
        {/* <li 
          className={
            classNames({
              active : this.isPathActive('/user/activities')
            })
          }
        >
          <Link to="/user/activities">
            <i><Icon style={{fontSize: "20px"}}>star</Icon></i>
            <p>Activities</p>
          </Link>
        </li> */}

        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/create')
            })
          }
        >
          <Link
            to='/user/campaigns/create'
          // to={this.props.user && this.props.user['guestUser']&& this.props.user['guestUserFlag'] ? '/user/campaigns/create/wizard' : '/user/campaigns/create'}
          >
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Create Campaigns</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/managecreative')
            })
          }
        >
          <Link to="/user/managecreative">
            <i><Icon style={{ fontSize: "20px" }}>build</Icon></i>
            <p>Manage Assets</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/ingestionLogs')
            })
          }
        >
          <Link to="/user/ingestionLogs">
            <i><Icon style={{ fontSize: "20px" }}>account_tree</Icon></i>
            <p>Ingestion Logs</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              My Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }
  getNavForCompanyAdmin = () => {
    return (
      <ul className="nav flex-column">

        {/* //changes made here */}

        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/socialanalytics')
            })
          }
        >
          <Link to={`/user/socialanalytics`}>
            <i><Icon style={{ fontSize: "20px" }}>assessment</Icon></i>
            <p>Social Analytics</p>
          </Link>
        </li> */}




        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        {/* <li 
          className={
            classNames({
              active : this.isPathActive('/user/activities')
            })
          }
        >
          <Link to="/user/activities">
            <i><Icon style={{fontSize: "20px"}}>star</Icon></i>
            <p>Activities</p>
          </Link>
        </li> */}

        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/create')
            })
          }
        >
          <Link
            to='/user/campaigns/create'
          // to={this.props.user && this.props.user['guestUser']&& this.props.user['guestUserFlag'] ? '/user/campaigns/create/wizard' : '/user/campaigns/create'}
          >
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Create Campaigns</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/managecreative')
            })
          }
        >
          <Link to="/user/managecreative">
            <i><Icon style={{ fontSize: "20px" }}>build</Icon></i>
            <p>Manage Assets</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/ingestionLogs')
            })
          }
        >
          <Link to="/user/ingestionLogs">
            <i><Icon style={{ fontSize: "20px" }}>account_tree</Icon></i>
            <p>Ingestion Logs</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              My Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }
  getNavForGroupUser = () => {
    return (
      <ul className="nav flex-column">

        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>

      </ul>
    )
  }
  getNavForSystemAdmin = () => {
    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/banks') || this.state.banksMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ banksMenuOpen: !this.state.banksMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Banks
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.banksMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/banks/list')
                    })
                  }
                >
                  <Link to="/user/banks/list">
                    <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                    <p>List</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/banks/users')
                    })
                  }
                >
                  <Link to="/user/banks/users">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/brands') || this.state.brandsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ brandsMenuOpen: !this.state.brandsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Brands
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.brandsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/brands/list')
                    })
                  }
                >
                  <Link to="/user/brands/list">
                    <i><Icon style={{ fontSize: "20px" }}>domain</Icon></i>
                    <p>List</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/brands/users')
                    })
                  }
                >
                  <Link to="/user/brands/users">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }
  getNavs = () => {
    let { user } = this.props;
    if (user.role === 'systemAdmin') {
      return this.getNavForSystemAdmin()
    } else if (user.role === 'user') {
      return this.getNavForUser()
    } else if (user.role === 'companyAdmin' || user.role === 'brandAdmin') {
      return this.getNavForCompanyAdmin()
    } else if (user.role === 'groupAdmin') {
      return this.getNavForCompanyAdmin()
    } else if (user.entityType === 'bank') {
      return this.getNavForBank()
    } else if (user.entityType === 'cardprovider') {
      return this.getNavForCardProvider()
    } else if (user.role === 'groupUser' || user.role === 'brandUser') {
      return this.getNavForGroupUser()
    } else {
      return ''
    }
  }
  render() {
    return this.getNavs()
  }
}

export default withRouter(Nav);