import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleMobileNavVisibility } from '../../redux/reducers/layout';
import Icon from '@material-ui/core/Icon'
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
const imgStyle = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
}
const Header = ({
  // showMobileMenu,
  toggleMobileNavVisibility,
  user,
  mobileNavVisibility
}) => (
    <nav className="main-nav mainNavBox" >
      <div className="navbar-toggle" >
        {/* <button type="button" className="navbar-toggle" data-toggle="collapse" onClick={toggleMobileNavVisibility}> */}
        {/* <i className="pe-7s-menu burger"></i> */}
        <p className='d-flex pt-2'>
          <span onClick={toggleMobileNavVisibility} className='mr-3 ml-2 '>
            <i><Icon className="burger" style={{ fontSize: "30px" }}>reorder</Icon>
            </i>
          </span>
          <span>
            <a href='https://krowd.info' target='_blank'>
              <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
            </a>
          </span>
        </p>

        {/* </button> */}
      </div>
    </nav>
  );

const mapDispatchToProp = dispatch => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility())
});

const mapStateToProp = state => ({
  user: state.user,
  mobileNavVisibility: state.Layout.mobileNavVisibility,
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);