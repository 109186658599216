
const pluralMap = {
  bank : 'banks',
  brand : 'brands',
  branch : 'branches',
  user : 'users',
  invoice: 'invoices',
  ingestionLog : 'ingestionLogs'
}

export const numberWithCommas = (x) => {
    // if (x && x.toString)
    // 	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return 0;
    if (x && x.toString) {
    x = parseFloat(x).toFixed(2)
    var parts = x.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    if(decimalPart[1] == 0 && decimalPart[0] == 0) {
      return numberPart.replace(thousands, ",");
    } else {
      return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }
  } else { return 0; }
}

export const pluralize = text => {
  if (pluralMap[text])
    return pluralMap[text]
  return text;
}

export const hslToRgb = (h, s, l) => {
  // var r, g, b;

  // if (s == 0) {
  //   r = g = b = l; // achromatic
  // } else {
  //   function hue2rgb(p, q, t) {
  //     if (t < 0) t += 1;
  //     if (t > 1) t -= 1;
  //     if (t < 1/6) return p + (q - p) * 6 * t;
  //     if (t < 1/2) return q;
  //     if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  //     return p;
  //   }

  //   var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  //   var p = 2 * l - q;

  //   r = hue2rgb(p, q, h + 1/3);
  //   g = hue2rgb(p, q, h);
  //   b = hue2rgb(p, q, h - 1/3);
  // }

  // return [ r * 255, g * 255, b * 255 ];
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c/2,
      r = 0,
      g = 0,
      b = 0;
  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [ r, g, b ];
}

export const getUniqueArray = (array, fieldName) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
      if(!map.has(item[fieldName])){
          map.set(item[fieldName], true);    // set any value to Map
          result.push(item);
      }
  }
  return result;
}