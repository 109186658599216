import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import './assets/styles/base.scss'
import './assets/newstyles/base.scss'

import 'chartjs-plugin-datalabels'

import store from './redux/store'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './ScrollToTop';

const Root = (props) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  )
}

const UnsecuredPage = () => (
  <div>
    <div>If you see this page, Webb App link you have clicked on is under Clickjacking security attack.</div>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>Click <a className="link" href={window.self.location.href} title='Web Application' target='blank'>here</a> to access WebApp safely.</h2>
  </div>
);

if (window.self === window.top) {
  ReactDOM.render(<Root />, document.getElementById('root'));
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
