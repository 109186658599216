import React, { useEffect, useState } from "react";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { useDispatch, useSelector } from "react-redux";
import { insightsSelectors } from "../../redux/insights";
import { numberWithCommas } from '../../utils/helpers';
import {
  BigContainer,
  Container,
  Textalign,
  // cardstyle,
  cardbody,
  cardtitle,
  cardsubtitle,
  cardtext
} from "./insights.styled";
import Card from "react-bootstrap/Card";
import Grid from '@mui/material/Grid';
import { Icon } from "@mui/material";
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { GetSessionValue } from "../../utils/sessionStorage";
import moment from 'moment'
import ModalComponent from '../../components/Modal';
import InsightsModel from "../../pages/InsightPageLayout/InsightsModel";
import { useHistory } from "react-router-dom";

const Statcard = (props) => {
  const history = useHistory()
  const data = GetSessionValue('loginCredentials');

  const [signupModel, setsignupModel] = useState({ isOpen: false });
  const [cuisineArr, setCuisineArr] = useState([]);

  // const nextMonth = moment().add(1, 'months').format('MMMM YYYY');
  // const previousMonth = moment().subtract(1, "month").startOf("month").format('MMMM');
  const nextMonth = moment().add(1, 'months').format('MMM `YY');
  const previousMonth = moment().subtract(1, "month").startOf("month").format('MMM `YY');
  const currentYear = new Date().getFullYear()
  const chartDataState = useSelector(insightsSelectors.getFactorChartDataState);
  const FactorChartloader = useSelector(insightsSelectors.getFactorChartloaderState);
  const [isInsightsLogin, setisInsightsLogin] = useState(false);
  const userDetails = useSelector(insightsSelectors.getUserdetailsState);
  // const [reviewCount, setReviewCount] = useState({ negative: 0, positive: 0 });

  // const trendschartloader = useSelector(insightsSelectors.getTrendsChartLoaderstate);
  // const trendschartDataState = useSelector(insightsSelectors.getTrendsChartstate);
  useEffect(() => {
    if (!window.location.pathname.includes("user/socialanalytics")) {
      setisInsightsLogin(true)
    }
  }, []);

  useEffect(() => {
    if (chartDataState.cusineRankingforselectedrange) {
      const test = Object.keys(chartDataState.cusineRankingforselectedrange);
      console.log('chartDataState.cusineRankingforselectedrange', chartDataState, test);
      if (cuisineArr.length == 0)
        setCuisineArr(test);
    }
  }, [chartDataState && chartDataState.cusineRankingforselectedrange]);


  // useEffect(() => {
  //   if (chartDataState != undefined && chartDataState.chartDetails && chartDataState.chartDetails.factorsAffecting) {
  //     let data = { negative: 0, positive: 0 };
  //     let { Negative, Positive, categories } = chartDataState.chartDetails.factorsAffecting || [];
  //     if (Negative) {
  //       Negative.forEach(e => {
  //         data.negative += e;
  //       });
  //     }
  //     if (Positive) {
  //       Positive.forEach(e => {
  //         data.positive += e;
  //       });
  //     }
  //     setReviewCount(data);
  //   }
  // }, [chartDataState.chartDetails && chartDataState.chartDetails.factorsAffecting]);

  const handleSubmitSignup = (data) => {
    console.log(data)
    setsignupModel({ ...signupModel, isOpen: false })
  }

  // const numberWithCommas = (n) => {
  //   var parts = n.toString().split(".");

  //   const numberPart = parts[0];
  //   const decimalPart = parts[1];
  //   const thousands = /\B(?=(\d{3})+(?!\d))/g;

  //   return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  // }

  return (
    <BigContainer className="insight">
      <ModalComponent
        close={() => {
          setsignupModel({ ...signupModel, isOpen: false })
        }}
        size="lg"
        callback={(data) => handleSubmitSignup(data)}
        show={signupModel.isOpen}
        title={<span><b>Sign Up</b></span>}
        message={<InsightsModel handleSubmitSignup={handleSubmitSignup} />}
        hideCancelBtn={true}
        actionType={null}
      />
      <Grid container>

        {(FactorChartloader) ?

          [1, 2, 3].map((res, index) =>
            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
              <Card className="cardstyle  skeleton justify-content-center">
                <div className="w-50 hide-text m-3"></div>
                <div className="w-75 hide-text m-3"></div>
                <div className="w-75 hide-text m-3"></div>
                <div className="w-75 hide-text m-3"></div>
              </Card>
            </Grid>
          )
          :
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {/* <Container> */}
              <Card className="cardstyle justify-content-center">
                <Card.Title style={cardtitle}>
                  {/* {(chartDataState.Nps ? numberWithCommas(chartDataState.Nps) : 'NA')} */}
                  {(chartDataState.krowdscore ? numberWithCommas(chartDataState.krowdscore) : 'NA')}
                </Card.Title>
                <Card.Subtitle style={cardsubtitle}>
                  <span className="d-flex align-items-center justify-content-center">
                    Krowd Aggregate Score
                    <ToolTipContainer
                      toolTipText={"Krowd Aggregate Score is derived from a holistic set of parameters, such as review rating, reviewer rating, sentiment scores, recency, frequency"}
                      id="discountValue"
                    >
                      <Icon style={{ fontSize: "1.5rem", marginTop: "6px", marginLeft: "4px" }} className="text-info">info</Icon>

                    </ToolTipContainer>
                  </span>
                </Card.Subtitle>
                <Card.Subtitle style={cardsubtitle}>
                  Based on <span style={{ fontWeight: "bold" }}>{chartDataState.totalreviewscount || 'NA'}</span>&nbsp;
                  Reviews in the last 12 months
                </Card.Subtitle>
              </Card>
              {/* </Container> */}
            </Grid>

            {!isInsightsLogin && chartDataState.currentYearRevenue ?
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card className="cardstyle justify-content-center">
                  <Card.Title style={cardtitle}>
                    {chartDataState.currentYearRevenue ? numberWithCommas(chartDataState.currentYearRevenue) : 0}
                  </Card.Title>
                  <Card.Subtitle style={cardsubtitle}>
                    <span className="d-flex align-items-center justify-content-center">
                      Revenue YTD (£)
                    </span>
                  </Card.Subtitle>
                </Card>
              </Grid>
              : ""}

            {isInsightsLogin || !chartDataState.currentYearRevenue ? 
              <Grid item xs={12} sm={12} md={4} lg={4}>
                {/* <Container> */}
                <Card className="cardstyle justify-content-center">

                  {/* {isInsightsLogin &&

                    <Card.Title style={cardtitle}>
                      {userDetails.revenueCount || 0}
                    </Card.Title>
                  } */}

                  { //!isInsightsLogin &&
                    //  <Card.Title style={cardtitle}>
                    //    {chartDataState && chartDataState.totalamount ? numberWithCommas(chartDataState.totalamount) :
                    //      <span className="btn  w-100 text-secondary blurText comingSoonFontSize">
                    //        <div className="ml-2" >0000</div>
                    //      </span>
                    //    }
                    //  </Card.Title>
                    //}
                    //{!isInsightsLogin &&
                    //  <Card.Subtitle style={cardsubtitle}>
                    //    {/* Customers you would have acquired if you ran a 20% offer in {previousMonth}. */}
                    //    {`Total revenues in ${currentYear}`}
                    //  </Card.Subtitle>
                    //
                  }


                  <div className="text-center">
                    <Card.Title style={cardtitle}>
                      {userDetails.revenueCount ? userDetails.revenueCount :
                        <span className="btn  w-100 text-secondary blurText comingSoonFontSize">
                          <div className="ml-2" >0000</div>
                        </span>
                      }
                    </Card.Title>
                    {`customers you could have acquired if you ran a 20% of campaign in ${previousMonth},`}

                    <div>
                      <span style={{ color: "blue", textDecoration: "underline" }}
                        onClick={() => {
                          history.push("/user/campaigns/create")
                        }}>
                        Click here
                      </span>
                      &nbsp; to create your {nextMonth} campaign so you don't miss out!
                    </div>
                  </div>

                  {/* {
                    isInsightsLogin &&

                    <div className="text-center">
                      {`Customers you could have acquired if you ran a 20% offer in ${previousMonth}`}
                     
                      <div >
                        <span style={{ color: "blue", textDecoration: "underline" }}
                          onClick={() => { 
                            history.push("/user/campaigns/create")
                          }}
                        >
                          Click here
                        </span>
                        &nbsp; to create your campaign in less than 5 minutes. */}
                  {/* to know more on how you can create a campaign for {nextMonth} in less than 5 mins. */}
                  {/* </div>
                    </div>
                  } */}

                </Card>
                {/* </Container> */}
              </Grid>
              : ""}


            {/* <Grid item xs={12} sm={12} md={4} lg={4}>

              <Card className="cardstyle justify-content-center">
                <div className="row w-100 justify-content-center align-items-center flex-column">
                  <Card.Title className="d-flex align-item-center" style={cardtitle}>
                    <div style={{ marginTop: "4px" }}>
                      {reviewCount.positive}
                    </div>
                  </Card.Title>
                  <Card.Subtitle style={cardsubtitle}>Positive Review</Card.Subtitle>
                </div>
                <div style={{ width: "75%", border: "1px solid #dedede", margin: "10px 10%", }}></div>

                <div className="row w-100 justify-content-center align-items-center flex-column">
                  <Card.Title className="d-flex align-item-center" style={cardtitle}>
                    <div style={{ marginTop: "4px" }}>
                      {reviewCount.negative}
                    </div>
                  </Card.Title>
                  <Card.Subtitle style={cardsubtitle}>Negative Review</Card.Subtitle>
                </div>
 
              </Card>

            </Grid> */}


            <Grid item xs={12} sm={12} md={4} lg={4}>
              {/* <Container> */}
              <Card className="cardstyle justify-content-center">
                <div className="row w-100 justify-content-center align-items-center flex-column">

                  <Card.Title className="d-flex align-item-center" style={cardtitle}>
                    <div style={{ marginTop: "4px" }}>
                      #{chartDataState.loggedinBrandoverallRank ? numberWithCommas(chartDataState.loggedinBrandoverallRank) : 'NA'}
                    </div>
                  </Card.Title>
                  <Card.Subtitle style={cardsubtitle}>Overall Rank</Card.Subtitle>


                </div>
                <div style={{
                  width: "75%",
                  border: "1px solid #dedede",
                  margin: "10px 10%",
                }}></div>

                <div className="d-flex w-100 pt-3 scrollbarCustom overFlowAuto"
                  style={cuisineArr.length < 3 ? { justifyContent: 'center' } : {}}

                >
                  {
                    chartDataState.cusineRankingforselectedrange ? Object.keys(chartDataState.cusineRankingforselectedrange).map((category, i) => {
                      return (

                        <div className="px-4 text-center l-space py-2" style={{ whiteSpace: "nowrap" }} key={i} >
                          <div className="f-16">
                            #<b>{chartDataState.cusineRankingforselectedrange[category] ? numberWithCommas(chartDataState.cusineRankingforselectedrange[category]) : ""}</b>
                          </div>
                          <div>
                            {category}
                          </div>
                        </div>
                      )
                    }) : ''
                  }
                </div>
              </Card>
              {/* </Container> */}
            </Grid>
          </>
        }

      </Grid>
    </BigContainer >
  );
};

export default Statcard;
