import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    centered = true,
    className,
    data
}) => {

    const agreeTermsCondition = () => {
        callback(data.type, data.summaryData);
    }

    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered} className={className}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> <b>Terms & Conditions</b> </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>

                <div className="row terms-conditions-modal">

                    <div className='col-12 t-c-title'>
                        Krowd Limited Terms and Conditions
                    </div>

                    <div className='col-12 m-t-15'>
                        These terms and conditions govern the access to, and use of, the marketplace offered by
                        Krowd Limited at www.krowdit.com by a Reward Partner.
                    </div>

                    <div className='col-12 m-t-10'>
                        Krowd Limited <b>(“Krowd”)</b> is a company incorporated in England and Wales with registered number
                        10027920 whose registered address is at Kemp House, 152-160 City Road, London, England, EC1V 2NX.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>1. Definitions</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        The following words shall have the following meanings within these terms and conditions.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Analytics Fee</b> means the fee of 2% of gross merchant value for the provision of Marketplace access and analytics tools provided by Krowd
                        to the Reward Partner. This is included in the Success Fee.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Cashback</b> means the relevant cashback offered to a Customer in an Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Customer</b> means the customers of the Publisher, to whom the Publisher has provided Offers to.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Success Fee</b> means the fee to be paid by the Reward Partner to Krowd for successful Offer Redemptions
                        as submitted by the Reward Partner as part of any Offer submission, which is inclusive of the Analytics Fee.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Marketplace</b> means the Krowd customer acquisition marketplace hosted at www.krowdit.com.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Offer </b> means the cashback offers uploaded by Reward Partners onto the Marketplace for the purchase of
                        certain goods or services from the Reward Partner.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Offer Redemptions</b> means the purchase of goods and services by a Customer that is subject to an Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Publisher</b> means participating banks, financial partners, airlines, employee benefit platforms and other
                        partners of Krowd who provide Offers to their end customers and users.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>Reward Partner</b> means a merchant who uploads an Offer onto the Marketplace.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>2. Making an Offer Available</b>
                    </div>


                    <div className='col-12 m-t-10'>
                        2.1)  The Reward Partner shall make an Offer available through the Marketplace by completing the Offer
                        submission on the Marketplace which shall include such information as is required for Krowd to host the
                        Offer on the Marketplace, including target Customer group, qualifying offer criteria, total available
                        offer budget as well as desired level of Cashback and the Success Fee as well as upload Reward Partner’s
                        logo and any other images or content as may be reasonably required to enable the hosting of the Offer.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.2) The value of the Offer and the Success Fee will in all circumstances be agreed between Krowd and the
                        Reward Partner prior to making the Offer available – either through the Marketplace or directly in writing.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.3) By making an Offer submission, the Reward Partner undertakes to honour such Offer for all and any
                        purchases made by Customers who make an Offer Redemption.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.4) Reward Partner Offer submissions may be amended by the Reward Partner at any point up until 30
                        calendar days before the end of the month prior to the month in which the Offer is due to be run
                        (the <b>“Amendment Period”</b>). Past this point, all amendments shall be at the discretion of Krowd.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.5) Krowd's campaigns and the Amex Dining program have different operational cycles, with Reward
                        partners automatically enrolled for subsequent cycles in both programs. To opt out of any cycle,
                        partners must provide a 30-day notice prior to the end of their current cycle.

                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.5.1.	Scenario 1: Krowd's Monthly Campaign
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Krowd conducts its campaigns on a monthly rotation. For instance, if a Reward partner is active in
                        the June campaign, running from June 1st to June 30th, they are set to be auto-enrolled in the
                        subsequent July campaign, covering July 1st to July 31st. To decline participation in the July cycle,
                        the partner needs to inform Krowd by May 31st, ensuring a 30-day notification ahead of the start of
                        the next campaign.
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.5.2.	Scenario 2: Amex Dining's 3-Month Cycle
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Amex Dining program operates in 3-month intervals. If the current cycle of a Reward partner
                        spans from January 1st to March 31st, and the next campaign interval is scheduled for April 1st to
                        June 30th. Partners wishing to opt out of the April to June campaign must provide their notice to
                        Krowd by March 1st, again adhering to the 30-day notification requirement before the onset of the
                        subsequent cycle.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.6) It's recommended to have a legal professional review this clause to ensure its suitability and clarity
                        within the context of your contract.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.7) Following successful completion of an Offer submission, Krowd shall confirm to the Reward Partner acceptance
                        of the Offer along with relevant details, Krowd reserves the right to not approve any Offer or ask for reasonable
                        amendments prior to such approval.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.8) Krowd shall make the Offer available to a portfolio of Publishers, should the Reward Partner not want
                        the Offer to be made with any specific Publishers then it must notify Krowd via <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a> within the
                        Amendment Period otherwise Krowd shall make the Offer available to all Publishers it deems fit.
                    </div>

                    <div className='col-12 m-t-10'>
                        2.9) Krowd may upload the Merchant IDs to the Marketplace up to 30 days before the Offer goes live in order to review
                        transaction analytics.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>3. Offer Redemption</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        3.1) Where the goods being offered by the Reward Partner have a return period, Krowd shall invoice
                        the Success Fee on completion the applicable return period (up to a maximum of 30 days).
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>4. Data Protection</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        4.1) Krowd does not store or process personal data from Reward Partners and will never ask the Reward Partner to share
                        such data with Krowd. If the Offer Redemption requires a unique code, the Reward Partner agrees to share the full list
                        or redeemed codes only. Krowd assumes no liability for any customer data shared accidentally or intentionally with Krowd.
                    </div>

                    <div className='col-12 m-t-10'>
                        4.2) The main purposes for which we use non-personal information are:
                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.1.	Management and administrative purposes of your purchases through Krowd
                    </div>

                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.2.	Providing services tailored to you.
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.3.	Analysis and market research
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.4.	Updates and service communications
                    </div>
                    <div className='col-12 m-t-10'>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.5.	Transaction monitoring
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>5. Invoicing and Payment Terms</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        5.1) The Reward Partner shall pay Krowd the Success Fee and Cashback for all Offer Redemptions on a weekly
                        basis. Krowd shall invoice the Reward Partner on a weekly basis through Direct Debit as provided via the
                        GoCardless Direct Debit on the Marketplace. The invoice shall be payable within 5 days. Late payments shall
                        accrue interest at a rate of 5% above the Bank of England base rate from time to time.
                    </div>
                    <div className='col-12 m-t-10'>
                        5.2) <b>Deposit and Refund</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        The merchant could be required to pay a deposit of £99 via direct debit to cover any cashback that may be
                        owed to the customers as part of the campaign. Any remaining funds from the deposit shall be returned to
                        the merchant within 8 working days after the end of the campaign. The deposit shall be refunded to the
                        merchant in the same method of payment that was used to pay the deposit. The merchant shall be
                        responsible for any fees or charges associated with the direct debit transaction, including but not limited to
                        bank fees or insufficient funds fees. In the event that the merchant breaches any terms of this agreement,
                        the deposit may be used to cover any damages or losses incurred by the Krowd.
                    </div>
                    <div className='col-12 m-t-10'>
                        5.3) Krowd undertakes to transfer the Cashback to the relevant Publisher for payment to its Customers,
                        pursuant to its relevant terms and conditions with that Publisher.
                    </div>
                    <div className='col-12 m-t-10'>
                        5.4) Should Krowd receive any data outside of the invoicing period that relates to Offer Redemptions,
                        Krowd shall be entitled to invoice for such Offer Redemptions in the next invoice.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>6. Enrolment with Publishers</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        6.1) Krowd will automatically promote your brand to a portfolio of exclusive Publishers that have access to
                        customers. If you do not want to be automatically enrolled, and consequently want to select a Publisher
                        of choice then the brand will need to opt-out.
                    </div>
                    <div className='col-12 m-t-10'>
                        6.2) Such requests should be communicated in writing to Krowd via <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>7. Removing an Offer</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        7.1) The Offer shall remain on the Marketplace for the duration of the Offer, as selected by the Reward Partner
                        during the Offer submission. The Reward Partner may request for an Offer to end early by providing written
                        notice to Krowd via <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>. Krowd shall, upon its discretion, remove the Offer by the end of the
                        month following the month in which such notice is given. Notwithstanding the foregoing, Krowd reserves the right
                        to remove an Offer at any time in its complete discretion.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>8. Krowd’s role as an intermediary </b>
                    </div>
                    <div className='col-12 m-t-10'>
                        8.1) Krowd’s role is as an intermediary and limited to providing the Offer to the Publisher who then
                        provides such Offer direct to its Customers. Any Offer Redemption is a separate contract between the Reward
                        Partner and the Customer and the Reward Partner is responsible for the fulfilment of such contract,
                        including abiding by all relevant laws in relation to the provision of the relevant goods or services
                        and fulfilment of such contract. The Reward Partner hereby indemnifies and agrees to keep Krowd and its
                        Publishers indemnified against any loss or claim it may suffer as a result of the Reward Partner’s failure
                        to successfully perform such contract (including not honouring the Offer), including any loss as a result
                        of any marketing material provided by the Reward Partner in any materials provided by the Reward Partner to
                        Krowd as part of the Offer submission. The Reward Partner further warrants to Krowd that is has sufficient
                        legal rights to provide all the marketing materials, logos, images etc it provides to Krowd for the Offer
                        submission.
                    </div>
                    <div className='col-12 m-t-10'>
                        8.2) Krowd is not responsible for Publishers subsequent marketing of the Offer and makes no representations or
                        warranties about the extent to which an Offer is marketed, including whether it is marketed at all. Krowd is
                        not responsible for any failure by a Publisher or itself to make an Offer available.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>9. License</b>
                    </div>

                    <div className='col-12 m-t-10'>
                        9.1) The Reward Partner grants Krowd and Publishers a royalty-free, non-exclusive license to use the Reward Partner’s
                        trade marks and other marketing material provided as part of the Offer submission by the Reward Partner through the Marketplace
                        for the purposes of making the Offer available and to be used in Krowd’s marketing materials, including Krowd’s website.
                    </div>


                    <div className='col-12 m-t-10'>
                        <b>10. Miscellaneous</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        10.1) The Reward Partner agrees not to work directly or indirectly with any Publisher introduced by Krowd
                        during and for 6 months after the campaign has completed.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.2) To the fullest extent permitted by law, Krowd’s liability under this agreement is limited to aggregate
                        Success Fees received from the Reward Partner in the preceding 12-month period.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.3) These terms come into effect on an Offer submission and remain in effect until that Offer has been completed.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.4) Both parties shall keep the terms of the agreement and all and any underlying data shared or processed as part
                        of the agreement confidential, save where required to disclose by law.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.5) All notices may be given, in the case of Krowd to the address at the top of these terms and conditions and by
                        email to <a className='anchor-tag' href='mail:info@krowdit.com'>info@krowdit.com</a>, in the case of the Reward Partner, to the address and email provided as part of the Offer submission.
                    </div>
                    <div className='col-12 m-t-10'>
                        10.6) This agreement constitutes the entire agreement between the parties in relation to the subject matter it contains.
                    </div>

                    <div className='col-12 m-t-10'>
                        <b>11. Governing law</b>
                    </div>
                    <div className='col-12 m-t-10'>
                        11.1) These terms and conditions and all matters, including non-contractual obligations, arising hereunder
                        shall be governed by the laws of England and Wales and each party irrevocably submits to the exclusive
                        jurisdiction of the courts of England and Wales.
                    </div>

                </div>

            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    Cancel
                </Button>
                <Button variant="success" onClick={agreeTermsCondition}>
                    Agree
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal

