export const InsightsToolTips={
  statistics:{
    'srs':{
      'single':'Total review of the brand'
    }
  }
}
export const DashboadToolTips = {
  statistics: {
    'reach': {
      'single': 'Total reach of the campaign',
      'aggregate': 'Total reach of all current/filtered campaigns'
    },
    'conversions': {
      'single': 'Total conversions of the campaign',
      'aggregate': 'Total conversions of all current/filtered campaigns'

    },
    'revenue': {
      'single': 'Total revenue of the campaign',
      'aggregate': 'Total revenue of all current/filtered campaigns'

    },
    'total_cost': {
      'single': 'Total cost of the campaign',
      'aggregate': 'Total cost of all current/filtered campaigns'
    },
    'totalCashback': {
      'single': 'Total cashback of the campaign',
      'aggregate': 'Total cashback of all current/filtered campaigns'
    },
    'totalSuccessFee': {
      'single': 'Total success fee of the campaign',
      'aggregate': 'Total success fee of all current/filtered campaigns'
    },
    'cpa': {
      'single': 'Average cost per acquisition of the campaign',
      'aggregate': 'Average cost per acquisition of all current/filtered campaigns'
    },
    'month': {
      'single': '',
      'aggregate': ''
    },
    'discountPercent': {
      'single': '',
      'aggregate': ''
    },
    date: {
      'single': {
        past: 'Month/Year the campaign was active',
        current: 'Days Left for campaign expiry',
        future: 'Days left to start of campaign'
      },
      'aggregate': 'No. of current/filtered campaigns'
    }
  },
  insights: {
    'highest_spend': {
      'single': 'Highest spend in the campaign',
      'aggregate': 'Highest spend among all current/filtered campaigns'

    },
    'lowest_spend': {
      'single': 'Lowest spend in the campaign',
      'aggregate': 'Lowest spend among all current/filtered campaigns'

    },
    'average_spend': {
      'single': 'Average spend in the campaign',
      'aggregate': 'Average spend among all current/filtered campaigns'

    },
    'repeat_customers': {
      'single': 'Repeat customers',
      'aggregate': 'Repeat customers'

    }
  },
}

export const DemographicTips = {
  age: "Distribution of redeemed offers, by Age",
  branches: "Distribution of redeemed offers, by Branch",
  nationality: "Distribution of redeemed offers, by Nationality",
  gender: "Distribution of redeemed offers, by Gender",
}
export const CreateCampaignFormTips = {
  name: "Enter the name of the campaign",
  selectBranch: "Select the branches of the campaign",
  start_date: "Select the  GO: LIVE campaign date",
  newCustomer: "Coming Soon",
  commingSoon: 'Coming Soon',
  existingCustomer: "Coming Soon",
  discountPercent: "The discount offered to a customer",
  discountValue: "Spend more than X to receive Y Discount",
  stretchSpend: "Spend more than X% of customer ATV to receive Y Discount",
  successFee: "Commission paid on gross spend is free for 3 months",
  branches: "The campaign is live for the following branches",
  age_range: "The offer is for the following age group",
  gender: "The offer is for the following gender group",
  allCustomer:"Coming Soon"
}
export const CampaignProjectionTips = {
  totalCardHolder: "The total number of cardholders in the ecosystem",
  addressableMarket: "The total number of cardholders based on selected criteria",
  expectedReach: "The offer will be distributed to N cardholders",
  expectedConversion: "Coming Soon",
  expectedRevenue: "Coming Soon",
  expectedCost: "Coming Soon",
  cpa: "Cost per acquisition"
}

export const CreateCampaignTips = {
  createCampaign: "The campaign will be allocated for the GO Live data"
}


export default {};



