import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import capitalize from 'lodash/capitalize'
import DoughnutChart from '../Charts/DoughnutChart'
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { DemographicTips } from '../../i18n/tooltips'


class Demographic extends Component {

  categories = [
    { key: "revenue", label: "Revenue" },
    { key: "publisher", label: 'Publisher' }
  ]

  state = {
    activeTab: 0,
    activeTabLabel: "revenue",
    // marks: {},
    labels: []
  }

  onClickTabItem = (tab, index) => {
    // this.setState({ activeTab: tab });
    this.setState({ activeTab: index });
    this.setState({ activeTabLabel: tab.key });

  }

  componentDidMount() {
    let { data } = this.props
    // let marks = this.getSliderMarks()
    let labels = Object.keys(data)
    // let activeTab = labels.length - 1
    let activeTab = 0;
    let activeTabLabel = "revenue";
    this.setState({
      // marks,
      labels,
      activeTab,
      activeTabLabel
    })
  }

  // getSliderMarks = () => {
  //   let { data } = this.props
  //   let labels = Object.keys(data)
  //   return labels.reduce((result, label, index) => {
  //     result[index] = (<ToolTipContainer toolTipText={DemographicTips[label.toLowerCase()]} id={DemographicTips[label.toLowerCase()]}><span style={{ marginLeft: '18px' }}>{capitalize(label)}</span></ToolTipContainer>)
  //     return result
  //   }, {})
  // }

  render() {
    const { activeTab,activeTabLabel, labels } = this.state;

    let { data } = this.props
    return (
      labels.length === 0
        ? ''
        : (
          <div className="card col demographic">
            <div className="row h-100"> 

              <div className="card-title w-100">
                <div className="row align-items-baseline w-100">
                  <div className="col-md-4">Distribution</div>
                  <div className="col-md-8">
                    <ul className="mt-2 mb-3 nav nav-pills">
                      {
                        this.categories.map((category, i) => {
                          return (
                            <li key={i} className="nav-item col pl-1 pr-1" onClick={() => this.onClickTabItem(category, i)}>
                              <button className={`btn btn-sm nav-link tab-selector w-100 ${activeTabLabel === category.key ? 'active btn-primary' : ''}`}>
                                {category.label}
                              </button>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-7 col-7 d-flex align-items-center">
                <DoughnutChart data={data[labels[activeTab]]} height={250} />
              </div>
            </div>
          </div> 
        
        )
    )
  }
}


export default Demographic
