import React, { Component } from "react";
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import Loader from '../../components/Loader'
import { Redirect } from "react-router-dom";
import Tabs from './tabs'
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import { GetSessionValue } from "../../utils/sessionStorage";
class CampaignPreview extends Component {
  state = {
    loginRedirect: false,
    sessionExpiryModal: false,
  }
  componentDidMount() {
    let sessionExpiryModal = false
    let sessionExpired = GetSessionValue('sessionExpired')
    if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
      sessionExpiryModal = true
    }
    this.setState({ isLoading: true, sessionExpiryModal: sessionExpiryModal })
  }
  render() {
    const { isLoading, previewData } = this.props;
    const { loginRedirect } = this.state
    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : null}
        {!previewData || previewData && previewData.length == 0 ? <Redirect to='/user/campaigns/create' /> : null}
        <Loader loader={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0 ">
                    <span>
                      <Icon className="heading-icon ">{'domain'}</Icon>
                    </span>
                    Campaign Template
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="content">
                <Tabs
                />
                {/* session expiry modal */}
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    previewData: state.selectEntities.previewData,

  }),
)(CampaignPreview);
