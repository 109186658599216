import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import moment from 'moment';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StartPauseCampaignApiCall } from '../../services/startPauseCampaignApiCall';
import { getCampaignApiCall } from '../../services/getCampaignApiCall';
import ModalComponent from '../../components/Modal'
import { notificationOpts } from '../../config';
import { Icon } from '@mui/material';

const RangePicker = (data) => {
    console.log(data)
    const dispatch = useDispatch();

    const [rangePicker, setRangePicker] = useState({
        isOpen: false,
        index: null,
        date: [null, null],
        isDialogOpen: false
    });

    const [showDeleteModal, setshowDeleteModal] = useState({
        isOpen: false,
        index: null
    });

    const openClosePicker = (value, type) => {

        if (type) {
            setRangePicker({ ...rangePicker, date: [null, null], index: null, isOpen: value })
        } else
            setRangePicker({ ...rangePicker, isOpen: value })
    }

    const startPauseCampaign = (date) => {
        if (data.campaignDetails) {
            let finalData = JSON.parse(JSON.stringify(data.campaignDetails.pausedDate || []))
            if (!date.includes(null)) {
                if (rangePicker.index || rangePicker.index == 0) {
                    finalData[rangePicker.index] = date.join(" - ")
                } else {
                    finalData.push(date.join(" - "))
                }
                setRangePicker({ date: [null, null], isDialogOpen: false, isOpen: false, index: null })

                apiCall(finalData,"add",rangePicker.date)
            }

        }
        // recurringCampaignapiCall()
    }

    const apiCall = (finalData, from ,newAddedDate) => {
        if(from == "add") {
            newAddedDate = [newAddedDate[0] + " - " + newAddedDate[1]]
        }
        let param = {
            range : finalData,
            action : from,
            newAddedDate : newAddedDate,
            brandName : data.campaignDetails.brandName
        }
        dispatch(StartPauseCampaignApiCall(data.campaignDetails._id, param ,
            (res) => {
                console.log(res);
                showMessage(res.respMessage, "")
                dispatch(getCampaignApiCall());
                // showMessage(res.respMessage, "success")
                // getCampaignList()
            }))
    }


    const editDate = (date, index) => {
        date = date.split("-")
        date = [new Date(date[0]).toString(), new Date(date[1]).toString()]
        setRangePicker({ ...rangePicker, date: date, isOpen: true, index })
    }

    const submitForm = (data) => {
        console.log(data)

        startPauseCampaign(rangePicker.date)
    }

    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    const checkCutoffDate = () => {
        let openingTime = new Date()
        let month = new Date(openingTime.getFullYear(), openingTime.getMonth(), 25)
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth();
        if (new Date() < month) {
            return new Date(y, m + 1, 1);
        } else {
            return new Date(y, m + 2, 1);
        }
    }

    const disableWeekends = (date) => {
        let dates = JSON.parse(JSON.stringify(data.campaignDetails.pausedDate || []))
        let dateArray = []
        dates.forEach((res, index) => {
            if (index != rangePicker.index) {
                let spDate = res.split("-")
                if ((date <= new Date(spDate[1]) && date >= new Date(spDate[0])))
                    dateArray.push(date)
            }
        })
        return dateArray.includes(date)
    }

    const getFormatedDate = (date) => {
        date = date.split("-")
        return `${moment(date[0]).format('DD-MMM-YYYY')} - ${moment(date[1]).format('DD-MMM-YYYY')}`
    }

    const onCloseDeleteModal = () => {
        setshowDeleteModal({ isOpen: false, index: null })
    }

    const handleDeleteCampaign = () => {
        let finalData = JSON.parse(JSON.stringify(data.campaignDetails.pausedDate)) || [];
        let removed = finalData.splice(showDeleteModal.index, 1);
        apiCall(finalData,"delete",removed);
    }

    const deleteDate = (res, index) => {
        setshowDeleteModal({ isOpen: true, index })
    }

    return <>
        <ModalComponent
            key={rangePicker.isDialogOpen}
            close={() => {
                setRangePicker({ ...rangePicker, date: [null, null], isDialogOpen: false })
            }}
            callback={submitForm}
            show={rangePicker.isDialogOpen}
            title={<span>Paused Campaign </span>}
            message={<span>Are you sure you want to pause Campaign between <b>{rangePicker.date.join(" - ")}</b> ?</span>}
            action={"Yes"}
            actionType={'primary'}
        />
        <ModalComponent
            close={onCloseDeleteModal}
            callback={handleDeleteCampaign}
            show={showDeleteModal.isOpen}
            title={"Delete Campaign"}
            message={<span>Are you sure you want to delete?</span>}
            action={"Delete"}
            actionType='danger'
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText="Check-in"
                endText="Check-out"
                clearText="Clear"
                okText="Ok"
                defaultCalendarMonth={checkCutoffDate()}
                allowSameDateSelection={true}
                open={rangePicker.isOpen}
                value={rangePicker.date}
                minDate={checkCutoffDate()}
                onOpen={() => openClosePicker(true)}
                onClose={() => openClosePicker(false)}
                clearable={true}
                shouldDisableDate={disableWeekends}
                InputAdornmentProps={<div className='range-link cursor-pointer d-flex w-100 justify-content-center'
                >
                    Add Range</div>}
                onChange={(newValue, input) => { }}
                onAccept={(newValue, input) => {
                    // let findEmpty = newValue.includes(null)
                    // if (findEmpty)
                    //     setRangePicker({ ...rangePicker, })
                    // else {
                    // submitValue(newValue)
                    if (!newValue.includes(null)) {
                        let date = newValue.map(res => {
                            res = moment(res).format('YYYY/MM/DD').toString()
                            return res
                        })
                        setRangePicker({ ...rangePicker, isDialogOpen: true, date, isOpen: false })
                    }
                    // }
                }}
                renderInput={(startProps, endProps) => {
                    return (
                        <React.Fragment>
                            {(data.campaignDetails.pausedDate || []).map((res1, index) =>
                                <div className='p-2 cursor-pointer d-flex justify-content-center align-items-center' >
                                    <div  >{getFormatedDate(res1)} </div>
                                    <div className='pl-2'>
                                        <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => editDate(res1, index)}>
                                            <Icon style={{ fontSize: "1.5rem" }} className="text-primary">border_color</Icon>
                                        </span>
                                        <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => deleteDate(index)} >
                                            <Icon style={{ fontSize: "1.5rem" }} className="text-danger">delete_outline</Icon>
                                        </span>


                                    </div>
                                </div>
                            )}
                            <div className='range-link cursor-pointer d-flex w-100 justify-content-center'
                                onClick={(e) => openClosePicker(true, "Add Range")} >
                                Add Range</div>
                        </React.Fragment>
                    )
                }}
            />
        </LocalizationProvider>
    </>
}

export default RangePicker