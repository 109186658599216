import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { signupAction } from '../../redux/actions/authActions'

import Signup from '../../components/Signup/Signup.component'
import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from './../../config'

const imgStyle = {
  'display': 'block',
  'height': "9rem",
  'textAlign': "center",
  'margin': '0 auto 0rem auto',
}

class SignupPage extends Component {
  onSubmit = (values, actions) => {
    let { signup, successNotification, history } = this.props
    console.log('values', values)
    signup(values, (res, type) => {
      if (type == 'error') {
        actions.setSubmitting(res)
        actions.setStatus(res)
        return

      } else if (type == 'success') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
        history.push('/')
      }
    })
  }
  render() {
    return (
      <div className="row form justify-content-center">
        <div className="col-sm-12 col-md-8 col-lg-8" >
          <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          <div className="card">
            <div className="header">
              <h3 className="text-center">Sign Up</h3>
            </div>
            <div className="content">
              <Signup
                onSubmit={this.onSubmit}
              />
            </div>
          </div>
          <div className="text-center mb-4">
            Already have an account ? Back to <Link to='/login' className="text-primary">Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    signup: bindActionCreators(signupAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch)
  })
)(SignupPage);

