import React from "react";
import styled from "styled-components";
import HorizontalBarchart from "../Charts/Horizontalchart";
import DistanceSimilarityComponent from "../BottomNavbarTabs/DistanceSimilarityComponent";
const BigContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-left: 10px;
  }
`;

const OverviewComponent = () => {
  return (
    <BigContainer className="d-block">
      <div className="row  ">
        <div className="col-12 col-lg-5 col-md-12 col-sm-12 ">
          <HorizontalBarchart />
        </div>
        <div className="col-12 col-lg-7 col-md-12 col-sm-12 ">
          {/* <ScatterCharts /> */}
          <DistanceSimilarityComponent />
        </div>
      </div>

    </BigContainer>
  );
};

export default React.memo(OverviewComponent);
