import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

const PrivateRouteForGuestUser = ({ userData, component: Component, isAuthenticated, to, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (userData && userData.guestUser && userData.guestUserFlag) ? (
        <Redirect
          to={{
            pathname: to,
            state: { redirect: props.location.pathname, isAuthenticated },
          }}
        />
      ) : (
        <Component {...props} />

      )
    }
  />
);


PrivateRouteForGuestUser.defaultProps = {
  to: '/login',
};

export default connect(
  state => ({
    isAuthenticated: !!state.user
  }),
  null
)(PrivateRouteForGuestUser);