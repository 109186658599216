import { encryptStorage } from './encryptStorage';


export function SetSessionValue(key, value) {
    encryptStorage.setItem(key, value)
    // sessionStorage.setItem(key, JSON.stringify(value))
}

export function GetSessionValue(key) {
    const value = encryptStorage.getItem(key) || undefined;
    // const value = sessionStorage.getItem(key) || undefined;
    if (value) {
        return value;
        // return JSON.parse(value);
    }
    return null;
}

export function DeleteSessionValue(key) {
    encryptStorage.removeItem(key);
}

export function ClearSessionValue() {
    encryptStorage.clear();
}
