import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux'; 
import AmexDining from './AmexDining';

class AMEXPublicPages extends Component {

    render() {
        let { notifications } = this.props
        return (
            <div className="container-fluid overflow-auto h-100 amex-dining-trainComponentBg ">
                <Switch>
                    <Route path="/amexdining" component={AmexDining} />
                </Switch>
                <Notifications
                    notifications={notifications}
                />
            </div>
        );
    }
}

const mapStateToProp = state => ({
    user: state.user,
    notifications: state.notifications
});

export default connect(mapStateToProp)(AMEXPublicPages)

