import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getCampaignApiCall } from '../../services/getCampaignApiCall';
import RangePicker from './RangePicker';
import { campaignSPSelectors } from '../../redux/campaignStartPause';

const CampaignSettings = (props) => {

    const dispatch = useDispatch();

    const CampaignState = useSelector(campaignSPSelectors.getCampaignState)

    const [campaignList, setcampaignList] = useState({
        list: [],
        futureCampaign: {}
    });

    useEffect(() => {
        dispatch(getCampaignApiCall());
    }, []);

    useEffect(() => {
        if (CampaignState.data.length > 0) {
            let { data } = CampaignState
            let futureCampaign = data.filter(res => {
                if ((new Date(res.openingTime) >= new Date())) {
                    return res
                }
            })
            let list = data.filter(res => {
                if (props.campaign.brandId === res.brandId && res.is_primary_campaign) {
                        return res;
                }
                // if (props.campaign.parent_campaign_id) {
                //     if (res._id == props.campaign.parent_campaign_id && res.is_primary_campaign)
                //         return res
                // } else {
                //     if (res._id == props.campaign._id && res.is_primary_campaign)
                //         return res
                // }
            })
            setcampaignList({
                ...campaignList,
                list,
                futureCampaign: futureCampaign.length > 0 ? futureCampaign[0] : {},
            })
        }

    }, [CampaignState.data]);

    return <>
        <div className='campaign-settings'>
            {/* <div className="row create-header">
                <h3 className="col-lg-6 offset-lg-3 col-md-12 text-center py-3">Campaign Settings</h3>
            </div> */}
            {/* <div className="row d-flex justify-content-center">
                
            </div> */}
            <div className="col-12 col-lg-12">
                <div className="card">
                    <div>
                        <table key={new Date().getTime()} className="table table-bordered">

                            <tr>
                                <th scope="col"> Primary Campaign Name</th>
                                <th scope="col"> Discount Percent(%)</th>
                                <th scope="col"> Started On</th>
                                <th scope="col"> Next Campaign</th>
                                <th scope="col text-center"> Paused Duration</th>
                            </tr>
                            {campaignList.list.map(res => {
                                return (
                                    ["Active", "Pending", "Bank Approved"].includes(res.status) && res.is_primary_campaign &&
                                    <tr>
                                        <td className='va-m'>{res.name}</td>
                                        <td className='va-m'>{res.discountPercent ? res.discountPercent + "%" : ""}</td>
                                        <td className='va-m'>{moment(res.openingTime).format('MMM-YYYY')}</td>
                                        <td className='va-m'>{campaignList.futureCampaign ?
                                            moment(campaignList.futureCampaign.openingTime).format('MMM-YYYY') : "-"}</td>
                                        <td className='va-m text-center'>
                                            <RangePicker campaignDetails={res} campaignList={campaignList} />
                                        </td>
                                    </tr>
                                )
                            })}

                        </table>
                    </div>

                </div>
            </div>
        </div>
    </>
}

export default CampaignSettings