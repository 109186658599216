import React from 'react';
import TrainComponent from './TrainComponent'

class TrainComponentOverView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className='trainComponentBg'>
                <TrainComponent
                    formValues={this.props.formValues}
                    submitGuestCampaignData={this.props.submitGuestCampaignData}
                    goToCreateCampaign={this.props.goToCreateCampaign}
                    setAllBranchValue={this.props.setAllBranchValue}
                    allBranch={this.props.allBranch}
                    trainComponentMerchantIds={this.props.trainComponentMerchantIds}
                    handleTrainComponentMerchantIds={this.props.handleTrainComponentMerchantIds}
                    brandData={this.props.brandData}
                />
            </div>
        )
    }
}

export default TrainComponentOverView