import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCampaignOverviewAction, clearCampaignOverviewAction } from '../../redux/actions'

import CampaignOverviewLayout from '../CampaignOverviewLayout'

class CampaignOverviewReportPage extends Component {
  componentDidMount() {
    let { getCampaignOverview, match } = this.props;
    let id = match.params.id || undefined
    getCampaignOverview({id})
  }
  componentDidUpdate(prevProps) {
    let { getCampaignOverview, clearCampaignOverview, match } = this.props;
    let { match : oMatch } = prevProps
    let nId = match.params.id || undefined
    let oId = oMatch.params.id || undefined
    if (nId !== oId) {
      clearCampaignOverview()
      getCampaignOverview({id : nId})
    }
  }
  handleFilterChange = value => {
    let { /*getCampaignOverview,*/ history  } = this.props;
    history.push(`/user/campaigns/reports/${value.id}`)
    // getCampaignOverview({id : value.id})
  }

  getTitle = () => {
    let { campaignOverview } = this.props;
    return `Campaign Overview ${campaignOverview ? '- ' + campaignOverview.name : ''}`
  }
  render() {
    let { campaignOverview } = this.props;
    return (
      <CampaignOverviewLayout 
        campaignOverview={campaignOverview} 
        title={this.getTitle()} 
        handleFilterChange={this.handleFilterChange} 
        displayFilter={false}
      />
    );
  }
}
export default connect(
  state => ({
    campaignOverview : state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview : bindActionCreators(getCampaignOverviewAction, dispatch),
    clearCampaignOverview : bindActionCreators(clearCampaignOverviewAction, dispatch),
  })
)(CampaignOverviewReportPage)




