import React, { Component } from "react";

import { connect } from 'react-redux'
import EntityList from '../EntityList'
import { UserForm } from '../../components/Forms/User'
import { Badge } from 'react-bootstrap'
let languageData = {}
class InvoiceList extends Component {
  tableData = []
  columns = [
     
    // {
    //   dataField: 'brandName',
    //   text: 'Brand Name',
    //   classes: 'vhalign'
    // }, 
    {
      dataField: 'year',
      text: languageData.created ? languageData.created : 'Invoice Month',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (row) {
          let month = row.month < 10 ? "0" + row.month : row.month;
          return `${month}-${row.year}`
        }
      }
    },
    {
      dataField: 'invoiceAmount',
      text: 'Invoice Amount',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (row) {
          return `£${row.invoiceAmount}`
        }
      } 
    }, 
    {
      dataField: 'InvoiceNumber',
      text: 'Invoice Number',
      classes: 'vhalign'
    }, 
    {
      dataField: 'dueDate',
      text: 'Due Date',
      classes: 'vhalign'
    }, 
    {
      dataField: 'paymentStatus',
      text: 'Payment Status',
      classes: 'vhalign'
    }, 
    // {
    //   dataField: 'status',
    //   text: 'Invoice Status',
    //   classes: 'vhalign'
    // },
    {
      dataField: 'actions',
      isDummyField: true,
      text: languageData.actions ? languageData.actions : 'Actions',
      classes: 'vhalign'
    },   
  ]

  render() {
    return <EntityList
      onRef={(ref) => this.usersRef = ref}
      entityType="invoice"
      preset={{ type: 'brand' }}
      prefix=" "
      heading='invoice'
      icon="receipt"
      tableData={this.tableData}
      apiUrl={'distributors/getInvoiceList/invoice'}
      columns={this.columns}
    />
  }
}

export default connect(
  null,
  dispatch => ({
    // getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(InvoiceList);

