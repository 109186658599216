import React, { Component } from 'react'
import { connect } from 'react-redux';
import { CampaignTemplateForm } from '../../components/Forms/CampaignTemplateForm'
import {
    Row, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import Icon from '@material-ui/core/Icon'
import ModalComponent from '../../components/Modal'
import {
    createEntityAction,
    editEntityAction,
    deleteEntityAction,
    createCampaignAction,
    editCampaignAction,
} from '../../redux/actions'
import {
    success as successNotificationAction,
} from 'react-notification-system-redux';
import {
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { bindActionCreators } from 'redux'
import Loader from './../../components/Loader'
import { notificationOpts } from '../../config'
import Scrollbar from 'react-smooth-scrollbar';
import TabImages from '../PreviewCampaign/ImageTabs'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import TnCComponent from './../CreateCampaign/TnC'
import EmailPreview from './EmailTemplate'
import Select from 'react-select';
import config from '../../config/config';
import { GetSessionValue } from '../../utils/sessionStorage';
class CampaignTemplateComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            templateText: '',
            isLoading: false,
            selectedIndex: 0,
            selectedEntity: '',
            showAddNewModal: false,
            showEditModal: false,
            templateDisplayText: '',
            showDeleteModal: false,
            showTCModal: false,
            successCampaign: false,
            selectedEmailTemplate: '',
            selectedSmsTemplate: '',
            selectedAppTemplate: '',
            landing: '',
            valid: '',
            spend: '',
            dynamicFields: [],
            fieldText: {
                sms: {
                    'Valid For': 'one'
                },
                app: {
                    'Valid For': 'one'
                },
                email: {
                    'Valid For': 'one'
                }
            },
            htmlData: {
                value: '',
                item: '',
                index: ''
            },
            validFor: 'one',
            Image1: {},
            Image2: {},
            DistributorName: '& <Name>',
            DistributorDepartment: '<Distributor Department>',
            Logo: {},
            bankOptions: config.bankOptions,
            selectedBank: config.selectedBank,
            promoCodesValue: 'XXXXX',
        }
    }

    //valid until array
    validUntilArray = ['valid until', 'Valid until', 'valid Until', 'Valid Until', 'validuntil', 'Validuntil', 'validUntil', 'ValidUntil']

    componentDidMount = async () => {
        let templates = await this.props.templates;
        if (templates && templates[0]) {
            let filteredTemplates = this.handleTemplateListData()
            this.handlePreviewText(filteredTemplates[0], 0);
        }
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        setTimeout(async () => await this.setState({ selectedIndex: 0 }), 1000)
        // this.dynamicFields(templates[0],0)

        let previewDataDate
        let todaydate = moment().format('DD')
        // if (todaydate > 25) {
        previewDataDate = this.props.previewData && this.props.previewData.start_date ? moment(this.props.previewData.start_date).endOf('month').add(1, 'M').format('MMM DD') : ''
        // } else {
        //     previewDataDate = this.props.previewData && this.props.previewData.start_date ? moment(this.props.previewData.start_date).endOf('month').format('MMM DD') : ''
        // }
        // this.props.previewData && this.props.previewData.start_date ? moment(this.props.previewData.start_date).endOf('month').format('MMM DD') : ''
        let { initialData, type } = this.props;
        let fieldText = this.state.fieldText
        let values = {}
        values['valid'] = initialData && initialData[type] && initialData[type]['valid'] ? initialData[type]['valid'] : previewDataDate
        values['valid Until'] = initialData && initialData[type] && initialData[type]['valid Until'] ? initialData[type]['valid Until'] : previewDataDate
        values['Valid Until'] = initialData && initialData[type] && initialData[type]['Valid Until'] ? initialData[type]['Valid Until'] : previewDataDate
        values['Valid until'] = initialData && initialData[type] && initialData[type]['Valid until'] ? initialData[type]['Valid until'] : previewDataDate
        values['Valid'] = initialData && initialData[type] && initialData[type]['Valid'] ? initialData[type]['Valid'] : previewDataDate
        values['bank'] = initialData && initialData[type] && initialData[type]['bank'] ? initialData[type]['bank'] : this.state.selectedBank.value
        values['Bank'] = initialData && initialData[type] && initialData[type]['Bank'] ? initialData[type]['Bank'] : this.state.selectedBank.value
        values['Valid For'] = initialData && initialData[type] && initialData[type]['Valid For'] ? initialData[type]['Valid For'] : 'one'
        fieldText['sms'] = values
        fieldText['email'] = values
        fieldText['app'] = values
        this.setState({ fieldText: fieldText })
    }

    submitForm = () => {
        if (this.formik)
            this.formik.submitForm()
    }

    formikRef = (e) => {
        this.formik = e
    }

    //to get dynamic values for each template
    dynamicFields = async (item, index) => {
        if (item && item.campaignTemplateText) {
            let campaignText = item.campaignTemplateText
            var reBrackets = /\&lt;(.*?)\&gt;/g;
            var listOfText = [];
            var found;
            let { templates, dynamicFields, type, dynamicFieldHandling, initialData } = this.props
            while (found = reBrackets.exec(campaignText)) {
                if (((found[1].toLowerCase()) != 'discount') && ((found[1].toLowerCase()) != 'business') && ((found[1].toLowerCase()) != 'business name' && ((found[1].toLowerCase()) != 'discount%') && ((found[1].toLowerCase()) != 'discount%') && ((found[1].toLowerCase()) != 'discount%') && ((found[1].toLowerCase()) != 'discount %') && ((found[1].toLowerCase()) != 'discount(aed)') && ((found[1].toLowerCase()) != 'discount (aed)') && ((found[1].toLowerCase()) != 'minimum spend') && ((found[1].toLowerCase()) != 'spend') && ((found[1].toLowerCase()) != 'bank') && ((found[1].toLowerCase()) != 'valid for') && ((found[1].toLowerCase()) != 'landing page'))) {
                    let fileName = null;
                    if (initialData && initialData[type] && found && found[1] && initialData[type][found[1]] && typeof (initialData[type][found[1]]) == 'string') {
                        let string = initialData[type][found[1]];
                        let stringArray = string.split('/')
                        fileName = stringArray[stringArray.length - 1]
                    } else {

                    }
                    listOfText.push({ fieldName: found[1], fileName: fileName });
                }

            };
            let fields = []

            let filteredTemplates = this.handleTemplateListData()
            fields = dynamicFields[type]['list']
            filteredTemplates.forEach((_item, _index) => {
                if (!fields[_index]) {
                    fields[_index] = {}
                }
            })
            let validForFieldFound = false
            listOfText.forEach((_item, _index) => {
                let fieldName = _item.fieldName.toLowerCase()
                if (fieldName == 'valid for' || fieldName == 'validfor') {
                    validForFieldFound = true
                }
            })
            if (!validForFieldFound) {
                listOfText.push({ fieldName: 'Valid For', fileName: undefined })
            }
            fields[index] = listOfText
            await dynamicFieldHandling(type, fields, index)
        }
    }

    //for setting inititial text to handle
    setInitialPreviewText = (value) => {
        let { templates } = this.props
        if (value && value.campaignTemplateText) {
            this.setState({ templateDisplayText: value.campaignTemplateText })
        }
    }

    formikRef = (e) => {
        this.formik = e
    }

    submit = () => {
        if (this.formik)
            this.formik.submitForm()
    }

    //templte field values submission
    submitValues = (values) => {
        // let { templateFieldValues } = this.props
        let { previewData, user, campaignSubmit } = this.props
        let data = this.getDataForCampaignSubmit(previewData)
        if (previewData && previewData.type) {
            data.type = previewData.type
        } else if (previewData && previewData.merchantIds) {
            data.merchantIds = previewData.merchantIds
        }
        if (previewData && previewData._id) {
            data._id = previewData._id
        }
        if (user && user.isTAndCAccepted) {
            campaignSubmit(data)
        } else {
            this.openTcModal()
        }
        // 
    }

    // to displagy prevuew text
    handlePreviewText = async (item, index, type) => {
        let loginCredentials = GetSessionValue('loginCredentials')
        let brandname = '';
        if (loginCredentials && loginCredentials.brandName) {
            brandname = loginCredentials.brandName;
        }

        this.dynamicFields(item, index)
        let { previewData, templates, selectedTemplateData, fields, dynamicFields, promoCodes, promoCodesMultiple, initialData } = this.props
        let { fieldText, selectedIndex, htmlData, valid, selectedBank } = this.state;
        let { value } = htmlData
        let userDetails = GetSessionValue('user')
        let filteredTemplates = this.handleTemplateListData()
        if (type && filteredTemplates && filteredTemplates.length) {
            item = filteredTemplates[0]
            index = 0
        } else if (type && filteredTemplates && filteredTemplates.length == 0) {
            return
        }
        let templateData = {}
        this.setState({ selectedIndex: index })
        let previewText
        let splittedArray = []
        let modifiedItem
        //date
        let date
        // Icons
        let icon1 = `${config.imgUrl}${config.Icon1}`
        let icon2 = `${config.imgUrl}${config.Icon2}`
        let icon3 = `${config.imgUrl}${config.Icon3}`
        let icon4 = `${config.imgUrl}${config.Icon4}`
        let todaydate = moment().format('DD')
        if (valid) {
            date = valid
        } else {
            // moment(cell).format('DD/MM/YYYY')
            // if (todaydate > 25) {
            date = previewData && previewData.start_date ? moment(previewData.start_date).endOf('month').add(1, 'M').format('MMM DD') : ''

            // } else {
            //     date = previewData && previewData.start_date ? moment(previewData.start_date).endOf('month').format('MMM DD') : ''
            // }

        }
        this.setState({ valid: date })
        if (item && item.campaignTemplateText) {
            modifiedItem = item
            previewText = item.campaignTemplateText;


            previewText = previewText.replace('&lt;Discount (AED)&gt;', `${previewData && previewData.discountValue ? previewData.discountValue : '##'} (AED)`)
            previewText = previewText.replace('&lt;Discount%&gt;', `${previewData && previewData.discountPercent ? previewData.discountPercent : '##'} %`)
            previewText = previewText.replace('&lt;Business Name&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;business name&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;Business&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;business&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Minimum Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;minimum spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;minimum Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Valid Until&gt;', `<strong>${initialData && initialData[this.props.type] && initialData[this.props.type]['Valid Until'] ? initialData[this.props.type]['Valid Until'] : date}</strong>`)
            previewText = previewText.replace('&lt;valid until&gt;', `<strong>${initialData && initialData[this.props.type] && initialData[this.props.type]['valid until'] ? initialData[this.props.type]['valid until'] : date}</strong>`)
            previewText = previewText.replace('&lt;Valid&gt;', `<strong>${initialData && initialData[this.props.type] && initialData[this.props.type]['Valid'] ? initialData[this.props.type]['Valid'] : date}</strong>`)
            previewText = previewText.replace('&lt;valid Until&gt;', `<strong>${initialData && initialData[this.props.type] && initialData[this.props.type]['valid Until'] ? initialData[this.props.type]['valid Until'] : date}</strong>`)
            previewText = previewText.replace('&lt;Valid until&gt;', `<strong>${initialData && initialData[this.props.type] && initialData[this.props.type]['Valid until'] ? initialData[this.props.type]['Valid until'] : date}</strong>`)
            previewText = previewText.replace('&lt;bank&gt;', `<strong>${selectedBank.value}</strong>`)
            previewText = previewText.replace('&lt;Bank&gt;', `<strong>${selectedBank.value}</strong>`)
            previewText = previewText.replace('[INSERT COMPANY].', `<strong>${brandname}</strong>`)

            // previewText = previewText.replace('&lt;[ENTER PROMO CODE]&gt;', `<strong>${this.state.promoCodesValue}</strong>`)
            //replacing Icons in emsil tab
            previewText = previewText.replace('Icon1', icon1)
            previewText = previewText.replace('Icon2', icon2)
            previewText = previewText.replace('Icon3', icon3)
            previewText = previewText.replace('Icon4', icon4)
            // if (promoCodes && promoCodes.length != 0) {
            //     previewText = previewText.replace('[ENTER PROMO CODE]', 'XXXXX')
            // }
            let dynamicFieldValues = dynamicFields[this.props.type]['list'][selectedIndex]
            if (dynamicFieldValues && dynamicFieldValues.length) {
                dynamicFieldValues.forEach((_item, _index) => {
                    if (this.previewText)
                        if (fields && fields[this.props.type] && fields[this.props.type][_item]) {
                            if (previewText.includes(`&lt;${_item}&gt;`)) {
                                previewText = previewText.replace(`&lt;${_item}&gt;`, fields[this.props.type][_item])
                            }
                        }
                })
            }
            if (value && value.name) {
                let fieldName = value.name
                if (fieldText[this.props.type][fieldName.split('.')[1]]) {
                    previewText = previewText.replace(`&lt;${fieldName.split('.')[1]}&gt;`, value.value)
                } else {
                    previewText = previewText.replace(fieldText[this.props.type][fieldName.split('.')[1]], value.value)
                }
            }
            templateData = {
                preview: previewText,
                type: item.type,
                templateId: item._id
            }
            await this.setState({ templateDisplayText: previewText })
            if (item.type == 'app') {
                selectedTemplateData('selectedAppTemplate', templateData)
                await this.setState({ selectedAppTemplate: templateData })
            } else if (item.type == 'sms') {
                selectedTemplateData('selectedSmsTemplate', templateData)
                await this.setState({ selectedSmsTemplate: templateData })
            } else if (item.type == 'email') {
                selectedTemplateData('selectedEmailTemplate', templateData)
                await this.setState({ selectedEmailTemplate: templateData })
            }
        }
    }

    //succes response
    getNotificationMessage = data => {
        let { campaignForEdit } = this.props;
        if (data._id) {
            return `Campaign "${data.name}" updated successfully`
        } else {
            return `Campaign "${data.name}" has been created successfully`
        }
    }

    // hanling terms and condition modal
    handleTCResponse = () => {
        this.setState({ showTCModal: false, showPreviewModal: false }, () => {
            //   this.submitCreateCampaign()
            let { previewData } = this.props
            let data = this.getDataForCampaignSubmit(previewData)
            if (previewData && previewData.type) {
                data.type = previewData.type
            } else if (previewData && previewData.merchantIds) {
                data.merchantIds = previewData.merchantIds
            }
            if (previewData && previewData._id) {
                data._id = previewData._id
            }
            let { campaignSubmit } = this.props;
            campaignSubmit(data)
        })
    }

    //open terms and condition modal on submit
    openTcModal = () => {

        this.setState({ showTCModal: true });
    }

    //close terms and condition modal on submit
    onCloseTCModal = () => {
        this.setState({ showTCModal: false });
    }

    // handling campaign submission data
    getDataForCampaignSubmit = (campaignFormData) => {
        let {
            customerType,
            lastTransaction,
            name,
            start_date,
            successFee,
            discountType,
            discountPercent,
            discountAmount,
            discountValue,
            discountSpreadAmount,
            discountSpreadValue,
            brand_id,
            brandId,
            suggestions,
            spread_avg_spend,
            recurring,
            promoCodesMultiple,
            promocodes,
            // smstemplate,
        } = campaignFormData
        // let {
        //     expectedReach,
        //     expectedConversion,
        //     expectedCost,
        //     expectedRevenue,
        //     cpa,
        //     spread_avg_spend
        // } = campaignSummay
        let { campaignForEdit } = this.props
        let currentBid = {
            name: name,
            customerType: customerType === "existing" ? 'existing' : 'new',
            openingTime: new Date(moment(start_date).startOf('month').toISOString()),
            closingTime: new Date(moment(start_date).endOf('month').toISOString()),
            name,
            campaign_status: 'future',
            successFee: parseInt(successFee),
            discountType,
            // smstemplate,
            suggestions,
            recurring
        }
        switch (discountType) {
            case 'dp': {
                currentBid['discountPercent'] = parseInt(discountPercent)
                break;
            }
            case 'dv': {
                currentBid['discountAmount'] = parseFloat(discountAmount)
                currentBid['discountValue'] = parseFloat(discountValue)
                break
            }
            case 'ss': {
                currentBid['discountSpreadAmount'] = parseInt(discountSpreadAmount)
                currentBid['discountSpreadValue'] = parseInt(discountSpreadValue)
                currentBid['spreadAvgSpend'] = parseFloat(spread_avg_spend)
                break
            }
            default: { }
        }
        if (campaignForEdit) {
            currentBid._id = campaignForEdit._id
        }
        if (customerType === 'existing') {
            currentBid.lastTransaction = parseInt(lastTransaction);
        } else {
            currentBid.lastTransaction = 'NULL';
        }
        if (brand_id)
            currentBid.brand_id = brand_id.id
        if (brandId)
            currentBid.brandId = brandId
        if (discountType)
            currentBid.discountType = discountType
        return currentBid;
    }

    // Declared previewText 
    previewText = ''

    // To Display string of html tags 
    getHtmlValue = () => {
        let loginCredentials = GetSessionValue('loginCredentials')
        let brandname = '';
        if (loginCredentials && loginCredentials.brandName) {
            brandname = loginCredentials.brandName;
        }
        let previewText = this.state.templateDisplayText
        // previewText = 'hiii'
        let { templates, type, previewData, dynamicFields, fields, promoCodes, promoCodesMultiple, initialData, promocodeType } = this.props
        let { selectedIndex, htmlData, fieldText, valid, selectedBank, promoCodesValue } = this.state
        let { value, item, index } = htmlData
        let userDetails = GetSessionValue('user')
        let filteredTemplates = this.handleTemplateListData()
        if (filteredTemplates && filteredTemplates.length && filteredTemplates[selectedIndex] && filteredTemplates[selectedIndex].campaignTemplateText) {
            if (!previewText) {
                previewText = filteredTemplates[selectedIndex].campaignTemplateText
            }
            //date
            let date
            // Icons
            let icon1 = `${config.imgUrl}${config.Icon1}`
            let icon2 = `${config.imgUrl}${config.Icon2}`
            let icon3 = `${config.imgUrl}${config.Icon3}`
            let icon4 = `${config.imgUrl}${config.Icon4}`
            let validUntilArray = this.validUntilArray
            let todaydate = moment().format('DD')
            if (valid) {
                date = valid
            } else {
                // moment(cell).format('DD/MM/YYYY')
                // if (todaydate > 25) {
                date = previewData && previewData.start_date ? moment(previewData.start_date).endOf('month').add(1, 'M').format('MMM DD') : ''
                // } else {
                // date = previewData && previewData.start_date ? moment(previewData.start_date).endOf('month').format('MMM DD') : ''
                // }
            }
            //repalcing fixed field values in template
            previewText = previewText.replace('&lt;Discount (AED)&gt;', `${previewData && previewData.discountValue ? previewData.discountValue : '##'} (AED)`)
            previewText = previewText.replace('&lt;Discount%&gt;', `${previewData && previewData.discountPercent ? previewData.discountPercent : '##'} %`)
            previewText = previewText.replace('&lt;Discount %&gt;', `${previewData && previewData.discountPercent ? previewData.discountPercent : '##'} %`)
            previewText = previewText.replace('&lt;Business Name&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;business name&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;Business&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;business&gt;', userDetails && userDetails.brandName ? userDetails.brandName : '')
            previewText = previewText.replace('&lt;spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Minimum Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;minimum spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;minimum Spend&gt;', `${previewData && previewData.discountAmount ? previewData.discountAmount : 0} `)
            previewText = previewText.replace('&lt;Valid Until&gt;', `<strong>${date}</strong>`)
            previewText = previewText.replace('&lt;valid until&gt;', `<strong>${date}</strong>`)
            previewText = previewText.replace('&lt;Valid&gt;', `<strong>${date}</strong>`)
            previewText = previewText.replace('&lt;valid Until&gt;', `<strong>${date}</strong>`)
            previewText = previewText.replace('&lt;Valid until&gt;', `<strong>${date}</strong>`)
            previewText = previewText.replace('[INSERT COMPANY]', `<strong>${brandname}</strong>`)
            //promocodes
            if (this.state.promoCodesValue !== 'XXXXX' && !promoCodesMultiple) {
                previewText = previewText.replace('[ENTER PROMO CODE]', `<strong>${this.state.promoCodesValue}</strong>`)
            }
            if (promoCodesMultiple && promoCodesMultiple.length != 0) {
                previewText = previewText.replace('[ENTER PROMO CODE]', `<strong>XXXXXXXXX</strong>`)
            }
            if (promocodeType == 'single') {
                previewText = previewText.replace('XXXXXXXXX', initialData[type]['promocodes'])
            }
            //replacing valid for value
            if (initialData && initialData[type] && (initialData[type]['validfor'] || initialData[type]['validFor'] || initialData[type]['valid For'] || initialData[type]['Valid For'] || initialData[type]['Valid for'] || initialData[type]['validfor'])) {
                let validFor = initialData[type]['valid for'] || initialData[type]['Valid for'] || initialData[type]['Valid For'] || initialData[type]['valid For'] || initialData[type]['validFor'] || initialData[type]['validfor']
                previewText = previewText.replace('&lt;valid for&gt;', validFor)
                previewText = previewText.replace('&lt;Valid for&gt;', validFor)
                previewText = previewText.replace('&lt;Valid For&gt;', validFor)
                previewText = previewText.replace('&lt;valid For&gt;', validFor)
                previewText = previewText.replace('&lt;validFor&gt;', validFor)
                previewText = previewText.replace('&lt;validfor&gt;', validFor)
                previewText = previewText.replace('&lt;valid for&gt;', validFor)
                previewText = previewText.replace('&lt;Valid for&gt;', validFor)
                previewText = previewText.replace('&lt;Valid For&gt;', validFor)
                previewText = previewText.replace('&lt;valid For&gt;', validFor)
                previewText = previewText.replace('&lt;validFor&gt;', validFor)
                previewText = previewText.replace('&lt;validfor&gt;', validFor)
            }
            if (initialData && initialData[type] && (initialData[type]['validuntil'] || initialData[type]['validUntil'] || initialData[type]['ValidUntil'] || initialData[type]['Validuntil'] || initialData[type]['Valid Until'] || initialData[type]['valid until'] || initialData[type]['Valid until'] || initialData[type]['valid Until'])) {
                let validUntil = initialData[type]['Valid Until'] || initialData[type]['Valid until'] || initialData[type]['valid Until'] || initialData[type]['valid until'] || initialData[type]['validuntil'] || initialData[type]['Validuntil'] || initialData[type]['validUntil'] || initialData[type]['ValidUntil']
                if (fieldText && fieldText[type]) {
                    validUntilArray.forEach((_item, _index) => {
                        previewText = previewText.replace(fieldText[type][_item], validUntil)
                    })
                }
            }
            //repalcing dynamic field values in template
            let dynamicFieldValues = dynamicFields[type]['list'][selectedIndex]
            if (dynamicFieldValues && dynamicFieldValues.length) {
                dynamicFieldValues.forEach((_item, _index) => {
                    if (this.previewText)
                        if (fields && fields[type] && fields[type][_item]) {
                            if (previewText.includes(`&lt;${_item}&gt;`)) {
                                previewText = previewText.replace(`&lt;${_item}&gt;`, `<strong>${fields[type][_item]}</strong>`)
                            } else {
                                previewText = previewText.replace(`&lt;${_item}&gt;`, `<strong>${fields[type][_item]}</strong>`)
                            }
                        }
                })
            }
            //replacing onblur values
            if (value && value.name) {
                let fieldName = value.name
                if (fieldText[type][fieldName.split('.')[1]]) {
                    previewText = previewText.replace(`&lt;${fieldName.split('.')[1]}&gt;`, `<strong>${value.value}</strong>`)
                } else {
                    previewText = previewText.replace(fieldText[type][fieldName.split('.')[1]], `<strong>${value.value}</strong>`)
                }
            }
            if (fieldText && fieldText[type] && fieldText[type]['bank']) {
                previewText = previewText.replace(fieldText[type]['bank'], selectedBank.value)
            }
            if (fieldText && fieldText[type] && fieldText[type]['Bank']) {
                previewText = previewText.replace(fieldText[type]['Bank'], selectedBank.value)
            }
            //replacing Icons in emsil tab
            previewText = previewText.replace('Icon1', icon1)
            previewText = previewText.replace('Icon2', icon2)
            previewText = previewText.replace('Icon3', icon3)
            previewText = previewText.replace('Icon4', icon4)
            //replacing Top and bottom Images in preview
            if (initialData && initialData[type]) {
                if (initialData[type]['Top Image']) {
                    previewText = previewText.replace('&lt;Top Image&gt;', initialData[type]['Top Image'])
                } if (initialData[type]['Bottom Image']) {
                    previewText = previewText.replace('&lt;Bottom Image&gt;', initialData[type]['Bottom Image'])
                }
            }
            if (initialData && initialData[type] && initialData[type]['Valid For']) {
                if (initialData[type]['Valid For'] != 'unlimited') {
                    previewText = previewText.replace('div1', 'd-none')
                } else {
                    previewText = previewText.replace('div2', 'd-none')
                }
            }
            this.previewText = previewText
            return { __html: previewText }
        }
    }

    //to bind dynamic field values in templates
    handleChange = async (item, index, value) => {
        console.log('value', value)
        let validDate
        let htmlData = {
            item: item,
            index: index,
            value: value
        }
        await this.setState({ htmlData: htmlData })
        let templateDisplayText = this.state.templateDisplayText
        let { spend, valid, landing, fieldText, selectedBank } = this.state
        let { previewData, selectedTemplateData, type, templateFieldValues, promocodeType } = this.props
        if (value.name == `${this.props.type}.promocodes`) {
            if (promocodeType == 'single') {
                await this.setState({ promoCodesValue: value['value'] })
            } else {
                await this.setState({ promoCodesValue: 'XXXXXXXXXX' })
            }
        }
        // if (value.name == 'promoCodesMultiple') {
        //     await this.setState({ promoCodesValue: 'XXXXXXXXXX' })
        // }
        if (value && value.name) {
            let fieldName = value.name
            let fieldValue = value.value
            let field = value.name.split('.')
            let lowerCaseFieldName = fieldName.toLowerCase()
            if (!fieldText[type][fieldName.split('.')[1]]) {
                templateDisplayText = templateDisplayText.replace(`&lt;${fieldName.split('.')[1]}&gt;`, fieldValue && typeof (fieldValue) == 'string' && !fieldValue.includes(config.imgUrl) ? `<strong>${fieldValue}</strong>` : fieldValue) //`<strong>${fieldValue}</strong>`
            } else {
                templateDisplayText = templateDisplayText.replace(fieldText[type][fieldName.split('.')[1]], fieldValue && typeof (fieldValue) == 'string' && !fieldValue.includes(config.imgUrl) ? `<strong>${fieldValue}</strong>` : fieldValue)
            }
            fieldText[type][fieldName.split('.')[1]] = fieldValue
            this.setState({ fieldText: fieldText })
            templateFieldValues(fieldText, type)
        } else if (value && value.label && value.value) {

            if (!fieldText[type]['bank']) {
                templateDisplayText = templateDisplayText.replace(`&lt;bank&gt;`, selectedBank.value)
                fieldText[type]['bank'] = selectedBank.value
            } else {
                templateDisplayText = templateDisplayText.replace(fieldText[type]['bank'], selectedBank.value)
                fieldText[type]['bank'] = selectedBank.value
            }
            if (!fieldText[type]['Bank']) {
                templateDisplayText = templateDisplayText.replace(`&lt;Bank&gt;`, selectedBank.value)
                fieldText[type]['Bank'] = selectedBank.value
            } else {
                templateDisplayText = templateDisplayText.replace(fieldText[type]['Bank'], selectedBank.value)
                fieldText[type]['Bank'] = selectedBank.value
            }
            this.setState({ fieldText: fieldText })
            templateFieldValues(fieldText, type)
        } else if (promocodeType) {
            if (promocodeType == 'single') {

            }
        }
        //date
        let date
        if (valid) {
            date = valid
        } else {
            // moment(cell).format('DD/MM/YYYY')
            date = previewData && previewData.start_date ? moment(previewData.start_date).format('MMM DD') : ''
        }
        if (templateDisplayText) {
            templateDisplayText = templateDisplayText.replace('&lt;Valid Until&gt;', date)
            templateDisplayText = templateDisplayText.replace('&lt;valid until&gt;', date)
            templateDisplayText = templateDisplayText.replace('&lt;Valid&gt;', date)
            templateDisplayText = templateDisplayText.replace('&lt;valid Until&gt;', date)
            templateDisplayText = templateDisplayText.replace('&lt;Valid until&gt;', date)
        }
        let templateData = {
            preview: this.previewText,
            type: type,
            templateId: item && item._id ? item._id : null
        }
        this.setState({ templateDisplayText: templateDisplayText })
        if (type == 'app') {
            selectedTemplateData('selectedAppTemplate', templateData)
            this.setState({ selectedAppTemplate: templateData })
        } else if (type == 'sms') {
            selectedTemplateData('selectedSmsTemplate', templateData)
            this.setState({ selectedSmsTemplate: templateData })
        } else if (type == 'email') {
            selectedTemplateData('selectedEmailTemplate', templateData)
            this.setState({ selectedEmailTemplate: templateData })
        }
    }

    //handle template list data
    handleTemplateListData = () => {
        let { templates, discountValueFound, discountPercentageFound } = this.props;
        let filteredTemplates = []
        if (templates) {
            templates.forEach((item, index) => {
                let campaignTemplateText = (item && item.campaignTemplateText ? item.campaignTemplateText : '').toLowerCase()
                if (discountValueFound) {
                    if (campaignTemplateText.includes('discount (aed)') || campaignTemplateText.includes('discount(aed)')) {
                        filteredTemplates.push(item)
                    }
                } else if (discountPercentageFound) {
                    if (campaignTemplateText.includes('discount %') || campaignTemplateText.includes('discount%')) {
                        filteredTemplates.push(item)
                    }
                }
            })
        }
        return filteredTemplates
    }
    render() {
        let { status, isSubmitting, values, handleChange, handleBlur, options, templateData, isLoading, type, templates, previewData, spend, fields, dynamicFields, validCampaignCreation, brandDetails, sample, handlePromoCode } = this.props;
        let { selectedIndex, templateDisplayText, successCampaign, bankOptions, selectedBank } = this.state
        let filteredTemplates = this.handleTemplateListData();
        return (
            <div>
                {successCampaign ? <Redirect to='/user/campaigns/future' /> : null}
                <Row className={'px-5'}>
                    <Loader loader={isLoading} />
                    <Col sm={6} xs={12} className='px-4 pt-4 pb-0'>
                        <h5 style={{ marginBottom: 13 }}><span>Templates</span></h5>
                        <Card className='template__inner_card_1'>

                            {type != 'app' ? <div>
                                <Scrollbar>
                                    <div style={{ minHeight: 200 }}>
                                        {filteredTemplates && filteredTemplates.length ? filteredTemplates.map((item, index) => {
                                            return <div className={index == selectedIndex ? 'selectedCard ' : ''} onClick={e => this.handlePreviewText(item, index)} key={index}>
                                                <p>{item.name}</p>
                                                {/* <div id='ball' dangerouslySetInnerHTML={{ __html: item.campaignTemplateText }} /> */}
                                            </div>
                                        }) : null}
                                    </div>
                                </Scrollbar>
                            </div> :
                                <div className='coming_soon'>
                                    Coming Soon
                                    </div>
                            }
                        </Card>
                        {/* ******************************************************************************************************** */}
                        {/* templats fie;d form */}
                        <h5><span>Campaign Settings</span></h5>
                        <Card className='template__inner_card_3'>

                            <Scrollbar>
                                {type != 'app' ?
                                    <div style={{ overflowX: 'hidden', minHeight: 300 }}>
                                        <CampaignTemplateForm
                                            formikRef={this.formikRef}
                                            onSubmit={this.submitValues}
                                            type={type}
                                            handleBlur={this.handleChange}
                                            previewData={previewData}
                                            spend={spend}
                                            fields={fields}
                                            item={filteredTemplates[selectedIndex]}
                                            index={selectedIndex}
                                            dynamicFields={dynamicFields && dynamicFields[type] && dynamicFields[type]['list'] ? dynamicFields[type]['list'] : null}
                                            // formLoad={this.formLoad}
                                            onRef={ref => this.campaignTemplateFormRef = (ref)}
                                            initialValues={this.initialValues}
                                            handlePromoCode={(type, item, index, value) => {
                                                if (type == 'single') {
                                                    this.handleChange(item, index, {
                                                        name: `${this.props.type}.promocodes`,
                                                        value: this.props.initialData[this.props.type]['promocodes']
                                                    });
                                                    handlePromoCode(type)
                                                } else {
                                                    handlePromoCode(type)
                                                }
                                            }}
                                        />
                                    </div>
                                    :
                                    <div className='coming_soon' style={{ minHeight: 300 }}>
                                        Coming Soon
                                    </div>
                                }


                            </Scrollbar>
                        </Card>
                    </Col>
                    <Col sm={6} xs={12} className='px-0  pb-0'>
                        {/* to disply preview of selected Template */}
                        <div
                            className='d-lg-flex justify-content-between pt-2'
                        // style={{ height: 36 }}
                        >

                            <h5 className='mb-0 mt-3'>Preview</h5>
                            <div
                                style={{ width: '23%' }}
                                className='selectBank'
                            >
                                <Select
                                    options={bankOptions}
                                    onChange={value => {
                                        this.setState({ selectedBank: value })
                                        this.handleChange(null, null, value)
                                    }}
                                    value={selectedBank}
                                />
                            </div>
                        </div>

                        <Card className={`template__inner_card_2 ${type == 'email' ? 'p-0' : ''}`} >

                            <div className='preview_card_col'>
                                {type == 'app' ?
                                    <div className='preview_card_text' >
                                        <div className='coming_soon'>
                                            Coming Soon
                  </div>
                                    </div> : null



                                }
                                {type == 'sms' ? <div className='preview_card_text' style={type == 'sms' ? { borderStyle: 'none', textAlign: 'left' } : {}}>
                                    <span id={selectedIndex} dangerouslySetInnerHTML={this.getHtmlValue()} />
                                </div> : null}
                                {type == 'email' ? <Scrollbar>
                                    <div id={selectedIndex} dangerouslySetInnerHTML={this.getHtmlValue()} style={{ height: 612, padding: 16 }} />
                                </Scrollbar> : null}
                            </div>

                        </Card>
                    </Col>
                </Row>
                <Row className='justify-content-end px-5 my-3'>
                    {validCampaignCreation != null && !validCampaignCreation && <div className='pr-2'>
                        <label className='text-danger pt-2'>Please Select atleast two Images</label>
                    </div>}
                    <div className='pr-2'>
                        <Link className="btn btn-primary" style={{ verticalAlign: 'bottom' }} to={{ pathname: `/user/campaigns/${previewData && previewData._id ? 'edit' : 'create'}`, isPreview: true }}>Back</Link>
                    </div>

                    <div>
                        <button className="btn btn-primary" style={{ verticalAlign: 'bottom' }} onClick={this.submit}>Submit</button>
                    </div>
                </Row>
                <ModalComponent close={this.onCloseTCModal}
                    callback={this.handleTCResponse}
                    show={this.state.showTCModal}
                    title={""}
                    size="lg"
                    message={<TnCComponent />}
                    action={"Accept"}
                />
            </div>
        )
    }
}
export const CampaignTemplateTabComponent = connect(
    state => ({
        banks: state.selectEntities.banks,
        brandDetails: state.selectEntities.brands,
        templates: state.selectEntities.campaignTemplates,
        previewData: state.selectEntities.previewData,
        promoCodesMultiple: state.selectEntities.promocodesCSVMULTIPLE,
        promoCodes: state.selectEntities.promocodesCSV,
        initialData: state.selectEntities.initialData,
        user: state.user
    }),
    (dispatch, ownProps) => ({
        createEntity: bindActionCreators(createEntityAction, dispatch),
        editEntity: bindActionCreators(editEntityAction('template'), dispatch),
        deleteEntity: bindActionCreators(deleteEntityAction, dispatch),
        createCampaign: bindActionCreators(createCampaignAction, dispatch),
        editCampaign: bindActionCreators(editCampaignAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    })
)(CampaignTemplateComponent)
