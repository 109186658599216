import axiosMethodRequest from '../config/service';
import { GET_BRANCH_DATA } from '../redux/insights/actionTypes'
export const getBranchDataAPICall = (brandId) => {
    return dispatch => {
        console.log('brandId', brandId);
        dispatch({
            type: GET_BRANCH_DATA,
            payload: { data: null, loader: true }
        })

        const url = `reviewsdata/branchdetails?brandId=${brandId}`
        return axiosMethodRequest('GET', url).then((response) => {
            console.log('GET_BRANCH_DATA response', response);
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_BRANCH_DATA,
                    // type: GET_REVIEW_DATA,
                    //payload: { data: response.data.insightsDetails, loader: false }
                    payload: { data: response.data, loader: false }

                })
            }
        })
    }
}
