import React, { Component } from "react";

import { connect } from 'react-redux'
import EntityList from '../EntityList'
import { MerchantForm } from '../../components/Forms/Merchant'
import { MerchantCommentsForm } from '../../components/Forms/MerchantComments'
import { UploadForm } from '../../components/Forms/FileUpload'
import { Badge } from "react-bootstrap";
class Merchants extends Component {
  tableData = []
  // Branch Name, Brand Name, Merchant Id, Status
  columns = [
    {
      dataField: 'checkBox',
      isDummyField: true,
      text: this.props.languageData.select ? this.props.languageData.select : 'Select',
      // style: { whiteSpace: 'nowrap' ,textAlign:'center',paddingTop:12}
      classes: 'vhalign'
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: this.props.languageData.actions ? this.props.languageData.actions : 'Actions',
      style: { whiteSpace: 'nowrap', textAlign: 'center' },
      classes: 'vhalign'
    },
    {
      dataField: 'name',
      sort: true,
      text: this.props.languageData.branchName ? this.props.languageData.branchName : 'Branch Name',
      classes: 'vhalign'
    },

    {
      dataField: 'merchantId',
      sort: true,
      // text: languageData.merchantId?languageData.merchantId:'Merchant ID',
      text: 'Merchant ID',
      classes: 'vhalign'
    },
    {
      dataField: 'tripadvisorUrl',
      sort: true,
      // text: languageData.merchantId?languageData.merchantId:'Merchant ID',
      text: 'Tripadvisor Link',
      classes: 'vhalign'
    },
    // {
    //   dataField: 'userName',
    //   sort: true,
    //   text: 'User Name',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'contactName',
    //   sort: true,
    //   text: languageData.contactName?languageData.contactName:'Contact Name',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'activatedDate',
    //   sort: true,
    //   text: languageData.activatedDate?languageData.activatedDate:'Activated Date',
    //   classes: 'vhalign',
    //   formatter: (col, row) => {
    //    if(col){
    //     let date = new Date(col);
    //     // adjust 0 before single digit date
    //     let dd = ("0" + date.getDate()).slice(-2);
    //     // current month
    //     let mm = ("0" + (date.getMonth() + 1)).slice(-2); 
    //     // current year
    //     let yy = date.getFullYear();
    //    return `${dd}/${mm}/${yy}`
    //    }
    //   }
    // },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col == 'Approved') {
          return <Badge
            className='approvedBadge'
          // style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Rejected') {
          return <Badge
            className='rejectedBadge'
          // style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Pending') {
          return <Badge
            className='pendingBadge'
          // style={{ color: 'white', background: 'orange', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Deleted') {
          return <Badge
            className='deletedBadge'
          // style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Updated') {
          return <Badge
            className='updatedBadge'
          // style={{ color: 'white', background: 'yellow', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        }

      }

    },
  ]
  viewFields = [
    { name: 'name', label: 'Branch Name' },
    { name: 'merchantId', label: 'Merchant ID' },
    { name: 'block', label: 'Block Name' },
    { name: 'contactName', label: 'Contact Name' },
    { name: 'contactPhone', label: 'Contact Phone' },
    { name: 'contactEmail', label: 'Contact Email' },
    { name: 'address', label: 'Address' },
    { name: 'brandName', label: 'Brand Name' },
    { name: 'brandId', label: 'Brand Id' },
    { name: 'created', label: 'Created' },
    { name: 'activatedDate', label: 'Activated Date' },
    { name: 'comments', label: 'Comments' },
    { name: 'approvedBy', label: 'Approved By' },
    { name: 'status', label: 'Status' },
    { name: 'tripadvisorUrl', label: 'Tripadvisor Link' }
  ]
  render() {
    return <EntityList
      onRef={(ref) => this.usersRef = ref}
      entityType="user"
      preset={{ type: 'brand' }}
      // prefix="Merchants"
      heading="branch"
      icon="domain"
      EntityForm={MerchantForm}
      tableData={this.tableData}
      apiUrl="merchants"
      globalSearchPlaceHolder={'Search Branch Name / Merchant ID'}
      // onSubmit={this.submit}
      columns={this.columns}
      showPasswordReset={true}
      uploadCSV={true}
      EntityUploadForm={UploadForm}
      CommentsForm={MerchantCommentsForm}
      viewFields={this.viewFields}
    />
  }
}

export default connect(
  state => ({
    languageData: state.languages
  }),
  dispatch => ({
    // getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Merchants);