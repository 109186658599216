import styled from "styled-components"
// .container{

// }
// h4{
//   font-size:1rem;
//   font-weight:800;
// }
// .selectbar{
//   display:flex;
//   justify-content: flex-end;
   
// }
// select {
//   background-color: white;
//   display: inline-block;
//   font: inherit;
//   line-height: 1.5em;
//   padding: 0.5em 3.5em 0.5em 1em; 

//   /* reset */

//    margin-top: 10px;
//   margin-right:5px;
//   margin-left:8px;
//    width:13em;      
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   -webkit-appearance: none;
//   -moz-appearance: none;

// }

// select.classic{
//   background-image:
//     linear-gradient(45deg, transparent 50%, white 50%),
//     linear-gradient(135deg, white 50%, transparent 50%),
//     linear-gradient(to right, black, black);
//   background-position:
//     calc(100% - 20px) calc(1em + 2px),
//     calc(100% - 15px) calc(1em + 2px),
//     100% 0;
//   background-size:
//     5px 5px,
//     5px 5px,
//     3.5em 2.5em;
//   background-repeat: no-repeat;
// }
export const Container = styled.div`
h4{
  font-size:1rem;
  font-weight:800;
}
` 
 export const SelectBar = styled.div`
 display:flex;
 justify-content: flex-end;
 
& select{
  /* styling */
   background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em; 

  /* reset */

   margin-top: 10px;
  margin-right:5px;
  margin-left:8px;
   width:13em;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

 ` 
 
  export const ClassN = styled.div`
& select.classic{
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, black, black);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    3.5em 2.5em;
  background-repeat: no-repeat;
}
 ` 


/* .wj-flexchart {
    height: 600px;
    width: 600px;
    margin: 10px 0;
}
.wj-flexchart a{
    visibility: hidden;
}
.fusioncharts-smartlabel-container a {
        visibility: hidden;
}
.container-fluid a{
    visibility: hidden;

}

body {
    margin-bottom: 24pt;
} */
/* // .container-fluid{
//     width:100px;
//     height: 50px;
// }
// .app {
//     font-family: sans-serif;
//     text-align: center;
//   }
   */