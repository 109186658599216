import React, { Component } from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'rc-slider/assets/index.css';
import 'react-datetime/css/react-datetime.css'

import { Switch, Route } from 'react-router-dom';

import PublicRoute from './utils/components/PublicRoute'
import PrivateRoute from './utils/components/PrivateRoute'
import PublicPages from './pages/PublicPages/PublicPages.component';
import LoginPage from './pages/PublicPages/LoginPage'
import Main from './pages/Main'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GetSessionValue } from './utils/sessionStorage';
import AMEXPublicPagesComponent from './pages/PublicPages/AMEXPublicPages.component';
import AMEXSignUpPublicPagesComponent from './pages/PublicPages/AMEXSignUpPublicPages.component';
import AMEXDiningSignUpPublicPagesComponent from './pages/PublicPages/AMEXDiningSignUpPublicPages.component';
import AMEXDiningBranchPublicPagesComponent from './pages/PublicPages/AMEXDiningBranchPublicPages.component';
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    // window.addEventListener('resize', this.updateDimensions);
    let { getSettingsData } = this.props;
    let userDetails = GetSessionValue('user')
    if (userDetails && getSettingsData) {

      console.log('refreshhhhhhhhhhhhhhhhhhhhhh')
      getSettingsData()
    }
  }
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateDimensions);
  // }
  // updateDimensions = async () => {
  //   let vh = window.innerHeight * 0.01;
  //   // Then we set the value in the --vh custom property to the root of the document
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // };
  render() {
    return (
      <div className="wrapper">
        <Switch>          
          <PrivateRoute
            path='/user'
            to='/login'
            // to={this.props.match.params && this.props.match.params.user ? `user/${this.props.match.params.user}` : '/login'}
            component={Main}
          />
          <PublicRoute path='/amexdining' to="/amexdining" component={AMEXPublicPagesComponent} />
          <PublicRoute path='/amexsignup' to="/amexsignup" component={AMEXSignUpPublicPagesComponent} />
          <PublicRoute path='/amexdiningsignup' to="/amexdiningsignup" component={AMEXDiningSignUpPublicPagesComponent} />
          <PublicRoute path='/amexdiningbranch/:id' to="/amexdiningbranch/:id" component={AMEXDiningBranchPublicPagesComponent} />                    
          <PublicRoute path='/' to="/user" component={PublicPages} />
        </Switch>
      </div>
    );
  }
}
// export default App = connect(
//   state => ({
//     users: state.selectEntities.users
//   }),
//   dispatch => ({
//     getSettingsData: bindActionCreators(settingsAction, dispatch),
//     // getBrandData: bindActionCreators(getBrandDataAction, dispatch)
//   }),
//   null
// )(App)

export default App;
