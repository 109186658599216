import React, { Component } from "react";
import { connect } from 'react-redux'
import EntityList from '../EntityList'


class IngestionLogs extends Component {
    tableData = []
    columns = [
        {
            dataField: 'data_source',
            sort: true,
            text: 'Data Source',
            classes: 'vhalign'
        },
        {
            dataField: 'uploadedDate',
            sort: true,
            text: 'Uploaded Date',
            classes: 'vhalign'
        },
        // {
        //     dataField: 'next_ingestion_date',
        //     sort: true,
        //     text: 'Next ingestion date',
        //     classes: 'vhalign'
        // },
        {
            dataField: 'GMV',
            sort: true,
            text: 'GMV',
            classes: 'vhalign'
        },
        {
            dataField: 'txnCount',
            sort: true,
            text: 'Transaction count',
            classes: 'vhalign'
        }
    ]

    render() {
        return <EntityList
            onRef={(ref) => this.usersRef = ref}
            entityType="ingestionLog"
            preset={{ type: 'data_source' }}
            prefix="brand"
            heading='ingestionLog'
            icon="account_tree"
            tableData={this.tableData}
            apiUrl={'ingestionLogs'}
            columns={this.columns}
        /> 
    }
}

export default connect(
    null,
    dispatch => ({
        // getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
    })
)(IngestionLogs);

