
const StatsCardConfig = {
  statistics: {
    'reach': {
      icon: 'remove_red_eye',
      value: '265,881',
      label: "Reach",
      type: 'int',
      isHide: true,
      iconType: "material"
    },
    'conversions': {
      icon: 'check_circle_outline',
      value: '10,122',
      label: "Conversions",
      type: 'string',
      iconType: "material"
    },
    'revenue': {
      icon: 'fa-gbp',
      value: '129,047',
      label: "Revenue",
      type: 'int',
      isCurrency: true,
      iconType: "fa"
    },
    'total_cost': {
      icon: 'fa-gbp',
      value: '37,389',
      label: "Total Cost",
      type: 'int',
      isCurrency: true,
      iconType: "fa",
      isHide: true
    },
    'totalCashback': {
      icon: 'fa-gbp',
      value: '37,389',
      label: "Total Cashback",
      type: 'int',
      isCurrency: true,
      iconType: "fa"
    },
    'totalSuccessFee': {
      icon: 'fa-gbp',
      value: '37,389',
      label: "Total Success Fee",
      type: 'int',
      isCurrency: true,
      iconType: "fa"
    },
    'cpa': {
      icon: 'poll',
      value: '3.69',
      label: "CPA",
      type: 'float',
      isCurrency: true,
      iconType: "material"
    },
    'month': {
      icon: 'today',
      value: '3.69',
      label: "Month",
      type: 'string',
      isCurrency: false,
      iconType: "material",
      isHide: true
    },
    'discountPercent': {
      icon: 'fa-percent',
      value: '3.69',
      label: "Offer",
      type: 'string',
      isCurrency: false,
      postFix: "%",
      isHide: true,
      iconType: "fa"
    },
    date: {
      icon: 'date_range',
      value: '4',
      label: "Day's left",
    }
  },
  insights: {
    'highest_spend': {
      value: '26.64',
      label: "Highest Spend",
      type: 'float',
      isCurrency: true
    },
    'lowest_spend': {
      value: '4.34',
      label: "Lowest Spend",
      type: 'float',
      isCurrency: true
    },
    'average_spend': {
      value: '12.81',
      label: "Average Spend",
      type: 'float',
      isCurrency: true
    },
    'repeat_customers': {
      value: '24%',
      label: "Repeat Customers",
    }
  },
}

export default StatsCardConfig;



