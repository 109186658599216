import styled from 'styled-components'

export const BigContainer = styled.div`
margin-top:10px;
`

export const CompetitionContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-left: 10px;
  }
`;

/* .bigcontainer{
margin-top:10px;
}

 */





/*
    
    /* .NavList .icon {
      display: none;
    }
    
    @media screen and (max-width: 600px) {
      .NavList a{
        display: none;
      }
      .NavList a.icon {
        float: left;
        display: block;
      }
    }
    
    @media screen and (max-width: 600px) {
      .NavList.responsive {position: relative;}
      .NavList.responsive .icon {
        position: absolute;
        left: 0;
        top: 0;
      }
      .NavList.responsive a:not(:last-child){
        position:relative;
        top:52px;
        float: none;
        text-align: left;
        display: block;
      }
      .NavList{
        overflow-y:scroll;
      }
    }
    li{
      font-size:15px;
    } */ 