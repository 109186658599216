import React, { Component } from "react";

import { connect } from 'react-redux'
import EntityList from '../EntityList'
import { UserForm } from '../../components/Forms/User'
import { Badge } from 'react-bootstrap'
let languageData = {}
class Users extends Component {
  tableData = []
  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: languageData.actions ? languageData.actions : 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'email',
      sort: true,
      text: languageData.email ? languageData.email : 'Email',
      classes: 'vhalign'
    },
    {
      dataField: 'displayName',
      sort: true,
      text: languageData.displayName ? languageData.displayName : 'Display Name',
      classes: 'vhalign'
    },
    {
      dataField: 'role',
      sort: true,
      text: languageData.role ? languageData.role : 'Role',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col == 'user') {
          return <Badge style={{ color: 'white', background: 'blue', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'companyAdmin') {
          return <Badge style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'brandAdmin') {
          return <Badge style={{ color: 'white', background: '#3d81f5', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'brandUser') {
          return <Badge style={{ color: 'white', background: '#8b6d69', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'groupAdmin') {
          return <Badge style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'groupUser') {
          return <Badge style={{ color: 'white', background: 'blue', padding: 6, borderRadius: 35 }}>{col}</Badge>
        }
      }
    },
    {
      dataField: 'brandName',
      // sort: true,
      text: 'Group/Brand Name',
      classes: 'vhalign',
      // formatter: (col, row) => {
      //   if (col == 'companyAdmin' || col == 'brandUser') {
      //     return row.brandName;
      //   }  else if (col == 'groupAdmin' || col == 'groupUser') {
      //     return "";
      //   }
      // }
    },
    // {
    //   dataField: 'MerchantList',
    //   // sort: true,
    //   text: languageData.branches ? languageData.branches : 'Branches',
    //   classes: 'vhalign'
    // },
    {
      dataField: 'phone',
      sort: true,
      text: languageData.contactPhone ? languageData.contactPhone : 'Contact Phone',
      classes: 'vhalign'
    },
    {
      dataField: 'created',
      sort: true,
      text: languageData.created ? languageData.created : 'Created',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = new Date(col);

          // adjust 0 before single digit date
          let dd = ("0" + date.getDate()).slice(-2);

          // current month
          let mm = ("0" + (date.getMonth() + 1)).slice(-2);

          // current year
          let yy = date.getFullYear();
          return `${dd}/${mm}/${yy}`
        }
      }
    },
  ]

  render() {
    return <EntityList
      onRef={(ref) => this.usersRef = ref}
      entityType="user"
      preset={{ type: 'brand' }}
      prefix=""
      heading='user'
      icon="domain"
      EntityForm={UserForm}
      tableData={this.tableData}
      globalSearchPlaceHolder={'Search Email / Display Name / Role'}
      apiUrl={'users'}
      // onSubmit={this.submit}
      columns={this.columns}
      showPasswordReset={true}
    />
  }
}

export default connect(
  null,
  dispatch => ({
    // getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Users);

