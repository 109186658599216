import React from 'react'

const SplitButton = (props) => {
	let { text='', primary='', className='', icon='', onClick=() =>{} ,disabled='',style={} } = props;
	return (
		<div className={`btn-group ${className} split-button`}  >
			<button className={`btn p-0 d-flex align-items-center justify-content-center ${primary}`} onClick={onClick} disabled={disabled} style={style}>
				<span className="text">{text}</span>
				<span className="icon">{icon}</span>
			</button>
		</div>
	)
}

export default SplitButton;