// h5{
//     font-size:1rem;
//     text-align:center;
//     font-weight:100;
//   }
//    h4{
//     font-size:1rem;
//     font-weight:800;
//   }












import styled from 'styled-components'
export const Container = styled.div`
//display:flex;
[pointer-events="bounding-box"] {
  display: none
};
//align-items:strech;
& h5{
  font-size:1rem;
  text-align:center;
  font-weight:100;
}
& h4{
  font-size:1rem;
  font-weight:800;
}
` 
