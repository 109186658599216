import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link,Redirect } from 'react-router-dom'
import { createPasswordAction } from '../../redux/actions/authActions'

import ForgotPassword from '../../components/ForgotPassword/Forgot.component';
import CreatePassword from '../../components/CreatePassword/CreatePassword';
import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
// import API from '../../mocks/API'
import { 
  success as successNotificationAction,
  error as errorNotificationAction
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
const imgStyle = {
  'display': 'block',
  'height': "9rem",
  'textAlign': "center",
  'margin': '0 auto 2rem auto',
}

class CreatePasswordForm extends Component {
    state={
      isPasswordCreated:false
    }
  componentDidMount=()=>{
    // setTimeout(() => {
    //   let notification = { ...notificationOpts }
    //   notification.message = 'apple'
    //   this.props.errorNotification(notification)
    // })
  }
  onSubmit = (values, actions) => {
      values.entityType='user'
      if(this.props && this.props.match && this.props.match.params && this.props.match.params.enEmail){
        values.enEmail=this.props.match.params.enEmail
      }
      const {CreatePassword,successNotification,errorNotification}=this.props
    CreatePassword(values, (res,type) => {
      if (type=='error') {
        actions.setSubmitting(false)
        actions.setStatus(res)
        // setTimeout(() => {
          setTimeout(() => {
            let notification = {...notificationOpts}
            notification.message = res
            errorNotification(notification)
          })
        // })
      }else if(type=='success'){
        this.setState({isPasswordCreated:true})
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
      }
    })
  }
  render() {
      const {isPasswordCreated}=this.state
    return (
      <div className="row form justify-content-center">
        {isPasswordCreated?<Redirect  to='/'/>:''}
        <div className="col-md-6 col-lg-4" >
          <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          <div className="card">
            <div className="header">
              <h3 className="text-center">Create Password</h3>
            </div>
            <div className="content">
              <CreatePassword onSubmit={this.onSubmit} />
            </div>
          </div>
          <div className="text-center mb-4">
            Back to <Link to='/login' className="text-primary">Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    CreatePassword: bindActionCreators(createPasswordAction, dispatch),
    successNotification : bindActionCreators(successNotificationAction, dispatch),
    errorNotification:bindActionCreators(errorNotificationAction,dispatch),
  })
)(CreatePasswordForm);
