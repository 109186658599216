import React from 'react'
import Button from 'react-bootstrap/Button';
import {Modal as ModalComponent} from 'react-bootstrap';
import Loader from '../components/Loader'
import { Link } from 'react-router-dom';

const Modal = ({
  close, 
  show, 
  title, 
  message, 
  data,
  action,  
  callback, 
  actionType="success", 
  size="md",
  cancelText="Cancel",
  hideCancelBtn=false,
  centered=false,
  isLoading
}) => {
  return (
    <ModalComponent show={show} onHide={close} size={size} centered={centered}>
      <ModalComponent.Header closeButton >
        <ModalComponent.Title>{title}</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body >
        <Loader loader={isLoading} />
                {message}
        {/* <ReactCrop src={data.preview} crop={this.state.crop}/> */}
        
        </ModalComponent.Body>
    </ModalComponent>
  )
}

export default Modal

