// import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
// import BarclaysLogo from '../../assets/images/barclays-logo.png';
// import CajunCrabLogo from '../../assets/images/cajuncrab-logo.jpg';

import { GetSessionValue, SetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage"

let Language = GetSessionValue('language') ? GetSessionValue('language') : null
// if (User) {
//   if (User['type'] == 'bank')
//     User['logo'] = BarclaysLogo
//   else
//     User['logo'] = CajunCrabLogo  
// }


export default function (state = Language, action) {
  switch (action.type) {
    case 'SET_LANGUAGE_DATA': {
      let language = action.payload ? { ...state, ...action.payload } : null
      //   user=JSON.parse(JSON.stringify(user))
      if (!language) {
        window.sessionStorage.removeItem('language')
        DeleteSessionValue('language');
      } else {
        // delete user.logo
        // if(typeof(user)=='object'){
        SetSessionValue('language', language)
        // }

        // if (user['type'] == 'bank')
        //   user['logo'] = BarclaysLogo
        // else
        //   user['logo'] = CajunCrabLogo
      }
      return language
    }
    default:
      return state
  }
}

