import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import capitalize from 'lodash/capitalize'
import Icon from '@material-ui/core/Icon'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { pluralize } from '../../utils/helpers'
import {
  getEntityAction,
  createEntityActionTrain,
  deleteEntityAction,
  editEntityAction,
  setEntityPageAction,
  setEntitySortAction,
  setEntitySearchAction,
  setEntityFiltersAction,
  uploadCSVForEntityAction,
  getEntityDetails,
  confirmMerchantAction,
  multiAction,
  createMultipleBranchAction,
  getUserGroupBrandAction
  // getBranchListByNameAction
} from '../../redux/actions/index'
import { getBranchListFromNodeAction } from "../../redux/actions"; //getBranchListByBrandIdAction
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import config from '../../config/config'
import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import { Redirect } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import ViewModal from '../../components/ViewModal'
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import moment from 'moment';
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import * as yup from 'yup';
import configMessages from "../../config/configMessages";
import { GetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage";
import { getUniqueArray } from '../../utils/helpers';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import InvalidFieldErrorMessageModalComponent from '../../components/InvalidFieldErrorMessageModal';

const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const NameRegex = /[A-Za-z_\s]/
const NameRegexTwo = /^[A-Za-z ]+$/i;
const PhoneRegex = /^\+?\d*$/
let schema =
  yup.object({
    items: yup.array().of(
      yup.object().shape({
        // contactName: yup.string(),
        // .matches(NameRegex)
        // .min(3)
        // .max(100),
        // amexMerchantID: yup.string().required('Amex/Zettle Merchant ID is required'),
        amexMerchantID: yup.string(),
        zettleMerchantID: yup.string(),
        // contactPhone: yup.number()
        //   .required('Contact Number is required')
        //   .typeError("It doesn't contain alphabets")
        //   .positive("Contact Number can't start with a minus"),
        // .min(12, `Contact Number should contain 12 digits`)
        // contactEmail: yup.string()
        //   // .required('Email Id is required')
        //   .email(configMessages.validEmail).matches(EmailRegex).label(configMessages.validEmail),
      })
    )
  })

let count = 0;
let merchantInputFieldCount = 0;
let disableNextButton = true;
let merchantIdValue = ''
class EntityList extends Component {

  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    showPasswordResetModal: false,
    showAddNewModal: false,
    showEditModal: false,
    showUploadModal: false,
    showApproveModal: false,
    tableData: [],
    isLoading: false,
    sortCount: 0,
    approvalAction: '',
    columns: this.props.columns,
    searchField: '',
    sessionExpiryModal: false,
    loginRedirect: false,
    first: 0,
    rows: 10,
    languageFields: this.props.language,
    selectedArray: [],
    actions: [
      { label: 'Delete', value: 'DELETE' },
      { label: 'Approve', value: 'APPROVE' },
      { label: 'Reject', value: 'REJECT' },
    ],
    status: [
      { label: 'Status (All)', value: null },
      { label: 'Approved', value: 'Approved' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Updated', value: 'Updated' },
      { label: 'Deleted', value: 'Deleted' },
    ],
    selectedStatus: { label: 'Status', value: null },
    selectedAction: '',
    openViewModal: false,
    disableNextButton: false,
    merchantIdValue: '',
    yesClickedOnInput: false,
    isValidate: false,
    refsList: {},
    branchNameRefsList: {},
    branchList: [],
    errorMessageModal: {
      showErrorMessageModal: false,
      data: []
    },
    isFormSubmit: false,
    group_brand_ids: [],
    selected_group_brand_ids: "All"
  };
  pagination = {
    limit: 10,
    page: 1
  }

  scrollToBottom = (res) => {
    setTimeout(() => {
      this.state.refsList[`${res._id}_ref`].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 200);
  }

  //get Data from sever
  fetchData = (filterType) => {
    let {
      getEntityData,
      filters,
      apiUrl,
      successNotification,
      // items
    } = this.props;
    let { limit, page } = this.pagination
    let { selectedArray, searchField, selectedStatus } = this.state;
    if (!filters) filters = {};
    if (searchField) {
      if (!filters) {
        filters = {}
      }
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    if (selectedStatus && selectedStatus.value) {
      if (!filters) {
        filters = {};;
      }
      filters.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }

    let userDetails = GetSessionValue('loginCredentials');

    if (userDetails.role === 'groupAdmin' || userDetails.role === 'groupUser') {
      if (this.state.selected_group_brand_ids == "All") {
        filters.brandId = userDetails.brandId;
        filters.from = "onboard";
      } else {
        filters.brandId = this.state.selected_group_brand_ids;
      }
      filters.role = userDetails.role;
    }
    this.setState({ isLoading: true })
    getEntityData(page, limit, filters, apiUrl, '', async (res, pagination, type) => {
      if (res) {
        let selectedArray = this.props.items;
        let itemsArray = [];
        let merids = this.props.trainComponentMerchantIds;
        if (selectedArray && selectedArray.length > 0) {
          if (merids && merids.length > 0) {
            this.setState({
              selectedArray: []
            })
            for (let i = 0; i < selectedArray.length; i++) {
              if (selectedArray[i]['status'] !== 'Deleted') {
                itemsArray.push(selectedArray[i])
                for (let j = 0; j < merids.length; j++) {
                  if (merids[j] === selectedArray[i]['_id']) {
                    selectedArray[i]['checked'] = true;
                    this.setCheckBoxData(merids[j]);
                    break;
                  }
                }
              }
            }
          } else {
            for (let i = 0; i < selectedArray.length; i++) {
              if (selectedArray[i]['status'] !== 'Deleted') {
                itemsArray.push(selectedArray[i])
                selectedArray[i]['checked'] = true
                this.setCheckBoxData(selectedArray[i]['_id'])
              }

            }
            this.props.setAllBranchValue(true)
          }
          this.setState({
            items: itemsArray
          })
        }
      }
      let sessionExpiryModal = false
      let sessionExpired = await GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      if (apiUrl == 'merchants' && type == 'success' && filterType == 'pageChanged') {
        // this.handleCheckboxforpageChange(res, false)
        this.setState({ tableData: res })

      }

      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (type == 'error') {
        if (sessionExpiryModal) {
          return
        }
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
      }
    })
  }
  setCheckBoxData = (id) => {
    let selectedArray = this.state.selectedArray;
    selectedArray.push(id);
    this.setState({
      selectedArray: selectedArray
    })
    console.log(selectedArray)
  }
  componentDidMount = async () => {

    let { preset, setEntityFilters, getUserGroupBrand, page, columns, heading, user } = this.props;

    if (heading == 'user') {
      this.props.getDetails(config.entityType, true, res => { })
    }
    if (heading == 'user' && columns && user && user.role == 'user') {
      let columns = this.props.columns
      columns = columns.splice(1, columns.length)
      this.setState({ columns: columns })
    }


    let userDetails = GetSessionValue('loginCredentials');
    if (userDetails.role === 'groupAdmin' || userDetails.role === 'groupUser') {
      getUserGroupBrand(userDetails._id, res => {
        if (res && res.stepOneData && res.stepOneData.group_brand_ids) {
          //  this.setState({ group_brand_ids : res.stepOneData.group_brand_ids, selected_group_brand_ids : res.stepOneData.group_brand_ids[0].value });
          this.setState({ group_brand_ids: res.stepOneData.group_brand_ids });
          this.fetchData(page || this.pagination.page);
        }
      });
    } else {
      this.fetchData(page || this.pagination.page);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if ((Object.keys(this.state.refsList).length == 0 && this.props.items.length > 0)) {
      let refsList = {}
      this.props.items.forEach(res => {
        refsList[`${res._id}_ref`] = React.createRef()
      })
      this.setState({ refsList: refsList })
    }

    if ((Object.keys(this.state.branchNameRefsList).length == 0 && this.props.items.length > 0)) {
      let branchNameRefsList = {}
      this.props.items.forEach(res => {
        branchNameRefsList[`${res._id}_ref`] = React.createRef()
      })
      this.setState({ branchNameRefsList: branchNameRefsList })
    }


    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      // this.fetchData()
    }
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { limit } = this.pagination;
    return parseInt(totalSize / limit) + (totalSize % limit ? 1 : 0)
  }

  //changes in table
  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters, status }) => {
    let {
      setEntitySort,
      setEntitySearch,
      setEntityFilters,
      getEntityData,
      apiUrl,
      setEntityPage
    } = this.props;

    let { limit, page } = this.pagination
    let { searchField, selectedStatus } = this.state
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    console.log('selected status', selectedStatus)
    if (selectedStatus && selectedStatus.value) {
      filterCriteria.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }
    if (type === 'pagination') {
      setEntityPage(page, sizePerPage)
      return;
    }
    if (type === 'sort') {
      let sortCount = this.state.sortCount
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      filterCriteria.sortField = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({ sortCount: sortCount })
      getEntityData(page, limit, filterCriteria, apiUrl, 'sort', (response, pagination, type) => {
        if (type == 'success') {
          if (response) {
            let selectedArray = this.props.items;
            let itemsArray = [];
            let merids = this.props.trainComponentMerchantIds;
            if (selectedArray && selectedArray.length > 0) {
              if (merids && merids.length > 0) {
                this.setState({
                  selectedArray: [],
                })
                for (let i = 0; i < selectedArray.length; i++) {
                  if (selectedArray[i]['status'] !== 'Deleted') {
                    itemsArray.push(selectedArray[i])
                    for (let j = 0; j < merids.length; j++) {
                      if (merids[j] === selectedArray[i]['_id']) {
                        selectedArray[i]['checked'] = true;
                        this.setCheckBoxData(merids[j]);
                        break;
                      }
                    }
                  }
                }
              } else {
                for (let i = 0; i < selectedArray.length; i++) {
                  if (selectedArray[i]['status'] !== 'Deleted') {
                    itemsArray.push(selectedArray[i])
                    selectedArray[i]['checked'] = true
                    this.setCheckBoxData(selectedArray[i]['_id'])
                  }

                }
                this.props.setAllBranchValue(true)
              }
              this.setState({
                items: itemsArray
              })
            }
          }
          // this.handleCheckboxforpageChange(response, false)
        }
      })
      return
    }
    if (type === 'search') {
      filterCriteria.globalSearch = {
        value: searchText,
        type: 'user'
      }
      getEntityData(page, limit, filterCriteria, apiUrl, 'globalsearch', (response, pagination, type) => {
        if (type == 'success') {
          this.handleCheckboxforpageChange(response, false)
        }
      })
    }
    if (type === 'dropdown') {
      // filterCriteria = [{
      //   key: 'status',
      //   value: status,
      //   type: 'eq'
      // }]
      getEntityData(page, limit, filterCriteria, apiUrl, 'dropdown', (response, pagination, type) => {
        if (type == 'success') {
          this.handleCheckboxforpageChange(response, false)
        }
      })
    }
    if (type === 'filter') {
      setEntityFilters({ ...filters })
    }
  }

  setShowAddNewModal = () => {
    this.getBranchList();
    if (this.state.items && this.state.items.length > 0) {
      let data = this.state.items[0];
      let selectedEntity = {
        contactEmail: data.contactEmail,
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        address: data.address,
        postcode: data.postcode,
        // isDisabledContact : true,
        mastercardMerchantID: "",
        amexMerchantID: "",
        zettleMerchantID: "",
        name: ""
      }
      this.setState({ showAddNewModal: true, selectedEntity });
    } else {
      this.setState({ showAddNewModal: true, selectedEntity: null });
    }

  }

  onCloseAddNewModal = () => {
    this.setState({ showAddNewModal: false, selectedEntity: null, isFormSubmit: false });
  }

  setShowUploadModal = () => {
    this.setState({ showUploadModal: true })
  }

  onCloseUploadModal = () => {
    this.setState({ showUploadModal: false, selectedEntity: null })
  }

  setShowEditModal = (selectedEntity) => {
    if (selectedEntity) {
      this.setState({ showEditModal: true, selectedEntity })
      return
    }
    this.setState({ showEditModal: true })
  }

  setApproveModal = (selectedEntity, type) => {
    this.setState({ showApproveModal: true, selectedEntity, approvalAction: type })
  }

  onCloseApproveModal = () => {
    this.setState({ showApproveModal: false, selectedEntity: null, approvalAction: null })
  }
  onCloseViewModal = () => {
    this.setState({ openViewModal: false })
    this.setShowEditModal()
  }
  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedEntity: null, isFormSubmit: false })
  }

  setShowDeleteModal = (selectedEntity) => {

    this.setState({ showDeleteModal: true, selectedEntity })
  }

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedEntity: null })
  }

  setShowPasswordResetModal = () => {
    this.setState({ showPasswordResetModal: true })
  }

  onClosePasswordResetModal = () => {
    this.setState({ showPasswordResetModal: false, selectedEntity: null })
  }
  //on editing new entitites
  onSubmitEditMerchant = async (values, actions) => {

    let { editEntity, page, successNotification, entityType, apiUrl } = this.props;
    this.setState({ isLoading: true });
    
    if (apiUrl == 'users') {
      let body = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        brandName: '',
      }
      if (values) {
        body['email'] = values['email'];
        body['firstName'] = values['firstName'];
        body['lastName'] = values['lastName'];
        body['phone'] = values['phone'];
        body['role'] = values['role'];
        body['brandName'] = values['brandName'];
      }
      if (!values.merchantIds) {
        values.merchantIds = []
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }

    if (apiUrl == 'merchants') {
      let body = {
        name: '',
        mastercardMerchantID: '',
        amexMerchantID: '',
        zettleMerchantID: '',
        // contactName: '',
        // contactPhone: '',
        // contactEmail: '',
        postcode: '',
        postalTown: '',
        address: ''
      }
      if (values) {
        body['name'] = values['name'];
        body['mastercardMerchantID'] = values['mastercardMerchantID'];
        body['amexMerchantID'] = values['amexMerchantID'];
        body['zettleMerchantID'] = values['zettleMerchantID'];
        // body['contactName'] = values['contactName'];
        // body['contactEmail'] = values['contactEmail'];
        // body['contactPhone'] = values['contactPhone'];
        body['address'] = values['address'];
        body['postalTown'] = values['postalTown'];
        body['postcode'] = values['postcode'];
      }

      if (values.branchName) {
        body['name'] = values.branchName;
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }


      let node_body = {
        "branch_name": values.branchName || values.name || "",
        "entity_url": values.tripadvisorUrl || "",
        "brandname": values.brandName || "",
        "brand_id": values.brandId || "",
        "city": values.city || "",
        "description": values.description || "",
        "address": values.address || "",
        "merchantId": values.mastercardMerchantID || "",
        "amexMerchantID": values.amexMerchantID || "",
        "postcode": values.postcode || "",
        "tripadvisorUrl": values.tripadvisorUrl || "",
        "sector_id": '16',
        "cuisines_list": [],
        "kmid": values.kmid || "",
        "zettleMerchantID": values.zettleMerchantID || "",
      };

      let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
      let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
      let response = await lambdaAxiosMethodRequest('POST', url, node_body, x_api_key);

      values = body;
    }

    let reqValues = JSON.parse(JSON.stringify(values));
    reqValues.merchantIds = {
      zettleMerchantID: values.zettleMerchantID || "",
      amexMerchantID: values.amexMerchantID || "",
      mastercardMerchantID: values.mastercardMerchantID || ""
    }
    delete reqValues.zettleMerchantID;
    delete reqValues.amexMerchantID;
    delete reqValues.mastercardMerchantID;
    editEntity(reqValues, apiUrl, (res, type) => {

      this.setState({ isLoading: false });

      if (actions) {
        actions.setSubmitting(false)
      }
      if (type == 'error' && actions) {
        actions.setStatus(res)
        return
      }

      // notification
      this.onCloseEditModal()
      this.fetchData(page)
      // setTimeout(() => {
      //   let notification = { ...notificationOpts }
      //   notification.message = res
      //   successNotification(notification)
      // })
    })

  }
  //on editing new entitites
  onSubmitEdit = async (values, actions) => {
    let { editEntity, page, successNotification, entityType, apiUrl } = this.props;
    this.setState({ isLoading: true })
    if (apiUrl == 'users') {
      let body = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        brandName: ''
      }
      if (values) {
        body['email'] = values['email'];
        body['firstName'] = values['firstName'];
        body['lastName'] = values['lastName'];
        body['phone'] = values['phone'];
        body['role'] = values['role'];
        body['brandName'] = values['brandName'];
      }
      if (!values.merchantIds) {
        values.merchantIds = []
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }
    if (apiUrl == 'merchants') {
      let body = {};
      if (values) {
        body['name'] = values['name'];
        body['mastercardMerchantID'] = values['mastercardMerchantID'];
        body['amexMerchantID'] = values['amexMerchantID'];
        body['zettleMerchantID'] = values['zettleMerchantID'];
        body['contactName'] = values['contactName'];
        body['contactEmail'] = values['contactEmail'];
        body['contactPhone'] = values['contactPhone'];
        body['address'] = values['address'];
        body['tripadvisorUrl'] = values['tripadvisorUrl'];
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = Object.assign(values, body);
    }

    let body = {
      "branch_name": values.name || "",
      "entity_url": values.tripadvisorUrl || "",
      "brandname": values.brandName || "",
      "brand_id": values.brandId || "",
      "city": values.city || "",
      "description": values.description || "",
      "address": values.address || "",
      "merchantId": values.mastercardMerchantID || "",
      "amexMerchantID": values.amexMerchantID || "",
      "zettleMerchantID": values.zettleMerchantID || "",
      "postcode": values.postcode || "",
      "tripadvisorUrl": values.tripadvisorUrl || "",
      "sector_id": '16',
      "cuisines_list": [],
      "kmid": values.kmid || ""
    };

    actions.setSubmitting(true);
    this.setState({ isFormSubmit: true })

    let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
    let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
    let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
    if (response.data) {
      editEntity(values, apiUrl, (res, type) => {
        this.setState({
          isLoading: false,
          yesClickedOnInput: false,
        })

        if (actions) {
          actions.setSubmitting(false)
        }
        if (type == 'error' && actions) {
          actions.setStatus(res)
          return
        }

        // notification
        this.onCloseEditModal()
        this.fetchData(page)
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
      })
    }
  }

  //on adding new entity
  // onSubmitAddNew = (values, actions) => {
  //   let { createMultipleBranchAction, createEntity, page, successNotification, entityType, apiUrl } = this.props;
  //   if (values.isNew) {
  //     let userDetails = GetSessionValue('loginCredentials')
  //     let brandId = userDetails ? userDetails.brandId : "";
  //     let brandName = userDetails ? userDetails.brandName : "";
  //     let finalobj = values;
  //     finalobj.brandId = brandId;
  //     finalobj.brandName = brandName;
  //     finalobj.brand_id = brandId;
  //     finalobj.created_by = "ui";
  //     finalobj.created_date = new Date();
  //     finalobj.datasource = "ui";
  //     delete finalobj.block;
  //     createMultipleBranchAction([finalobj], async (res, type) => {
  //       console.log(res)
  //       if (res.details.branch[0].updateOne.filter._id) {
  //         let mId = res.details.branch[0].updateOne.filter._id
  //         this.addBranchSuccess(res, mId, actions, type)
  //       }
  //     })
  //   } else {
  //     this.setState({ isLoading: true })
  //     createEntity(values, apiUrl, async (res, type) => {
  //       this.addBranchSuccess(res, res.mastercardMerchantID, actions, type)
  //     })
  //   }

  // }


  onSubmitAddNew = async (values, actions) => {
    let { createMultipleBranchAction } = this.props;

    let userDetails = GetSessionValue('loginCredentials')
    let brandId = userDetails ? userDetails.brandId : "";
    let brandName = userDetails ? userDetails.brandName : "";
    let finalobj = values;
    finalobj.brandId = brandId;
    finalobj.brand_id = brandId;
    finalobj.brandName = brandName;
    finalobj.brandname = brandName;
    finalobj.brand_id = brandId;
    finalobj.created_by = "ui";
    finalobj.created_date = new Date();
    finalobj.datasource = "ui";
    finalobj.branch_name = finalobj.name;
    finalobj.entity_url = finalobj.tripadvisorUrl;
    finalobj.city = finalobj.city || "";
    finalobj.description = finalobj.description || "";
    finalobj.sector_id = '16';
    finalobj.cuisines_list = [];
    finalobj.kmid = finalobj.kmid || "";
    delete finalobj.block;

    actions.setSubmitting(true);
    this.setState({ isFormSubmit: true })

    let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
    let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
    let response = await lambdaAxiosMethodRequest('POST', url, finalobj, x_api_key);
    if (response.data && response.data[0] && response.data[0].kmid) {
      finalobj.kmid = response.data[0].kmid;
      createMultipleBranchAction([finalobj], async (res, type) => {
        console.log(res)
        if (res.details.branch[0].updateOne.filter._id) {
          let mId = res.details.branch[0].updateOne.filter._id
          this.addBranchSuccess(res, mId, actions, type)
        }
      })
    }
  }

  addBranchSuccess = async (res, mastercardMerchantID, actions, type) => {
    let { page, successNotification } = this.props;
    if (mastercardMerchantID) {
      await this.setCheckBoxData(mastercardMerchantID);
      await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    }
    this.setState({ isLoading: false })
    actions.setSubmitting(false)
    count = 0;
    merchantInputFieldCount = 0;
    if (type == 'error') {
      actions.setStatus(res)
      return
    }
    // notification
    this.onCloseAddNewModal()
    this.fetchData(page)
    setTimeout(() => {
      let notification = { ...notificationOpts }
      notification.message = res['respMessage'] ? res['respMessage'] : res
      successNotification(notification)
    })
  }

  //for uplaod csv files
  onSubmitUpload = (values, actions) => {
    let { uploadCSVForEntity, page, successNotification, entityType, apiUrl } = this.props;
    uploadCSVForEntity(values.file, apiUrl, (res, type) => {
      actions.setSubmitting(false)
      if (type == 'error') {
        actions.setStatus(res)
        return
      }
      // notification
      this.onCloseUploadModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }

  //for deeting row
  handleDeleteEntity = () => {
    let { deleteEntity, page, successNotification, entityType, apiUrl, filters } = this.props;
    let { selectedEntity } = this.state
    deleteEntity(selectedEntity, apiUrl, (res, type) => {
      if (type == 'error') {
        return
      }
      // notification
      this.onCloseDeleteModal()
      count = 0;
      merchantInputFieldCount = 0;
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }

  //handle merchant
  handleConfirmMerchant = (values, actions) => {
    let { confirmMerchant, page, successNotification, entityType } = this.props;
    let { selectedEntity, approvalAction } = this.state
    values.status = `${approvalAction}d`
    values.MId = selectedEntity._id
    let url = approvalAction == 'Approve' ? `users/approveMerchantId` : approvalAction == 'Reject' ? `users/approveMerchantId` : ''
    confirmMerchant(values, url, (res, type) => {
      this.onCloseApproveModal()
      this.fetchData(page)
      count = 0;
      merchantInputFieldCount = 0;
      setTimeout(() => {
        let notification = { ...notificationOpts }
        if (type == 'error') {
          notification.message = res
        } else {
          notification.message = `Merchant ${approvalAction == 'Approve' ? 'approved' : 'rejected'} successfully`
        }
        successNotification(notification)
      })
    })
  }

  submitForm = () => {
    if (this.formik)
      this.formik.submitForm()
  }

  formikRef = (e) => {
    this.formik = e
  }
  //handleCheckbox for pageChange
  handleCheckboxforpageChange = (data, action) => {
    let { selectedArray, tableData } = this.state
    if (data) {
      data.forEach((item, index) => {
        if (document.getElementById(item._id)) {
          document.getElementById(item._id).checked = action
        }
      })
    }
    // else if(tableData && tableData.length && selectedArray && selectedArray.length){
    //   let matchedMultiSelected=[]
    //   let unmatchedSelcted=[]
    //   for(let i=0;i<tableData.length;i++){
    //     for(let j=0;j<selectedArray.length;j++){
    //       if(tableData[i]._id==selectedArray[j]){
    //         matchedMultiSelected.push(selectedArray[j])
    //       }
    //     }
    //   }
    //   matchedMultiSelected.forEach((item,index)=>{
    //     document.getElementById(item).checked = true
    //   })
    // }

  }
  //for global search
  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  //for multiselect
  multiAction = () => {
    let { multiAction, successNotification } = this.props
    let { selectedArray, selectedAction } = this.state
    let values = {}
    values.selectedIds = selectedArray
    multiAction(values, selectedAction.value, (res, type) => {
      let notification = { ...notificationOpts }
      notification.message = res
      setTimeout(() => {
        successNotification(notification)
      })
      if (type == 'success') {
        selectedArray.forEach((item, index) => {
          document.getElementById(item).checked = false
        })
        selectedArray.forEach((item, index) => {
          return this.handleCheckBox(item, type)
        })
        this.setState({ selectedArray: [] })
        this.fetchData()
      }
    })
  }
  handleAllChange = async (e) => {
    let items = this.state.items;
    let selectedArray = [];
    for (let i = 0; i < items.length; i++) {
      items[i]['checked'] = e.target.checked;
      if (e.target.checked === true) {
        selectedArray.push(items[i]['_id'])
      }
      if (document.getElementById(items[i]['_id'])) {
        document.getElementById(items[i]['_id']).checked = e.target.checked
      }
      this.setState({
        selectedArray: selectedArray,
        items: items
      })
    }
    this.props.setAllBranchValue(e.target.checked);
    this.props.handleTrainComponentMerchantIds(selectedArray)
  }
  handleIdsOFCheckBox = async (selectedId, type) => {
    let items = this.state.items;
    for (let i = 0; i < items.length; i++) {
      if (document.getElementById(selectedId) && selectedId == items[i]['_id']) {
        document.getElementById(items[i]['_id']).checked = !items[i]['checked'];
        items[i]['checked'] = !items[i]['checked'];
      } else {
        document.getElementById(items[i]['_id']).checked = items[i]['checked'];
        items[i]['checked'] = items[i]['checked'];
      }
      if (type === 'splice') {
        if (this.state.items && this.state.items.length > 1) {
          await this.props.setAllBranchValue(false);
        } else {
          await this.props.setAllBranchValue(true);
        }
      } else if (type === 'add') {
        if (this.state.items && this.state.selectedArray && (this.state.items.length === this.state.selectedArray.length)) {
          await this.props.setAllBranchValue(true);
        } else {
          if (this.state.items && this.state.items.length > 1) {
            await this.props.setAllBranchValue(false);
          } else {
            await this.props.setAllBranchValue(true);
          }
        }
      }
      this.setState({
        items: items
      })
    }
  }
  //for multiselect checkbox
  handleCheckBox = (e, type) => {
    let selectedId
    if (!type) {
      selectedId = e.target.value
    } else {
      selectedId = e
    }
    let selectedArray = this.state.selectedArray;

    let selectedIdFound = false
    let selectedIdIndex
    selectedArray.forEach((item, index) => {
      if (item == selectedId) {
        selectedIdFound = true
        selectedIdIndex = index
      }
    })
    if (selectedIdFound) {
      console.log(selectedId, 'selectedId')
      this.handleIdsOFCheckBox(selectedId, 'splice')
      selectedArray.splice(selectedIdIndex, 1);
    } else {
      this.handleIdsOFCheckBox(selectedId, 'add')
      selectedArray.push(selectedId)
    }
    this.setState({ selectedArray: selectedArray })
    console.log(selectedArray)
    this.props.handleTrainComponentMerchantIds(selectedArray)
  }
  submitData = async (row) => {

    if (document.getElementById(`${row._id}mastercardMerchantID${row._id}`) && this.state.yesClickedOnInput) {
      row["mastercardMerchantID"] = document.getElementById(`${row._id}mastercardMerchantID${row._id}`).value;
      console.log(document.getElementById(`${row._id}mastercardMerchantID${row._id}`).value)
      this.onSubmitEditMerchant(row)
    }
  }
  //for multiselect checkbox
  handleInputText = async (e, row) => {
    if (e) {
      if (e.target.value) {
        if (document.getElementById(`${row._id}mastercardMerchantID${row._id}`) && e && e.target.value) {
          document.getElementById(`${row._id}mastercardMerchantID${row._id}`).value = e.target.value
        }
        // row["mastercardMerchantID"] = e.target.value
        // this.onSubmitEditMerchant(row)
        count = 0;
        merchantInputFieldCount = 0;
        disableNextButton = false
        // merchantIdValue = e.target.value
        await this.setState({
          disableNextButton: false,
          merchantIdValue: e.target.value,
          yesClickedOnInput: true
        })
      } else {
        row["mastercardMerchantID"] = ''
        // this.onSubmitEditMerchant(row)
        count = 0;
        merchantInputFieldCount = 0;
        merchantIdValue = ''
        this.setState({
          disableNextButton: true,
          merchantIdValue: '',
          yesClickedOnInput: true,
        })
      }
    } else {
      row["mastercardMerchantID"] = ''
      // this.onSubmitEditMerchant(row)
      this.setState({
        disableNextButton: true,
        merchantIdValue: '',
        yesClickedOnInput: true,
      })
      count = 0;
      merchantInputFieldCount = 0;
      merchantIdValue = ''
    }

    if (e && e.target && e.target.value || document.getElementById(`${row._id}VishMaster${row._id}`).value) {
      disableNextButton = true;
      this.setState({
        disableNextButton: true
      })
    }

  }

  //view modal
  openViewModal = async (rowData) => {
    let keys = Object.keys(rowData);
    let viewFields = this.props.viewFields ?
      await this.props.viewFields : [];
    if (viewFields) {
      // if (this.props.type == 'Schedule') {
      if (rowData) {
        let values;
        viewFields.forEach((item, index) => {
          if (item.name == 'created') {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          } else if (item.name == 'activatedDate' && rowData[item.name]) {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          } else {
            item.value = rowData[item.name]
          }
        })
      }
      // }
      await this.setState({
        openViewModal: true,
        selectedEntity: rowData
      });
      this.viewModalRef.getRowData(viewFields);
    }
  }

  //for performing actions
  actionsFormatter = (cell, row) => {
    let { user, heading } = this.props;
    let userId = user && user._id ? user._id : '';
    let rowId = row && row.createdBy && row.createdBy.user ? row.createdBy.user : ''
    let relatedMerchant = row && row.userId ? row.userId : '';
    count = count + 1;
    console.log(row.mastercardMerchantID, 'loginid', userId, 'createdd', rowId, 'userId', relatedMerchant)
    console.log(user)
    if (row.defaultBranch) {
      return (
        <React.Fragment>
          <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setShowEditModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
          </span>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)}  >
            <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
          </span>
          <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setShowEditModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
          </span>
          {heading == 'branch' && (row.status == 'Deleted' || row.status == 'Pending' || row.status == 'Rejected') && row && row.createdBy && !row.createdBy.user ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Approve')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">done</Icon>
            </span> : ''}
          {heading == 'branch' && (row.status == 'Deleted' || row.status == 'Pending' || row.status == 'Active' || row.status == 'Approved') && row && row.createdBy && !row.createdBy.user ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Reject')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-danger">clear</Icon>
            </span> : ''}
          {/* {heading == 'branch' ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.openViewModal(row)} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">visibility</Icon>
            </span>
            : ''} */}
        </React.Fragment>
      )
    }
  }

  //for insert check box in table
  checkBoxFormatter = (cell, row) => {
    let { user, heading } = this.props
    return (
      <div className="input-group justify-content-center"
        title='Apply campaign to this branch'>
        <input
          name={row._id}
          value={row._id}
          type="checkbox" className="table_select_checkbox"
          onChange={this.handleCheckBox}
          // checked={row.checked}
          id={row._id}
          defaultChecked={row.checked}
        />
      </div>)
  }
  //for insert check box in table
  textInputFormatter = (cell, row, index, row2) => {
    let { user, heading } = this.props;
    merchantInputFieldCount = merchantInputFieldCount + 1;
    // if (row.defaultBranch) {
    if (row.mastercardMerchantID) {
      disableNextButton = false;
      if (document.getElementById(`${row._id}mastercardMerchantID${row._id}`)) {
        document.getElementById(`${row._id}mastercardMerchantID${row._id}`).value = row.mastercardMerchantID
      }
      this.setState({
        disableNextButton: false,
        merchantIdValue: row.mastercardMerchantID
      })
      // merchantIdValue = row.mastercardMerchantID
    } else {
      disableNextButton = true;
      this.setState({ disableNextButton: true, })
    }
    return (
      <React.Fragment>
        <div className="row no-gutters d-flex align-items-center">
          <div className="p-1  col-12 input-group justify-content-center">
            <input type="text" className="form-control "
              id={`${row._id}mastercardMerchantID${row._id}`}
              onChange={(e) => this.handleInputText(e, row)}
              // value={row.mastercardMerchantID}
              defaultValue={row.mastercardMerchantID}
              onBlur={(e) => this.submitData(row)}
              defaultChecked={false}
              placeholder="Visa/MasterCard"
            />
          </div>
          <div className="p-1 input-group  col-12 justify-content-center ">
            <input type="text"
              // className="form-control "
              id={`${row._id}VishMaster${row._id}`}
              value={row.amexMerchantID}
              defaultValue={row.amexMerchantID}
              onBlur={(e) => this.submitData(row)}
              onChange={e => this.handleInputChangeNew(e, index, "amexMerchantID", row)}
              defaultChecked={false}
              placeholder="Amex"
              className={`${(this.state.isValidate && (!row.amexMerchantID)) ? "is-invalid" : ""} form-control`}
            />
          </div>
          <div className="p-1 input-group  col-12 justify-content-center ">
            <input type="text"
              id={`${row._id}zettleMerchantID${row._id}`}
              value={row.zettleMerchantID}
              defaultValue={row.zettleMerchantID}
              onBlur={(e) => this.submitData(row)}
              onChange={e => this.handleInputChangeNew(e, index, "zettleMerchantID", row)}
              defaultChecked={false}
              placeholder="Zettle/Square etc."
              className={`${(this.state.isValidate && (!row.zettleMerchantID)) ? "is-invalid" : ""} form-control`}
            />
          </div>

          {/* <div className="input-group justify-content-center positionMerchantIdInputClass">
            <input type="text" className="form-control positionMerchantIdInputField"
              id={`${row._id}mastercardMerchantID${row._id}`}
              onChange={(e) => this.handleInputText(e, row)}
              // value={row.mastercardMerchantID}
              defaultValue={row.mastercardMerchantID}

              onBlur={(e) => this.submitData(row)}
              defaultChecked={false} />
          </div>
          <div className="infoIcon">
            <ToolTipContainer
              placement="right"
              className="ml-2 mb-1"
              toolTipText="If you have multiple merchant IDs, separate them by commas."
              id="discountPercent"
            >
              <Icon className="heading-icon">info</Icon>
            </ToolTipContainer>
          </div> */}
        </div>
      </React.Fragment>
    )
    // } else {
    //   return (
    //     <p>{row.mastercardMerchantID}</p>
    //   )
    // }

  }

  handleInputChangeNew = (e, index, type, row) => {

    let { value } = e.target;
    let data1 = [...this.state.items]
    let data2 = { ...data1[index], [type]: value }
    data1[index] = data2
    this.setState({
      items: data1,
      yesClickedOnInput: true
    })

    if (e.target.value || document.getElementById(`${row._id}mastercardMerchantID${row._id}`).value) {
      disableNextButton = true;
      this.setState({
        disableNextButton: true
      })
    }
  };

  handleBranchChange = (e, index, type, row) => {

    let { value } = e.target;
    let data1 = JSON.parse(JSON.stringify(this.state.items));
    let data2 = { ...data1[index], [type]: value };
    data1[index] = data2;
    this.setState({
      items: data1,
      yesClickedOnInput: true
    })

    if (e.target.value || document.getElementById(`${row._id}name${row._id}`).value) {
      disableNextButton = true;
      this.setState({
        disableNextButton: true
      })
    }
  };

  BranchNameFormatter = (cell, row, index) => {

    return (
      <React.Fragment>
        <div className="row no-gutters d-flex align-items-center">
          <div className="p-1  col-12 input-group justify-content-center">
            <input type="text" className="form-control "
              id={`${row._id}name${row._id}`}
              // onChange={(e) => this.handleInputText(e, row)}
              onChange={e => this.handleBranchChange(e, index, "branchName", row)}
              // value={row.mastercardMerchantID}
              defaultValue={row.name}
              onBlur={(e) => this.submitData(row)}
              defaultChecked={false}
              placeholder="Branch Name"
            />
          </div>

        </div>
      </React.Fragment>
    )

  }

  ContactDetailsFormatter = (cell, row, index) => {

    return <React.Fragment>
      <div ref={this.state.refsList[`${row._id}_ref`]} key={row._id + "cardDet"} className="p-0 col-12 row d-flex align-items-center no-gutters">
        {/* <div className="p-2 col-lg-6  input-group justify-content-center">
          <input value={row.contactName}
            id={`contactName${row._id}`}
            name={`contactName${row._id}`}
            type="text" placeholder="Name"
            className={`${(this.state.isValidate && (!row.contactName || !NameRegex.test(row.contactName))) ? "is-invalid" : ""} form-control`}
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "contactName",row)} />
        </div>
        <div className="p-2 col-lg-6  input-group justify-content-center ">
          <input value={row.contactEmail}
            id={`contactEmail${row._id}`}
            name={`contactEmail${row._id}`}
            className={`${(this.state.isValidate && (!row.contactEmail || !EmailRegex.test(row.contactEmail))) ? "is-invalid" : ""} form-control`}
            type="text" placeholder="Email"
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "contactEmail",row)} />
        </div>
        <div className="p-2 col-lg-12  input-group justify-content-center ">
          <input type="text" value={row.contactPhone}
            id={`contactPhone${row._id}`}
            name={`contactPhone${row._id}`}
            className={`${(this.state.isValidate && (!row.contactPhone || !PhoneRegex.test(row.contactPhone))) ? "is-invalid" : ""} form-control`} placeholder="Contact Number"
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "contactPhone",row)} />
        </div> */}

        <div className="p-2 col-lg-6  input-group justify-content-center">
          <input value={row.postcode}
            id={`postcode${row._id}`}
            name={`postcode${row._id}`}
            type="text" placeholder="Post Code"
            className={`form-control`}
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "postcode", row)} />
        </div>
        <div className="p-2 col-lg-6  input-group justify-content-center ">
          <input value={row.postalTown}
            id={`postalTown${row._id}`}
            name={`postalTown${row._id}`}
            type="text" placeholder="Postal Town"
            className={`form-control`}
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "postalTown", row)} />
        </div>
        <div className="p-2 col-lg-12  input-group justify-content-center ">
          <input type="text" value={row.address}
            id={`address${row._id}`}
            placeholder="Address"
            name={`address${row._id}`}
            className={`form-control`}
            onBlur={(e) => this.submitData(row)}
            onChange={e => this.handleInputChangeNew(e, index, "address", row)} />
        </div>

      </div>
    </React.Fragment>

  }

  TripAdvicerUrlFormatter = (cell, row) => {
    return <ToolTipContainer
      containerClassNames="ellipsis-text"
      toolTipText={`${row.tripadvisorUrl}`}
      id="discountPercent"
    >
      {row.tripadvisorUrl}
    </ToolTipContainer>

  }


  hasEditPermission = () => {
    let { user } = this.props;
    // we may need to do this on resource type
    if (user.role != null) {
      return user.role === 'companyAdmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'groupAdmin' || user.role === 'brandAdmin'
    }
  }

  sessionExpired = () => {
    // sessionStorage.removeItem('user');
    DeleteSessionValue('user');
    setTimeout(() => this.setState({ loginRedirect: true }), 1000)
  }

  onPageChange = async (page) => {
    this.pagination.page = page
    await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    await this.fetchData('pageChanged')
    this.setState({ selectedArray: [] })
  }

  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  //when performing multi action , to select action
  changeAction = (selectedAction) => {
    this.setState({ selectedAction })
  }

  //when performing multi action , to select action
  changeStatus = async (selectedStatus) => {
    await this.setState({ selectedStatus })
    let status = selectedStatus.value
    this.handleTableChange('dropdown', { status })
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-2" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content-custom">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <p
                  className='dropdownPageSize mb-0'
                  // href="#"
                  onClick={() => this.setPageLimit(option.page)} key={index}>
                  {option.text}</p>
              );
            })
          }
        </div>
      </div>
    </div>
  )
  backAction = async () => {
    count = 0;
    merchantInputFieldCount = 0;
    await this.props.handleTrainComponentMerchantIds(this.state.selectedArray);
    this.props.backAction();
  }

  nextAction = async (e) => {

    e.preventDefault();
    // this.scrollToBottom(itemDetails)
    let finalArr = []
    this.state.items.forEach(async (res, index) => {
      let obj = {
        contactName: res.contactName,
        contactEmail: res.contactEmail,
        contactPhone: res.contactPhone,
        amexMerchantID: res.amexMerchantID || "",
        mastercardMerchantID: res.mastercardMerchantID || "",
        zettleMerchantID: res.zettleMerchantID || ""
      }
      finalArr.push(obj)
      // schema.isValid({ items: [obj] }).then(async (res1) => {
      //     if (!res1) {
      //       console.log(res1, res)
      //       this.scrollToBottom(res)
      //     }
      //   })
    });

    let isInvalidKey = {};
    finalArr.forEach(element => {
      if (!element.mastercardMerchantID && !element.amexMerchantID && !element.zettleMerchantID) {
        isInvalidKey.mastercardMerchantID = "Atleast one Merchant ID is required.";
      }
      // if(element.contactEmail && !EmailRegex.test(element.contactEmail)) {
      //   isInvalidKey.contactEmail = configMessages.validEmail;
      // } 
      // else if(!element.contactEmail){
      //   isInvalidKey.contactEmail = "Contact email is required.";
      // }

      // if(element.contactName && !NameRegexTwo.test(element.contactName)) {
      //   isInvalidKey.contactName = "Please provide a valid contact name";
      // } else if(!element.contactName) {
      //   isInvalidKey.contactName = "Contact name is required.";
      // }

      // if(element.contactPhone && !PhoneRegex.test(element.contactPhone)) {
      //   isInvalidKey.contactPhone = "Please provide a valid contact phone";
      // }
      //  else if(!element.contactPhone) {
      //   isInvalidKey.contactPhone = "Contact phone is required.";
      // }

    });

    if (Object.keys(isInvalidKey).length > 0) {
      console.log("isInvalidKey", isInvalidKey);
      this.setState({ errorMessageModal: { showErrorMessageModal: true, data: isInvalidKey } });
      return;
    }

    // await schema.isValid({ items: finalArr }).then(async (res) => {
    //     if (res) {
    //       count = 0;
    //       merchantInputFieldCount = 0;
    //       await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    //       this.props.nextAction();
    //     } else {
    //       this.setState({ isValidate: true })
    //     }
    //   })
    count = 0;
    merchantInputFieldCount = 0;
    await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    this.props.nextAction();


    // if (await !this.validate()) {
    //   count = 0;
    //   merchantInputFieldCount = 0;
    //   await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    //   this.props.nextAction();
    // } else {
    //   this.setState({ isValidate: true })
    // }

  }

  onCloseErrorMessageModal = () => {
    this.setState({ errorMessageModal: { showErrorMessageModal: false, data: {} } });
  }

  getBranchList = async () => {
    let userDetails = await GetSessionValue('loginCredentials')
    // let brands = userDetails ? userDetails.brandId : "";
    // if (brands)
    //   this.props.getBranchListByBrandId(brands, (res) => {
    //     console.log(res)
    //     if (res && res.length) {
    //       res.forEach((item, index) => {
    //         item.label = item.name
    //         item.value = item._id
    //       })
    //       this.setState({ branchList: res })
    //     }
    //   })

    let brandName = userDetails ? userDetails.brandName : "";
    if (brandName)
      this.props.getBranchListFromNode(brandName, (branchList) => {
        console.log(branchList)
        if (branchList && branchList.length) {
          const uniqueBranch = getUniqueArray(branchList, 'merchant_name');
          uniqueBranch.forEach((item, index) => {
            item.label = item.merchant_name;
            item.value = item.kmid;
            item.name = item.merchant_name;
          })
          this.setState({ branchList: uniqueBranch })
        }
      })

  }

  getBranchData = (value) => {
    // let filterCriteria = {
    //   'criteria': [
    //     {
    //       'key': 'name',
    //       'value': value,
    //       'type': 'regexOr',
    //     },
    //     { "key": "active", "value": null, "type": "eq" },
    //   ]
    // }
    // this.props.getBranchListByNameAction(filterCriteria, (res) => {
    //   console.log(res)
    //   if (res && res.length) {
    //     res.forEach((item, index) => {
    //       item.label = item.name
    //       item.value = item._id
    //     })
    //     this.setState({ branchList: res })
    //   }
    // })

    if (value)
      this.props.getBranchListFromNode(value, (branchList) => {
        console.log(branchList)
        if (branchList && branchList.length) {
          const uniqueBranch = getUniqueArray(branchList, 'merchant_name');
          uniqueBranch.forEach((item, index) => {
            item.label = item.merchant_name;
            item.value = item.kmid;
            item.name = item.merchant_name;
          });
          this.setState({ branchList: uniqueBranch });
        }
      })
  }

  onBrandHandleChange = (e) => {
    if (e && e.target && e.target.value) {
      let { page } = this.props;
      this.setState({ selected_group_brand_ids: e.target.value });
      setTimeout(() => {
        this.fetchData(page);
      }, 1000);
    }
  }

  render() {
    const { items, disableSelect, backAction, nextAction, totalCount, heading, entityType, icon, EntityForm, prefix = '', uploadCSV, EntityUploadForm = 'div', CommentsForm, isLoading } = this.props;
    const { approvalAction, columns, searchField, sessionExpired, loginRedirect, selectedArray, actions, status } = this.state;
    let userDetails = GetSessionValue('loginCredentials');
    let index = columns.findIndex(c => c.dataField === 'actions' && c.isDummyField === true)
    if (columns[index]) {
      columns[index].formatter = this.actionsFormatter
    }
    let checkBoxIndex = columns.findIndex(c => c.dataField === 'checkBox' && c.isDummyField === true)
    if (columns[checkBoxIndex]) {
      columns[checkBoxIndex].formatter = this.checkBoxFormatter
    }

    let nameIndex = columns.findIndex(c => c.dataField === 'name')
    if (columns[nameIndex]) {
      columns[nameIndex].formatter = this.BranchNameFormatter
    }

    let textFieldIndex = columns.findIndex(c => c.dataField === 'mastercardMerchantID' && c.isDummyField === true)
    if (columns[textFieldIndex]) {
      columns[textFieldIndex].formatter = this.textInputFormatter
    }
    let ContactDetailsIndex = columns.findIndex(c => c.dataField === 'ContactDetails')
    if (columns[ContactDetailsIndex]) {
      columns[ContactDetailsIndex].formatter = this.ContactDetailsFormatter
    }
    let tripadvisorUrlIndex = columns.findIndex(c => c.dataField === 'tripadvisorUrl')
    if (columns[tripadvisorUrlIndex]) {
      columns[tripadvisorUrlIndex].formatter = this.TripAdvicerUrlFormatter
    }


    const { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page: page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      // custom:true,
      open: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    return (
      <div >
        {loginRedirect ? <Redirect to='/' /> : null}
        <Loader loader={isLoading} />

        {(this.state.isLoading || isLoading) &&
          // <div className="d-flex justify-content-center spinner-block">
          //   <div className="spinner-border" role="status">
          //     <span className="sr-only">Loading...</span>
          //   </div>
          // </div>
          <div className="loading">Loading...</div>
        }

        {/* {!isLoading && */}
        <div className="row justify-content-center">
          <div className="col-md-12 ">
            {/* <div className="heading">
                <div className="row">
                  <div className="col-md-8 d-flex align-items-center">
                    <h2 className="m-0 ">
                      <span>
                        <Icon className="heading-icon ">{icon}</Icon>
                      </span>
                      {heading ? heading == 'branch' ? `${capitalize(heading.toLowerCase())}es` : `${capitalize(heading.toLowerCase())}s` : ''}
                    </h2>
                  </div>
                </div>
              </div> */}
            <div className="card guestUserCards">
              <div className="container-fluid">
                {this.props.globalSearchPlaceHolder && <div className="row">
                  <div className="col-md-4" style={{ height: 67 }}>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder={this.props.globalSearchPlaceHolder ? this.props.globalSearchPlaceHolder : ''} />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>{searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                  </div>
                </div>}
              </div>
              <div >
                <div>
                  <Row>
                    <div className={heading == 'user' || heading == 'branch' ? 'pl-4 d-flex' : 'd-none'}>

                      {/* <button className="btn btn-primary mb-3 table_action_btn" style={{ verticalAlign: 'bottom' }} onClick={this.setShowAddNewModal}>
                          Add New
                          <Icon className="ml-2 checkBoxAlignMent" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">add_circle_outline</Icon>
                        </button> */}

                      {(userDetails.role === 'groupAdmin' || userDetails.role === 'groupUser') && this.state.group_brand_ids && this.state.group_brand_ids.length > 0 &&
                        <div className="form-group brand-select-width">
                          <label className="brand-select-groupbrand">Select Brand</label>
                          <select name="group_brand_ids" className="form-control" defaultValue={this.state.selected_group_brand_ids}
                            onChange={(e) => this.onBrandHandleChange(e)}>
                            <option value="All">All</option>
                            {
                              this.state.group_brand_ids.map((e, index) => {
                                return (
                                  <option key={"group_brand_ids_" + index} value={e.value}>{e.label}</option>
                                );
                              })
                            }
                          </select>
                        </div>}

                    </div>
                    <div className={uploadCSV ? '' : 'd-none'}>
                      <button className="btn btn-primary mb-3 ml-3 table_action_btn" style={{ verticalAlign: 'bottom' }} onClick={this.setShowUploadModal}>
                        Upload CSV
                        <span className="cursor-pointer align-middle ml-2" >
                          <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">cloud_upload</Icon>
                        </span>
                      </button>
                    </div>
                    {heading && heading != 'user' && !disableSelect ?
                      <div
                        // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                        className='pl-3'
                        style={{ width: '13%' }}>
                        <Select
                          options={actions}
                          onChange={this.changeAction}
                          value={this.state.selectedAction}
                          className='action_Selecter'
                        />
                      </div> : null}
                    {/* <div className={selectedArray && selectedArray.length ?
                        '' : 'd-none'}>
                        <button className="btn btn-primary mb-3 table_action_btn" style={{ verticalAlign: 'bottom', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                          onClick={this.multiAction}
                        > Go</button>
                      </div> */}

                    <div className={
                      heading == 'branch' && !disableSelect
                        ? 'ml-auto mr-4' : 'd-none'} style={{ width: '13%' }}>
                      <Select
                        options={status}
                        onChange={this.changeStatus}
                        value={this.state.selectedStatus}
                      // className='action_Selecter'
                      />
                    </div>
                    <div className={
                      heading == 'branch' && disableSelect
                        ? 'ml-auto mr-4' : 'd-none'} >
                      <button className="btn btn-primary mb-3 table_action_btn mr-3 px-lg-5 px-md-1" style={{ verticalAlign: 'bottom' }}
                        onClick={this.backAction}>
                        Back

                      </button>
                      <button className="btn btn-primary mb-3 table_action_btn px-lg-5 px-md-1"
                        style={{ verticalAlign: 'bottom' }}
                        // disabled={this.state.selectedArray && this.state.selectedArray.length > 0 ? false : true}
                        onClick={this.nextAction}>
                        Next

                      </button>
                    </div>
                  </Row>
                </div>
                {/* bootstrap table */}
                <BootstrapTable
                  key={this.state.isValidate ? "Table1" : "Table2"}
                  bootstrap4={true}
                  keyField='id'
                  data={this.state.items && this.state.items.length > 0 ? this.state.items : []}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />

                {/* <div id="targetElement" className='pt-5'>
                    <input type='checkbox' name='allbranch'
                      defaultChecked={this.props.allBranch}
                      checked={this.props.allBranch}
                      disabled={this.state.items && this.state.items.length > 1 ? false : true}
                      id='allbranch'
                      onChange={(e) => {
                        this.handleAllChange(e)
                      }}
                    />
                    <label className='pl-2'>
                      Apply campaign to all branches
                    </label>
                  </div> */}

                {/* delete modal */}
                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.handleDeleteEntity}
                  isLoading={isLoading}
                  show={this.state.showDeleteModal}
                  title={<span>Delete {heading}</span>}
                  message={<span>Are you sure you want to delete the  {heading} ?</span>}
                  action={"Delete"}
                  actionType={'danger'}
                />
                {/* <EnhancedTable></EnhancedTable> */}
                {/* edit modal */}
                <ModalComponent close={this.onCloseEditModal}
                  callback={this.submitForm}
                  show={this.state.showEditModal}
                  isLoading={isLoading}
                  isDisabled={this.state.isFormSubmit}
                  openingFrom={"CreateBranch"}
                  title={`Edit ${heading ? `${capitalize(heading)}` : ''}`}
                  message={<EntityForm
                    initialValues={this.state.selectedEntity}
                    prefix={prefix}
                    onSubmit={this.onSubmitEdit}
                    formikRef={this.formikRef}
                    formType='edit'
                    branchList={this.state.branchList}
                  />}
                  action={"Save"}
                />
                {/* add modal */}
                <ModalComponent close={this.onCloseAddNewModal}
                  callback={this.submitForm}
                  show={this.state.showAddNewModal}
                  isLoading={isLoading}
                  isDisabled={this.state.isFormSubmit}
                  openingFrom={"CreateBranch"}
                  title={`Add New ${heading ? `${capitalize(heading)}` : ''} `}
                  message={<EntityForm
                    initialValues={this.state.selectedEntity}
                    formikRef={this.formikRef}
                    onSubmit={this.onSubmitAddNew}
                    prefix={prefix}
                    branchList={this.state.branchList}
                    getBranchData={this.getBranchData}
                  />}
                  action={"Add"}
                />
                {/* uplaod modal */}
                <ModalComponent close={this.onCloseUploadModal}
                  callback={this.submitForm}
                  show={this.state.showUploadModal}
                  title={`Upload CSV file for ${capitalize(heading)}`}
                  message={<EntityUploadForm formikRef={this.formikRef} onSubmit={this.onSubmitUpload} prefix={prefix} />}
                  action={"Upload"}
                />
                {/* session expiry modal */}
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                  withoutBrach={true}
                />
                {/* show merchant approval modall */}
                <ModalComponent close={this.onCloseApproveModal}
                  callback={this.submitForm}
                  show={this.state.showApproveModal}
                  title={`${approvalAction}`}
                  message={<CommentsForm
                    formikRef={this.formikRef}
                    onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Confirm"}
                  actionType={approvalAction == 'Approve' ? 'success' : 'danger'}
                />
                {/* show view modall */}
                <ModalComponent close={e => this.setState({ openViewModal: false })}
                  callback={this.onCloseViewModal}
                  show={this.state.openViewModal}
                  title={`Branch View`}
                  message={<ViewModal
                    onRef={(ref) => this.viewModalRef = (ref)}
                    // onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Edit"}
                  actionType={'primary'}
                  size='lg'
                  cancelText={'Close'}
                />

                {/* Invalid Value message */}
                <InvalidFieldErrorMessageModalComponent
                  close={this.onCloseErrorMessageModal}
                  show={this.state.errorMessageModal.showErrorMessageModal}
                  isLoading={isLoading}
                  data={this.state.errorMessageModal.data}
                />
              </div>
            </div>
          </div>
        </div>
        {/* } */}

      </div >
    );
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    items: state[pluralize(ownProps.heading)].items,
    sample: state[pluralize(ownProps.heading)],
    isLoading: state[pluralize(ownProps.heading)].isLoading,
    page: state[pluralize(ownProps.heading)].page,
    totalCount: state[pluralize(ownProps.heading)].totalCount,
    sizePerPage: state[pluralize(ownProps.heading)].sizePerPage,
    filters: state[pluralize(ownProps.heading)].filters,
  }),
  (dispatch, ownProps) => ({
    getEntityData: bindActionCreators(getEntityAction(ownProps.heading), dispatch),
    uploadCSVForEntity: bindActionCreators(uploadCSVForEntityAction, dispatch),
    createEntity: bindActionCreators(createEntityActionTrain, dispatch),
    deleteEntity: bindActionCreators(deleteEntityAction, dispatch),
    editEntity: bindActionCreators(editEntityAction(ownProps.heading), dispatch),
    setEntityPage: bindActionCreators(setEntityPageAction(ownProps.heading), dispatch),
    setEntitySort: bindActionCreators(setEntitySortAction(ownProps.heading), dispatch),
    setEntitySearch: bindActionCreators(setEntitySearchAction(ownProps.heading), dispatch),
    setEntityFilters: bindActionCreators(setEntityFiltersAction(ownProps.heading), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    getDetails: bindActionCreators(getEntityDetails, dispatch),
    confirmMerchant: bindActionCreators(confirmMerchantAction, dispatch),
    multiAction: bindActionCreators(multiAction, dispatch),
    // getBranchListByBrandId: bindActionCreators(getBranchListByBrandIdAction, dispatch),
    getBranchListFromNode: bindActionCreators(getBranchListFromNodeAction, dispatch),
    createMultipleBranchAction: bindActionCreators(createMultipleBranchAction, dispatch),
    // getBranchListByNameAction: bindActionCreators(getBranchListByNameAction, dispatch)
    getUserGroupBrand: bindActionCreators(getUserGroupBrandAction, dispatch),
  })
)(EntityList);
