import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import ImageModal from '../../components/ImageModal'
import CropModal from '../../components/CropModal'
import ImageCrop from './ImageCrop'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Managecreative } from '../../components/Forms/Managecreative'
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CropIcon from '@material-ui/icons/Crop';
import BootstrapTable from 'react-bootstrap-table-next';
import classnames from 'classnames';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import Icon from '@material-ui/core/Icon'
import RouteLeavingGuard from '../../utils/components/RouteLeavingGuard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUpload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import config from '../../config/config'
import { Tabs, Tab, Col, Row, Card } from 'react-bootstrap'
import Scrollbar from 'react-smooth-scrollbar';
import {
  manageCreativeAction,
  getManageCreativeAction,
  deleteEntityAction,
  createCopyTextAction,
  getBrandDetailsAction
} from '../../redux/actions'
import { Redirect } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  success as successNotificationAction,
  error as errorNotificationAction
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import { green } from "@material-ui/core/colors";
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal';
import { GetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage";

class Merchants extends Component {
  fileInputRef = React.createRef();
  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    showVerifyModal: false,
    activeTab: '1',
    type: 'files',
    filesArray: [],
    loader: false,
    verifyModalText: <span><b>Branch Details are not yet verified.</b><br /> Please edit and verify all the fields related Branch Information.</span>,
    progressData: {},
    documents: [],
    brandDetails: [],
    sessionExpiryModal: false,
    loginRedirect: false,
    showViewModal: false,
    showCropModal: false,
    selectedEntity: {},
    id: '',
    selectedIndex: '',
    fileSizeExceeded: false,
    copyText: "",
    additionalCopyText: "",
    finalCropWidth: 1000,
    finalCropHeight: 600
  };
  pagination = {
    sizePerPage: 10,
    page: 1
  }

  fetchData = () => {
    let {
      getManageCreative, getBrandDetailsAction
    } = this.props;
    let { sizePerPage } = this.pagination
    this.setState({ isLoading: true })
    getManageCreative('', (res, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (sessionExpiryModal) {
        return
      }
      if (type == 'success') {
        this.setState({ documents: res })
      } else if (type == 'error') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          this.props.successNotification(notification)
        })
      }
    })

    getBrandDetailsAction('', (res, type) => {
      if (type == 'success') {
        this.setState({ brandDetails: res[0] });
        this.setState({ copyText: res[0] ? res[0].copy : "" });
        this.setState({ additionalCopyText: res[0] ? res[0].additionalCopy : "" });
      }
    })

  }
  componentDidMount() {
    this.fetchData(this.props.page || this.pagination.page)
    this.dragCounter = 0
    let div = this.dropRef.current
    if (div) {
      div.addEventListener('dragenter', this.handleDragIn)
      div.addEventListener('dragleave', this.handleDragOut)
      div.addEventListener('dragover', this.handleDrag)
      div.addEventListener('drop', this.handleDrop)
    }
    let userDetails = GetSessionValue('loginCredentials')
    if (userDetails) {
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      this.fetchData()
    }
  }

  setShowAddNewModal = () => {
    this.setState({ showAddNewModal: true })
  }
  onCloseAddNewModal = () => {
    this.setState({ showAddNewModal: false, selectedEntity: null })
  }

  setShowEditModal = (selectedEntity) => {
    this.setState({ showEditModal: true, selectedEntity })
  }
  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedEntity: null })
  }
  setShowDeleteModal = (selectedEntity) => {
    this.setState({ showDeleteModal: true, selectedEntity })
  }
  setShowViewModal = (selectedEntity) => {
    this.setState({ showViewModal: true, selectedEntity })
  }
  onCloseViewModal = (selectedEntity) => {
    this.setState({ showViewModal: false })
  }
  setShowCropModal = (index, selectedEntity, id) => {
    this.setState({ showCropModal: true, selectedEntity, id, selectedIndex: index })
  }
  onCloseCropModal = (selectedEntity) => {
    this.setState({ showCropModal: false })
  }
  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedEntity: null })
  }
  onSubmitCroppedImg = () => {
    let index = this.state.selectedIndex
    let filesArray = this.state.filesArray
    if (this.cropImgRef && this.cropImgRef.state && this.cropImgRef.state.croppedImg) {
      let file = filesArray[index]
      let croppedImg = this.cropImgRef.state.croppedImg
      croppedImg.name = file.name
      croppedImg.lastModified = file.lastModified
      croppedImg.lastModifiedDate = file.lastModifiedDate
      filesArray[index] = croppedImg
    }
    this.onCloseCropModal()
    this.setState({ filesArray: filesArray })
  }

  handleDeleteFile = () => {
    let { deleteBranch, page, successNotification, deleteCreative } = this.props;
    let { selectedEntity } = this.state
    selectedEntity.docType = "creatives"
    deleteCreative(selectedEntity, 'documents', (res, type) => {
      if (type == 'error') {
        return
      }
      // notification
      this.onCloseDeleteModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = 'File deleted succesfully'
        successNotification(notification)
      })
    })
  }
  submitForm = () => {
    if (this.formik)
      this.formik.submitForm()
  }
  formikRef = (e) => {
    this.formik = e
  }
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true, })
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter > 0) return
    // this.setState({ dragging: false, displayAttachmentsModal: true })
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.dragCounter = 0
      this.setState({ dragging: false, displayAttachmentsModal: true })
      let files = e.dataTransfer.files;
      this.appendFiles(files)
    }
  }

  //after selcting files append recent files to previous files
  appendFiles = async (files) => {
    let filesList = []
    this.reader = {}
    let img = {}
    for (let i = 0; i < files.length; i++) {
      this.reader[i] = new FileReader();
      if (!files[i].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        console.log('Please select valid image.')
      }
      this.reader[i].onload = (e) => {
        img[i] = new Image();
        img[i].onload = () => {
          filesList.push(files[i])
          if (i === (files.length - 1)) {
            this.finalUploadFile(filesList, files)
          }
          // if (img[i].width < finalCropWidth || img[i].height < finalCropHeight) {
          let ratioWidth = img[i].height * 5 / 3;
          if (img[i].width != ratioWidth) {
            setTimeout(() => {
              this.setShowCropModal(i, files[i], document.getElementById(`${files[i].fileName}__${i}`))
            }, 1000);
          }
          console.log("this.state", this.state);
        };
        img[i].onerror = () => {
          if (i === (files.length - 1))
            this.finalUploadFile(filesList, files)
          console.log('Invalid image content.')
        };
        img[i].src = e.target.result;
      };
      this.reader[i].readAsDataURL(files[i]);
    }

  }

  finalUploadFile = async (filesList, files) => {
    let errorcount = files.length - filesList.length
    let errorMsg = ""
    if (errorcount >= 1) {
      errorMsg = errorcount > 1 ? `${errorcount} files are invalid.` : `${errorcount} file is invalid.`
      let notification = { ...notificationOpts };
      notification.message = `${errorMsg} Please select valid Image file.`;
      this.props.errorNotification(notification)
    }
    let modifiedFiles = []
    for (let i = 0; i < filesList.length; i++) {
      modifiedFiles.push(filesList[`${i}`])
    }
    // let filesArray = this.state.filesArray ? this.state.filesArray : []//arr2
    let filesArray = [];
    let newaArr = []
    for (let index in modifiedFiles) {
      let response = await filesArray.findIndex((obj) => {
        return obj.name === modifiedFiles[index].name
      })
      newaArr.push(modifiedFiles[index])
    }
    filesArray = [...filesArray, ...newaArr]
    console.log('files', filesArray);
    filesArray.forEach((item, index) => {
      if (item && !item.preview) {
        item['preview'] = URL.createObjectURL(item);
      }
    })
    this.setState({ fileSizeExceeded: false })
    filesArray.forEach((item, index) => {
      if (item.size > 2072576) {
        this.setState({ fileSizeExceeded: true })
      }
    })
    if (document.getElementById('FilesUpload')) {
      document.getElementById('FilesUpload').value = ''
    }
    this.setState({ filesArray: filesArray, displayAttachmentsModal: true })
  }

  //remove selected files
  removeFile = (ind, file) => {
    let filesArray = this.state.filesArray
    // delete filesArray[ind];
    filesArray.splice(ind, 1);
    this.setState({ fileSizeExceeded: false })
    filesArray.forEach((item, index) => {
      if (item.size > 2072576) {
        this.setState({ fileSizeExceeded: true })
      }
    })
    if (filesArray && filesArray.length && filesArray.length > 0) {
      this.setState({ filesArray })
    } else {
      this.setState({ filesArray, displayAttachmentsModal: false })
    }
  }

  //submit uplaod
  submit = (e) => {
    if (this.state.displayAttachmentsModal) {
      this.setState({ displayAttachmentsModal: false, })
    } else if (this.state.displayEmojiPicker) {
      this.setState({ displayEmojiPicker: false, })
    }
    e.preventDefault()
    if (this.state.filesArray && this.state.filesArray.length && this.state.filesArray.length > 0) {
      this.onFileSelect()
    } else {
      this.sendDataToServer()
    }
  }

  //on file nselection
  onFileSelect = (event) => {
    let filesArray = this.state.filesArray;
    filesArray.map((item, index) => {
      let fileId = `${item.name}-${item.lastModified}`;
      let loginData = GetSessionValue('loginCredentials');
      let headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${loginData.accessToken}`,
      };
      let activeContact = this.state.activeContact;
      let appendFielData = {};
      let fileUrl = ''
      appendFielData = {
        logUserId: loginData.userId ? loginData.userId : '',
      }
      fileUrl = `${config.apiUrl}uploads/files?preview=${item.preview}&size=${item.size.toString()}&fileId=${fileId}&mimetype=${item.type}&name=${item.name}&uploadPath=docs&docType=creatives`
      let body = item.slice(0, item.size + 1)
      let url = fileUrl
      this.setState({ loader: true })
      let request = fetch(url, { method: 'POST', headers: headers, body: body })
      return request.then(res => res.json())
        .then(async responseJson => {
          this.fetchData();
          if (this.state.filesArray.length - 1 === 0) {
            setTimeout(() => {
              let notification = { ...notificationOpts };
              notification.message = responseJson.respMessage;
              this.props.successNotification(notification)
            })
          }

          let filesArray = this.state.filesArray
          filesArray.forEach((item, index) => {
            if (item.name == responseJson.name) {
              filesArray.splice(index, 1)
              this.fetchData()
            }
          })
          this.setState({ filesArray: filesArray })
          // return responseJson;
        }).catch(err => {
          return 'serverError';
        });
    })
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn)
      div.removeEventListener('dragleave', this.handleDragOut)
      div.removeEventListener('dragover', this.handleDrag)
      div.removeEventListener('drop', this.handleDrop)
    }
  }

  sessionExpired = () => {
    DeleteSessionValue('user')
    setTimeout(() => this.setState({ loginRedirect: true }), 1000)
  }
  // Toggling Tabs
  toggle = async (tab) => {
    const { activeTab } = this.state;
    let { getCampaignTemplateData, previewData, setTabs } = this.props
    let type = this.state.type
    if (activeTab !== tab) {
      if (tab == '1') {
        type = 'files'
      } else if (tab == '2') {
        type = 'media'
      }
      await this.setState({
        activeTab: tab,
        type: type
      });

    }
  };


  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  }

  dragOver = (e) => {
    e.preventDefault(e);
  }

  dragEnter = (e) => {
    e.preventDefault(e);
  }

  dragLeave = (e) => {
    e.preventDefault(e);
  }

  fileDrop = (e) => {
    e.preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      this.appendFiles(files);
    }
  }

  fileInputClicked = () => {
    this.fileInputRef.current.click();
  }


  createCopyText = () => {
    let obj = {
      brandId: this.props.user.brandId,
      copy: this.state.copyText,
      additionalCopy: this.state.additionalCopyText
    }

    this.props.createCopyText(obj, (res, type) => {
      // console.log(res, type)
      if (type != "error")
        setTimeout(() => {
          // this.setState({ copyText: "" })
          let notification = { ...notificationOpts }
          notification.message = res.respMessage
          this.props.successNotification(notification)
        })
      else {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res.errorMessage
          this.props.successNotification(notification)
        })
      }
    })
  }

  render() {
    const { showDeleteModal, selectedBranch, showVerifyModal, verifyModalText, progressData, sessionExpired, loginRedirect, selectedEntity, id, fileSizeExceeded, activeTab, copyText, additionalCopyText } = this.state
    console.log("this.state", this.state);
    const { branches, page, totalCount, user, history, documents, isLoading } = this.props;
    let pagination = paginationFactory({
      page,
      sizePerPage: this.pagination.sizePerPage,
      totalSize: totalCount,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true
      // onPageChange : (page, sizePerPage) => {
      //   console.log('sdfjsdk')
      // }
    })
    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : null}
        <Loader loader={isLoading} />
        <ModalComponent
          callback={this.sessionExpired}
          show={sessionExpired}
          title={"Session Expired"}
          message={<span>Session Expired, Please login Again</span>}
          action={"Go to Login Page"}
          actionType='success'
          hideCancelBtn={true}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-lg-4 d-flex align-items-center">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">build</Icon>
                    </span> Manage Assets
                  </h2>
                </div>

              </div>
            </div>
            <div className="card">
              <div className="mt-4 container pl-0 pr-0">
                <Tabs defaultActiveKey="Images" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="Images" title="Manage Creatives">
                    <div className="tabs tabs--justify">
                      <div className="tabs__wrap">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '1' })}
                              onClick={() => {
                                this.toggle('1');
                              }}
                            >Upload Images
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '2' })}
                              onClick={() => {
                                this.toggle('2');
                              }}
                            >Media Library
                            </NavLink>
                          </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}  >
                          {/* sms */}
                          <TabPane tabId="1" className='template__card'>
                            <div className="row">

                              <div className="col-md-12 text-center ">
                                {/* <div className='col-sm-12 pt-4 pl-4 ml-2  '>
                           
                          </div> */}
                                <p className='text-secondary text-left mb-0 mt-3 main-file-uploadHeading'>
                                  The media uploaded here will be used for partner email and app creatives
                                </p>

                                <div className="drop-container main-file-upload"
                                  onDragOver={this.dragOver}
                                  onDragEnter={this.dragEnter}
                                  onDragLeave={this.dragLeave}
                                  onDrop={this.fileDrop}
                                  onClick={this.fileInputClicked}
                                >
                                  <div className="drop-inner-content">
                                    <div className='dotsCircle'>
                                      <FontAwesomeIcon icon={faUpload} style={{ fontSize: 40 }} />
                                    </div>
                                    <h5 className='pt-4 text-secondary'><b>Drag & drop images here or use the button</b></h5>
                                    {/* <h5 className='text-secondary'><b> 1000px X 600px Resolution (5:3 aspect ratio)</b></h5> */}
                                    <h5 className='text-secondary'><b> 5:3 Aspect ratio </b></h5>
                                    <input
                                      ref={this.fileInputRef}
                                      className="file-input"
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      // multiple
                                      onChange={(e) => this.appendFiles(e.target.files)}
                                    />
                                  </div>
                                </div>

                                {/* <div className='main-file-upload'>
                            <div className="upload-btn-wrapper">
                              <button className="custom-btn custom-btnCustomized">
                                <div className='dotsCircle'>
                                  <FontAwesomeIcon icon={faUpload} style={{ fontSize: 40 }} />
                                </div>
                                <h5 className='pt-4 text-secondary'><b>Drag & Drop images here or use button</b></h5>
                              </button>
                              <input id='FilesUpload' type="file" className='custom-file-input' multiple onChange={(e) => this.appendFiles(e.target.files)} />
                            </div>
                          </div> */}
                                <div>
                                  <label className={fileSizeExceeded ? 'img_error pt-1' : 'd-none'}>
                                    File size must be less than 2 MB
                                  </label>
                                </div>
                                {this.state.filesArray.length ? <div className='pt-4 mr-4 float-right'>
                                  <button className="btn btn-primary mb-3 uploadBtnCustomized"
                                    onClick={this.submit}
                                    disabled={this.state.filesArray.length && !fileSizeExceeded ? false : true}
                                  > Save
                                  </button>
                                </div> : null}
                              </div>
                              <div className="col-sm-12">
                                <div
                                  // className='onDragOver onDragOverCustmized'
                                  style={this.state.filesArray.length ? { height: 350, overflowX: 'hidden', overflowY: 'auto' } : {}}
                                >
                                  {/* <div className='row'>


                            </div> */}

                                  <div
                                    className='row pl-lg-5 pl-md-0 pl-sm-0 pl-1 pr-1 ml-lg-5'
                                  // style={this.state.filesArray.length ? { height: 267 } : {}}
                                  // className={`dropzone__imgs-wrapper ${this.state.filesArray.length ? 'p-lg-5 p-md-2 p-sm-2 p-2' : ''}`}
                                  >
                                    {this.state.filesArray && this.state.filesArray.length && this.state.filesArray.length > 0 ? this.state.filesArray.map((file, i) => (
                                      file.type && file.type.startsWith("image") ?
                                        <div className='col-lg-2 col-md-4 col-sm-4 col-6 text-center  mx-lg-2 mx-0'>

                                          <div
                                          // className="d-flex dropImagesWidth"
                                          >
                                            <div
                                              // className="dropzone__img"
                                              key={i}
                                            // style={{
                                            //   backgroundImage: `url(${file.preview})`,
                                            //   backgroundSize: 'contain',
                                            // }}
                                            >
                                              <h2 className='iconsTextPostion'>

                                                <span className='float-right pl-2'>
                                                  <FontAwesomeIcon icon={faTimesCircle}
                                                    color="#f44336" className="heading-icon"
                                                    style={{ fontSize: '1.5rem' }}
                                                    onClick={() => this.removeFile(i, file)}
                                                  />
                                                </span>
                                                <span className='float-right '>
                                                  <CropIcon
                                                    className="heading-icon" color='primary'
                                                    onClick={() => this.setShowCropModal(i, file, document.getElementById(`${file.fileName}__${i}`))} />
                                                </span>
                                              </h2>
                                              {/* <img
                                                src={file.preview}
                                                id={`${file.fileName}__${i}`}
                                                className='rounded-circle manageCreativeImage' /> */}
                                                 <img
                                                src={file.preview}
                                                id={`${file.fileName}__${i}`}
                                                className='manageCreativeImage' />
                                              {/* <ClearIcon className="heading-icon" color="error" onClick={() => this.removeFile(i, file)} /> */}
                                            </div>

                                            {/* <CircularProgress className="centered" style={{ width: '25px', height: '25px' }} variant="static"
                                        value={file && file.percentage ? file.percentage : 0}
                                      /> */}
                                          </div>
                                          <label className={file.size > 2072576 ? 'img_error' : 'd-none'}>File size must be less than 2 MB</label>
                                        </div> :
                                        <div className="dropzone__img" key={i} style={{ backgroundImage: `url(${file.preview})` }} >
                                          <div className='fileName'>{file.name}</div>
                                          <i className="fas fa-times"></i>
                                          <Icon className="heading-icon" color="error" onClick={() => this.removeFile(i, file)}>close</Icon>
                                        </div>
                                    )) : null}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="2" className='template__card'>
                            <div className='row'>
                              <div className='col-sm-12'>

                                <div className="content">
                                  <Scrollbar className="images_scrollbar">
                                    <div style={{ overflowX: 'hidden' }} className="">
                                      <Row className='ml-0 mr-0'>
                                        {documents && documents.length ? documents.map((item, index) => {
                                          return <Col lg={2} md={3} sm={4} className='pl-0 pr-0' >
                                            <div className='center_align'><Card
                                              className="Img__card"
                                            >
                                              <img src={`${config.imgUrl}docs/${item.fileName}`} className="list_img" />
                                              <div className='overlay'>
                                                <Icon className="icondisplay1" onClick={() => this.setShowDeleteModal(item)} >delete</Icon>
                                                <VisibilityIcon className="icondisplay2" onClick={() => this.setShowViewModal(item)} />
                                                <img src={`${config.imgUrl}docs/${item.fileName}`} className="list_img" />
                                              </div>

                                            </Card>
                                            </div>
                                          </Col>
                                        }) : null}
                                      </Row>
                                    </div>
                                  </Scrollbar>
                                  <ModalComponent close={this.onCloseDeleteModal}
                                    callback={this.handleDeleteFile}
                                    show={this.state.showDeleteModal}
                                    title={"Delete File"}
                                    message={`Are you sure you want to delete the File`}
                                    action={"Delete"}
                                    actionType={'danger'}
                                  />
                                  <ImageModal close={this.onCloseViewModal}
                                    show={this.state.showViewModal}
                                    title={"mc"}
                                    message={<img src={selectedEntity ? `${config.imgUrl}docs/${selectedEntity.fileName}` : ''} alt={selectedEntity ? selectedEntity.fileName : ''} style={{ maxHeight: 700, objectFit: 'contain' }} className='w-100' />}
                                    cancelText={false}
                                    size={'xl'}
                                  />
                                  <CropModal close={this.onCloseCropModal}
                                    // callback={this.submitForm}
                                    show={this.state.showCropModal}
                                    title={"Image Crop"}
                                    data={selectedEntity}
                                    message={<ImageCrop
                                      selectedImage={selectedEntity}
                                      id={id}
                                      onRef={(ref) => this.cropImgRef = ref}
                                      callback={this.onSubmitCroppedImg}
                                      close={this.onCloseCropModal}
                                    />}
                                    size={'lg'}
                                  />
                                  <ModalComponent close={this.onCloseAddNewModal}
                                    callback={this.submitForm}
                                    show={this.state.showAddNewModal}
                                    title={"Add New Manage creative"}
                                    message={<Managecreative formikRef={this.formikRef} onSubmit={this.onSubmitAddNew} />}
                                    action={"Add"}
                                  />
                                  {/* session expiry modal */}
                                  <SessionExpiryModal
                                    isOpen={this.state.sessionExpiryModal}
                                  />
                                </div>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Copy" title="Copy">
                    <div className="row">
                      <div className="col-4">

                        <div className="form-group">
                          <label>Copy Text (200 character)</label>
                          <textarea value={copyText} id="copy" onChange={(e) => {
                            this.setState({ copyText: e.target.value })
                          }} name="copy" rows="4" cols="50" maxlength="200" placeholder="Add text which will display to the user" className="form-control" />
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="form-group">
                          <label>Additional Copy Text (100 character)</label>
                          <textarea value={additionalCopyText} id="additionalCopy" onChange={(e) => {
                            this.setState({ additionalCopyText: e.target.value })
                          }} name="additionalCopy" rows="4" cols="50" maxlength="100" placeholder="Add text which will display to the user" className="form-control" />
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-sm btn-primary" type="button" onClick={() => this.createCopyText()}
                        >
                          Submit
                        </button>
                      </div>

                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    isLoading: state.documents.isLoading,
    documents: state.documents.items,
    user: state.user,
    brandDetails: state.brandDetails
  }),
  dispatch => ({
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    manageCreative: bindActionCreators(manageCreativeAction, dispatch),
    getManageCreative: bindActionCreators(getManageCreativeAction('manageCreative'), dispatch),
    deleteCreative: bindActionCreators(deleteEntityAction, dispatch),
    createCopyText: bindActionCreators(createCopyTextAction, dispatch),
    getBrandDetailsAction: bindActionCreators(getBrandDetailsAction(), dispatch)
  })
)(Merchants);
