import axiosMethodRequest from '../config/service';
import { GET_TRENDS_DATA, GET_SELECTED_TRENDS_DATA } from '../redux/insights/actionTypes';

export const getTrendsDataAPICall = (brandId, datePayload, trendFlag, branchId, entityId) => {
    return dispatch => {
        console.log('getTrendsDataAPICall brandId', brandId, datePayload, branchId);
        branchId = branchId == 'all' ? '' : branchId;
        let type = entityId ? GET_SELECTED_TRENDS_DATA : GET_TRENDS_DATA;
        dispatch({
            type: type,
            payload: { data: null, loader: true }
        })
        //const url = `reviewsdata`
        //const url = `reviewsdata?date=${date}`
        // brandId = 2811;
        const url = `reviewsdata?brandId=${brandId}&datePayload=${JSON.stringify(datePayload)}&trendFlag=${trendFlag}&kmid=${branchId}&entityId=${entityId}`
        return axiosMethodRequest('GET', url).then((response) => {
            if (response && response.data && response.data.respCode) {// 
                dispatch({
                    type: type,
                    payload: { data: response.data, loader: false }
                })
            }
        })
    }
}
