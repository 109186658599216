import React, { PureComponent } from 'react';
import {
    Row, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// import Smstemplate from './SmsTemplate'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getCampaignTemplateData
} from '../../redux/actions'
import placeHolderImg from '../../assets/images/placeholder150.png'
class CampaignPreview extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor() {
        super();
        this.state = {
            activeTab: '1',
        };
    }

    toggle = async (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            await this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const { activeTab, isLoading } = this.state;
        const { templateData, type } = this.props
        return (
            <Col md={12} lg={12} xl={12} className='px-0'>
                <div className="tabs tabs--justify">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        this.toggle('1');
                                    }}
                                >{type == 'app' ? 'Detail' : 'Image 1'}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >{type == 'app' ? 'Summary' : 'Image 2'}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => {
                                        this.toggle('3');
                                    }}
                                >Logo
                    </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}  >
                            <TabPane tabId="1" className='template__card_preview'>
                                <div className='text-center'>
                                    <img src={placeHolderImg} className='preview_col_img' />
                                </div>
                            </TabPane>
                            <TabPane tabId="2" className='template__card_preview'>
                                <div className='text-center'>
                                    <img src={placeHolderImg} className='preview_col_img' />
                                </div>
                            </TabPane>
                            <TabPane tabId="3" className='template__card_preview'>
                                <div className='text-center'>
                                    <img src={placeHolderImg} className='preview_col_img' />
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </Col>
        );
    }
}

export default connect(
    state => ({
        sample: state,
    }),
    dispatch => ({
        getCampaignTemplateData: bindActionCreators(getCampaignTemplateData, dispatch),

    })
)(CampaignPreview)
// export default withTranslation('common')(JustifyTabs);