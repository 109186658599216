import React, { Component } from "react";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import ModalComponent from './../../components/Modal'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon'
import Loader from '../../components/Loader'
import {
  getCampaignAction,
  deleteCampaignAction,
  setCampaignForEditAction,
  setCampaignsPageAction,
  setCampaignsSortAction,
  setCampaignsSearchAction,
  setCampaignsFiltersAction,
  savecampaignResponseAction,
  editCampaignAction,
  getProjectionsForUserActionTrain,
  loadBrandData,
  getBrandGroupWiseAction
} from '../../redux/actions'
import config from '../../config/config'
import { Redirect } from "react-router-dom";
import {
  success as successNotificationAction,
  error as errorNotificationAction
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { GetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage";
import { customerType } from "../../utils/constants/constant";
import CampaignSettings from "../CampaignSettings/CampaignSettings";

let languageData = {}
const getCurrency = () => {
  // return <i className="fa fa-usd"></i>
  return 'GBP'
}
class FutureCampaign extends Component {
  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    start_date: '',
    end_date: '',
    isLoading: false,
    campaigns: [],
    pagination: {},
    sortCount: 0,
    sessionExpiryModal: false,
    loginRedirect: false,
    templateAcceptanceModal: false,
    templateRejectModal: false,
    searchField: "",
    selectedCampaign: {},
    isPasscutoffDate: new Date() < new Date(new Date().getFullYear(), new Date().getMonth(), 25)
  };
  pagination = {
    limit: 10,
    page: 1
  }

  checkCutoffDate = (row) => {
    let openingTime = new Date(row.openingTime)
    let month = new Date(openingTime.getFullYear(), openingTime.getMonth() - 1, 25)
    return (new Date() < month)
  }

  fetchData = () => {
    let {
      getFutureCampaignData,
      filters,
      successNotification
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField } = this.state
    if (searchField) {
      if (!filters) {
        filters = {}
      }
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    this.setState({ isLoading: true })
    getFutureCampaignData(page, limit, filters, '', (response, pagination, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (sessionExpiryModal) {
        return
      }
      if (type == 'error') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = response
          successNotification(notification)
        })
      }
    })
  }

  componentDidMount = async () => {
    this.fetchData(this.props.page || this.pagination.page)
    // let {campaigns,campaignResponseAction}=await this.props;
    // if(campaigns && campaigns.length){
    //   setTimeout(()=>campaignResponseAction(campaigns[0]),1000)
    // }
    let {
      getProjectionsForUser,
      loadBrandsData,
      getBrandGroupWise
    } = this.props
    let userDetails = GetSessionValue('user');
    
    if (userDetails) {
      if (userDetails.role !== "groupAdmin" && userDetails.role !== "groupUser") {
        await getProjectionsForUser(userDetails.brandObjId, async (res) => {
          loadBrandsData(res)
        })
      } else if (userDetails.role === "groupAdmin") {
        await  getBrandGroupWise(userDetails.brandId, (res) => {         
          if (res && res.length > 0) {
            let brandObjId = res[0]._id;
            getProjectionsForUser(brandObjId, (res) => {
              loadBrandsData(res);
            })
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      // this.fetchData()
    }
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getMonthText = lastTransaction => {
    return lastTransaction <= 1 ? `${lastTransaction} Month` : `${lastTransaction} Months`
  }

  columns = [
    {
    dataField: 'actions',
    isDummyField: true,
    text: languageData.actions ? languageData.actions : 'Actions',
    classes: 'vhalign',
    formatter: (cell, row) => {
      return (
        <React.Fragment>
          {/* <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-danger">delete</Icon>
          </span>
          {this.checkCutoffDate(row) &&
            <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }}
              onClick={() => this.handleEditCampaign(row)} > 
              <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
            </span>
          } */}
          <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => this.setShowCampaignModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-primary" title="Pause Campaign">pause_circle_outline</Icon>
          </span>
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'name',
    sort: true,
    text: languageData.campaignName ? languageData.campaignName : 'Campaign Name',
    classes: 'vhalign'
    // formatter: (cell, row) => {
    //   return (
    //     <React.Fragment>

    //       <span className={` ${this.checkCutoffDate(row) ? " future-action cursor-pointer" : ""}`} style={{
    //         display: 'inline-block',
    //         textDecoration: `${this.checkCutoffDate(row) ? "underline" : ""}`,
    //       }}
    //         onClick={() => this.handleEditCampaign(row)} >
    //         {cell.toLocaleString()}
    //       </span>
    //     </React.Fragment>
    //   )
    // }
  },
  {
    dataField: 'status',
    text: 'Status',
    classes: 'vhalign',
    formatter: cell => {
      let status = ""
      if (["Active", "Bank Approved"].includes(cell)) {
        status = "Approved"
      }
      if (["Pending", "Bank Approval Pending"].includes(cell)) {
        status = "Submitted"
      }
      if (["Rejected", "Krowd Rejected", "Bank Rejected"].includes(cell)) {
        status = "Rejected"
      }
      return status
      // return cell.toLocaleString()
    }
  },
  {
    dataField: 'discountPercent',
    sort: true,
    isDummyField: true,
    text: 'Discount(%)',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      switch (row.discountType) {
        case 'dp': {
          return row.discountPercent && <span>{row.discountPercent}%</span>
        }
        case 'dv': {
          return <span> {row.discountValue} {getCurrency()}</span>
        }
        case 'ss': {
          return (
            <span> {row.discountSpreadValue}% </span>
          )
        }
        default: return 'none'
      }
    }
  },

  {
    dataField: 'successFee',
    sort: true,
    text: languageData.successFee ? languageData.successFee : 'Success Fee',
    classes: 'vhalign',
    formatter: (cell, row) => {
      if (cell)
        return cell + '%'
      return cell
    }
  },
  {
    dataField: 'openingTime',
    sort: true,
    text: languageData.period ? languageData.period : 'Period',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      return moment(cell).format('MM/YYYY')
    }
  },

  {
    dataField: 'merchantNames',
    sort: true,
    text: languageData.branchName ? languageData.branchName : 'Branch Name',
    classes: 'vhalign',
    formatter: cell => {
      if (cell) {
        return cell.toLocaleString()
      }
    }
  },
  {
    dataField: 'customerType',
    sort: true,
    text: languageData.customerType ? languageData.customerType : 'Customer Type',
    classes: 'vhalign',
    formatter: (col, row) => {
      return customerType[col]
      // return col === 'new' ? 'New Customer' : col === 'all' ? 'All' : `Existing Customer `
    }
  },
  // {
  //   dataField: 'discountType',
  //   sort: true,
  //   text: languageData.discountType ? languageData.discountType : 'Discount Type',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     switch (cell) {
  //       case 'dp': {
  //         return 'Flat Percentage'
  //       }
  //       case 'dv': {
  //         return 'Discount Value'
  //       }
  //       case 'ss': {
  //         return 'Stretch Spend'
  //       }
  //       default: return ''
  //     }
  //   }
  // },
  // {
  //   dataField: 'discountAmount',
  //   sort: true,
  //   isDummyField: true,
  //   text: languageData.minimumSpend ? languageData.minimumSpend : 'Minimum Spend',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     switch (row.discountType) {
  //       case 'dp': {
  //         return 'none'
  //       }
  //       case 'dv': {
  //         return <span> {row.discountAmount} {getCurrency()}</span>
  //       }
  //       case 'ss': {
  //         return (
  //           <span> {row.discountSpreadAmount}% <br /> <small>Avg. Spend : {row.spread_avg_spend} {getCurrency()}</small></span>
  //         )
  //       }
  //       default: return 'none'
  //     }
  //   }
  // },
  {
    dataField: 'suggestions',
    sort: true,
    text: "Additional Comments",
    classes: 'vhalign ',
    formatter: cell => {
      return <ToolTipContainer
        toolTipText={cell.toLocaleString()}
        containerClassNames="ellipsis-text ml-2 mb-1"
        id="discountPercent"
      >
        {cell.toLocaleString()}
      </ToolTipContainer>
      // return cell.toLocaleString()
    }
  },
  {
    dataField: 'recurring',
    sort: true,
    isDummyField: true,
    text: languageData.recurring ? languageData.recurring : 'Recurring Campaign',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      switch (row.recurring) {
        case true: {
          return <p>Yes, it is a monthly recurring campaign</p>
        }
        case false: {
          return <p>Not a monthly recurring campaign</p>
        }

        default: return <p>Not a monthly recurring campaign</p>
      }
    }
  },
  ]

  setShowDeleteModal = (campaign) => {
    this.setState({ showDeleteModal: true, selectedCampaign: campaign });
  }

  handleDeleteCampaign = () => {
    let { deleteCampaign, successNotification } = this.props;
    let { selectedCampaign: campaign } = this.state;
    deleteCampaign(campaign, (err) => {
      if (err) {
        return
      }
      this.setState({ showDeleteModal: false, selectedCampaign: {} }, () => {
        // show notification
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = 'Campaign deleted successfully'
          successNotification(notification)
        })
      });
      this.fetchData(this.props.page)
    })
  }

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  }

  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { sizePerPage } = this.pagination;
    return parseInt(totalSize / sizePerPage) + (totalSize % sizePerPage ? 1 : 0)
  }

  handleEditCampaign = (campaign) => {
    if (this.checkCutoffDate(campaign)) {
      let { setCampaignForEdit } = this.props;
      campaign.start_date = new Date(campaign.openingTime)
      setCampaignForEdit(campaign)
      this.props.history.push(`/user/campaigns/edit?brandId=${campaign.brandId}`)
    }
  }

  handleEditCampaignOverview = campaign => {
    this.props.history.push(`/user/campaign-overview/edit`)
  }

  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters }) => {
    let {
      setFutureCampaignsPage,
      setFutureCampaignsSort,
      setFutureCampaignsSearch,
      setFutureCampaignsFilters,
      getFutureCampaignData
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField } = this.state
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    switch (type) {
      case 'pagination': {
        setFutureCampaignsPage(page, sizePerPage)
        break;
      }
      case 'sort': {
        let sortCount = this.state.sortCount
        sortCount = sortCount == 0 ? sortCount + 1 : 0;
        filterCriteria.sortField = {
          direction: sortCount == 0 ? "desc" : 'asc',
          sortfield: sortField,
        }
        this.setState({ sortCount: sortCount })
        getFutureCampaignData(page, limit, filterCriteria, 'sort', (response, pagination) => {
          this.setState({ campaigns: response })
          if (pagination && pagination.totalCount) {
            this.setState({ pagination: pagination })
          }
        })

        break
      }
      case 'search': {
        filterCriteria.globalSearch = {
          value: searchText,
          type: 'user'
        }
        getFutureCampaignData(page, limit, filterCriteria, 'globalsearch', (response, pagination) => {
          this.setState({ campaigns: response })
          if (pagination && pagination.totalCount) {
            this.setState({ pagination: pagination })
          }
        })
        break
      }
      case 'filter': {
        setFutureCampaignsFilters({ ...filters })
        setFutureCampaignsPage(1, sizePerPage)
        break
      }
      default:
        return
    }
  }

  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  handleDateChange = (name, value) => {
    this.handleTableChange('filter', { filters: { [`${name}`]: value ? value.toISOString() : '' } })
  }

  getCurrentPageTotalPage = paginationProps => {
    let { page } = paginationProps
    let totalPages = this.getPageCount(paginationProps)
    if (!totalPages)
      return `0/0`;
    return `${page}/${totalPages}`
  }

  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }

  sessionExpired = () => {
    DeleteSessionValue('user');
    setTimeout(() => this.setState({ loginRedirect: true }), 1000)
  }

  onPageChange = async (page) => {
    this.pagination.page = page
    this.fetchData()
  }

  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  confirmTemplate = () => {
    let { campaignResponse, editCampaign, successNotification, errorNotification, campaigns } = this.props
    let data = campaigns[0]
    data.templates = campaignResponse.templates
    editCampaign(data, (res, type) => {
      if (type == 'success') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
        this.closeTemplateModal(res)
        return;
      }
      let error = res ? res : ''
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = `Error : ${error}`
        errorNotification(notification)
      })
    })

  }
  closeTemplateModal = (type) => {
    console.log('type', type)
    let { campaigns, campaignResponseAction } = this.props;
    if (type) {
      campaignResponseAction(null)
      this.fetchData()
    }
    this.setState({ templateAcceptanceModal: false, templateRejectModal: false })
  }
  rejectTemplates = () => {
    let { campaigns, campaignResponseAction } = this.props;
    this.closeTemplateModal('reject')
  }
  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-2" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content-custom">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)} key={index}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )

  setShowCampaignModal = (campaign) => {
    this.setState({ showCampaignModal: true, selectedCampaign: campaign });
  }

  setCloseCampaignModal = () => {
    this.setState({ showCampaignModal: false });
  }

  render() {
    const { showDeleteModal, selectedCampaign, sessionExpired, loginRedirect, templateAcceptanceModal, templateRejectModal, showCampaignModal } = this.state
    const { totalCount, filters, campaigns, isLoading, campaignResponse } = this.props;
    console.log('Campaign response', campaignResponse)
    let thisMonth = moment().startOf('month')
    const { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      open: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    let templates = campaignResponse && campaignResponse.templates && campaignResponse.templates.length ? campaignResponse.templates : []
    // let data = sessionStorage.getItem('data')
    // let templates = [
    //   {
    //     campaignTemplateText: '<p>Get 5 %&nbsp;off at Nike&nbsp;on a minimum spend of 0 &nbsp;&nbsp;Valid until &lt;Valid Until&gt;&nbsp;[Click Here for More Information] </p>',
    //     type: 'Sms'
    //   },
    //   {
    //     campaignTemplateText: data,
    //     type: 'Email'
    //   },

    // ]
    // console.log('templates', templates)
    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : null}
        <Loader loader={isLoading} />
        <ModalComponent
          close={this.onCloseDeleteModal}
          callback={this.handleDeleteCampaign}
          show={showDeleteModal}
          title={"Delete Campaign"}
          message={<span>Are you sure you want to delete the <b>"{selectedCampaign ? selectedCampaign.name : ''}"</b> campaign ?</span>}
          action={"Delete"}
          actionType='danger'
        />
        <ModalComponent
          callback={this.sessionExpired}
          show={sessionExpired}
          title={"Session Expired"}
          message={<span>Session Expired, Please login Again</span>}
          action={"Go to Login Page"}
          actionType='success'
          hideCancelBtn={true}
        />
        <ModalComponent
          centered={false}
          close={this.setCloseCampaignModal}
          show={showCampaignModal}
          size="xl"
          title={`Edit details of child campaign (${selectedCampaign.name})`}
          message={
            <CampaignSettings campaign={selectedCampaign} />
          }
          actionType={null}
          hideCancelBtn={true}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">fast_forward</Icon>
                    </span> Future Campaigns
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 container-fluid pr-4">
                <div className="row">
                  <div className="col-md-4 offset-md-8 px-0 text-right col-sm-8 offset-sm-4 col-9 offset-3">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder="Search Campaign Name" />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`col-md-5 offset-3 ${campaignResponse && campaignResponse.name ? '' : 'd-none'}`} onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                    Campaign template preview - <strong>{campaigns && campaigns.length && campaigns[0] && campaigns[0].name ? campaigns[0].name : ''}</strong> <span style={{ textDecoration: 'underline', color: 'blue' }}>click here</span>
                    <button className="btn pull-right btn-outline-primary" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <i className="fa fa-filter"></i>
                    </button>
                  </div> */}
                </div>
                {/* <div className={`row ${campaignResponse && campaignResponse.name ? '' : 'd-none'}`}>
                  <div className="col-12">
                    <Collapse
                      in={this.state.showFilter}
                    >
                      <div className="card">
                        <div className="content">
                          <h3>Campaign template preview</h3>
                          <hr />
                          <div className='row'>
                            {templates.map((item, index) => {
                              return <div className='col-sm-6'>
                                <h4 className='text-center font-weight-bold'>{item.type}</h4>
                                <div dangerouslySetInnerHTML={{ __html: item.preview }} />
                              </div>
                            })}
                          </div>
                          <div className='row justify-content-end'>
                            <div>
                              <button className='btn btn-success' onClick={e => this.setState({ templateAcceptanceModal: true })}>Approve</button>
                            </div>
                            <div className='pl-3'>
                              <button className='btn btn-danger' onClick={e => this.setState({ templateRejectModal: true })}>Reject</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div> */}
              </div>
              <div className="content my-4">
                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={campaigns}
                  columns={this.columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />
              </div>
              {/* session expiry modal */}
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />
              {/* templates approval */}
              <ModalComponent
                title={'Templates Confirmation'}
                message={'Are you sure you want to confirm attached templates'}
                show={templateAcceptanceModal}
                action={'Confirm'}
                callback={this.confirmTemplate}
                close={e => this.closeTemplateModal()}
              />
              {/* templates reject */}
              <ModalComponent
                title={'Templates Confirmation'}
                message={'Are you sure you want to reject attached templates'}
                show={templateRejectModal}
                action={'Reject'}
                actionType='danger'
                callback={this.rejectTemplates}
                close={e => this.closeTemplateModal()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    campaigns: state.campaigns.futureCampaigns.campaigns,
    isLoading: state.campaigns.futureCampaigns.isLoading,
    page: state.campaigns.futureCampaigns.page,
    totalCount: state.campaigns.futureCampaigns.totalCount,
    sizePerPage: state.campaigns.futureCampaigns.sizePerPage,
    filters: state.campaigns.futureCampaigns.filters,
    campaignResponse: state.selectEntities.campaignResponseData,
  }),
  dispatch => ({
    loadBrandsData: bindActionCreators(loadBrandData, dispatch),
    getProjectionsForUser: bindActionCreators(getProjectionsForUserActionTrain, dispatch),
    getFutureCampaignData: bindActionCreators(getCampaignAction('future'), dispatch),
    deleteCampaign: bindActionCreators(deleteCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    setFutureCampaignsPage: bindActionCreators(setCampaignsPageAction('future'), dispatch),
    setFutureCampaignsSort: bindActionCreators(setCampaignsSortAction('future'), dispatch),
    setFutureCampaignsSearch: bindActionCreators(setCampaignsSearchAction('future'), dispatch),
    setFutureCampaignsFilters: bindActionCreators(setCampaignsFiltersAction('future'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    campaignResponseAction: bindActionCreators(savecampaignResponseAction, dispatch),
    editCampaign: bindActionCreators(editCampaignAction, dispatch),
    getBrandGroupWise: bindActionCreators(getBrandGroupWiseAction, dispatch)
  })
)(FutureCampaign);
