import React, {Component} from 'react'
import { Formik, Form, Field, FieldArray } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'



class StatsFormComponent extends Component {
  handleAddDataFor = (type, form) => {
    // e.preventDefault()
    // let target = e.target
    // let parentRow = target.closest('.value-form')
    // let label = parentRow.querySelector('input[name=label]').value
    // let value = parentRow.querySelector('input[name=value]').value
    // parentRow.querySelector('input[name=label]').value = ''
    // parentRow.querySelector('input[name=value]').value = ''
    // arrayAction({label, value, type : key})
    let tempData = {
      type,
      label : form.values.label,
      value : form.values.value
    }
    form.setFieldValue('tempData', tempData)
  }
  setStatsActiveLink = () => {
    let { resetForm,values } = this.props;
    let newValues = {
      ...values,
      label : '',
      value : ''
    }
    resetForm(newValues)
  }
  getEntriesFor = ({ key , remove, values }) => {
    let data = values.stats.filter(v => v && v.type == key)
    return (
      <React.Fragment>
        {
          data.map((item, i) => {
            return (
              <div className="row pr-5 pl-5" key={`${key}${i}`}>
                <div className="col-4 text-center">{item.label}</div>
                <div className="col-4 text-center">{item.value}</div>
                <div className="col-4 text-center">
                  <i 
                    className="fa fa-trash"
                    style={{pointer : 'cursor'}}
                    onClick={() => remove(i)}
                  ></i>
                </div>
              </div>
            )
          })
        }
      </React.Fragment>
    );
  }
  render() {
    let { values, status } = this.props;
    return (
      <Form>
        <Tab.Container id="left-tabs-example" defaultActiveKey="age">
          <div className="row">
            <div className="col-md-3 mt-5">
              <Nav variant="pills" className="flex-column" onSelect={this.setStatsActiveLink}>
                <Nav.Item>
                  <Nav.Link eventKey="age">Age</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="branches">Branches</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nationality">Nationality</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="gender">Gender</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-9">
              <Tab.Content>
                <Tab.Pane eventKey="age">
                  <FieldArray name="stats">
                  {({remove, form}) => {
                    return (
                      <React.Fragment>
                        <div style={{minHeight : '200px'}}>
                        <div className="row pr-5 pl-5">
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Label</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Value</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Actions</div>
                        </div>
                        {this.getEntriesFor({key : 'age', remove, values})}
                        </div>
                        <div className="row pr-5 pl-5 value-form">
                          <div className="col-4">
                            <Field placeholder="Label" name="label" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <Field placeholder="Value" name="value" type="number" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <button 
                              className="btn btn-sm btn-primary" 
                              onClick={() => this.handleAddDataFor('age', form)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  }}
                  </FieldArray>
                </Tab.Pane>
                <Tab.Pane eventKey="branches">
                  <FieldArray name="stats">
                  {({remove, form}) => {
                    return (
                      <React.Fragment>
                        <div style={{minHeight : '200px'}}>
                        <div className="row pr-5 pl-5">
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Label</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Value</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Actions</div>
                        </div>
                        {this.getEntriesFor({key : 'branches', remove, values})}
                        </div>
                        <div className="row pr-5 pl-5 value-form">
                          <div className="col-4 text-center">
                            <Field placeholder="Label" name="label" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4 text-center">
                            <Field placeholder="Value" name="value" type="number" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4 text-center">
                            <button 
                              className="btn btn-sm btn-primary" 
                              onClick={() => this.handleAddDataFor('branches', form)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  }}
                  </FieldArray>
                </Tab.Pane>
                <Tab.Pane eventKey="nationality">
                  <FieldArray name="stats">
                  {({remove, form}) => {
                    return (
                      <React.Fragment>
                        <div style={{minHeight : '200px'}}>
                        <div className="row pr-5 pl-5">
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Label</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Value</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Actions</div>
                        </div>
                        {this.getEntriesFor({key : 'nationality', remove, values})}
                        </div>
                        <div className="row pr-5 pl-5 value-form">
                          <div className="col-4">
                            <Field placeholder="Label" name="label" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <Field placeholder="Value" name="value" type="number" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <button 
                              className="btn btn-sm btn-primary" 
                              onClick={() => this.handleAddDataFor('nationality', form)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  }}
                  </FieldArray>
                </Tab.Pane>
                <Tab.Pane eventKey="gender">
                  <FieldArray name="stats">
                  {({remove, form}) => {
                    return (
                      <React.Fragment>
                        <div style={{minHeight : '200px'}}>
                        <div className="row pr-5 pl-5">
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Label</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Value</div>
                          <div className="col-4 text-center" style={{borderBottom : '1px solid #ddd'}}>Actions</div>
                        </div>
                        {this.getEntriesFor({key : 'gender', remove, values})}
                        </div>
                        <div className="row pr-5 pl-5 value-form">
                          <div className="col-4">
                            <Field placeholder="Label" name="label" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <Field placeholder="Value" name="value" type="number" className="form-control form-control-sm"/>
                          </div>
                          <div className="col-4">
                            <button 
                              className="btn btn-sm btn-primary" 
                              onClick={() => this.handleAddDataFor('gender', form)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  }}
                  </FieldArray>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
        <div className="error text-center mt-3">{status}</div>
      </Form>
    )
  }
}


export class StatsForm extends Component {
  initialValues = {
    stats : [],
    label : '',
    value : '',
    tempData : ''
  }
  validationSchema = YupObject({
    label : YupString()
            .required('Label is required.'),
    value : YupNumber()
            .required('Value is required.'),
  })
  noop = () => {}
  onSubmitEx = (values, actions) => {
    let { onSubmit } = this.props;
    let stats;
    stats = [...values.stats, values.tempData]
    onSubmit({stats}, actions)
  }
  render() {
    let { initialValues, formikRef=this.noop, enableReinitialize, options } = this.props;
    initialValues = {...this.initialValues, ...initialValues}
    if (!initialValues.stats) {
      initialValues.stats = []
    }
    return(
      <Formik
        initialValues={ initialValues }
        children={ props => <StatsFormComponent {...props} options={options} /> }
        onSubmit={this.onSubmitEx}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}



