import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class PageNotFound extends Component {

  goBack = () => {
    let { history } = this.props;
    history.push("/user/campaigns/create")
  }

  render() {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center" >
        <div>
          <h1>Page Not found</h1>
          <br />
          <h5>Looks like you've followed a broken link or entered a URL that doesn't exist on this site.</h5>
          <br />
          <div>
            <button className="btn btn-primary btn-lg" onClick={() => this.goBack()} >Go Back</button>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(
  connect(
    state => ({
      user: state.user,
    }),
    null
  )(PageNotFound)
)
