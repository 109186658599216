import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import Card from "react-bootstrap/Card";
import { Container, } from "./scatter.styled";
import { getScatterChartDataAPICall } from "../../../../services/getScatterChartDataAPICall";
import { insightsSelectors } from "../../../../redux/insights";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SET_INSIGHT_DATE } from "../../../../redux/insights/actionTypes";

export const options = {
  title: "",
  curveType: "function",
  hAxis: {
    title: "Aggregate Review Score",
    titleTextStyle: {
      //
      fontName: "sans-serif",
      italic: false
    },
  },
  vAxis: {
    title: "Review Count",
    titleTextStyle: {
      fontName: "sans-serif",
      italic: false
    },
  },
  height: 430,
  chartArea: { left: 20, top: 10, width: "80%", height: "80%" },
  bubble: {
    textStyle: {
      fontSize: 11,
      color: "black",
      italic: false,
      auraColor: "white",
      // sizeAxis.maxValue: 2,
      // sizeAxis.minValue: 2,
    }
  },
  legend: {
    position: 'none',
    textStyle: { color: 'blue', fontSize: 16 }
  },
  'tooltip': {
    trigger: 'none'
  }
  //  sizeAxis: {
  //   maxValue: 2,
  //   minValue: 5,
  //  }
};

function ScatterCharts() {
  const dispatch = useDispatch();

  const [chartData, setchartData] = useState([]);

  const scatterchartDataState = useSelector(
    insightsSelectors.scatterchartDetails
  );
  console.log("scatterdata=====", scatterchartDataState);
  // const chartDataState = useSelector(insightsSelectors.scatterchartDetails);
  const FactorDataState = useSelector(insightsSelectors.getFactorChartDataState);
  const loader = useSelector(insightsSelectors.scatterchartDetailsLoader);
  const { brandId } = useSelector(insightsSelectors.getUserdetailsState)

  const insightsDates = useSelector(insightsSelectors.insightsDates);
  const [cuisineData, setCuisineData] = useState();
  const [cuisineValue, setCuisineValue] = useState();

  useEffect(() => {
    if (FactorDataState && FactorDataState.cusineRankingforselectedrange) {
      let arr = [];
      // arr.push({ label: 'All', value: '' });
      Object.keys(FactorDataState.cusineRankingforselectedrange).forEach((key, value) => {
        if (JSON.stringify(key).indexOf('₹') == -1) {
          let obj = { label: key, value: key };
          arr.push(obj);
        }
      });
      setCuisineData(arr);
      setCuisineValue(arr[0]);
      dispatch({
        type: SET_INSIGHT_DATE,
        payload: { cuisine: arr[0] ? arr[0].value : '', similarDates: insightsDates.similar_dates }
      });
      if (insightsDates.date)
        dispatch(getScatterChartDataAPICall(brandId, insightsDates.date, arr.length > 0 ? arr[0].value : "", insightsDates.kmid));   // Testing in progress
    }

  }, [FactorDataState && FactorDataState.cusineRankingforselectedrange]);
  useEffect(() => {
    if (scatterchartDataState.data) {
      let prepareChartData = {};
      prepareChartData.data = scatterchartDataState.data ? scatterchartDataState.data : [];
      setchartData(prepareChartData);
    }
  }, [scatterchartDataState && scatterchartDataState.data]);
  const handlePeriodChange = (selVal) => {
    setCuisineValue(selVal);
    dispatch({
      type: SET_INSIGHT_DATE,
      payload: { cuisine: selVal.value, similarDates: insightsDates.similar_dates }
    });
    if (selVal.value)
      dispatch(getScatterChartDataAPICall(brandId, insightsDates.date, selVal.value, insightsDates.kmid));   // Testing in progress
  }
  return (
    <Container>
      <Card className="p-r-0">
        <Card.Body className="p-r-0" style={{ padding: "0 10px" }}>
          <div className="d-flex align-items-center justify-content-between p-r-0">
            <div></div>
            {scatterchartDataState && scatterchartDataState.data && scatterchartDataState.data.length > 1
              ? <div className="col-md-7 col-lg-6 col-sm-6 col-md-3 p-0">
                <div className="heading m-0">
                  <div className="search">
                    <Select
                      options={cuisineData}
                      onChange={(val) => handlePeriodChange(val)}
                      value={cuisineValue}
                      classNamePrefix="reactselect"
                    />
                  </div>
                </div>
              </div> : null
            }

          </div>
          {
            loader &&
            <div className="d-flex justify-content-center spinner-block">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          {scatterchartDataState && scatterchartDataState.data && (
            scatterchartDataState.data.length > 1 ?
              < Chart
                id="ScatterCharts"
                key={chartData.data}
                chartType="BubbleChart"
                height={430}
                data={chartData.data}
                options={options}
              // marginTop="1px"
              />
              :
              <p className="text-center pt-3">No data available</p>
          )}


        </Card.Body>
      </Card>
    </Container>
  );
}

export default React.memo(ScatterCharts);
