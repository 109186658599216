import React, { Component, useState } from 'react';
import { GetSessionValue, SetSessionValue } from '../../utils/sessionStorage';
import { connect } from 'react-redux';
import { updateBrandForInsightsAction } from '../../redux/actions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import {
    object as YupObject,
    string as YupString,
} from 'yup'
import { bindActionCreators } from 'redux';
import { notificationOpts } from '../../config';

class InsightsModel extends Component {
    validationSchema = YupObject({
        email: YupString()
            .email("Invalid email address")
            .required("Please enter email"),
        // firstName: YupString().required("Please enter First Name"),
        // lastName: YupString().required("Please enter Last Name"),
        contactName: YupString().required("Please enter Contact Name"),
        phoneNumber: YupString(),
        organizationName: YupString().required("Please enter Organization Name"),
    })
    render() {
        const userData = GetSessionValue('loginCredentials') || {}
        return (
            <>
                < Formik
                    initialValues={{
                        contactName: userData.contactName || '',
                        organizationName: userData.organizationName || '',
                        // lastName: userData.lastName || '',
                        email: userData.email || '',
                        phoneNumber: userData.phoneNumber || "",
                        isInstrested: userData.isInstrested || false,
                        isSocialAnalytics: userData.isSocialAnalytics || false,
                        isReferrals: userData.isReferrals || false
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={values => {
                        let data = GetSessionValue('loginCredentials')
                        values._id = data._id
                        this.props.updateBrandForInsights(values, (res, type) => {
                            console.log('updateBrandForInsights', res);
                            let notification = { ...notificationOpts }
                            this.props.handleSubmitSignup(res)
                            if (type == "success") {
                                if (res.details.brands) {
                                    SetSessionValue('loginCredentials', res.details.brands)
                                }
                                notification.message = res.respMessage
                            } else {
                                notification.message = "Error while updating user detaiils."
                            }
                            this.props.successNotification(notification)
                        })
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className='row no-gutters'>
                                {/* <div className="form-group px-3">
                                <label >First Name</label>
                                <Field id="firstName"
                                    name="firstName" className="form-control" />
                                <ErrorMessage name="firstName" component="label" className="error" />
                            </div> */}
                                <div className="form-group px-3">
                                    <label >Contact Name</label>
                                    <Field id="contactName"
                                        name="contactName" className="form-control" />
                                    <ErrorMessage name="contactName" component="label" className="error" />
                                </div>
                                <div className="form-group px-3">
                                    <label >Organization Name</label>
                                    <Field id="organizationName"
                                        name="organizationName" className="form-control" />
                                    <ErrorMessage name="organizationName" component="label" className="error" />
                                </div>
                                <div className="form-group px-3">
                                    <label >Phone Number</label>
                                    <Field id="phoneNumber"
                                        name="phoneNumber" className="form-control" />
                                    <ErrorMessage name="phoneNumber" component="label" className="error" />
                                </div>
                                <div className="form-group px-3">
                                    <label>Email Address</label>
                                    <Field id="email" readOnly
                                        name="email" className="form-control" />
                                    <ErrorMessage name="email" component="label" className="error" />
                                </div>
                            </div>
                            <div className='col-12 no-gutters'>
                                <div className='px-3'>
                                    <Field id="isInstrested" name="isInstrested" type="checkbox" className="form-check-input"
                                    // onChange={formik.handleChange} value={formik.values.isInstrested}
                                    />
                                    <span className='pl-2'>
                                        I am interested in krowd customer acquisition marketplace
                                    </span>
                                </div>
                                <div className='px-3'>
                                    <Field id="isSocialAnalytics" name="isSocialAnalytics" type="checkbox" className="form-check-input"
                                    // onChange={formik.handleChange} value={formik.values.isSocialAnalytics} 
                                    />
                                    <span className='pl-2'>
                                        I am interested in Krowd social analytics free 1 month subscription worth £99
                                    </span>
                                </div>
                                <div className='px-3'>
                                    <Field id="isReferrals" name="isReferrals" type="checkbox" className="form-check-input"
                                    // onChange={formik.handleChange} value={formik.values.isReferrals}
                                    />
                                    <span className='pl-2'>
                                        I am happy to provide referrals and win up to 6 months of subscription for krowd social analytics worth £499
                                    </span>
                                </div>
                            </div>
                            <div className='col-12 pt-3 d-flex justify-content-end'>
                                <input type='submit' className="btn btn-sm btn-primary" />
                            </div>
                        </Form >)
                    }
                </Formik >
                <div className='text-center pt-2'>
                    <iframe style={{ border: "1px" }} width="420" height="240"
                        src="https://www.youtube.com/embed/YY840U9fq7o">
                    </iframe>
                </div>
            </>
        )
    }
}
export default connect(
    null,
    dispatch => ({
        updateBrandForInsights: bindActionCreators(updateBrandForInsightsAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    })

)(InsightsModel);