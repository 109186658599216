import axios from 'axios';

const lambdaAxiosMethodRequest = (method, url, body, x_api_key) => {
    return sendRequestToServer(method, url, body, x_api_key)
}

const sendRequestToServer = (method, url, body, x_api_key) => {
    if (method === 'GET') {
        let request = axios.get(`${url}`, body, {
            headers: {
                "x-api-key": x_api_key,
                'Content-Type': 'application/json'
            },
        })
        let modifiedRequest = request
        modifiedRequest.then((res) => {

        })
        return request;
    } else if (method === 'POST') {
        let request = axios.post(`${url}`, body, {
            headers: {
                "x-api-key": x_api_key,
                'Content-Type': 'application/json',
            },
        })
        let modifiedRequest = request
        modifiedRequest.then((res) => {

        })
        return request;
    }

}


export default lambdaAxiosMethodRequest;