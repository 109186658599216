// import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
// import BarclaysLogo from '../../assets/images/barclays-logo.png';
// import CajunCrabLogo from '../../assets/images/cajuncrab-logo.jpg';

import { GetSessionValue, SetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage"

let User = GetSessionValue('user') ? GetSessionValue('user') : null
// if (User) {
//   if (User['type'] == 'bank')
//     User['logo'] = BarclaysLogo
//   else
//     User['logo'] = CajunCrabLogo  
// }


export default function (state = User, action) {
  switch (action.type) {
    case 'SET_USER': {
      let user = action.payload ? { ...state, ...action.payload } : null
      user = JSON.parse(JSON.stringify(user))
      if (!user) {
        window.sessionStorage.removeItem('user');
        DeleteSessionValue('user');
      } else {
        // delete user.logo
        // if(typeof(user)=='object'){
        SetSessionValue('user', user)
        // }

        // if (user['type'] == 'bank')
        //   user['logo'] = BarclaysLogo
        // else
        //   user['logo'] = CajunCrabLogo
      }
      return user
    }
    case 'SET_USER_ENTITY_DETAILS' : {
      let user = action.payload ? {...state, entity : {...action.payload}} : null
      if (!user) {
        window.sessionStorage.removeItem('user')
        DeleteSessionValue('user');
      } else {
        SetSessionValue('user', user)
      }
      return user
    }
    default:
      return state 
  }
}

