import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef, boolean as YupBool, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { Collapse } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import configMessages from './../../config/configMessages'
import ModalComponent from './../Modal'
import { GetSessionValue } from '../../utils/sessionStorage';
import { onlycharRegex } from '../../utils/constants/constant';
class UserFormComponent extends Component {
    state = {
        merchants: [],
        isDisplayMerchantId: false,
        merchantList: []
    }

    handleShowPassword = () => {
        let { values, setFieldValue } = this.props;
        setFieldValue('editPassword', !values.editPassword)
    }

    componentDidMount = () => {
        let { setFieldValue, options } = this.props
        let { prefix } = options
        let userDetails = GetSessionValue('user')
        if (userDetails && userDetails.merchantIds && userDetails.merchantIds.length > 0) {
            let merchantIds = userDetails.merchantIds
            let activeMerchants = []
            merchantIds.forEach((item, index) => {
                if (item) {
                    item.label = item.branchName
                    item.value = item.merchantObjId
                    activeMerchants.push(item)
                }
            })
            this.setState({ merchants: activeMerchants })
        }
    }

    //Multiselect for merchant ids
    onSelect = (selectedItem) => {
        this.setState({ merchantList: selectedItem })
        this.props.selectMerchantId(selectedItem)
    }

    render() {
        let { status, isSubmitting, values, handleChange, handleBlur, options, selectMerchantId } = this.props;
        let { prefix, mode } = options
        let { merchants, isDisplayMerchantId, merchantList } = this.state;
        let merchantListValue = merchantList && merchantList.length ? merchantList : values.merchantIds ? values.merchantIds : [];
        return (
            <Form>
                <div className="form-group">
                    <label>Email </label>
                    <Field name="email" className="form-control" disabled={mode == 'edit' ? true : isSubmitting} />
                    <ErrorMessage name="email" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>First Name  </label>
                    <Field name="firstName" className="form-control" disabled={isSubmitting} />
                    <ErrorMessage name="firstName" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Last Name </label>
                    <Field name="lastName" className="form-control" disabled={isSubmitting} />
                    <ErrorMessage name="lastName" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Contact Phone </label>
                    <Field name="phone" type='number' className="form-control" maxLength='12' disabled={isSubmitting} />
                    <ErrorMessage name="phone" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Role</label>
                    <select
                        name="role"
                        className="form-control"
                        defaultValue={values.role}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option value="" disabled>Select Role</option>
                        <option value="companyAdmin">Company Admin</option>
                        <option value="user">User</option>
                    </select>
                    <ErrorMessage name="role" component="label" className="error" />
                </div>
                {/* {isDisplayMerchantId? */}
                {/* {values.role && values.role == 'user' ?
          <div className="form-group">
            <label>{capitalize("Branch")} </label>
            <MultiSelect
              options={this.state.merchants}
              value={merchantList && merchantList.length ? merchantList : values.merchantIds ? [...values.merchantIds] : []}
              onChange={this.onSelect}
              labelledBy={"Select Merchant Id"}
              required={true}
            />
            <ErrorMessage name="merchantId" component="label" className="error" />
          </div> : null} */}
                <input
                    value={prefix}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="entityType"
                    type="hidden"
                    className="form-control" />
                {
                    status && (
                        <div className="form-group text-center">
                            <label className="error">{status}</label>
                        </div>
                    )
                }
            </Form>
        )
    }
}

export class FormikForm extends Component {
    state = {
        merchantList: ''
    };

    initialValues = {
        'firstName': '',
        'lastName': '',
        'email': '',
        'phone': '',
        'role': '',
        'merchantId': ''

    };

    validationSchemaCreate = YupObject({
        firstName: YupString()
            .required(configMessages.name)
            .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
            .min(3, configMessages.minName)
            .max(100, configMessages.maxName),
        lastName: YupString()
            .required(configMessages.name)
            .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
            .min(3, configMessages.minName)
            .max(100, configMessages.maxName),
        email: YupString()
            .required(configMessages.email)
            .email(configMessages.validEmail),
        phone: YupString()
            .required(configMessages.phone)
            .matches(/[\d]+/, configMessages.phoneValidation),
        role: YupString()
            .required(configMessages.role),
    })


    noop = () => { }

    onSubmitValues = (values, actions) => {
        if (this.state.merchantList) {
            values.merchantIds = this.state.merchantList
        }
        let { onSubmit } = this.props;
        onSubmit(values, actions)
    }

    onSelect = (selectedItem) => {
        let merchantList = selectedItem
        this.setState({ merchantList: selectedItem })
    }

    render() {
        let { initialValues, onSubmit, formikRef = this.noop, brands, banks, prefix } = this.props;
        let defaultValues;
        if (prefix && !initialValues) {
            defaultValues = { ...this.initialValues }
            defaultValues.entityType = prefix
        } else {
            defaultValues = { ...initialValues, password: '', confirmPassword: '' }
        }
        let mode = initialValues ? 'edit' : ''
        return (
            <Formik
                initialValues={defaultValues}
                render={props => <UserFormComponent {...props}
                    selectMerchantId={this.onSelect}
                    options={{ banks, brands, prefix, mode }} /> /*this.getForm(props, {banks, brands, prefix, mode})*/}
                onSubmit={this.onSubmitValues}
                validationSchema={this.validationSchemaCreate}
                ref={formikRef}
            />
        )
    }
}

export const UserForm = connect(
    state => ({
        banks: state.selectEntities.banks,
        brands: state.selectEntities.brands
    }),
    null
)(FormikForm)

class SimpleUserFormComponent extends Component {
    state = {
        confirmationModal: false
    }

    //closing confirmation modal
    onCloseConfirmationModal = () => {
        this.setState({ confirmationModal: false })
    }

    //confirmation modal
    handleConfirmationModal = () => {
        let { values, submit } = this.props
        if (submit) {
            values.status = 'deactivate'
            submit(values, null)
        }
    }
    render() {
        let { status, isSubmitting, values, options, languageData
            // ,handleBlur,handleChange
        } = this.props;
        let { isDisabled } = options
        let { confirmationModal } = this.state
        let user = GetSessionValue('user')
        return (
            <Form className="form">
                <div className="form-group">
                    <label>Amount </label>
                    <Field name="amount" className="form-control"
                    // disabled={mode == 'edit' ? true : isSubmitting}
                    />
                    <ErrorMessage name="amount" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Currency </label>
                    <Field name="currency" className="form-control" disabled={isSubmitting} />
                    <ErrorMessage name="currency" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Mandate </label>
                    <Field name="mandate" className="form-control" disabled={isSubmitting} />
                    <ErrorMessage name="mandate" component="label" className="error" />
                </div>


                {/* <ModalComponent
                    close={this.onCloseConfirmationModal}
                    show={confirmationModal}
                    callback={this.handleConfirmationModal}
                    title={"Account Confirmation"}
                    message={"Are you sure you want to deactivate Account ?"}
                    action={"Yes"}
                    cancelText={'No'}
                /> */}
            </Form>
        )
    }
}

export class SimpleUserForm extends Component {

    initialValues = {
        amount: '',
        currency: '',
        mandate: '',
    }
    validationSchema = YupObject({
        mandate: YupString()
            .required('Mandate is required')
            .min(3, 'Mandate should at least contain 3 characters')
            .max(100, 'Mandate should at most contain 100 characters'),
        currency: YupString()
            .required('Currency is required')
            .matches(/[A-Za-z_-\s@\.]/, 'Currency can only contain [A-Za-z_-\s@\.]')
            .min(3, 'Currency should at least contain 3 characters')
            .max(100, 'Currency should at most contain 100 characters'),
        amount: YupNumber()
            .required('Amount is required')
            .typeError("it doesn't contain alphabets")
            .positive("amount can't start with a minus"),



    })

    //onsubmit profile
    onSubmit = (values, actions) => {
        this.props.onSubmit(values, actions)
    }
    noop = () => { }
    render() {
        let { initialValues, onSubmit, formikRef = this.noop, options, handleChange, handleBlur } = this.props;
        let defaultValues;
        if (!initialValues) {
            defaultValues = { ...this.initialValues }
        } else {
            defaultValues = {
                ...initialValues, amount: '',
                currency: '',
                mandate: '',
            }
        }
        return (
            <Formik
                initialValues={defaultValues}
                render={props => <SimpleUserFormComponent
                    {...props}
                    options={options}
                    languageData={this.props.languageData}
                    onRef={ref => this.userProfileRef = (ref)}
                    submit={this.onSubmit}
                />}
                onSubmit={this.onSubmit}
                validationSchema={this.validationSchema}
                ref={formikRef}
            />
        )
    }
}
