import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePasswordAction, getEntityDetails } from '../../redux/actions'
import { ChangePasswordForm } from '../../components/Forms/ChangePassword'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import SessionExpiryModal from './../../components/SessionExpiryModal'
import ChangePasswordModal from './../../components/changePasswordModal'
import { notificationOpts } from '../../config'
import ProfileImg from './updateProfileImg';
import config from './../../config/config'
import { GetSessionValue } from '../../utils/sessionStorage';
let languageData = {}
class ChangePassword extends Component {
  state = {
    isDisabled: true,
    btnLabel: 'Update Profile',
    sessionExpiryModal: false,
    changePasswordModal: false
  }

  componentDidMount = async () => {
    let { getEntityDetails } = this.props
    await getEntityDetails(config.entityType, false, (res, type) => {

    })
    let sessionExpiryModal = false
    let sessionExpired = GetSessionValue('sessionExpired')
    if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
      sessionExpiryModal = true
    }
    await this.setState({ sessionExpiryModal: sessionExpiryModal })
  }
  onSubmit = (values, actions) => {
    let { changePassword, successNotification } = this.props;

    changePassword(values, (res, type) => {
      actions.setSubmitting(false)
      actions.setStatus('')
      if (type == 'error') {
        actions.setStatus(res)
        return;
      }
      setTimeout(() => {
        // let notification = { ...notificationOpts }
        // notification.message = res
        // successNotification(notification)
        // this.getEntityDetailsApicall()
        this.setState({ changePasswordModal: true })

      })
      this.setState({ isDisabled: true })
    })
  }

  getEntityDetailsApicall = () => {
    let { getEntityDetails } = this.props
    getEntityDetails(config.entityType, false, async (res, type) => {
      if (type == 'success') {
        await this.setState({ userDetails: res })
      } else {
        let sessionExpiryModal = false
        let sessionExpired = GetSessionValue('sessionExpired')
        if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
          sessionExpiryModal = true
        }
        await this.setState({ sessionExpiryModal: sessionExpiryModal })
      }
    })
  }


  formikRef = (e) => {
    this.formik = e
  }

  formikSubmit = () => {
    if (this.formik)
      this.formik.submitForm()
  }

  onToggleEdit = () => {
    let isDisabled = !this.state.isDisabled
    // if (isDisabled && this.formikRef) {
    //   this.formikRef.resetForm()
    // }
    this.setState({ isDisabled })
  }

  getActionBtns = () => {
    if (this.state.isDisabled) {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd px-5" onClick={this.onToggleEdit}>{/*<Icon fontSize="small">edit</Icon>*/} Edit </button>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
        </div>
      )
    }
  }

  render() {
    let { user } = this.props;
    return (
      <div>
        <div className="row create-header">
          <h3 className="col-lg-6 offset-lg-3 col-md-12 text-center py-3">Change Password</h3>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="">
                <ChangePasswordForm
                  onSubmit={this.onSubmit}
                  initialValues={user}
                  options={{ ...this.state }}
                  formikRef={this.formikRef}
                  languageData={languageData}
                />
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />
                <ChangePasswordModal
                  isOpen={this.state.changePasswordModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({
    changePassword: bindActionCreators(changePasswordAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    getEntityDetails: bindActionCreators(getEntityDetails, dispatch)
  })
)(ChangePassword);

