import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon'
import Loader from '../../components/Loader'
import {
  getCampaignAction,
  deleteCampaignAction,
  setCampaignForEditAction,
  setCampaignsPageAction,
  setCampaignsSortAction,
  setCampaignsSearchAction,
  setCampaignsFiltersAction
} from '../../redux/actions/campaignActions'
import { Redirect } from 'react-router-dom';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'
import config from '../../config/config'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import { GetSessionValue, ClearSessionValue } from "../../utils/sessionStorage";
let languageData = {}
const getCurrency = () => {
  // return <i className="fa fa-usd"></i>
  return 'GBP'
}
class CurrentCampaign extends Component {
  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    start_date: '',
    end_date: '',
    isLoading: false,
    campaigns: [],
    pagination: {},
    sortCount: 0,
    sessionExpiryModal: false,
    loginRedirect: false,
    editCampaignOverviewId: '',
    searchField: ""
  };
  pagination = {
    limit: 10,
    page: 1
  }
  fetchData = () => {
    let {
      getCurrentCampaignData,
      filters,
      successNotification
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField } = this.state
    if (searchField) {
      if (!filters) {
        filters = {}
      }
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    this.setState({ isLoading: true })
    getCurrentCampaignData(page, limit, filters, '', (response, pagination, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (sessionExpiryModal) {
        return
      }
      if (type == 'error') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = response
          successNotification(notification)
        })
      }
    })

  }

  componentDidMount() {
    this.fetchData(this.props.page || this.pagination.page)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      // this.fetchData()
    }
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getMonthText = lastTransaction => {
    return lastTransaction <= 1 ? `${lastTransaction} Month` : `${lastTransaction} Months`
  }

  // columns = [
  // //   {
  // //   dataField: 'actions',
  // //   isDummyField: true,
  // //   text: 'Actions',
  // //   classes: 'vhalign',
  // //   formatter: (cell, row) => {
  // //     return (
  // //       <React.Fragment>
  // //         <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }}
  // //           onClick={() => this.handleEditCampaignOverview(row)}
  // //         >
  // //           <Icon style={{ fontSize: "1.5rem" }} className="text-info">bar_chart</Icon>
  // //         </span>
  // //         <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.handleCampaignReportDownload(row)} >
  // //           <Icon style={{ fontSize: "1.5rem" }} className="text-info">cloud_download</Icon>
  // //         </span>
  // //       </React.Fragment>
  // //     )
  // //   }
  // // },
  // {
  //   dataField: 'name',
  //   sort: true,
  //   text: languageData.campaignName ? languageData.campaignName : 'Campaign Name',
  //   classes: 'vhalign'
  // },
  // {
  //   dataField: 'discountPercent',
  //   sort: true,
  //   isDummyField: true,
  //   text: 'Discount(%)',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     switch (row.discountType) {
  //       case 'dp': {
  //         return row.discountPercent && <span>{row.discountPercent}%</span>
  //       }
  //       case 'dv': {
  //         return <span> {row.discountValue} {getCurrency()}</span>
  //       }
  //       case 'ss': {
  //         return (
  //           <span> {row.discountSpreadValue}% </span>
  //         )
  //       }
  //       default: return 'none'
  //     }
  //   }
  // },
  // {
  //   dataField: 'successFee',
  //   sort: true,
  //   text: languageData.successFee ? languageData.successFee : 'Success Fee',
  //   classes: 'vhalign',
  //   formatter: (cell, row) => {
  //     if (cell)
  //       return cell + '%'
  //     return cell
  //   }
  // },
  // {
  //   dataField: 'openingTime',
  //   sort: true,
  //   text: languageData.period ? languageData.period : 'Period',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     return moment(cell).format('MM/YYYY')
  //   }
  // },
  // {
  //   dataField: 'merchantNames',
  //   sort: true,
  //   text: languageData.branchName ? languageData.branchName : 'Branch Name',
  //   classes: 'vhalign',
  //   formatter: cell => {
  //     if (cell) {
  //       return cell.toLocaleString()
  //     }
  //   }
  // },
  // {
  //   dataField: 'customerType',
  //   sort: true,
  //   text: languageData.customerType ? languageData.customerType : 'Customer Type',
  //   classes: 'vhalign',
  //   formatter: (col, row) => {
  //     return col === 'new' ? 'New Customer' : col === 'all' ? 'All' : `Existing Customer`
  //   }
  // },
  // // {
  // //   dataField: 'discountType',
  // //   sort: true,
  // //   text: languageData.discountType ? languageData.discountType : 'Discount Type',
  // //   classes: 'vhalign',
  // //   formatter: (cell, row, rowIndex) => {
  // //     switch (cell) {
  // //       case 'dp': {
  // //         return 'Flat Percentage'
  // //       }
  // //       case 'dv': {
  // //         return 'Discount Value'
  // //       }
  // //       case 'ss': {
  // //         return 'Stretch Spend'
  // //       }
  // //       default: return ''
  // //     }
  // //   }
  // // },
  // // {
  // //   dataField: 'discountAmount',
  // //   sort: true,
  // //   isDummyField: true,
  // //   text: languageData.minimumSpend ? languageData.minimumSpend : 'Minimum Spend',
  // //   classes: 'vhalign',
  // //   formatter: (cell, row, rowIndex) => {
  // //     switch (row.discountType) {
  // //       case 'dp': {
  // //         return 'none'
  // //       }
  // //       case 'dv': {
  // //         return <span> {row.discountAmount} {getCurrency()}</span>
  // //       }
  // //       case 'ss': {
  // //         return (
  // //           <span> {row.discountSpreadAmount}% <br /> <small>Avg. Spend : {row.spread_avg_spend} {getCurrency()}</small></span>
  // //         )
  // //       }
  // //       default: return 'none'
  // //     }
  // //   }
  // // },

  // {
  //   dataField: 'suggestions',
  //   sort: true,
  //   text: "Additional Comments",
  //   classes: 'vhalign',
  //   formatter: cell => {
  //     if (cell) {
  //       return cell.toLocaleString()
  //     }
  //   }
  // },
  // {
  //   dataField: 'recurring',
  //   sort: true,
  //   isDummyField: true,
  //   text: languageData.recurring ? languageData.recurring : 'Recurring Campaign',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     switch (row.recurring) {
  //       case true: {
  //         return <p>Yes, it is a monthly recurring campaign</p>
  //       }
  //       case false: {
  //         return <p>Not a monthly recurring campaign</p>
  //       }

  //       default: return <p>Not a monthly recurring campaign</p>
  //     }
  //   }
  // },
  // ]
  columns = [
    {
      dataField: 'name',
      sort: true,
      text: languageData.campaignName ? languageData.campaignName : 'Campaign Name',
      classes: 'vhalign'
    },
    {
      dataField: 'discountPercent',
      sort: true,
      isDummyField: true,
      text: 'Discount(%)',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        if (row.discountType) {
          switch (row.discountType) {
            case 'dp': {
              return row.discountPercent ? <span>{row.discountPercent}%</span> : 'N/A';
            }
            case 'dv': {
              return row.discountValue ? <span>{row.discountValue} {getCurrency()}</span> : 'N/A';
            }
            case 'ss': {
              return row.discountSpreadValue ? <span>{row.discountSpreadValue}%</span> : 'N/A';
            }
            default:
              return 'none'; // Handle unknown discount type
          }
        }
        return 'none'; // Fallback if `discountType` is undefined
      }
    },
    {
      dataField: 'successFee',
      sort: true,
      text: languageData.successFee ? languageData.successFee : 'Success Fee',
      classes: 'vhalign',
      formatter: (cell, row) => {
        return cell ? `${cell}%` : 'N/A'; // Ensure safe fallback for missing data
      }
    },
    {
      dataField: 'openingTime',
      sort: true,
      text: languageData.period ? languageData.period : 'Period',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        return cell ? moment(cell).format('MM/YYYY') : 'N/A'; // Handle undefined or null dates
      }
    },
    {
      dataField: 'merchantNames',
      sort: true,
      text: languageData.branchName ? languageData.branchName : 'Branch Name',
      classes: 'vhalign',
      formatter: cell => {
        return cell ? cell.toLocaleString() : 'N/A'; // Safe handling for undefined or null values
      }
    },
    {
      dataField: 'customerType',
      sort: true,
      text: languageData.customerType ? languageData.customerType : 'Customer Type',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col === 'new') return 'New Customer';
        if (col === 'all') return 'All';
        return `Existing Customer`; // Handle undefined or unknown customer types
      }
    },
    {
      dataField: 'suggestions',
      sort: true,
      text: "Additional Comments",
      classes: 'vhalign',
      formatter: cell => {
        return cell ? cell.toLocaleString() : 'N/A'; // Safe handling for undefined or null values
      }
    },
    {
      dataField: 'recurring',
      sort: true,
      isDummyField: true,
      text: languageData.recurring ? languageData.recurring : 'Recurring Campaign',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        switch (row.recurring) {
          case true:
            return <p>Yes, it is a monthly recurring campaign</p>;
          case false:
            return <p>Not a monthly recurring campaign</p>;
          default:
            return <p>Not a monthly recurring campaign</p>; // Handle undefined or null values for `recurring`
        }
      }
    }
  ];
  
  handleCampaignReportDownload = campaign => {
    window.open(`/user/campaigns/print/${campaign._id}`, '_blank')
  }
  handleEditCampaignOverview = campaign => {
    let { history } = this.props;
    let user = GetSessionValue('user')
    if (user.role == 'user' || user.role == 'companyAdmin' || user.role == 'groupAdmin' || user.role == 'brandAdmin') {
      history.push(`/user/campaign-overview/edit/${campaign._id}`)
    } else {
      history.push(`/user/campaigns/reports/${campaign._id}`)
    }
  }
  setShowDeleteModal = (campaign) => {
    this.setState({ showDeleteModal: true, selectedCampaign: campaign });
  }
  handleDeleteCampaign = () => {
    let { deleteCampaign, successNotification } = this.props;
    let { selectedCampaign: campaign } = this.state;
    deleteCampaign(campaign, (err) => {
      if (err) {
        return
      }
      this.setState({ showDeleteModal: false, selectedCampaign: null }, () => {
        // show notification
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = 'Campaign deleted successfully'
          successNotification(notification)
        })
      });
      this.fetchData(this.props.page)
    })
  }
  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  }
  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { limit } = this.pagination;
    return parseInt(totalSize / limit) + (totalSize % limit ? 1 : 0)
  }
  handleEditCampaign = (campaign) => {
    let { setCampaignForEdit } = this.props;
    campaign.start_date = new Date(campaign.openingTime)
    setCampaignForEdit(campaign)
    this.props.history.push(`/user/campaigns/edit`)
  }

  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters }) => {
    let {
      setCurrentCampaignsPage,
      setCurrentCampaignsSort,
      setCurrentCampaignsSearch,
      setCurrentCampaignsFilters,
      getCurrentCampaignData
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField } = this.state
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    switch (type) {
      case 'pagination': {
        setCurrentCampaignsPage(page, sizePerPage)
        break;
      }
      case 'sort': {
        let sortCount = this.state.sortCount
        sortCount = sortCount == 0 ? sortCount + 1 : 0;
        filterCriteria.sortField = {
          direction: sortCount == 0 ? "desc" : 'asc',
          sortfield: sortField,
        }
        this.setState({ sortCount: sortCount })
        getCurrentCampaignData(page, limit, filterCriteria, 'sort', (response, pagination) => {
        })

        break
      }
      case 'search': {
        filterCriteria.globalSearch = {
          value: searchText,
          type: 'user'
        }
        getCurrentCampaignData(page, limit, filterCriteria, 'globalsearch', (response, pagination) => {
          this.setState({ campaigns: response })
          if (pagination && pagination.totalCount) {
            this.setState({ pagination: pagination })
          }
        })
        break
      }
      case 'filter': {
        setCurrentCampaignsFilters({ ...filters })
        setCurrentCampaignsPage(1, sizePerPage)
        break
      }
      default:
        return
    }
  }
  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }
  handleDateChange = (name, value) => {
    this.handleTableChange('filter', { filters: { [`${name}`]: value ? value.toISOString() : '' } })
  }
  getCurrentPageTotalPage = paginationProps => {
    let { page } = paginationProps
    let totalPages = this.getPageCount(paginationProps)
    if (!totalPages)
      return `0/0`;
    return `${page}/${totalPages}`
  }
  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }
  sessionExpired = () => {
    // sessionStorage.clear()
    ClearSessionValue();
    setTimeout(async () => await this.setState({ loginRedirect: true }), 1000)

  }
  onPageChange = async (page) => {
    this.pagination.page = page
    this.fetchData()
  }
  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }
  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-2" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content-custom">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)} key={index}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )
  render() {
    const { showDeleteModal, selectedCampaign, sessionExpired, loginRedirect } = this.state
    const { totalCount, filters, campaigns, isLoading } = this.props;
    let thisMonth = moment().startOf('month')
    const { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      // custom:true,
      open: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : null}
        {this.state.editCampaignOverviewId ? <Redirect to={`/user/campaign-overview/edit/${this.state.editCampaignOverviewId}`} /> : ''}
        <Loader loader={isLoading} />
        <ModalComponent
          close={this.onCloseDeleteModal}
          callback={this.handleDeleteCampaign}
          show={showDeleteModal}
          title={"Delete Campaign"}
          message={<span>Are you sure you want to delete the <b>"{selectedCampaign ? selectedCampaign.name : ''}"</b> campaign ?</span>}
          action={"Delete"}
          actionType='danger'
        />
        <ModalComponent
          callback={this.sessionExpired}
          show={sessionExpired}
          title={"Session Expired"}
          message={<span>Session Expired, Please login Again</span>}
          action={"Go to Login Page"}
          actionType='success'
          hideCancelBtn={true}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">fast_forward</Icon>
                    </span> Current Campaigns
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 container-fluid pr-4">
                <div className="row">
                  <div className="col-md-4 offset-md-8 px-0 text-right col-sm-8 offset-sm-4 col-9 offset-3">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder="Search Campaign Name" />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 offset-7 d-none">
                    <button className="btn pull-right btn-outline-primary" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <i className="fa fa-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Collapse
                      in={this.state.showFilter}
                    >
                      <div className="card">
                        <div className="content">
                          <div className="form-group mb-3">
                            <label>Date Range </label>
                            <br />
                            <div className="row">
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrBefore(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('start_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.start_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrAfter(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('end_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.end_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
              <div className="content my-4">
                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={campaigns}
                  columns={this.columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />
              </div>
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    campaigns: state.campaigns.currentCampaigns.campaigns,
    isLoading: state.campaigns.currentCampaigns.isLoading,
    page: state.campaigns.currentCampaigns.page,
    totalCount: state.campaigns.currentCampaigns.totalCount,
    sizePerPage: state.campaigns.currentCampaigns.sizePerPage,
    filters: state.campaigns.currentCampaigns.filters,
  }),
  dispatch => ({
    getCurrentCampaignData: bindActionCreators(getCampaignAction('current'), dispatch),
    deleteCampaign: bindActionCreators(deleteCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    setCurrentCampaignsPage: bindActionCreators(setCampaignsPageAction('current'), dispatch),
    setCurrentCampaignsSort: bindActionCreators(setCampaignsSortAction('current'), dispatch),
    setCurrentCampaignsSearch: bindActionCreators(setCampaignsSearchAction('current'), dispatch),
    setCurrentCampaignsFilters: bindActionCreators(setCampaignsFiltersAction('current'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),

  })
)(CurrentCampaign);
