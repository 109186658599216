import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, date } from 'yup';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'reactstrap'
import * as Datetime from 'react-datetime'
import moment from 'moment';
import FormikImageUpload from '../../utils/components/FormikImageUpload'
import axiosMethodRequest from '../../config/service'
import Loader from '../Loader'
import config from './../../config/config'
import { saveCsvAction, saveinitialValuesAction } from './../../redux/actions'
import BackupIcon from '@material-ui/icons/Backup';
import CropModal from './../Modal'
import ImageSelection from './../../pages/PreviewCampaign/ImageSelectionModal'
import capitalize from 'lodash/capitalize'
class TemplateFormComponent extends Component {
    state = {
        isLoading: false,
        showCropModal: false,
        name: '',
        dynamicFields: [],
        selectedFieldIndex: null,
        multipleUpload: false,
        fileName: '',
    }
    name = ''
    initialName = ''

    componentDidMount = async () => {
        let { options } = this.props;
        let { dynamicFields } = options;
        await this.setState({ dynamicFields: dynamicFields })
    }

    //setting input valurs
    handleBlur = async (e, type, dropdownData) => {
        let { options } = this.props;
        let { item, index, dynamicFields, handleBlur } = options;
        let { selectedFieldIndex } = this.state
        let value = {}
        let Name = e && e.target && e.target.name ? (e.target.name).toLowerCase() : null
        this.name = Name
        this.initialName = e && e.target && e.target.name ? e.target.name : null
        //loop to enter image
        if (type == 'upload' && dropdownData && dropdownData.name && dropdownData.value) {
            let file = dropdownData.value
            if (file) {
                this.setState({ isLoading: true })
                let imageFile
                await axiosMethodRequest('POST', 'campaignTemplates/upload?uploadPath=campaign', file, 'upload').then((response) => {
                    if (response && response.data && response.data.fileName) {
                        imageFile = `${config.imgUrl}campaign/${response.data.fileName}`
                        let modifiedDynamicFields
                        if (dynamicFields && dynamicFields[index] && dynamicFields[index][selectedFieldIndex]) {
                            this.props.options.dynamicFields[index][selectedFieldIndex].fileName = response.data.fileName
                        }
                    }
                    this.setState({ isLoading: false })
                })
                value = {
                    name: dropdownData.name,
                    value: imageFile
                }
            }
        } else if (type == 'creative' && dropdownData && dropdownData.name && dropdownData.value) {
            if (dropdownData && dropdownData.name && dropdownData.value) {
                let imageNameArray = dropdownData.value.split('/')
                if (dynamicFields && dynamicFields[index] && dynamicFields[index][selectedFieldIndex]) {

                    this.props.options.dynamicFields[index][selectedFieldIndex].fileName = imageNameArray[imageNameArray.length - 1]
                }
                value = dropdownData
            }
        } else if (e && e.target && e.target.name == 'promocodes') {//loop to enter csv file
            let file = e.target.files
            if (file && file.length) {
                file = file[0]
                let date = new Date();
                let milliseconds = date.getTime()
            }
            value = {
                name: e.target.name,
                value: file ? file : e.target.value
            }
        }
        else if (e && e.target && e.target.name == 'promoCodesMultiple') {//loop to enter csv file
            let file = e.target.files
            if (file && file.length) {
                file = file[0]
                let date = new Date();
                let milliseconds = date.getTime()

            }
            value = {
                name: e.target.name,
                value: file ? file : e.target.value
            }
        }
        else if (e && e.target && e.target.name && e.target.value) {   //loop to enter remianing
            value = {
                name: e.target.name,
                value: e.target.value
            }
        }
        handleBlur(item, index, value)
    }

    //data formatt
    handleValidDate = (name, date) => {
        let datesArray = ['valid until', 'valid Until', 'Valid until', 'Valid Until', 'valid', 'Valid']
        let todaydate = moment().format('DD')
        var end
        // if (todaydate > 25) {
        //     end = date.add(1, "M").format("MMM") + ' ' + date.daysInMonth();
        // } else {
        end = date.format("MMM") + ' ' + date.daysInMonth();
        // }
        let value = {
            name: name,
            value: end
        }
        let { options } = this.props;
        let { item, index, handleBlur, setInitialData, initialData } = options;
        initialData.date = date
        datesArray.forEach((_item, _index) => {
            initialData['email'][_item] = end
            initialData['sms'][_item] = end
            initialData['app'][_item] = end
        })
        setInitialData(initialData)
        handleBlur(item, index, value)
    }

    //Image Selectiom Modal
    onCloseCropModal = () => {
        if (this.ImgSelectionRef && this.ImgSelectionRef.file) {
            console.log(this.ImgSelectionRef.file, 'this.ImgSelectionRef.file')
            let ImgSelectionRef = this.ImgSelectionRef
            let file = this.ImgSelectionRef.file
            let croppedImg
            let orginalImg
            if (this.ImgSelectionRef && this.ImgSelectionRef.state && this.ImgSelectionRef.state.image) {
                orginalImg = this.ImgSelectionRef.state.image
            }
            if (ImgSelectionRef && ImgSelectionRef.cropImgRef && ImgSelectionRef.cropImgRef.state && ImgSelectionRef.cropImgRef.state.croppedImg) {
                croppedImg = ImgSelectionRef.cropImgRef.state.croppedImg
            }
            let imageFile = {
                name: this.state.name,
                value: orginalImg
            }
            this.handleBlur(null, 'upload', imageFile)
        }
        this.setState({ showCropModal: false })
    }
    //hanglePromoCode
    hanglePromoCode = (e) => {
        // if (e && e.target.value) {
        //     document.getElementById('enterpromocode').innerHTML = "e.target.value"
        // }
    }
    //Form field Values
    getFormFields = () => {
        let { options, handleBlur, handleChange, values, onChange } = this.props;
        let { item, index, type, previewData, promocodes, promoCodesMultiple, dynamicFields, initialData, handlePromoCode } = options;
        let thisMonth = moment().startOf('month')
        let todaydate = moment().format('DD')
        let validDate
        if (initialData && initialData['date']) {
            validDate = initialData['date']
        } else if (previewData && previewData.start_date) {
            let startdate = previewData.start_date
            // if (todaydate > 25) {
            validDate = moment(startdate).add(1, 'M')
            // } else {
            //     validDate = previewData.start_date
            // }
        }
        return <div style={{ overflowX: 'hidden' }}>
            {dynamicFields && dynamicFields[index] && dynamicFields[index].length ? dynamicFields[index].map((_item, _index) => {
                let Item = (_item.fieldName).toLowerCase()
                if (Item == 'validfor' || Item == 'valid for') {
                    return <Row className='' key={_index} style={{ overflowX: 'hidden' }}>
                        <Col sm={{ size: 4, offset: 1 }} className='pr-0'>
                            <label className='mt-2'>
                                {_item.fieldName}
                            </label>
                        </Col>
                        <Col sm={5} className='px-lg-0 px-md-2'>
                            <div className="form-group">
                                <select
                                    name={`${type}.${_item.fieldName}`}
                                    className="form-control"
                                    defaultValue={values[type][_item.fieldName]}
                                    onChange={(e) => {
                                        handleChange(e)
                                        this.handleBlur(e)
                                    }}
                                    onBlur={(e) => {
                                        handleBlur(e)
                                    }}
                                >
                                    <option value="unlimited">Unlimited times</option>
                                    <option value={'one'}>One time</option>
                                    <option value={'two'}>Two times</option>
                                    <option value={'three'}>Three times</option>

                                </select>
                                <ErrorMessage name="role" component="label" className="error" />
                            </div>
                        </Col>
                    </Row>
                } else {
                    return <div key={_index}
                    // style={{ overflowX: 'hidden' }}
                    >
                        <Row className='templates'
                        // style={{ height: 35 }}
                        >
                            <Col sm={{ size: 4, offset: 1 }} className='pr-0'>
                                <label className='mt-2'>
                                    {_item.fieldName}
                                </label>
                            </Col>
                            <Col sm={5} className='px-lg-0 px-md-2'>
                                {Item == 'valid until' || Item == 'valid' ?
                                    <div>
                                        <Datetime
                                            viewMode="months"
                                            timeFormat={false}
                                            dateFormat="MM/YYYY"
                                            // defaultValue={previewData && previewData.start_date ? previewData.start_date : ''}
                                            defaultValue={validDate}
                                            minDate={previewData.start_date}
                                            // isValidDate={current => {
                                            //     return current.isAfter(thisMonth, 'month')
                                            // }}
                                            onChange={(date) => {
                                                this.handleValidDate(`${type}.${_item.fieldName}`, date)
                                            }}
                                            renderInput={(props, openCalendar) => {
                                                return (
                                                    <div className="input-group mb-2">
                                                        <input {...props} type="text" className="form-control" name="start_date" />
                                                        <div className="input-group-append date-dropdown-btn">
                                                            <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                        {/* <p style={{ color: 'gray', fontSize: 10 }}>Campaign will be sent monthly and will stop by the end of the selected month</p> */}
                                    </div>
                                    :
                                    Item.includes('image') ?
                                        <div className="form-group templates">
                                            <div className='csv-file-input'>
                                                <label className='mt-2 ml-2' htmlFor='image-file' onClick={e => {
                                                    this.setState({ showCropModal: true, name: `${type}.${_item.fieldName}`, selectedFieldIndex: _index })
                                                }}>
                                                    <BackupIcon />
                                                    <span className='px-1' />
                                                    {/* {_item.fileName ? _item.fileName : ''} */}
                                                    {/* {initialData && initialData[type] && initialData[type] && initialData[type][_item.fieldName] ? initialData[type][_item.fieldName].split('/')[-1] : ''}  */}
                                                    {_item.fileName ? _item.fileName : ''}
                                                </label>
                                            </div>
                                        </div> :
                                        <div className="form-group templates">

                                            <Field
                                                name={`${type}.${_item.fieldName}`}
                                                onBlur={this.handleBlur}
                                                className="form-control"
                                                type={Item == 'date' || Item == 'valid' ? 'date' : 'text'}
                                                placeholder={_item.fieldName}
                                            />
                                        </div>
                                }
                            </Col>
                        </Row>
                        <Row className='templates'>
                            <Col sm={{ size: 6, offset: 5 }} className='px-lg-0 px-md-2'>
                                {Item == 'valid until' || Item == 'valid' ?
                                    <div>

                                        <p style={{ color: 'gray', fontSize: 10 }}>Campaign will be sent monthly and will stop by the end of the selected month</p>
                                    </div> : null

                                }
                            </Col>
                        </Row>
                    </div>
                }

            }) : null}
            {type != 'app' ? <div>
                <Row className=''
                // style={{ height: 35 }}
                >
                    <Col sm={{ size: 4, offset: 1 }} className='pr-0'>
                        <label className='mt-2'>
                            Landing Page
             </label>
                    </Col>
                    <Col sm={5} className='px-lg-0 px-md-2'>
                        <div className="form-group mb-2">
                            <Field
                                name={`${type}.Landing Page`}
                                // onChange={onChange}
                                onBlur={this.handleBlur}
                                className="form-control"
                            />

                        </div>
                    </Col>
                </Row>
                <Row className='templates'>
                    <Col sm={{ size: 6, offset: 5 }} className='px-lg-0 px-md-2'>
                        <p style={{ color: 'gray', fontSize: 10 }}>Landing page url is mandatory for online offers</p>
                    </Col>
                </Row>
                <Row className=''
                // style={{ height: 35 }}
                >
                    <Col sm={{ size: 4, offset: 1 }} className='pr-0'>

                    </Col>
                    <Col sm={5} className='px-lg-0 px-md-2'>
                        <div className='singleMultipleDiv d-flex'>
                            <div className={!this.state.multipleUpload ? 'py-2 bg-primary singleMultipleDivinside text-white' : 'py-2  singleMultipleDivinside'} onClick={(e) => {
                                let { options } = this.props;
                                // let { item, index, dynamicFields, handleBlur } = options;
                                this.setState({ multipleUpload: false, });
                                handlePromoCode('single', item, index, {
                                    name: e.target.name,
                                    value: e.target.value
                                })
                            }}>
                                Single
                            </div>
                            <div className={this.state.multipleUpload ? 'py-2 bg-primary singleMultipleDivinside text-white' : 'py-2  singleMultipleDivinside'} onClick={() => {
                                this.setState({ multipleUpload: true, })
                                handlePromoCode('multiple');
                            }}>
                                Multiple
                            </div>
                        </div>
                    </Col>
                    <Col sm={{ size: 4, offset: 1 }} className='pr-0'>
                        <label className='mt-2'>
                            Promo Codes
                        </label>
                    </Col>
                    <Col sm={5} className='px-lg-0 px-md-2 pt-2'>
                        {!this.state.multipleUpload ?
                            <div className="form-group mb-2">
                                {/* <Field
                                    name={`promocodes`}
                                    // onChange={onChange}
                                    onChange={(e) => {
                                        handleChange(e)
                                        this.handleBlur(e)
                                    }}
                                    onBlur={this.handleBlur}
                                    className="form-control"
                                /> */}
                                <Field
                                    name={`${type}.promocodes`}
                                    // onChange={onChange}
                                    onBlur={this.handleBlur}
                                    className="form-control"
                                />
                            </div>
                            : <div className="form-group templates mb-2">
                                <div className='csv-file-input'>
                                    <label className='mt-2 ml-2' htmlFor='promocode-file'>
                                        {this.state.multipleUpload && <BackupIcon />}{promoCodesMultiple && promoCodesMultiple.name ? promoCodesMultiple.name : ''}
                                    </label>
                                    <Field
                                        id='promocode-file'
                                        name={`promoCodesMultiple`}
                                        onChange={this.handleBlur}
                                        className="form-control d-none"
                                        type={'file'}
                                        accept=".csv"
                                        style={{ height: 38 }}
                                    />
                                </div>
                            </div>}
                    </Col>
                </Row>
                {this.state.multipleUpload && <Row className='templates'>
                    <Col sm={{ size: 6, offset: 5 }} className='px-lg-0 px-md-2'>
                        <p style={{ color: 'gray', fontSize: 10 }}>

                            min number of promo codes should be {((previewData.Expected_reach) * 1.2).toFixed()}(20% more than the expected reach)</p>
                    </Col>
                </Row>}
            </div> : null}
        </div>
    }

    render() {
        let { status, isSubmitting, values, options, setFieldValue } = this.props;
        let { isDisabled, type, handleChange, previewData, spend, fields, dynamicFields, index } = options;
        return (
            <Form>
                <Loader loader={this.state.isLoading} />
                {this.getFormFields()}
                {
                    status && (
                        <div className="form-group text-center">
                            <label className="error">{status}</label>
                        </div>
                    )
                }
                <CropModal close={this.onCloseCropModal}
                    callback={this.onCloseCropModal}
                    show={this.state.showCropModal}
                    title={"Select Image"}
                    message={<ImageSelection
                        name={this.state.name}
                        handleBlur={this.handleBlur}
                        onRef={(ref) => this.ImgSelectionRef = ref}
                    />}
                    action={'Done'}
                    hideCancelBtn={true}
                    size={'lg'}
                />
            </Form>
        )
    }
}

class FormikForm extends Component {
    constructor(props) {
        super(props)
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }
    date = new Date()
    initialValues = {
        sms: {
            landing: 'apple.com'
        },
        email: {
            validFor: 'one',
            landing: 'apple.com'
        },
        app: {
            landing: 'apple.com'
        },
        // promocodes: "C:\fakepath\merchant.5d35da03.csv"
    }

    validationSchema =
        YupObject({
            "sms.Link": YupString()
                .required('Website is required')
        })

    noop = () => { }

    //to save csv file in redux store
    handleBlur = (item, index, value) => {
        let { handleBlur, saveCsv, setInitialData, initialData } = this.props;
        if (value && value.name && value.value) {
            let field = value.name.split('.')
            // this.initialValues[field[0]][field[1]]=value.value
            initialData['sms'][field[1]] = value.value
            initialData['email'][field[1]] = value.value
            setInitialData(initialData)
        }
        handleBlur(item, index, value)
        if (value && value.name == 'promoCodesMultiple') {
            saveCsv(value.value)
        }
    }

    render() {
        let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options, type, handleChange, previewData, spend, fields, handleBlur, item, index, dynamicFields, promoCodesMultiple, promocodes, initialData, setInitialData, handlePromoCode } = this.props;
        if (dynamicFields && dynamicFields.length && dynamicFields[index] && dynamicFields[index].length) {
            // dynamicFields[index].forEach((_item, _index) => {
            //     this.initialValues[type][_item.fieldName] = fields[type][_item.fieldName]
            // })
        }
        return (
            <Formik
                initialValues={this.props.initialData}
                render={props => <TemplateFormComponent
                    {...props}
                    options={{ banks, ...options, type, handleBlur: this.handleBlur, previewData, spend, fields, item, index, dynamicFields, promocodes, promoCodesMultiple, setInitialData, initialData, handlePromoCode }}
                />}
                onSubmit={onSubmit}
                // validationSchema={this.validationSchema}
                ref={formikRef}
                enableReinitialize={enableReinitialize}
            />
        )
    }
}

export const CampaignTemplateForm = connect(
    state => ({
        banks: state.selectEntities.banks,
        promoCodesMultiple: state.selectEntities.promocodesCSVMULTIPLE,
        promoCodes: state.selectEntities.promocodesCSV,
        initialData: state.selectEntities.initialData
    }),
    (dispatch, ownProps) => ({
        saveCsv: bindActionCreators(saveCsvAction, dispatch),
        setInitialData: bindActionCreators(saveinitialValuesAction, dispatch)
    })
)(FormikForm)

