import React, {Component} from 'react'
import uniqueId from 'lodash/uniqueId'
import placeHolderImg from '../../assets/images/placeholder150.png'


class FormikImageUpload extends Component {
  constructor(props) {
    super(props)
    this.id = uniqueId('imageupload-')
    this.state = {
      image : props.image
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      this.setState({image : this.props.image})
    }
  }
  handleChange = e => {
    let {onChange} = this.props;
    let file = e.target.files[0]
    let image ='';
    if (file) {
      image = URL.createObjectURL(file)
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        onChange(reader.result)  
      };
      reader.onerror = function (error) {
      };
    } else {
      onChange('')
    }
    
    this.setState({image})
    
  }
  render() {
    let {image} = this.state;
    let { disabled } = this.props;
    return (
      <div className="file-upload">
        <label htmlFor={this.id} >
          {
            image
            ? <img src={image} alt="logo"/>
            : <img src={placeHolderImg} alt="placeholder"/>
          }
        </label>
        <input id={this.id} accept="image/*" type="file" disabled={disabled} onChange={this.handleChange}/>
      </div>
    )
  }
}

export default FormikImageUpload;