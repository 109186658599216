import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
import Loader from '../Loader'
//device info
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
import InsightsLoginForm from "./InsightsLogin.component";
import { getEntityDataByFilterAction, saveBrandForInsightsAction } from "../../redux/actions";
import { withRouter } from "react-router-dom";
const publicIp = require('public-ip');

const imgStyle = {
  'display': 'block',
  'height': "6rem",
  'textAlign': "center",
  'margin': 'auto',
  'marginTop': '2em',
  marginBottom: '1em'
}
const logoContainerStyle = {
  marginBottom: '2em',
  textAlign: 'center'
}

class InsightsLoginPage extends Component {
  state = {
    loader: false,
    deviceInfo: {},
    paramsExist: true,
    errorExist: false,
    errorMsgText: '',
    brands: []
  }

  componentDidMount = () => {
    // this.props.getBrandData({}, res => {
    //   if (res && res.length) {
    //     res.forEach((item, index) => {
    //       item.label = item.name
    //       item.value = item.brandId
    //     })
    //     this.setState({ brands: res })
    //   }
    // })
    this.getBrand("")
  }

  onSubmit = async (values, actions) => {
    let { history } = this.props;
    this.setState({ loader: true })

    await publicIp.v4().then((res) => {
      values.ipAddress = res
    })
    values.browserName = browserName
    values.osName = osName
    values.osVersion = osVersion
    values.deviceType = deviceType
    this.props.saveBrandForInsights(values, (res, type) => {
      history.push("/socialanalytics", { state: 'login' });
      this.setState({ loader: false })
    })
  }

  getBrand = (value) => {
    let filterCriteria = {
      'criteria': [
        {
          'key': 'brandname',
          'value': value,
          'type': 'regexOr',

        }
        // { "key": "active", "value": null, "type": "eq" }
      ]
    }
    this.props.getEntityDataByFilter(filterCriteria, res => {

      if (res && res.length) {
        res.forEach((item, index) => {
          item.label = item.brandname;
          item.value = item.brand_id;
        })
      }
      this.setState({
        brands: res || [],
      })
    })
  }

  render() {
    const { brands } = this.state
    return (
      <div>
        {
          this.state.errorExist ?
            <div className='row errorTextDiv' >
              <div className='col-sm-12 text-center'>
                <h4>{this.state.errorMsgText}</h4>
              </div>
            </div>
            : null}
        <Loader loader={this.state.loader} />
        <div style={logoContainerStyle}>
          <a href='https://krowd.info' target='_blank'>
            <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          </a>
          <h5> Customer Acquisition Marketplace</h5>
        </div>
        <div className="row form justify-content-center">
          <div className="col-md-4 col-lg-3" >
            <div className="card">
              <div className="header">
                <h3 className="text-center">Login</h3>
              </div>
              <div className="content">
                <InsightsLoginForm onSubmit={this.onSubmit} brands={brands} getBrand={this.getBrand} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveBrandForInsights: bindActionCreators(saveBrandForInsightsAction, dispatch),
    getEntityDataByFilter: bindActionCreators(getEntityDataByFilterAction, dispatch),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(InsightsLoginPage));


