import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, mixed as YupMixed } from 'yup';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
export class FileInputComponent extends Component {
  handleFileChange = e => {
    let { setFieldValue, setFileName } = this.props;
    let file = e.target.files[0]
    let name = this.props.field.name
    setFieldValue(name, file)
    setFileName(name, file)
  }
  render() {
    let { field: { name }, className, onBlur, accept, handleFileRef } = this.props;
    return <input
      ref={e => handleFileRef(name, e)}
      name={name}
      className={className}
      onBlur={onBlur}
      accept={accept}
      type='file'
      onChange={this.handleFileChange}
    />
  }
}

class UploadFormComponent extends Component {
  state = {
    formName: ''
  }
  fileUploadRef = {}
  handleFileRef = (name, ref) => {
    this.fileUploadRef[name] = ref;
  }
  componentDidMount = () => {
    // console.log('location',window.location.pathname)
    let url = window.location.pathname
    let urlArray = url.split('/')
    let formName = urlArray[urlArray.length - 1]
    if (formName == 'brands') {
      this.setState({ formName: '../../assets/csv/brand.csv' })
    } else if (formName == 'branches') {
      this.setState({ formName: 'http://api.krowd.dosystemsinc.com/docs/branch.csv' })
    }
    // console.log('urlName',formName)
  }
  handleFormReset = () => {
    Object.keys(this.fileUploadRef).forEach(it => {
      this.fileUploadRef[it].value = ''
    })
  }
  setFileName = async (name, file) => {
    console.log(file.type)
    if (file.type != 'text/csv') {
      await this.setState({
        status: 'File uploaded is not with .csv extension'
      })
      this.props.getFileType('wrong')
    } else {
      await this.setState({
        status: ''
      })
      this.props.getFileType('csv')
    }
  }
  getErrorBlock = status => {
    if (!status)
      return ''
    if (status instanceof Array) {
      return (
        <div className="form-group text-center">
          <ul className="error">
            {
              status.map((item, i) => {
                return (
                  <li key={i}>
                    <label>{item}</label>
                  </li>
                )
              })
            }
          </ul>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <label className="error">{status}</label>
        </div>
      )
    }
  }
  render() {
    let { status, isSubmitting, setFieldValue, touched, handleBlur, errors } = this.props;
    const { formName } = this.state
    return (
      <Form>
        <div className="form-group">
          <label className="pr-3">Sample File</label>
          <a
            // href={formName?require(`../../assets/csv/${formName}`):''} 
            // href={formName ?require(formName):''}
            href={formName}
            className='badge bg-info' download={true}><ArrowDownwardIcon /></a>
        </div>
        <div className="form-group">
          <label>CSV File</label>
          <Field
            name="file"
            type='file'
            className="form-control typeFile"
            disabled={isSubmitting}
            // accept="text/csv"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            setFieldValue={setFieldValue}
            component={FileInputComponent}
            touched={touched["file"]}
            onBlur={handleBlur}
            setFileName={this.setFileName}
            handleFileRef={this.handleFileRef}
          />
          <ErrorMessage name="file" component="label" className="error" />
          {!errors["file"] && <p className='text-secondary mb-0'><small>
            Download the sample file to see data format.</small></p>}
        </div>
        {this.getErrorBlock(status ? status : this.state.status)}
      </Form>
    )
  }
}


export class UploadForm extends Component {
  initialValues = {
    file: ''
  }
  validationSchema = YupObject({
    file: YupMixed()
      .required('File is required')
      .test('fileSize', 'File is empty', value => value && value.size > 0)
  })
  state = {
    isDisabled: false
  }
  noop = () => { }
  getFileType = async (item) => {
    console.log(item)
    if (item === 'csv') {
      await this.setState({
        isDisabled: false
      })
    } else {
      await this.setState({
        isDisabled: true
      })
    }
    this.props.formikRef({ 'uploadProps': { 'isDisabled': this.state.isDisabled } })
  }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options, enableReinitialize } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <UploadFormComponent {...props} options={options} getFileType={this.getFileType} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

