const configMessages = {
    name: 'Name is required',
    minName: 'Name should at least contain 3 characters',
    maxName: 'Name should at most contain 100 characters',
    email: 'Email is required',
    validEmail: 'Please provide a valid email',
    phone: 'Contact Number is required',
    phoneValidation: 'Contact Number can contain only numbers',
    role: 'Role is required',
    branchId: 'Visa/MasterCard  Merchant Id is required',
    amexMerchantID: 'Amex Merchant ID is required',
    zettleMerchantID: 'Zettle/square, etc. Merchant ID is required',
    password: 'Password is required',
    postCode: 'Post Code is required',
}
export default configMessages;