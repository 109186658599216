import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import styled from 'styled-components'
import Card from "react-bootstrap/Card";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Container } from './lineschart.styled.js'
// import { getReviewDataApiCall } from "../../../services/getInsightsDataApiCall.js";
import { insightsSelectors } from "../../../redux/insights/index.js";
import { useDispatch, useSelector } from "react-redux";
import { GetSessionValue } from "../../../utils/sessionStorage";

export const cardstyle = {
  borderRadius: '10px',
}
export const cardbody = {
  padding: '10px',
  justifyContent: 'center',
}

export const options = {
  title: "Net Positive Reviews",
  curveType: "function",
  legend: { position: "top" },
};

function LineChart() {
  const trendschartDataState = useSelector(insightsSelectors.getTrendsChartstate);
  const selectTrendschartDataState = useSelector(insightsSelectors.getSelectedTrendsChartstate);
  const selectTrendschart = useSelector(insightsSelectors.getSelectedTrends);
  const userDetails = GetSessionValue('user');

  const [reviewType, setReviewType] = useState({
    Food: [], Price: [], Ambiance: [], Service: []
  });
  const reviewTypeList = [
    { id: 1, type: "Food" },
    { id: 2, type: "Price" },
    { id: 3, type: "Ambiance" },
    { id: 4, type: "Service" }];
  const [selectedReviewType, setSelectedReviewType] = useState({ id: 1, type: "Food" });

  useEffect(() => {
    setTypeData();
  }, [trendschartDataState && trendschartDataState.lineschartDetails && trendschartDataState.lineschartDetails.data]);

  useEffect(() => {
    console.log("selectTrendschart", selectTrendschart);
    if (selectTrendschart && selectTrendschart.data && selectTrendschart.data.entityId &&
      selectTrendschartDataState && selectTrendschartDataState.lineschartDetails && selectTrendschartDataState.lineschartDetails.data) {
      let tempType = { Food: [], Price: [], Ambiance: [], Service: [] };
      trendschartDataState.lineschartDetails.data.forEach((e, index) => {
        if (index == 0) {
          tempType.Food.push(["Year", userDetails.brandDetails.name, selectTrendschart.data.displayname]);
          tempType.Price.push(["Year", userDetails.brandDetails.name, selectTrendschart.data.displayname]);
          tempType.Ambiance.push(["Year", userDetails.brandDetails.name, selectTrendschart.data.displayname]);
          tempType.Service.push(["Year", userDetails.brandDetails.name, selectTrendschart.data.displayname]);
        } else {
          tempType.Food.push([e[0], e[1], selectTrendschartDataState.lineschartDetails.data[index][1]]);
          tempType.Price.push([e[0], e[2], selectTrendschartDataState.lineschartDetails.data[index][2]]);
          tempType.Ambiance.push([e[0], e[3], selectTrendschartDataState.lineschartDetails.data[index][3]]);
          tempType.Service.push([e[0], e[4], selectTrendschartDataState.lineschartDetails.data[index][4]]);
        }
      });
      setInReviewType(tempType);
    } else {
      setTypeData();
    }
  }, [selectTrendschartDataState && selectTrendschartDataState.lineschartDetails && selectTrendschartDataState.lineschartDetails.data]);

  useEffect(() => {
    if (selectTrendschart && selectTrendschart.data && !selectTrendschart.data.entityId) {
      setTypeData(); 
    }
  }, [selectTrendschart]);

  const setTypeData = () => {
    if (trendschartDataState && trendschartDataState.lineschartDetails && trendschartDataState.lineschartDetails.data) {
      let tempType = { Food: [], Price: [], Ambiance: [], Service: [] };
      trendschartDataState.lineschartDetails.data.forEach((e, index) => {
        if (index == 0) {
          tempType.Food.push(["Year", userDetails.brandDetails.name]);
          tempType.Price.push(["Year", userDetails.brandDetails.name]);
          tempType.Ambiance.push(["Year", userDetails.brandDetails.name]);
          tempType.Service.push(["Year", userDetails.brandDetails.name]);
        } else {
          tempType.Food.push([e[0], e[1]]);
          tempType.Price.push([e[0], e[2]]);
          tempType.Ambiance.push([e[0], e[3]]);
          tempType.Service.push([e[0], e[4]]);
        }
      });
      setInReviewType(tempType);
    }
  }

  const setInReviewType = (dataList) => {
    if(dataList && dataList.Food.length == 13) {
      dataList.Food.splice(dataList.Food.length -3,3);
      dataList.Price.splice(dataList.Price.length -3,3);
      dataList.Ambiance.splice(dataList.Ambiance.length -3,3);
      dataList.Service.splice(dataList.Service.length -3,3);
    }
    setReviewType(dataList);
  }

  const getReviewTypeValue = (ele, val) => {
    console.log(ele, val);
    if (val) {
      setSelectedReviewType(val)
    }
  }

  return (
    <Container>
      <Card style={cardstyle} className="mb-3">
        <Card.Body style={cardbody} className="mb-3">
          {trendschartDataState && trendschartDataState.lineschartDetails.data ?
            (trendschartDataState.lineschartDetails.data.length > 1 ?

              <div className="row form">
                <div className="col-12 trends-chart-dropdown-contain">
                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300, height: 40, paddingTop: '5px', marginRight: '10px' }}
                    options={reviewTypeList}
                    autoHighlight
                    className='trends-chart-dropdown' 
                    // className='float-right'
                    getOptionLabel={(option) => option.type}
                    onChange={getReviewTypeValue}
                    size='small'
                    variant="outlined"
                    value={selectedReviewType}
                    renderOption={(props, option) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }} {...props}>
                        {option.type}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Type"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-12">
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="300px"
                    //data={trendschartDataState.lineschartDetails.data || []}
                    data={reviewType[selectedReviewType.type] || []}
                    options={options}
                  />
                </div>
              </div>
              :
              <p className="text-center"
              >No data available</p>) :
            <div className="d-flex justify-content-center spinner-block">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default LineChart


  // const userstate = useSelector(
  //   insightsSelectors.getUserstate);
  // console.log("=====",userstate)


