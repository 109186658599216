import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import configMessages from './../../config/configMessages';

const Modal = ({
    close,
    show,
    callback,
    size = "md",
    centered = true,
    className,
    data
}) => {

    let initialValues = {
        name: '',
        email: data.email || "",
        phone: data.phone || "",
        company: '',
        website: data.website || ""
        // designation: ''
    }

    const validationSchema = YupObject({
        name: YupString()
            .required('Name is required'),
        email: YupString()
            .required(configMessages.email)
            .email(configMessages.validEmail),
        // phone: YupString()
        //     .required('Phone number is required'),
        company: YupString()
            .required('Restaurant name is required'),
    })

    const getForm = props => {
        let { status, isSubmitting } = props;
        return (

            <Form className="form">

                <div className="form-group">
                    <label>Name</label>
                    <Field name="name" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="name" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="email" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Phone</label>
                    <Field name="phone" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="phone" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Restaurant Name</label>
                    <Field name="company" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="company" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Website</label>
                    <Field name="website" className="form-control" disabled={isSubmitting} autoComplete="off"/>
                    <ErrorMessage name="website" component="label" className="error" />
                </div>

                {/* <div className="form-group">
                    <label>Designation</label>
                    <Field name="designation" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="designation" component="label" className="error" />
                </div> */}

                <div className="form-group text-center">
                    <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting}>Submit</button>
                </div>
            </Form>
        )
    }

    const onSubmit = (values, actions) => {
        actions.setSubmitting(true);
        callback(values)
    }

    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered} className={className}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title>   </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>

                <div className="col-12 row d-flex align-items-center no-gutters amex-contactus-modal">

                    <div className="col-12 user-msg">
                        Please fill the details below and we will get back to you shortly with the next steps.
                    </div>

                    <div className="col-12 m-t-15">
                        <Formik
                            initialValues={initialValues}
                            render={props => getForm(props)}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        />
                    </div>

                </div>

            </ModalComponent.Body>

        </ModalComponent>
    )
}

export default Modal

