import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef } from 'yup';
import { passwordRegex } from '../../utils/constants/constant';

class CreatePasswordForm extends Component {

  initialValues = {
    newPassword: '',
    confirmPassword: ''
  }

  validationSchema = YupObject({
    newPassword: YupString()
      .required('New Password is required')
      .matches(passwordRegex, 'password must contain One special characters, upper case, number and min 8 and max 20 characters.')
      .min(8, 'Atleast 8 Characters')
      .max(20, 'Only 20 Characters are allowed'),
    confirmPassword: YupString()
      .required('Confirm Password is required')
      .oneOf([YupRef('newPassword'), null], 'Passwords must match'),
  })

  getForm = (props, options = { isDisabled: false, btnLabel: 'Submit' }) => {
    let { status, isSubmitting } = props;
    let { isDisabled, btnLabel } = options
    return (
      <Form className="form">
        <div className="form-group">
          <label>New Password</label>
          <Field name="newPassword" type='password' className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="newPassword" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <Field name="confirmPassword" type='password' className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="confirmPassword" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" type="submit" disabled={isSubmitting || isDisabled}>{btnLabel}</button>
        </div>
      </Form>
    )
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, options)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required
export default CreatePasswordForm










