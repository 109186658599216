import React from 'react'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'
import { numberWithCommas } from '../../utils/helpers'

const getCurrency = () => {
  // return <i className="fa fa-usd"></i>
  return "£"; //"GBP"
}

const StatCard = ({ icon, value, label, type, isCurrency, postFix, iconType }) => {
  return (
    <div>
      {icon && <div className="icon-with-bg">
        {iconType === 'material' ? <Icon className="text-primary" fontSize="inherit">{icon}</Icon>
          : <i className={`text-primary fa ${icon}`} ></i>}
      </div>}
      <p className={classNames('bold', { "mb-2": !icon })}>{
          value && type !== 'string' ? numberWithCommas(value) : value ? 
          !isNaN(value) ? numberWithCommas(value) :  value + (postFix ? postFix : "") : 0
      }</p>
      <p>
        {label}
        {
          isCurrency
            ? (
              <span> ({getCurrency()})</span>
            ) : ''
        }
      </p>
    </div>
  )
}

export const StatsContainer = ({ children, className }) => <div className={className}>{children}</div>

export default StatCard