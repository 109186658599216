import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAmexUserAction, notifyAdminForAmexAction } from '../../redux/actions/authActions';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import AmexContactUsModalComponent from './AmexContactUsModal';
import KrowdLogo from '../../assets/images/k_logo-1.png';
import AMEXLogo from '../../assets/images/american_Express_logo.png';
import AmexDiningBG from '../../assets/images/amex-dining.png';
import configMessages from './../../config/configMessages';
import TalkToUsImg from '../../assets/images/talk-bubble-02.png';

const imgStyle = {
    'display': 'block',
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const AMEXImgStyle = {
    // 'display': 'block',
    // 'height': "6rem",
    // 'textAlign': "right",
    // 'margin': 'auto',
    // 'marginTop': '2em',
    // 'marginBottom': '1em',
    // 'width': "7em",
    // 'right': 25,
    // 'position': 'relative'

    'display': 'block',
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const TalkToUsStyle = {
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}

const AmexDiningBGImgStyle = {
    'width': "100%",
}


class AmexSignup extends Component {

    initialValues = {
        amexMerchantID: '',
        email: '',
        phone: ''
    }

    state = {
        amexContactUsDialog: {
            isOpen: false,
            data: {}
        }
    }

    validationSchema = YupObject({
        amexMerchantID: YupString()
            .required('Merchant ID is required'),
        email: YupString()
            .required(configMessages.email)
            .email(configMessages.validEmail),
        // phone: YupString()
        //     .required('Phone number is required'),
    })

    getForm = props => {
        let { status, isSubmitting } = props;
        return (

            <Form className="form">

                <div className="form-group">
                    <label>Enter your Merchant ID</label>
                    <Field name="amexMerchantID" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="amexMerchantID" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="email" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Phone</label>
                    <Field name="phone" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="phone" component="label" className="error" />
                </div>

                <div className="form-group">
                    <label>Website</label>
                    <Field name="website" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="website" component="label" className="error" />
                </div>

                <div className="form-group text-center">
                    <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting}>Continue</button>
                </div>

            </Form>
        )
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(true);
        let { getAmexUser, history } = this.props;

        getAmexUser(values, (res, type) => {
            if (type == 'error') {
                actions.setSubmitting(false);
                this.setState({ amexContactUsDialog: { isOpen: true, data: values } })
            } else if (type == 'success') {
                actions.setSubmitting(false);
                history.push(`/guestUser/${res.email}?type=campaign&user=amex`)
            }
        })
    }

    submitNotifyAdminForAmex = (values) => {
        let { notifyAdminForAmex } = this.props;
        if (this.state.amexContactUsDialog.data && this.state.amexContactUsDialog.data.amexMerchantID) {
            values.amexMerchantID = this.state.amexContactUsDialog.data.amexMerchantID;
        }
        values.from = "amexsignup"
        notifyAdminForAmex(values, (res, type) => {
            window.open("https://krowd.info", "_self");
        })
    }

    openLink = (url) => {
        window.open(url, "_blank");
    }

    render() {

        return (


            <div className="row justify-content-center amex-dining-contain">

                <div className="logos-contain">
                    <div className="krowd-logo-container-style">
                        <img src={KrowdLogo} onClick={(e) => { this.openLink("https://krowdit.com/") }} style={imgStyle} alt="krowd-logo" />
                    </div>
                    <div className="amex-logo-container-style">
                        <img src={AMEXLogo} onClick={(e) => { this.openLink("https://amexdining.com/") }} style={AMEXImgStyle} alt="amex-logo" />
                    </div>
                </div>

                <div className="talk-to-us-area">
                    <img src={TalkToUsImg} onClick={(e) => { this.openLink("https://calendly.com/stacey-krowd/30min") }} style={TalkToUsStyle} alt="amex-dining" />
                </div>

                <div className="row col-12 justify-content-center bg-1-amex bg-1-amex-signup">
                    <div className="row col-xl-4 col-lg-4 col-md-4 col-sm-12 form amex-signup-1">
                        <div className="col-12">
                            <div className="card">
                                <div className="header">
                                    <div className="logo-container-style">
                                        <h3 className="text-center">Sign Up</h3>
                                        <h5 className="customer-am-title-signup"> Customer Acquisition Marketplace</h5>
                                    </div>

                                </div>
                                <div className="content form-box">
                                    <Formik
                                        initialValues={this.initialValues}
                                        render={props => this.getForm(props)}
                                        onSubmit={this.onSubmit}
                                        validationSchema={this.validationSchema}
                                    />

                                    {this.state.amexContactUsDialog.isOpen ?
                                        <AmexContactUsModalComponent
                                            close={() => { this.setState({ amexContactUsDialog: { isOpen: false } }); }}
                                            callback={this.submitNotifyAdminForAmex}
                                            show={this.state.amexContactUsDialog.isOpen}
                                            data={this.state.amexContactUsDialog.data}
                                        />
                                        : ""}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row col-12 m-t-10">
                    <div className="border-1"> </div>
                    <div className="border-2"> </div>
                </div>
                <div className="row col-12 justify-content-center amex-dining-bg">
                    <img src={AmexDiningBG} style={AmexDiningBGImgStyle} alt="amex-dining" />
                </div>
            </div>

        );
    }
}

export default connect(
    null,
    dispatch => ({
        getAmexUser: bindActionCreators(getAmexUserAction, dispatch),
        notifyAdminForAmex: bindActionCreators(notifyAdminForAmexAction, dispatch)
    })
)(AmexSignup);

