import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import Effect from '../../utils/components/FormikEffect'

import {
  object as YupObject,
  string as YupString,
  number as YupNumber,
  date as YupDate,
  array as YupArray,
} from 'yup'
import Slider from 'rc-slider';
import moment from 'moment';
import * as Datetime from 'react-datetime'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { CreateCampaignFormTips, CreateCampaignTips } from '../../i18n/tooltips'
import SplitButton from '../../utils/components/SplitButton'
import MultiSelect from "react-multi-select-component";
import Select from 'react-select';
import { GetSessionValue } from "../../utils/sessionStorage";

const getCurrency = () => {
  return 'GBP'
}
let overrideStrings = {
  "selectSomeItems": "Select Branches",
  "allItemsAreSelected": "All branches are selected.",
  "selectAll": "Select All Branches",
  "search": "Search",
  "clearSearch": "Clear Search"
}

let brandOverrideStrings = {
  "selectSomeItems": "Select Brands",
  "allItemsAreSelected": "All brands are selected.",
  "selectAll": "Select All Brands",
  "search": "Search",
  "clearSearch": "Clear Search"
}

let currentDate = new Date().getDate() > 25

export const CampaignFormInitialData = {
  name: '',
  branches: '',
  start_date: moment().startOf('month').add(currentDate ? 2 : 1, 'month').toDate(),
  // start_date: moment().set('month', 6).toDate(),
  start_time: "2021-05-03T04:30:00.000Z",
  end_time: "2021-05-03T11:30:00.000Z",
  customerType: 'all',
  lastTransaction: 1,
  // successFee: 0,
  budgetType: 'na',
  budget: '',
  age_range: [10, 100],
  gender: {
    male: true,
    female: true
  },
  discountType: 'dp',
  // discountPercent: '10',
  discountAmount: '',
  discountValue: '',
  discountSpreadAmount: '',
  discountSpreadValue: '',
  avgSpend: 0,
  brand_id: '',
  suggestions: '',
  merchantIds: [],
  weekDays: [],
  recurring: true,
  group_brand_ids: []
}
class CampaignForm extends Component {
  state = {
    dateIsFocused: false,
    isMenuShowing: false,
    brandOptions: [],
    merchantList: [],
    selectedValue: [],
    selectedMerchantAll: false,
    documents: this.props.documents,
    selectedImage: '',
    isMultiSelected: false,
    values: '',
    showAlertModal: false,
    open: false,
    endOpen: false,
    userFrom: "",
    tempSuccessFee: 0
  }

  /* TODO Optimize discount data storage */
  validationSchema = YupObject({
    userRole: YupString(),
    name: YupString()
      .required('Campaign name is required').min(5).max(70),
    start_date: YupDate()
      .typeError('Invalid date')
      .required('Campaign date is required'),
    customerType: YupString()
      .required('Customer Type is required'),
    discountType: YupString(),
    /* Flat Percent discount */
    // discountPercent: YupNumber()
    //   .when('discountType', (discountType, schema) => {
    //     return discountType === 'dp' ? schema.required('Discount percent is required').typeError('Discount percent must be a number') : schema
    //   }),
    /* Value discount  */
    discountAmount: YupNumber() /* in currency or actual value */
      .when('discountType', (discountType, schema) => {
        return discountType === 'dv' ? schema.required('Discount amount is required').typeError('Discount amount must be a number') : schema
      }),
    discountValue: YupNumber() /* in currency or actual value */
      .when('discountType', (discountType, schema) => {
        return discountType === 'dv' ? schema.required('Discount is required').typeError('Discount must be a number') : schema
      }),
    /* Spread Discount fields */
    discountSpreadAmount: YupNumber() /* percentage of avg spend amount */
      .when('discountType', (discountType, schema) => {
        return discountType === 'ss' ? schema.required('ATV percent is required').typeError('ATV much be a number') : schema
      }),
    discountSpreadValue: YupNumber() /* percentage of discount */
      .when('discountType', (discountType, schema) => {
        return discountType === 'ss' ? schema.required('Discount is required').typeError('Discount must be a number') : schema
      }),
    lastTransaction: YupNumber()
      .when('customerType', (customerType, schema) => {
        return customerType === 'existing' ? schema.required('Please select the last transaction') : schema
      })
  })

  monthOptions = []
  constructor(props) {
    super(props)
    this.setMonthOptions(0, 6)
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    let { history } = this.props;
    this.setState({ history: history });
    setTimeout(async () => {
      let selectedMerchants = []
      let merchantIds = this.props.merchantIds
      if (merchantIds) {
        merchantIds.forEach((item, index) => {
          selectedMerchants.push({ label: item.name, value: item.merchantId })
        })
        await this.setState({ merchantList: selectedMerchants })
      }
    }, 2000)
    let userDetails = await GetSessionValue('loginCredentials')
    let brands = userDetails ? userDetails.brandIds : ""
    let brandsArray = []
    if (brands) {
      brands.forEach((item, index) => {
        brandsArray.push({
          label: item.brandName,
          value: item.brandId
        })
      })
    }
    await this.setState({ brandOptions: brandsArray });

    let userFrom = sessionStorage.getItem("userFrom");
    if (userFrom == "amex") {
      this.setState({ userFrom: "amex" });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let selectedMerchants = []
    let merchantIds = props.merchantIds
    if (merchantIds.length && !state.isMultiSelected) {
      merchantIds.forEach((item, index) => {
        selectedMerchants.push({ label: item.name, value: item.merchantId })
      })
      return { merchantList: selectedMerchants, values: props.initialValues };
    } else if (props.initialValues) {
      return { values: props.initialValues }
    }
  }

  onRemove(selectedList, removedItem) {

  }

  branchValidation = () => {
    let { branches } = this.props;
    if (branches && branches.length) {
      return YupArray().required('At least one is branch required')
    }
    return YupArray()
  }

  getRangeSliderMarks = () => {
    let nArray = new Array(10).fill(10)
    let result = nArray.reduce((result, item, index) => {
      result[(index + 1) * 10] = `${(index + 1) * 10}`
      return result
    }, {})
    return result
  }

  setMonthOptions = (startMonth, endMonth) => {
    if (!this.monthOptions.length) {
      let options = []
      for (let i = startMonth; i <= endMonth; i++) {
        options.push({
          value: i,
          label: `${i} Month${i > 1 ? 's' : ''} ago`
        })
      }
      this.monthOptions = options
    }
  }

  getExistingCustomerDefault = value => {
    let o = this.monthOptions.filter(a => a.value === value)
    return o.length ? o[0] : this.monthOptions[0]
  }
  setOpen = ({ open }) => {
    this.setState({ open });
  };

  setEndOpen = () => {
    this.setState({ endOpen: !this.state.endOpen });
  };
  customValueRenderer = (selected, _options) => {
    if (selected.length != _options.length) {
      return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "multiple branches selected" : "No Items Selected"
    }
  };
  brandCustomValueRenderer = (selected, _options) => {
    if (selected.length != _options.length) {
      return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "multiple branches selected" : "No Items Selected"
    }
  };
  handleShowAlertModal = async () => {
    await this.setState({ showAlertModal: !this.state.showAlertModal })
  }

  getForm = (props, options) => {
    let { isSubmitting, values, setFieldValue, formType } = props;
    const { languageData } = this.props
    if (values.discountType == 'dp') {
      values.discountValue = '';
      values.discountAmount = '';
    }
    values.customerType = "all";

    if (values.customerType) {
      let userDetails = GetSessionValue('loginCredentials')
      if (values.start_date && this.props.formType != 'edit') {
        let userFrom = sessionStorage.getItem("userFrom");
        if (userFrom == "amex") {
          // values.start_date.setMonth(9);
          let currentMonth = `${new Date(0, values.start_date.getMonth()).toLocaleString('default', { month: 'long' })}`;
          values.name = `${userDetails.brandName}_${currentMonth}_${values.start_date.getFullYear()}`;
          // values.name = `AMEX_${userDetails.brandName}_${currentMonth}_${values.start_date.getFullYear()}`;
        } else {
          let customerType = {
            lapsed: "Lapsed",
            all: "All",
            new: "New",
          }
          let currentMonth = `${new Date(0, values.start_date.getMonth()).toLocaleString('default', { month: 'short' })}`;
          // values.name = `${userDetails.brandName}_${customerType[values.customerType]}_${currentMonth}_${values.start_date.getFullYear()}`;
          values.name = `${userDetails.brandName}_${currentMonth}_${values.start_date.getFullYear()}`;
          console.log("userDetails", userDetails);
        }
      }
    }
    let thisMonth = currentDate ? moment().add(1, 'months') : moment().startOf('month')
    let sixMonth = currentDate ? moment().add(6, 'months') : moment().add(7, 'months')
    // let thisMonth = moment().set('month', 5)
    let campMonthValid = (current) => {
      return current.isAfter(thisMonth, 'month') && current.isBefore(sixMonth, 'month')
    };

    let { user, brands, title, onChangeListener, setShowTCModal, settingsState } = options;
    let discountStep = settingsState && settingsState.campaignDiscountStep ? settingsState.campaignDiscountStep : 1
    let successFeeStep = settingsState && settingsState.campaignSuccessFeeStep ? settingsState.campaignSuccessFeeStep : 1
    let minDiscount = settingsState && settingsState.campaignDiscountCount ? settingsState.campaignDiscountCount : 1
    let minSucess = settingsState && settingsState.campaignSuccessFeeCount ? settingsState.campaignSuccessFeeCount : 1

    // if(this.props.formType != 'edit' && this.state.tempSuccessFee === 0) {
    //   this.setState({ tempSuccessFee : brands.successFee}, () => {
    //     minSucess = values.successFee = this.state.tempSuccessFee;
    //   });      
    // } else {
    //   minSucess = brands.successFee;
    // }

    let userDetails = GetSessionValue('loginCredentials')
    if (userDetails && userDetails.email === "chirantan@krowdit.com" || this.state.userFrom == "amex") {
      values.discountPercent = 10;
    }

    if (brands && (brands.length > 0 || Object.keys(brands).length > 0)) {
      values.distributorDealSetWithBrand = brands.distributorDealSetWithBrand;
    }

    if (this.props.selectedGroupBrands) {
      values.group_brand_ids = this.props.selectedGroupBrands;
    }

    // const getDiscountInput = type => {
    //   if (type === 'dp') {
    //     return (
    //       <div className="form-group">
    //         <label className="d-flex align-items-center">
    //           <ToolTipContainer
    //             toolTipText={CreateCampaignFormTips['discountPercent']}
    //             containerClassNames=""
    //             id="discountPercent"
    //           >
    //             {`${languageData && languageData['cashback'] ? languageData['cashback'] : 'Cashback'}`}
    //           </ToolTipContainer>

    //           <span className="btn  btn-sm ml-auto text-primary">
    //             {values.discountPercent}%
    //           </span>
    //         </label>
    //         <Slider
    //           min={minDiscount}
    //           max={75}
    //           step={discountStep}
    //           value={values.discountPercent}
    //           onChange={(value) => setFieldValue('discountPercent', value)}
    //         />
    //       </div>
    //     )
    //   } else if (type === 'dv') {
    //     let disA = values.discountAmount && values.discountAmount > 0 ? values.discountAmount : '';
    //     let disV = values.discountValue && values.discountValue > 0 ? values.discountValue : '';
    //     return (
    //       <div className='row form-group'>
    //         <div className='col-sm-6 pb-3'>
    //           <div >
    //             <div>
    //               <p>If customer spends ({getCurrency()})</p>
    //               <Field className="form-control" name="discountAmount"
    //                 value={disA} />
    //             </div>
    //             <ErrorMessage name="discountAmount" component="label" className="error" />
    //           </div>
    //         </div>
    //         <div className='col-sm-6'>
    //           <div>
    //             <div >
    //               <p >Customer receives ({getCurrency()})</p>
    //               <Field className="form-control" name="discountValue"
    //                 value={disV}
    //               />
    //             </div>
    //             <ErrorMessage name="discountValue" component="label" className="error" />
    //           </div>

    //         </div>
    //       </div> 
    //     )
    //   }
    // }


    const getDiscountList = (values) => {


      return (
        <div className="row col-12 mt-3 brand-d-s">
          <table className="table table-bordered">
            <thead>
              <tr>
                <td> Distributor name </td>
                <td> Cashback % </td>
                <td> Success Fee % </td>
                <td> Start date </td>
              </tr>
            </thead>
            <tbody>

              {values.distributorDealSetWithBrand && values.distributorDealSetWithBrand.length > 0 ? values.distributorDealSetWithBrand.map((dis_row, index) => (

                <tr className="distributors-row" key={"discount" + index}>
                  <td className="p-t-10"> {dis_row.distributors_name}  </td>
                  <td>
                    {dis_row.percentage}
                  </td>
                  <td>
                    {dis_row.successFee}
                  </td>
                  <td className='date-rang-box'>
                    {dis_row.startDate ? moment(new Date(dis_row.startDate)).format('DD-MM-YYYY') : ""}
                  </td>
                </tr>
              )) : null}

            </tbody>
          </table>
        </div>
      )

    }


    console.log("start_date", values.start_date);
    return (
      <Form className="form">
        <Effect onChange={(currentFormikState, nextFormikState) => {
          if (onChangeListener) {
            onChangeListener(currentFormikState, nextFormikState)
          }
        }}
        />
        <div className="row">
          <div className="form-group col-lg-7 col-md-6 mb-lg-0">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['name']}
                id="name"
              >
                {`${languageData && languageData['campaign'] ? languageData['campaign'] : 'Campaign'} ${languageData && languageData['name'] ? languageData['name'] : 'Name'}`}
              </ToolTipContainer>
            </label>
            <Field name="name" className="form-control" disabled={true}
            />
            <ErrorMessage name="name" component="label" className="error" />
          </div>
          <div className="form-group col-lg-5 col-md-6 mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['start_date']}
                id="start_date"
              >
                {`${languageData && languageData['startDate'] ? languageData['startDate'] : 'Campaign Month'} `}
              </ToolTipContainer>
            </label>
            <Datetime
              viewMode="months"
              timeFormat={false}
              dateFormat="MM/YYYY"
              defaultValue={values.start_date}
              minDate={values.start_date}
              isValidDate={campMonthValid}
              onChange={(date) => setFieldValue('start_date', date._d)}
              renderInput={(props, openCalendar) => {
                return (
                  <div className="input-group mb-3">
                    <input {...props} type="text" className="form-control" name="start_date" />
                    <div className="input-group-append date-dropdown-btn">
                      <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                    </div>
                  </div>
                )
              }}
            />
            <ErrorMessage name="start_date" component="label" className="error" />
          </div>

        </div>

        {/* ........ Always Hide ........... */}
        {false && this.state.userFrom !== "amex" ?
          <div className="row mt-3">
            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
              <label>
                Type of Customer
              </label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <ToolTipContainer
                    toolTipText={""}
                    containerClassNames="ml-2 mb-1"
                    id="allCustomer"
                  >
                    <Field checked={values.customerType === 'all'} name="customerType" type="radio" id="customer1"
                      className="form-check-input mr-2" value="all"
                    />
                  </ToolTipContainer>
                  <label className="form-check-label" htmlFor="customer1">
                    <ToolTipContainer
                      toolTipText={""}
                      containerClassNames=" mb-1"
                      id="allCustomer"
                    >
                      <div className="pb-1">
                        {`${languageData && languageData['all'] ? languageData['all'] : 'All'}`}
                      </div>
                    </ToolTipContainer>
                  </label>
                </div>

                {/* <div className="form-check form-check-inline custom-align ">
                    <ToolTipContainer
                      // toolTipText={brands.association == "Revolut" ? "" : CreateCampaignFormTips['existingCustomer']}
                      toolTipText={""}
                      containerClassNames="ml-2 mb-1"
                      id="existingCustomerradio"
                    >
                      <Field
                        // onClick={(e) => this.setState({ showAlertModal: true })}
                        checked={values.customerType === 'existing'} name="customerType"
                        type="radio" id="customer2" className="form-check-input "
                        value="existing"
                      // disabled={isSubmitting}
                      // disabled={brands.association == "BA"}
                      />
                    </ToolTipContainer>
                    <label className="form-check-label" htmlFor="customer2">
                      <ToolTipContainer
                        // toolTipText={brands.association == "Revolut" ? "" : CreateCampaignFormTips['existingCustomer']}
                        toolTipText={""}
                        containerClassNames="mb-1"
                        id="existingCustomer"
                      >
                        <div className="pb-1">
                            Existing
                        </div>
                      </ToolTipContainer>
                    </label>
                  </div> */}


                <div className="form-check form-check-inline custom-align ">
                  <ToolTipContainer
                    // toolTipText={brands.association == "Revolut" ? "" : CreateCampaignFormTips['existingCustomer']}
                    toolTipText={""}
                    containerClassNames="ml-2 mb-1"
                    id="existingCustomerradio"
                  >
                    <Field
                      // onClick={(e) => this.setState({ showAlertModal: true })}
                      checked={values.customerType === 'lapsed'} name="customerType"
                      type="radio" id="customer2" className="form-check-input "
                      value="lapsed"
                    // disabled={isSubmitting}
                    // disabled={brands.association == "BA"}
                    />
                  </ToolTipContainer>
                  <label className="form-check-label" htmlFor="customer2">
                    <ToolTipContainer
                      // toolTipText={brands.association == "Revolut" ? "" : CreateCampaignFormTips['existingCustomer']}
                      toolTipText={"Not available for BA program"}
                      containerClassNames="mb-1"
                      id="existingCustomer"
                    >
                      <div className="pb-1">
                        {'Lapsed (> 6 Months)'}
                      </div>
                    </ToolTipContainer>
                  </label>
                </div>


                <div className="form-check form-check-inline custom-align mr-4">
                  <ToolTipContainer
                    // toolTipText={CreateCampaignFormTips['newCustomer']}
                    toolTipText={""}
                    containerClassNames="ml-2 mb-1"
                    id="newCustomerradio"
                  >
                    <Field
                      disabled={!brands.isNewCustomer}
                      // onClick={(e) => this.setState({ showAlertModal: true })}
                      checked={values.customerType === 'new'} name="customerType"
                      type="radio" id="customer1" className="form-check-input " value="new"
                    />
                  </ToolTipContainer>

                  <label className="form-check-label" htmlFor="customer1">
                    <ToolTipContainer
                      toolTipText={brands.isNewCustomer ? "Not available for BA program" : "Coming soon"}
                      containerClassNames=" mb-1"
                      id="newCustomer"
                    >
                      <div className="pb-1">
                        {/* {`${languageData && languageData['new'] ? languageData['new'] : 'New'} ${languageData && languageData['customers'] ? languageData['customers'] : 'Customers'}`} */}
                        New

                      </div>
                    </ToolTipContainer>
                  </label>
                </div>
              </div>
              <ErrorMessage name="customerType" component="label" className="error" />
            </div>



            {/* {values.customerType === 'existing' ?
                <div className="form-group col-lg-5 col-md-12 col-sm-12 col-12 mb-0" style={{ visibility: values.customerType === 'existing' ? 'visible' : 'hidden' }}>
                  <label>{`${languageData && languageData['last'] ? languageData['last'] : 'Last'} ${languageData && languageData['transacted'] ? languageData['transacted'] : 'Transacted'}`}</label>
                  <Select
                    classNamePrefix="reactselect"
                    options={this.monthOptions}
                    defaultValue={this.getExistingCustomerDefault(values.lastTransaction)}
                    onChange={(obj) => {
                      setFieldValue('lastTransaction', obj.value)
                    }}
                  />
                  <ErrorMessage name="lastTransaction" component="label" className="error" />
                </div> : null
              } */}
          </div>
          : ""}
        <hr className="divider mb-4 mt-4" />

        {false &&

          <div className='row pt-lg-3 pt-md-2 pt-sm-2 pt-2'>
            {/* <div className="form-group col-lg-8 col-md-8 col-sm-12 col-12">
            

            </div> */}
            <div className="form-group col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field checked={values.discountType === 'dp'} name="discountType" type="radio" id="discountType" className="form-check-input mr-2" value="dp"
                  />
                  <label className="form-check-label" htmlFor="discountType">
                    <ToolTipContainer
                      toolTipText={CreateCampaignFormTips['discountPercent']}
                      containerClassNames="ml-2 mb-1"
                      id="discountPercent"
                    >
                      {`${languageData && languageData['discount'] ? languageData['discount'] : 'Discount'}`} (%)
                    </ToolTipContainer>
                  </label>
                </div>
                <div className="form-check form-check-inline custom-align customMargin">
                  <Field checked={values.discountType === 'dv'} name="discountType" type="radio" id="discount_type2" className="form-check-input mr-2" value="dv"
                    disabled
                  />
                  <label className="form-check-label" htmlFor="discount_type2">
                    <ToolTipContainer
                      toolTipText={CreateCampaignFormTips['discountValue']}
                      containerClassNames="ml-2 mb-1"
                      id="discountValue"
                    >
                      {`${languageData && languageData['discount'] ? languageData['discount'] : 'Discount'} `} (value)
                    </ToolTipContainer>
                  </label>
                </div>
              </div>
              <ErrorMessage name="discountType" component="label" className="error" />
            </div>
          </div>
        }
        {/* {getDiscountInput(values.discountType)} */}
        {/* <div className="form-group">
          <label className="d-flex align-items-center mb-3">
           
            {`${languageData && languageData['success'] ? languageData['success'] : 'Success'} ${languageData && languageData['fee'] ? languageData['fee'] : 'Fee'}`}
            
            <span className="btn btn-sm ml-auto text-secondary ">
              {values.successFee}%
            </span>
          </label>
          <Slider
            min={minSucess}
            max={50}
            step={successFeeStep}
            value={values.successFee}
            onChange={(value) => setFieldValue('successFee', value)}
          />

        </div> */}

        {getDiscountList(values)}

        <div className='row '>
          <div className="form-group col-lg-12  mb-0 d-none">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['week_days']}
                id="week_days"
              >
                {`${languageData && languageData['weekDays'] ? languageData['weekDays'] : 'Week Days'} `}
              </ToolTipContainer>
            </label>
            <div>
              <div className="input-group mb-3">
                <div className='d-flex '>
                  {this.props.weekDaysArray && this.props.weekDaysArray.length > 0 ? this.props.weekDaysArray.map((weekItem, weekIndex) => (
                    <div className='d-flex pb-2' key={"weekIndex" + weekIndex}>
                      <div
                        className={weekItem.selected ? 'weekDaysDiv' : 'weekDayNotSelected'}
                        onClick={() => this.props.handleWeekDayArray(weekIndex, weekItem.label)}
                      >
                        <span className='weekDaysDivSpan'>{weekItem.label}</span>
                      </div>
                      {weekIndex < this.props.weekDaysArray.length - 1 ? <div className='weeksDaysBarDiv'></div> : null}
                    </div>
                  )) : null}

                </div>
              </div>
            </div>

            <ErrorMessage name="week_days" component="label" className="error" />
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-0 d-none">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['start_time']}
                id="start_time"
              >
                {`${languageData && languageData['startTime'] ? languageData['startTime'] : 'Start Time'} `}
              </ToolTipContainer>
            </label>
            <div>
              <div className="input-group mb-3">
                <TimePicker
                  onChange={(date) => setFieldValue('start_time', date)}
                  format="h:mm a"
                  name='start_time'
                  showSecond={false}
                  open={this.state.open}
                  onOpen={this.setOpen}
                  onClose={this.setOpen}
                  // showTime={{ user12hours: true, format: "HH:mm a" }}
                  // showTimeSelect
                  use12Hours={'h:mm a'}
                  autoComplete='off'
                  value={values.start_time ? moment(values.start_time) : null}
                />
              </div>

            </div>
            <ErrorMessage name="start_time" component="label" className="error" />
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-0 d-none">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['end_time']}
                id="end_time"
              >
                {`${languageData && languageData['endTime'] ? languageData['endTime'] : 'End Time'} `}
              </ToolTipContainer>
            </label>
            <div>
              <div className="input-group mb-3">
                <TimePicker
                  onChange={(date) => setFieldValue('end_time', date)}
                  format="h:mm a"
                  name='end_time'
                  showSecond={false}
                  open={this.state.endOpen}
                  onOpen={this.setEndOpen}
                  onClose={this.setEndOpen}
                  // showTime={{ user12hours: true, format: "HH:mm a" }}
                  // showTimeSelect
                  use12Hours={'h:mm a'}
                  autoComplete='off'
                  value={values.end_time ? moment(values.end_time) : null}
                />
              </div>

            </div>
            <ErrorMessage name="end_time" component="label" className="error" />
          </div>
        </div>
        <div className="row">

          {userDetails.role === "groupAdmin" && this.props.formType !== 'edit' && <div className="form-group col-md-12 mb-2">
            <label>
              Select Brand
            </label>


            <MultiSelect
              className='multi-select'
              options={this.props.groupBrandList}
              value={this.props.selectedGroupBrands}
              onChange={(value) => {
                this.props.onSelectBrand(value)
                setFieldValue('group_brand_ids', value)
              }}
              labelledBy={"Select Brand"}
              required={true}
              overrideStrings={brandOverrideStrings}
              valueRenderer={this.brandCustomValueRenderer}
            />
            {this.props.merchantList.length > 0 ? <label className='error'>Please select Brand</label> : ''}
            <ErrorMessage name="brandId" component="label" className="error" />
          </div>}



          {!this.props.withoutBranch && <div className="form-group col-md-12 mb-2">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['selectBranch']}
                containerClassNames="ml-2"
                id="selectBranch"
              >
                {`${languageData && languageData['select'] ? languageData['select'] : 'Select'} ${languageData && languageData['branches'] ? languageData['branches'] : 'Branches'}`}
              </ToolTipContainer>
            </label>


            <MultiSelect
              className='multi-select'
              options={this.props.merchantIds}
              value={this.props.merchantList}
              onChange={(value) => {
                this.props.onSelect(value)
                setFieldValue('branches', value)
              }}
              labelledBy={"Select Merchant Id"}
              required={true}
              overrideStrings={overrideStrings}
              valueRenderer={this.customValueRenderer}
            />
            {this.props.merchantsValid ? <label className='error'>Please select Branches</label> : ''}
            <ErrorMessage name="brandId" component="label" className="error" />
          </div>}
          {!this.props.withoutBranch && <div className="form-group col-md-6 d-none">
            <label>{`${languageData && languageData['select'] ? languageData['select'] : 'Select'} ${languageData && languageData['image'] ? languageData['image'] : 'Image'}`}</label>
            <Select
              options={this.props.documents}
              onChange={e => this.setState({ selectedImage: e.value })}
              labelledBy={"Select Merchant Id"}
              required={true}
            />
          </div>}
        </div>
        <hr className="divider mb-4" />
        <div className="row">
          <div className="form-group col-md-12">
            <label>
              <ToolTipContainer
                toolTipText={CreateCampaignFormTips['suggestions']}
                id="smstemplate"
              >
                {`${languageData && languageData['additional'] ? languageData['additional'] : 'Additional'} ${languageData && languageData['comments'] ? languageData['comments'] : 'Comments'}`}
              </ToolTipContainer>
            </label>
            <div
              style={{
                float: 'right',
                padding: '3px',
                margin: '4px',
                color: '#007bff',
                borderRadius: '3px'
              }}
            >{values && values.suggestions ? values.suggestions.length : 0}/600</div>
            <Field
              placeholder="Use this space to add any relevant information not already captured"
              rows="4"
              component="textarea"
              name="suggestions"
              className="form-control"
              maxLength="600"
            />
          </div>
        </div>
        <div className="row">
        </div>
      </Form>
    )
  }
  noop = () => { }
  render() {
    let {
      initialValues,
      onSubmit,
      onChangeListener,
      formikRef = this.noop,
      enableReinitialize,
      branches,
      brands,
      user,
      title,
      setShowTCModal,
      showPreviewModal,
      settings,
      settingsState
    } = this.props;
    let campaignFormInitialDataEx = initialValues ? { ...initialValues } : { ...CampaignFormInitialData }

    // if (!this.props.withoutBranch) {
    //   CampaignFormInitialData.discountPercent = 5
    //   CampaignFormInitialData.successFee = 1;
    // }

    // let minSucess = settingsState && settingsState.campaignSuccessFeeCount
    // if (minSucess && campaignFormInitialDataEx.successFee == 0) { 
    //   campaignFormInitialDataEx.successFee = minSucess
    // }


    if (user) {
      if (user.role == 'cardprovider') {
        if (!campaignFormInitialDataEx.branches && branches.length)
          campaignFormInitialDataEx.branches = branches
      } else {
        if (!campaignFormInitialDataEx.branches)
          campaignFormInitialDataEx.branches = branches
      }
    }

    if (!this.props.withoutBranch) {
      if (!campaignFormInitialDataEx.userRole)
        campaignFormInitialDataEx.userRole = user.role
    }
    return (
      <div>
        <Formik
          initialValues={campaignFormInitialDataEx}
          render={props => this.getForm(props, { onChangeListener, branches, user, brands, title, setShowTCModal, settings, settingsState })}
          onSubmit={onSubmit}
          validationSchema={this.validationSchema}
          ref={formikRef}
          enableReinitialize={enableReinitialize}
        />
        <Modal isOpen={this.state.showAlertModal} size='md' centered={true}>
          <ModalHeader className="bold-text" style={{ backgroundColor: '#323657', color: 'white', textAlign: 'center' }}  >
            Alert
          </ModalHeader>
          <ModalBody className='content__modal'>
            <h5 className="py-3">{'Selecting All works best as that will ensure you dont lose out on acquiring new customers and reengaging with dormant customers in a post pandemic world'}</h5>
            <Row className="justify-content-center">
              <button className="btn btn-primary btn-lg" onClick={this.handleShowAlertModal} style={{ backgroundColor: '#323657', border: 'none' }}>Ok</button>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default connect(
  state => ({
    branches: state.selectEntities.branches,
    brands: state.selectEntities.brands,
    user: state.user,
    settings: state.selectEntities.settingsData
  }),
  null
)(CampaignForm);

