import axiosMethodRequest from '../config/service';
import { GET_RADAR_DATA } from '../redux/insights/actionTypes'
export const getSimilarityDataAPICall = (similarDates, entityId) => {
    return dispatch => {
        dispatch({
            type: GET_RADAR_DATA,
            payload: { data: null, loader: true }
        })
        const url = `reviewsdata/similarity?${similarDates}&entityId=${entityId}`
        return axiosMethodRequest('GET', url).then((response) => {
            console.log('GET_RADAR_DATA response', response);
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_RADAR_DATA,
                    payload: { data: response.data, loader: false }

                });
            }
        });
    }
}
