import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import Loader from './Loader'
import { Link } from 'react-router-dom';
const Modal = ({
    close,
    show,
    title,
    size = "md", 
    centered = true,
    isLoading,
    className,
    data
}) => {
    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered} className="invalid-field-error-message-modal">
            <ModalComponent.Header closeButton>
                <ModalComponent.Title>Validation Error</ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>
                <Loader loader={isLoading} />

                <div className="row invalid-field-error-message-modal">
                            {/* <div className="col-12 title">
                                    Validation Error
                            </div> */}
                    {Object.keys(data).map((item, i) => {
                        return ( 
                            <div className="col-12" key={i}>
                                {data[item]}
                            </div>

                        );
                    })}
                </div>
            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    Close
                </Button>
            </ModalComponent.Footer>
        </ModalComponent >
    )
}

export default Modal

