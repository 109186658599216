export const GET_INSIGHTS_DATA = 'GET_INSIGHTS_DATA'
export const GET_REVIEW_DATA = 'GET_REVIEW_DATA'
export const GET_REVIEW_FACTRS_DATA = 'GET_REVIEW_FACTRS_DATA'
export const GET_BRANCH_DATA = 'GET_BRANCH_DATA'
export const GET_RADAR_DATA = 'GET_RADAR_DATA'
export const GET_SCATTER_DATA = 'GET_SCATTER_DATA'
export const GET_REVIEW_CHART_DATA ='GET_REVIEW_CHART_DATA'
export const SET_INSIGHT_DATE ='SET_INSIGHT_DATE'
export const GET_TRENDS_DATA ='GET_TRENDS_DATA'
export const GET_SELECTED_TRENDS_DATA ='GET_SELECTED_TRENDS_DATA'
export const GET_SELECTED_TRENDS ='GET_SELECTED_TRENDS'
export const GET_CUSTOMER_RATING_DATA ='GET_CUSTOMER_RATING_DATA'
export const RESET_INSIGHTS_DATA ='RESET_INSIGHTS_DATA'
export const GET_BRANCHES_NODES_IN_GRAPH ='GET_BRANCHES_NODES_IN_GRAPH'
export const GET_FILTER_DATA ='GET_FILTER_DATA'