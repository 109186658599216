
import axios from '../../utils/AxiosWrapper'
import { pluralize } from '../../utils/helpers'
import axiosMethodRequest from '../../config/service'
import apiCalls from '../../config/apiCalls'
import config from '../../config/config'
import { GetSessionValue, SetSessionValue } from '../../utils/sessionStorage'
import { setUserAction } from './authActions';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import { GET_BRANCHES_NODES_IN_GRAPH } from '../../redux/insights/actionTypes';

export const setEntityPageAction = type => {
  return (page, sizePerPage) => {
    return {
      type: `SET_${type.toUpperCase()}_PAGE`,
      payload: {
        page,
        sizePerPage
      }
    }
  }
}
export const setEntitySortAction = type => {
  return (sortField, sortOrder) => {
    return {
      type: `SET_${type.toUpperCase()}_SORT`,
      payload: {
        sortField,
        sortOrder
      }
    }
  }
}

export const setEntitySearchAction = type => {
  return (search) => {
    return {
      type: `SET_${type.toUpperCase()}_SEARCH`,
      payload: { search }
    }
  }
}

export const setEntityFiltersAction = type => {
  return (filters) => {
    return {
      type: `SET_${type.toUpperCase()}_FILTERS`,
      payload: filters
    }
  }
}


const setEntityData = (type, data, pagination) => {
  return {
    type: `SET_${type.toUpperCase()}_DATA`,
    payload: data,
    pagination: pagination
  }
}

const setEntityLoadingState = (type, loadingState) => {
  return {
    type: `SET_${type.toUpperCase()}_DATA_LOADINGSTATE`,
    payload: loadingState
  }
}

const setManageCreativeData = (type, data) => {
  return {
    type: `SET_MANAGECREATIVE_DATA`,
    payload: data
  }
}

const setBrandDetailsData = (data) => {
  return {
    type: `SET_BRAND_DETAILS_DATA`,
    payload: data
  }
}

export const getEntityAction = entityType => {
  return (page, pageSize, filters, apiUrl, type, callback) => {
    return dispatch => {
      let userDetails = GetSessionValue('loginCredentials')
      let url = ''
      console.log('filters', filters)
      let filterCriteria = {};
      filterCriteria['limit'] = pageSize;
      filterCriteria['page'] = page;
      filterCriteria['criteria'] = [];
      if (filters && filters.dropDown) {
        filterCriteria['criteria'] = [filters.dropDown]
      }
      if (filters && filters.brandId) {
        filterCriteria.brandId = filters.brandId;
      }
      if (filters && filters.role) {
        filterCriteria.role = filters.role;
      }
      if (filters && filters.email) {
        filterCriteria.email = filters.email;
      }
      filterCriteria['direction'] = 'desc';
      filterCriteria.sortfield = 'created';
      if (filters && filters.globalSearch && filters.globalSearch.value) {
        filterCriteria.globalSearch = filters.globalSearch
      }
      ///////global search
      if (type == 'globalsearch' && apiUrl != 'users/getMerchantIds' && filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {
        filterCriteria.globalSearch = filters.globalSearch
      }
      if (!page) {
        filterCriteria['page'] = 1;
      }
      if (!pageSize) {
        filterCriteria['limit'] = 10;
      }
      ////////sorting 
      if (type == "sort" && filters.sortField && filters.sortField.direction) {
        filterCriteria['limit'] = pageSize;
        filterCriteria['page'] = page;
        // filterCriteria['criteria'] = [];
        filterCriteria['direction'] = filters.sortField.direction
        filterCriteria.sortfield = filters.sortField.sortfield;
      }
      ////////dropdown 
      // if (type == "dropdown" && filters) {
      //   filterCriteria['limit'] = pageSize;
      //   filterCriteria['page'] = page;
      //   if (filters && filters[0] && filters[0].value) {
      //     filterCriteria['criteria'] = filters;
      //   } else {
      //     filterCriteria['criteria'] = []
      //   }
      // }
      if (apiUrl == 'merchants') {
        filterCriteria['type'] = 'All';
      }
      if(filters.from) {
        filterCriteria.from = filters.from;
      }
      if (type == 'globalsearch' && apiUrl == 'users/getMerchantIds' && filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {

        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}&search=${filters.globalSearch.value}`;
      } else if (filters && filters.globalSearch && filters.globalSearch.value) {
        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}&search=${filters.globalSearch.value}`;
      } else {
        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (type == 'multiselect' && filterCriteria.role != "groupAdmin" && filterCriteria.role != "groupUser") {
        filterCriteria = {
          'criteria': [
            {
              key: 'status',
              value: 'Approved',
              type: 'eq'
            }
          ]
        };
        // url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
        url = `${apiUrl}?sortId=true`;
      }
      
      dispatch(setEntityLoadingState(entityType, true))
      return axiosMethodRequest('GET', url).then((res) => {
        if (res && res.data && res.data.respCode) {
          let data = []
          let pagination = {}
          if (apiUrl == 'users/getMerchantIds') {
            data = res.data.details;
            pagination = res.data.pagination;
          } else if (apiUrl == 'distributors/getInvoiceList/invoice') {
            data = res.data.invoiceList;
            pagination = res.data.pagination;
          } else {
            data = res.data[apiUrl];
            pagination = res.data.pagination;
            let modifiedData = []
            data.forEach((item, index) => {
              let merchantString = ''
              if (item.merchantIds) {
                let merchantIds = [];
                Object.keys(item.merchantIds).forEach(element => {
                  merchantIds.push(item.merchantIds[element]);
                });
                merchantIds.forEach((item, index) => {
                  if (item.merchantId) {
                    merchantString = merchantString + item.merchantId + `${index < (merchantIds.length - 1) ? ',' : ''}`
                  }
                })
              }
              item.MerchantList = merchantString
              modifiedData.push(item)
            })
          }
          dispatch(setEntityData(entityType, data, pagination))
          callback(data, pagination, 'success')
        } else {
          callback(res.data.errorMessage, '', 'error')
        }
        dispatch(setEntityLoadingState(entityType, false))
      })
    }
  }
}

export const deleteEntityAction = (entity, apiUrl, callback) => {
  let url = ''
  if (apiUrl == 'users/getMerchantIds') {
    url = `users/deleteMerchantId?MId=${entity._id}`
  } else {
    url = `${apiUrl}/${entity._id}`
    if (entity.docType == "creatives") {
      url = url + "?docType=creatives"
    }
  }
  return dispatch => {
    return axiosMethodRequest('DELETE', url).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const createEntityAction = (data, apiUrl, callback) => {
  return dispatch => {
    if (data && data.entityType) {
      delete data['entityType']
    }
    if (apiUrl == 'users') {
      data.merchantId = [data.merchantId]
    }
    return axiosMethodRequest('POST', apiUrl, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const createEntityActionTrain = (data, apiUrl, callback) => {
  return dispatch => {
    if (data && data.entityType) {
      delete data['entityType']
    }
    if (apiUrl == 'users') {
      data.merchantId = [data.merchantId]
    }
    return axiosMethodRequest('POST', apiUrl, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}
export const editEntityAction = type => {
  return (data, apiUrl, callback) => {
    return dispatch => {
      delete data['email']
      delete data['password']
      if (apiUrl == 'users') {
        // data.merchantId = [data.merchantId]
      }
      return axiosMethodRequest('PUT', `${apiUrl}/${data._id}`, data).then((response) => {
        if (response && response.data && response.data.respCode) {
          callback(response.data.respMessage, "success")
        } else {
          callback(response.data.errorMessage, 'error')
        }
      })
    }
  }
}

export const getMerchantDataAction = type => {
  return (apiUrl, callback) => {
    return dispatch => {
      return axiosMethodRequest('GET', apiUrl).then((res) => {
        if (res && res.data && res.data.respCode) {
          let data = res.data.details
          callback(data)
        }
      })
    }
  }
}

export const confirmMerchantAction = (data, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', `${apiUrl}`, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}
export const multiAction = (data, actionType, callback) => {
  return dispatch => {
    let apiUrl
    if (actionType == 'APPROVE') {
      apiUrl = 'merchants/multiApprove'
      data.status = 'Approved'
    } else if (actionType == 'REJECT') {
      apiUrl = 'merchants/multiApprove'
      data.status = 'Rejected'
    } else if (actionType == 'DELETE') {
      apiUrl = 'merchants/multiRemove'
    }
    return axiosMethodRequest('POST', `${apiUrl}`, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const manageCreativeAction = (file, callback) => {
  return dispatch => {
    if (file && file.length) {
      file.map((item, index) => {
        let fileId = `${item.name}-${item.lastModified}`;
        let loginData = GetSessionValue('loginCredentials');
        let headers = {
          'Accept': 'application/json',
          'Authorization': `Bearer ${loginData.accessToken}`,
        };
        let appendFielData = {};
        let fileUrl = ''
        let body = item.slice(0, item.size + 1)
        fileUrl = `uploads/files?preview=${item.preview}&size=${item.size.toString()}&fileId=${fileId}&mimetype=${item.type}&name=${item.name}&uploadPath=docs`
        return axiosMethodRequest('POST', fileUrl, item, 'upload').then((response) => {
          if (response && response.data) {
            callback()
          }
        })
      })
    }
  }
}

export const getManageCreativeAction = (type) => {
  return (file, callback) => {
    return dispatch => {
      dispatch(setEntityLoadingState('SET_MANAGECREATIVE_DATA', true))
      return axiosMethodRequest('GET', 'documents').then((res) => {
        if (res && res.data && res.data.respCode) {
          callback(res.data.documentss, 'success')
          dispatch(setManageCreativeData(type, res.data.documentss))
        } else {
          callback(res.data.errorMessage, 'error')
        }
        dispatch(setEntityLoadingState('SET_MANAGECREATIVE_DATA', false))
      })
    }
  }
}

export const getBrandDetailsAction = () => {
  return (file, callback) => {
    return dispatch => {
      return axiosMethodRequest('GET', 'documents').then((res) => {
        if (res && res.data && res.data.respCode) {
          dispatch(setBrandDetailsData(res.data.brandDetails))
          callback(res.data.brandDetails, 'success')
        } else {
          callback(res.data.errorMessage, 'error')
        }
      })
    }
  }
}

export const uploadCSVForEntityAction = (file, apiUrl, callback) => {
  return dispatch => {
    let url = `uploads/csvUpload?type=${apiUrl}`
    let formData = new FormData()
    formData.append('file', file)
    return axiosMethodRequest('POST', url, file, 'upload').then((res) => {
      if (res && res.data && res.data.respCode) {
        callback(res.data.respMessage, 'success')
      } else if (res && res.data && res.data.failure) {
        callback(res.data.failure.message, "error")
      } else {
        callback(res.data.errorMessage, "error")
      }
    })
  }
}

export const setEntityForEditAction = type => {
  return entity => {
    return {
      type: `SET_${type.toUpperCase()}_EDIT`,
      payload: entity
    }
  }
}


export const getBranchListByBrandIdAction = (brand_id, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `entity/getAllEntityByBrandId/${brand_id}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.entityList)
      } else {
        callback([])
      }
    })
  }
}

export const getBranchListFromNodeAction = (brandName, callback) => {
  return dispatch => {
    // let url = "https://ykkydvweug.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
    let url = "https://dnbfyn7ccl.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
    let body = {
      "name": brandName
    }
    // let x_api_key = "W5v8CrRCTL7rApej5f5yr6jM1LooFH0G6R0ny9Pc";
    let x_api_key = "lQKYGZEsmZ6gYvbs08C0J6v8oE41k9Fa5umPkbRh";
    return lambdaAxiosMethodRequest('POST', url, body, x_api_key).then((response) => {
      if (response && response.status == 200 && response.data) {
        dispatch({
          type: GET_BRANCHES_NODES_IN_GRAPH,
          payload: { data: response.data, loader: false }
        })
        callback(response.data)
      } else {
        callback([])
      }
    })
  }
}

export const getBranchListFromNodeActionAdditionalFilter = (brandName) => {
  return dispatch => {
    // let url = "https://ykkydvweug.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
    let url = "https://dnbfyn7ccl.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
    let body = {
      "name": brandName
    }
    // let x_api_key = "W5v8CrRCTL7rApej5f5yr6jM1LooFH0G6R0ny9Pc";
    let x_api_key = "lQKYGZEsmZ6gYvbs08C0J6v8oE41k9Fa5umPkbRh";
    return lambdaAxiosMethodRequest('POST', url, body, x_api_key).then((response) => {
      if (response && response.status == 200 && response.data) {
        dispatch({
          type: GET_BRANCHES_NODES_IN_GRAPH,
          payload: { data: response.data, loader: false }
        })
      }
    })
  }
}


export const createMultipleBranchAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', "merchants/createMultiple", data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data)
      } else {
        callback([])
      }
    })
  }
}

export const getBranchListByNameAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `entity/getAllEntityBranch?filter=${JSON.stringify(data)}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.entity)
      } else {
        callback([])
      }
    })
  }
}

export const createCopyTextAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', "brands/createCopyText", data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data)
      } else {
        callback(response, "error")
      }
    })
  }
}

export const saveBrandForInsightsAction = (data, callback) => {
  data.entityType = config.entityType
  return dispatch => {
    return axiosMethodRequest('POST', 'insights/savebrandDetails', data)
      .then(async (response) => {
        if (response && response.data && response.data.respCode) {
          // await SetSessionValue('insightsCredentials', data)
          await SetSessionValue('loginCredentials', response.data.details.brands);
          dispatch(setUserAction(response.data.details.brands))
          if (callback)
            callback(data, 'success')
        } else {
          if (callback)
            callback(response.data.errorMessage, 'error')
        }
      })
  }
}

export const updateBrandForInsightsAction = (data, callback) => {
  data.entityType = config.entityType
  return dispatch => {
    return axiosMethodRequest('POST', `insights/updatebrandDetails/${data._id}`, data)
      .then(async (response) => {
        if (response && response.data && response.data.respCode) {
          if (callback)
            callback(response.data, 'success')
        } else {
          if (callback)
            callback(response.data.errorMessage, 'error')
        }
      })
  }
}

export const getEntityDataByFilterAction = (data, callback) => {
  return dispatch => {
    const url = data ? `reviewsdata/getBrandInsights?filter=${JSON.stringify(data)}` : "entity"
    return axiosMethodRequest('GET', url).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.brands)
      } else {
        callback()
      }
    })
  }
}

export const getXeroInvoiceAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', "distributors/getXeroInvoice/invoice", data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data)
      }
    })
  }
}

export const getUserGroupBrandAction = (userId, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `users/getUserGroupBrand/${userId}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.userCampaignDetail)
      } else {
        callback([])
      }
    })
  }
}
