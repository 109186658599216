import styled from 'styled-components'

export const Container = styled.div`
& h6{
  margin-left:10px;
  font-weight:200px;
}
` 


/* h6{
    margin-left:10px;
    font-weight:200px;
  }
 */




