import {

  GET_CAMPAIGN_LIST
} from './actionTypes';

export const initialState = {

  campaignList: {
    data: [],
    loader: false
  }
};

export default (
  state = initialState,
  action
) => {
  switch (action.type) {

    case GET_CAMPAIGN_LIST: {
      return {
        ...state,
        campaignList: {
          ...state.campaignList,
          data: action.payload.data,
          loader: action.payload.loader
        },
      };
    }


    default:
      return state;
  }
};
