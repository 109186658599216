import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserProfileAction, getLanguageDataAction, clearStoreData, getEntityDetails } from '../../redux/actions'
import { SimpleUserForm } from '../../components/Forms/User'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
import ProfileImg from './updateProfileImg';
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import config from "./../../config/config";
import { GetSessionValue } from '../../utils/sessionStorage';
let languageData = {}
class UserProfile extends Component {
  state = {
    isDisabled: true,
    btnLabel: 'Update Profile',
    userDetails: '',
    sessionExpiryModal: false,
  }

  onSubmit = (values, actions) => {
    let { updateUserProfile, successNotification, clearData } = this.props;
    if (values && values.merchantId && typeof (values.merchantId) == 'string') {
      let merchantIds = values.merchantId
      values.merchantId = merchantIds.split(',')
      delete values['merchantIds']
    }
    let body = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      brandName: '',
      address: '',
    }
    if (values) {
      body['email'] = values['email'];
      body['firstName'] = values['firstName'];
      body['lastName'] = values['lastName'];
      body['phone'] = values['phone'];
      body['brandName'] = values['brandName'];
      body['address'] = values['address'];
    }

    if (values['_id']) {
      body['_id'] = values['_id']
      body['brandId'] = values['brandId']
    }

    updateUserProfile(body, (res, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal })
      let message
      let details
      if (res && res.details) {
        details = res.details
      }
      if (res && res.respMessage) {
        message = res.respMessage
      } else if (res && res.errorMessage) {
        message = res.errorMessage
      }
      if (actions) {
        actions.setSubmitting(false)
      }
      if (type == 'error') {
        if (actions) {
          actions.setStatus(message)
        }
        return;
      }
      this.updateUserProfile()
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = message
        successNotification(notification)
      })
      if (details && details.status && details.status == 'deactivate') {
        clearData(res => {

        })
      }
      this.setState({ isDisabled: true })
    })
  }

  componentDidMount = () => {
    this.updateUserProfile()
    this.props.getLanguageData((res) => {

    })
  }

  updateUserProfile = () => {
    let { getEntityDetails } = this.props
    getEntityDetails(config.entityType, false, async (res, type) => {
      if (type == 'success') {
        await this.setState({ userDetails: res })
      } else {
        let sessionExpiryModal = false
        let sessionExpired = GetSessionValue('sessionExpired')
        if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
          sessionExpiryModal = true
        }
        await this.setState({ sessionExpiryModal: sessionExpiryModal })
      }
    })
  }

  formikRef = (e) => {
    this.formik = e
  }

  formikSubmit = () => {
    if (this.formik)
      this.formik.submitForm()
  }
  onProfileSubmit = () => {
    this.setState({ isDisabled: false })
  }
  onToggleEdit = () => {
    let isDisabled = !this.state.isDisabled
    this.setState({ isDisabled })
  }

  getActionBtns = () => {
    if (this.state.isDisabled) {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd px-5" onClick={this.onToggleEdit}>{/*<Icon fontSize="small">edit</Icon>*/} Edit </button>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
        </div>
      )
    }
  }

  render() {
    let { user } = this.props;
    return (
      <div>
        <div className="row create-header">
          <h3 className="col-lg-6 offset-lg-3 col-md-12 text-center py-3">My Profile</h3>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="">
                <ProfileImg
                  name="profile"
                  field="name"
                  user={user}
                  onProfileSubmit={this.onProfileSubmit}
                />
                <SimpleUserForm
                  onSubmit={this.onSubmit}
                  initialValues={user}
                  options={{ ...this.state }}
                  formikRef={this.formikRef}
                  languageData={languageData}
                />
                {this.getActionBtns()}
              </div>
              {/* session expiry modal */}
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({
    updateUserProfile: bindActionCreators(updateUserProfileAction, dispatch),
    getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    clearData: bindActionCreators(clearStoreData, dispatch),
    getEntityDetails: bindActionCreators(getEntityDetails, dispatch)
  })
)(UserProfile);

