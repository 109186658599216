import styled from 'styled-components'

export const Container = styled.div`
& h6{
  font-weight:200px;
  float:left;
}
` 

// h6{
//     font-weight:200px;
//     float:left;
// }