import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Card from "react-bootstrap/Card";
//import './trends.css'
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Container } from "./trends.styled";
import { insightsSelectors } from "../../../../redux/insights";
import { getTrendsDataAPICall } from "../../../../services/getTrendsDataAPICall";
import { GetSessionValue } from "../../../../utils/sessionStorage";
import { GET_SELECTED_TRENDS } from '../../../../redux/insights/actionTypes';
import FilterAdditionalData from '../../../InsightsLayout/BottomNavbarTabs/FilterAdditionalData';

export const cardstyle = {
  borderRadius: '10px',
}
export const cardbody = {
  textAlign: 'center',
  justifyContent: 'center',
  paddingTop: '0px'
}

// export const options = {
//   title: "Krowd Rating",
//   vAxis: { title: "" },
//   hAxis: { title: "" },
//   seriesType: "bars",
//   series: { 1: { type: "line" } },
//   legend: { position: "top" },
// };

export const options = {
  // title: "Krowd Rating",
  title: "Krowd Aggregate Score",
  curveType: "function",
  legend: { position: "top" },
  // responsive: true,
};

function Trends() {

  const dispatch = useDispatch();
  const trendschartDataState = useSelector(insightsSelectors.getTrendsChartstate);
  const radarDataState = useSelector(insightsSelectors.getSimilarityData);
  const insightsDates = useSelector(insightsSelectors.insightsDates);
  const [selectedBrand, setSelectedBrand] = useState({ id: 0, entityId: "", displayname: "", address: "" });
  const [brandList, setBrandList] = useState([]);
  const selectTrendschartDataState = useSelector(insightsSelectors.getSelectedTrendsChartstate);
  const [activeChartData, setActiveChartData] = useState([]);
  const userDetails = GetSessionValue('user');

  useEffect(() => {
    if (radarDataState.data.brandList) {
      let brandList = [];
      radarDataState.data.brandList.forEach((element) => {
        let displayname = element.displayname ? element.displayname.toLowerCase().replace(/\s/g,'') : "";
        let name = userDetails.brandDetails && userDetails.brandDetails.name ? userDetails.brandDetails.name.toLowerCase().replace(/\s/g,'') : "";
        console.log("displayname name",displayname, name);
        if(displayname !== name) {
          brandList.push(element);
        }
      });
      console.log("brandList",brandList);
      setBrandList(brandList);
      getBrandValue("",brandList[0]);
    }
  }, [radarDataState && radarDataState.data && radarDataState.data.brandList]);

  useEffect(() => {
    if(selectedBrand.entityId && selectTrendschartDataState && selectTrendschartDataState.trendschartDetails && selectTrendschartDataState.trendschartDetails.data &&
      trendschartDataState && trendschartDataState.trendschartDetails && trendschartDataState.trendschartDetails.data) {
      let activeData = [];
      trendschartDataState.trendschartDetails.data.forEach((element,index) => {
        if(index == 0) {
          activeData.push(["Year", userDetails.brandDetails.name,selectedBrand.displayname ]);
        } else {
          activeData.push([element[0],element[1],selectTrendschartDataState.trendschartDetails.data[index][1]]);
        }
      });
      setIntoActiveChartData(activeData);
    } else {
      setIntoActiveChartData(trendschartDataState.trendschartDetails.data);
    }
  }, [selectTrendschartDataState && selectTrendschartDataState.trendschartDetails && selectTrendschartDataState.trendschartDetails.data]);

  useEffect(() => {
    if(trendschartDataState && trendschartDataState.trendschartDetails && trendschartDataState.trendschartDetails.data) {
      setActiveChartDetails();
    }    
  }, [trendschartDataState && trendschartDataState.trendschartDetails && trendschartDataState.trendschartDetails.data]);

  const getBrandValue = (ele, val) => {
    let active = {};
    if(val) {
      active = { id: val.id, entityId: val.entityId , displayname: val.displayname , address: val.address };
      dispatch(getTrendsDataAPICall("", insightsDates.date, insightsDates.trendFlag, "" ,val.entityId));
      setSelectedBrand(val);
    } else {
      setSelectedBrand({ id: 0, entityId: "", displayname: "", address: "" });
      setActiveChartDetails();
      active = { id: 0, entityId: "", displayname: "", address: "" }
    }
    dispatch({ type: GET_SELECTED_TRENDS, payload: { selectedTrends : active } });
  }

  const setActiveChartDetails = () => {
    trendschartDataState.trendschartDetails.data[0][1] = userDetails.brandDetails.name;
    setIntoActiveChartData(trendschartDataState.trendschartDetails.data);
  }

  const setIntoActiveChartData = (chart_data) => { 
    if(chart_data && chart_data.length == 13) {
      // chart_data.splice(chart_data.length - 1,1);      
      chart_data.splice(chart_data.length - 3, 3);
    }
    setActiveChartData(chart_data);
  }

  return (
    <Container>
      <Card style={cardstyle}>
        <Card.Body style={cardbody} >
          {activeChartData ?
            (activeChartData.length > 1 ?
              <div className="row form">
                <div className="col-12 trends-chart-dropdown-contain">
                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300, height: 40, paddingTop: '5px', marginRight: '10px' }}
                    options={brandList}
                    autoHighlight
                    className='trends-chart-dropdown' 
                    getOptionLabel={(option) => option.displayname}
                    onChange={getBrandValue}
                    size='small'
                    variant="outlined"
                    value={selectedBrand}
                    renderOption={(props, option) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }} {...props}>
                        {option.displayname}
                        <br />
                        <small>
                          ({option.address})
                        </small>

                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select branch"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                    {/* <FilterAdditionalData></FilterAdditionalData> */}
                </div>
                <div className="col-12">
                  <Chart
                    chartType="LineChart"  //chartType="ComboChart"
                    width="100%"
                    height="300px"
                    data={activeChartData || []}
                    options={options}
                  />
                </div>
              </div>


              :
              <p>No data available</p>) :
            <div className="d-flex justify-content-center spinner-block">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>}
        </Card.Body>
      </Card>
    </Container>
  );
}
export default Trends