const config = {

  // //local
  // apiUrl: 'http://api.krowd.dosystemsinc.com/api/',
  // imgUrl: 'http://api.krowd.dosystemsinc.com/images/',
  // socketUrl: 'http://api.krowd.dosystemsinc.com',

  // testurl
  // apiUrl: 'https://offersapi-test.krowdit.com/api/',
  // imgUrl: 'https://offersapi-test.krowdit.com/images/',

  // // client obm site
  // apiUrl: 'https://obmapi.krowdit.com/api/',
  // imgUrl: 'https://obmapi.krowdit.com/images/',
  // socketUrl: 'http://obmapi.krowdit.com/',

  // //local
  apiUrl: process.env.REACT_APP_APIURL,
  imgUrl: process.env.REACT_APP_IMGURL,
  gocardlessJavascript: process.env.REACT_APP_GOCARDLESS_JAVASCRIPT,
  dashboardurl:process.env.REACT_APP_DASH_BOARD_API,

  serverErrMessage: 'Could Not reach server',
  bankOptions: [
    { value: 'HSBC', label: 'HSBC' },
    { value: 'DIB', label: 'DIB' },
  ],
  selectedBank: { value: 'DIB', label: 'DIB' },
  Icon1: 'campaign/1.jpg',
  Icon2: 'campaign/2.jpg',
  Icon3: 'campaign/3.jpg',
  Icon4: 'campaign/4.jpg',
  entityType: 'user',
  templateColor: '#0e4768',
  whiteColor: '#ffffff',
  blackColor: '#000',
  appName: 'krowd',

  //regex
  borderValidation: false,
  messages: true,
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,

  // server response codes
  updateResCode: 205,
  deleteResCode: 206,
  ddMMYYYFormat: 'DD/MM/YYYY',
  datePlaceholder: '--/--/----',
  dateFormat: 'MM/DD/YYYY',
  dateTabularFormat: 'MMM DD YYYY',
  dateDisplayModalFormat: 'DD MMM YYYY',
  dateDBFormat: 'MM-DD-YYYY',
  dateDayMonthFormat: 'DD-MM-YYYY',
  basicDateFromat: 'MM/DD/YYYY HH:mm A',
  descDateFromat: 'MMM DD YYYY HH:mm A',
  timeFormat: 'HH:mm',
  syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
  lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
  dateTimeFormat: 'MM/DD/YYYY hh:mm',
  fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
  dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
  dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00Z',
  ESTTimezone: "America/New_York",
  monthYearFormat: "MMM YYYY",
  dayMonthYearFormat: ' MMM  DD, YYYY',

};
export default config;
