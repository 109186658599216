import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { forgotPasswordAction } from '../../redux/actions/authActions'
import config from './../../config/config'
import ForgotPassword from '../../components/ForgotPassword/Forgot.component';
import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from './../../config'
// import API from '../../mocks/API'

const imgStyle = {
  'display': 'block',
  'height': "9rem",
  'textAlign': "center",
  'margin': '0 auto 2rem auto',
}

class ForgotPasswordForm extends Component {
  onSubmit = (values, actions) => {
    values.entityType = config.entityType
    let { successNotification, forgotPassword, history } = this.props;
    forgotPassword(values, (res, type) => {
      if (type == 'error') {
        actions.setSubmitting(false)
        actions.setStatus(res)
        return
      } else if (type == 'success') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
        history.push('/')
      }
    })
  }
  render() {
    console.log(this.props)
    return (
      <div className="row form justify-content-center">
        <div className="col-md-6 col-lg-4" >
          <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          <div className="card">
            <div className="header">
              <h3 className="text-center">Forgot Password</h3>
            </div>
            <div className="content">
              <ForgotPassword onSubmit={this.onSubmit} />
            </div>
          </div>
          <div className="text-center mb-4">
            Back to <Link to='/login' className="text-primary">Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    forgotPassword: bindActionCreators(forgotPasswordAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
  })
)(ForgotPasswordForm);
