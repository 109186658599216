import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { hslToRgb } from '../../utils/helpers'

class DoughnutChart extends Component {
  dynamicPastelColors = function () {
    let [r, g, b] = hslToRgb(360 * Math.random(), (25 + 70 * Math.random()), 70)
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  backgroundColor = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#e8c2b9',
    '#c35850',
    '#d82329', //'#1e90ff'
    '#02a88f', //'#3e95cd'
    '#83c273',
    '#25e0a1',
    '#f2abac',
    '#ef9144'
  ]
  getChartOptions = () => {
    let { options = {}, data } = this.props
    // data.labels && data.labels.some(item => item.length > 14) && data.labels.length > 3
    if (data && data.some(item => item.length > 14) && data.length > 3) {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        plugins: {
          datalabels: false
        },
        ...options
      }
    }
    return {
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          boxWidth: 20
        },
        position: 'bottom',
      },
      plugins: {
        datalabels: false
      },
      ...options
    }
  }

  getChartData = () => {
    let { data } = this.props;
    let labelsData = [];
    let valueData = [];
    let backgroundColor = this.backgroundColor.slice()
    // this.backgroundColor.length < data.labels.length
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        labelsData.push(data[i]['label'])
        valueData.push(data[i]['value'])
      }
    }
    if (this.backgroundColor.length < data.length) {
      // let bgColor = data.labels.slice(this.backgroundColor.length).map(it => {
      //   return this.dynamicPastelColors()
      // })
      let bgColor = data.slice(this.backgroundColor.length).map(it => {
        return this.dynamicPastelColors()
      })
      backgroundColor = backgroundColor.concat(bgColor)
    }
    return {
      // labels: data.labels.slice(),
      labels: labelsData,
      datasets: [{
        // data: data.data.slice(),
        data: valueData,
        backgroundColor: backgroundColor,
        // hoverBackgroundColor: 'rgba(0, 0, 0, .4)'
      }],
    }
  }

  render() {
    let { height, data } = this.props;
    return <Doughnut data={this.getChartData()} options={this.getChartOptions(data)} height={height} />
  }
}

export default DoughnutChart
