
import { combineReducers } from 'redux';

const INITIAL_STATE_CAMPAIGN_LIST = {
  page : 1,
  sizePerPage : 0,
  campaigns : [],
  isLoading : false,
  totalCount : 0,
  filters : {}
}

const campaignList = type => {
  return (state = INITIAL_STATE_CAMPAIGN_LIST, action) => {
    switch (action.type) {
      case `SET_${type.toUpperCase()}_CAMPAIGN_DATA`:
        return {campaigns:action.payload,page:action.pagination.page,totalCount:action.pagination.totalCount, isLoading : false};
      case `SET_${type.toUpperCase()}_CAMPAIGN_PAGE`: {
        return {...state, ...action.payload}
      }
      case `SET_${type.toUpperCase()}_CAMPAIGN_SORT`: {
        return {...state, filters : {...state.filters, ...action.payload}}
      }
      case `SET_${type.toUpperCase()}_CAMPAIGN_SEARCH`: {
        let newState = {...state, filters : {...state.filters, ...action.payload}}
        if (!action.payload.search)
          delete newState.filters.search
        return newState
      }
      case `SET_${type.toUpperCase()}_CAMPAIGN_FILTERS`: {
        let newState = {...state, filters : {...state.filters, ...action.payload}}
        let filters = newState.filters
        Object.keys(filters).forEach(key => {
          if (!filters[key])
            delete filters[key]
        })
        return newState
      }
      case `CLEAR_${type.toUpperCase()}_CAMPAIGN_FILTERS`: {
        return {}
      }
      case `SET_${type.toUpperCase()}_CAMPAIGN_DATA_LOADINGSTATE` : {
        return {...state, isLoading : action.payload}
      }
      default:
        return state;
    }
  }
}


const selectedCampaign = (state = null, action) => {
  switch(action.type) {
    case 'SET_SELECTED_CAMPAIGN' :
      return action.payload
    default : 
      return state;
  }
}
const campaignForEdit = (state = null, action) => {
  switch(action.type) {
    case 'SET_CAMPAIGN_EDIT' :
      return action.payload
    default : 
      return state;
  }
}

const currentCampaignCount = (state = 0, action) => {
  switch(action.type) {
    case 'SET_CURRENT_CAMPAIGN_COUNT' :
      return action.payload
    default : 
      return state;
  }
}


export default combineReducers({
  selectedCampaign,
  campaignForEdit, 
  pastCampaigns : campaignList('past'),
  currentCampaigns : campaignList('current'),
  futureCampaigns : campaignList('future'),
  currentCampaignCount
})

