import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import Loader from '../Loader';
import { Link } from 'react-router-dom'
class LoginForm extends Component {

  state = {
    loader: true
  }
  initialValues = {
    email: '',
    password: '',
  }

  validationSchema = YupObject({
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
    password: YupString()
      .required('Password is required')

  })
  componentDidMount = () => {
    this.setState({ loader: false })
  }

  getForm = props => {
    let { status, isSubmitting } = props;
    return (

      <Form className="form">
        <Loader loader={this.state.loader} />

        <div className="form-group">
          <label>Email</label>
          <Field
            name="email" className="form-control" disabled={isSubmitting}
            autocomplete="off"
          />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Password</label>
          <Field name="password" className="form-control" type="password" disabled={isSubmitting} />
          <ErrorMessage name="password" component="label" className="error" />
        </div>
        <div className="text-right mb-3">
          <Link to='/forgot' className="text-primary">Forgot password?</Link>
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting}>Login</button>
        </div>
      </Form>
    )
  }

  render() {
    let { initialValues, onSubmit } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required



export default LoginForm










