import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./BottomNavbar.styled.js";
import OverviewComponent from "./OverviewComponent";
import TrendsComponent from "./TrendsComponent";
import SimilarityComponent from "./SimilarityComponent";
import { BigContainer, CompetitionContainer } from "./BottomNavbar.styled.js";
import ScatterCharts from "../Charts/ScatterBubbleChart/ScatterChart";

const BottomNavbar = () => {
  const [key, setKey] = useState("overview");

  return (
    <BigContainer>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="overview" title="Overview">
          <OverviewComponent />
        </Tab>
        <Tab eventKey="trends" title="Trends">
          <TrendsComponent />
        </Tab>
        <Tab eventKey="similarity" title="Similarity">
          <SimilarityComponent />
        </Tab>
        {/* <Tab eventKey="competition" title="Competition">
          <CompetitionContainer className="d-block">
            <div className="row">
              <div className="col-12 col-lg-6 col-md-12 col-sm-12 ">
                <ScatterCharts />
              </div>
            </div>
          </CompetitionContainer>
        </Tab> */}
        {/* <Tab eventKey=" Customer reviews" title="Customer reviews">
          <CustomerReviewComponent />
        </Tab> */}
      </Tabs>
    </BigContainer>
  );
};

export default BottomNavbar;

