import {
  GET_INSIGHTS_DATA,
  GET_REVIEW_DATA,
  GET_REVIEW_FACTRS_DATA,
  GET_SCATTER_DATA,
  SET_INSIGHT_DATE,
  GET_TRENDS_DATA,
  GET_SELECTED_TRENDS_DATA,
  GET_SELECTED_TRENDS,
  GET_CUSTOMER_RATING_DATA,
  GET_BRANCH_DATA,
  GET_RADAR_DATA,
  RESET_INSIGHTS_DATA,
  GET_BRANCHES_NODES_IN_GRAPH,
  GET_FILTER_DATA
} from './actionTypes';

export const initialState = {
  insightsData: {
    loader: false,
    data: []
  },
  reviewData: {
    loader: false,
    data: {
      insightsDetails: {},
      chartDetails: {},
      trendschartDetails: {},
      lineschartDetails: {},
      scatterchartDetails: {}
    }
  },
  reviewScatterData: {
    loader: false,
    data: {
      scatterchartDetails: {}
    }
  },
  insightSelectedDate: {
    date: '',
    trendFlag: false,
    cuisine: '',
    kmid: '',
    entityId: '',
    similarDates: '',
  },
  reviewFactorsData: {
    loader: false,
    data: {
      chartDetails: {},

    }
  },
  reviewTrendsData: {
    loader: false,
    data: {
      trendschartDetails: [],
      lineschartDetails: {},
      comparisionDetails: {}
    }
  },
  selectedReviewTrendsData: {
    loader: false,
    data: {
      trendschartDetails: [],
      lineschartDetails: {},
      comparisionDetails: {}
    }
  },
  selectedTrends : {},
  branchData: {
    loader: false,
    data: {
      allbranches: []
    }
  },
  similarityData: {
    loader: false,
    data: {
      // allbranches: []
    }
  },
  customerReview: {
    loader: false,
    data: {}
  },
  brandListFromNode: {
    loader: false,
    data: {}
  },
  filterModalData: {
    loader: false,
    data: {}
  }
};
export default (
  state = initialState,
  action
) => {
  switch (action.type) {

    case GET_INSIGHTS_DATA: {
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          loader: action.payload.loader,
          data: action.payload.data
        },
      };
    }
    case GET_REVIEW_DATA: {
      return {
        ...state,
        reviewData: {
          ...state.reviewData,
          loader: action.payload.loader,
          data: action.payload.data || {
            insightsDetails: {},
            chartDetails: {},
            trendschartDetails: {},
            lineschartDetails: {},
            scatterchartDetails: {}
          }
        },
      };
    }
    case GET_REVIEW_FACTRS_DATA: {
      return {
        ...state,
        reviewFactorsData: {
          ...state.reviewFactorsData,
          loader: action.payload.loader,
          data: action.payload.data || {
            chartDetails: {},
          }
        },
      };
    }
    case GET_TRENDS_DATA: {
      return {
        ...state,
        reviewTrendsData: {
          ...state.reviewTrendsData,
          loader: action.payload.loader,
          data: action.payload.data || {
            trendschartDetails: {},
            lineschartDetails: {},
            comparisionDetails: {}
          }
        },
      };
    }
    case GET_SELECTED_TRENDS_DATA: {
      return {
        ...state,
        selectedReviewTrendsData: {
          ...state.selectedReviewTrendsData,
          loader: action.payload.loader,
          data: action.payload.data || {
            trendschartDetails: {},
            lineschartDetails: {},
            comparisionDetails: {}
          }
        },
      };
    }
    case GET_SCATTER_DATA: {
      return {
        ...state,
        reviewScatterData: {
          ...state.reviewScatterData,
          loader: action.payload.loader,
          data: action.payload.data || {
            scatterchartDetails: {}
          }
        },
      };
    }
    case SET_INSIGHT_DATE: {
      return {
        ...state,
        insightSelectedDate: {
          ...state.insightSelectedDate,
          date: action.payload.data ? action.payload.data : state.insightSelectedDate.date,
          trendFlag: action.payload.trendFlag ? action.payload.trendFlag : state.insightSelectedDate.trendFlag,
          cuisine: action.payload.cuisine ? action.payload.cuisine : state.insightSelectedDate.cuisine,
          selectedKmid: action.payload.selectedKmid,
          kmid: action.payload.kmid ? action.payload.kmid : state.insightSelectedDate.kmid,
          entityId: action.payload.entityId ? action.payload.entityId : state.insightSelectedDate.entityId,
          similarDates: action.payload.similarDates ? action.payload.similarDates : state.insightSelectedDate.similarDates,
        },
      };
    }
    case GET_CUSTOMER_RATING_DATA: {
      return {
        ...state,
        customerReview: {
          ...state.customerReview,
          data: action.payload.data ? action.payload.data : state.customerReview.data,
          loader: action.payload.loader,
        },
      };
    }
    case GET_BRANCH_DATA: {
      return {
        ...state,
        branchData: {
          ...state.branchData,
          data: action.payload.data ? action.payload.data : state.branchData.data,
          loader: action.payload.loader,
        },
      };
    }
    case GET_RADAR_DATA: {
      return {
        ...state,
        similarityData: {
          ...state.similarityData,
          data: action.payload.data ? action.payload.data : (state.similarityData ? state.similarityData.data : {}),
          loader: action.payload.loader,
        },
      };
    }
    case GET_SELECTED_TRENDS: {
      return {
        ...state,
        selectedTrends: {
          ...state.selectedTrends, 
          data: action.payload.selectedTrends 
        },
      };
    }
    case GET_BRANCHES_NODES_IN_GRAPH: {
      return {
        ...state,
        brandListFromNode: {
          ...state.brandListFromNode,
          data: action.payload.data ? action.payload.data : state.brandListFromNode.data,
          loader: action.payload.loader,
        },
      };
    }
    case GET_FILTER_DATA: {
      return {
        ...state,
        filterModalData: {
          ...state.filterModalData,
          data: action.payload.data ? action.payload.data : state.filterModalData.data,
          loader: action.payload.loader,
        },
      };
    }
    case RESET_INSIGHTS_DATA: {
      return initialState
    }
    default:
      return state;
  }
};
