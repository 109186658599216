import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {FileDropzone} from './FileDropzone'
// import {Dropzone} from './reactdrop'
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { onlycharRegex, phoneRegex } from '../../utils/constants/constant';

export class Managecreative extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filesArray: []
    }
  }
  initialValues = {
    'name': '',
    'address': '',
    'contact_name': '',
    'contact_phone': '',
    'contact_email': '',
    'merchant_id': '',
    'terminal_id': '',
    'files': []
  }
  validationSchema = YupObject({
    name: YupString()
      .required('Branch name is required')
      .matches(onlycharRegex, 'Branch name can only contain [A-Za-z_-\s@\.]')
      .min(3, 'Branch name should at least contain 3 characters')
      .max(100, 'Branch name should at most contain 100 characters'),
    address: YupString()
      .required('Address is required'),
    contact_name: YupString()
      .required('Contact Name is required')
      .matches(onlycharRegex, 'Contact Name can contain only alphabets, "-" and/or space')
      .min(3, 'Contact Name should at least contain 3 characters')
      .max(20, 'Contact Name should at most contain 20 characters'),
    contact_phone: YupString()
      .required('Contact Phone number is required')
      .matches(phoneRegex, 'Contact Phone number can contain only numbers'),
    contact_email: YupString()
      .required('Contact Email is required')
      .email('Please provide a valid email'),
    merchant_id: YupString()
      .required('Merchant Id is required'),
    // terminal_id : YupString()
    //         .required('Terminal Id is required'),
  })
  fileUploadRef = {}
  handleFileRef = (name, ref) => {
    this.fileUploadRef[name] = ref;
  }
  getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status) => {
    let data = [];
    data.push(file)
    this.setState({ data: data })
  }
  getForm = (props, options = {}) => {
    let { status, isSubmitting, setFieldValue, touched, handleBlur } = props;
    return (
      <Form>
        <div className="form-group">
          <label>Location</label>

          {/* <Field 
            name="transactions"  
            className="form-control _imgupload_field" 
            disabled={isSubmitting} 
            accept="text/csv"
            setFieldValue={setFieldValue}
            component={FileInputComponent}
            touched={touched["transactions"]}
            onBlur={handleBlur}
            handleFileRef={this.handleFileRef}
          
          /> */}
          <ErrorMessage name="transactions" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>City</label>
          <Field name="contact_phone" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contact_phone" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Brand Name</label>
          <Field name="contact_email" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contact_email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Merchant Id</label>
          <Field name="merchant_id" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="merchant_id" component="label" className="error" />
        </div>
        {/* <div className="form-group">
          <label>Terminal Id</label>
          <Field name="terminal_id" className="form-control" disabled={isSubmitting}/>
          <ErrorMessage name="terminal_id" component="label" className="error"/>
        </div> */}
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
  componentDidMount = () => {
    this.dragCounter = 0
    let div = this.dropRef.current
    if (div) {
      div.addEventListener('dragenter', this.handleDragIn)
      div.addEventListener('dragleave', this.handleDragOut)
      div.addEventListener('dragover', this.handleDrag)
      div.addEventListener('drop', this.handleDrop)
    }
  }
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true, })
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter > 0) return
    // this.setState({ dragging: false, displayAttachmentsModal: true })
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.dragCounter = 0
      this.setState({ dragging: false, displayAttachmentsModal: true })
      let files = e.dataTransfer.files;
      this.appendFiles(files)
    }
  }


  //after selcting files append recent files to previous files
  appendFiles = (files) => {
    let filesArray = this.state.filesArray ? this.state.filesArray : []
    for (let fl in files) {
      if (files[fl].type) {
        files[fl]['preview'] = URL.createObjectURL(files[fl]);
        filesArray.push(files[fl])
      }
    }

    this.setState({ filesArray, displayAttachmentsModal: true })
  }

  //remove selected files
  removeFile = (ind, file) => {
    let filesArray = this.state.filesArray
    // delete filesArray[ind];
    filesArray.splice(ind, 1);
    if (filesArray && filesArray.length && filesArray.length > 0) {
      this.setState({ filesArray })
    } else {
      this.setState({ filesArray, displayAttachmentsModal: false })
    }
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn)
      div.removeEventListener('dragleave', this.handleDragOut)
      div.removeEventListener('dragover', this.handleDrag)
      div.removeEventListener('drop', this.handleDrop)
    }
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop } = this.props;
    return (
      // <div>

      // </div>
      <div className='onDragOver' >
        <div className="dropzone__imgs-wrapper">
          {this.state.filesArray && this.state.filesArray.length && this.state.filesArray.length > 0 ? this.state.filesArray.map((file, i) => (
            file.type && file.type.startsWith("image") ?
              <div className="dropzone__img" key={i} style={{ backgroundImage: `url(${file.preview})` }} >
                <FontAwesomeIcon className="fileDelete" icon='times-circle' onClick={() => this.removeFile(i, file)} />
              </div> :
              <div className="dropzone__img" key={i} style={{ backgroundImage: `url(${file.preview})` }} >
                <div className='fileName'>{file.name}</div>
                <FontAwesomeIcon className="fileDelete" icon='times-circle' onClick={() => this.removeFile(i, file)} />
              </div>
          )) : null}
          <div className='file_add'>
            <label htmlFor='file'><FontAwesomeIcon className="file_Plus_Icon" icon='plus' /></label>
            <input
              id='file'
              type="file"
              className='hideFileTxt'
              multiple
              onChange={(e) => this.appendFiles(e.target.files)}
            />
          </div>
        </div>
      </div>
      // <div>
      //   <Dropzone
      //                           submitButtonDisabled={true}
      //                           getUploadParams={this.getUploadParams}
      //                           onChangeStatus={this.handleChangeStatus}
      //                           // onSubmit={this.handleSubmit}
      //                           styles={{ dropzone: { minHeight: 400, maxHeight: 500 } }}
      //                       />
      // </div>
      // <Formik
      //   initialValues={ initialValues || this.initialValues }
      //   render={ props => this.getForm(props) }
      //   onSubmit={onSubmit}
      //   validationSchema={this.validationSchema}
      //   ref={formikRef}
      // />
    )
  }
}

class Branch extends Component {
  render() {
    return <div>dflkdf</div>
  }
}

export default Branch





