import axios from 'axios';
import config from '../../config/config'
import axiosMethodRequest from '../../config/service'
import { GetSessionValue, SetSessionValue, ClearSessionValue } from '../../utils/sessionStorage';
export function signout() {
	return {
		type: 'SIGN_OUT'
	}
}

export const setUserAction = user => {
	return {
		type: 'SET_USER',
		payload: user
	}
}
export const setTrialAction = languages => {
	return {
		type: 'SET_LANGUAGE_DATA',
		payload: languages
	}
}
export const loaderAction = loader => {
	return {
		type: 'SET_USER',
		loader: loader
	}
}
export const setEntityDetails = entityDetails => {
	return {
		type: 'SET_USER_ENTITY_DETAILS',
		payload: entityDetails || {}
	}
}

export const setSettingsData123 = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('GET', 'settings').then(async (response) => {
			if (response && response.data && response.data.respCode) {
				data = await response.data.settings[0]
				return {
					type: 'SET_SETTING_DATA',
					payload: data || {}
				}
			}
		})
	}
}

//saving settiungs data
export const setSettingsData = (data) => {
	return {
		type: 'SET_SETTING_DATA',
		payload: data || {}
	}
}

//getting settings data
export const settingsAction = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('GET', 'settings').then(async (response) => {
			if (response && response.data && response.data.respCode) {
				data = await response.data.settings[0]
				if (callback) {
					callback(response.data.settings[0])
				}
				await dispatch(setSettingsData(response.data.settings[0]))
			}
		})
	}
}
//forgot password
export const forgotPasswordAction = (data, callback) => {
	return dispatch => {
		let url = `auth/forgotPassword?email=${data.email}`
		delete data['email']
		return axiosMethodRequest('POST', url, data).then((res) => {
			console.log('res', res)
			if (res && res.data && res.data.respCode) {
				callback(res.data.respMessage, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const signupAction = (data, callback) => {
	return dispatch => {
		let url = `users/signup`
		return axiosMethodRequest('POST', url, data).then((res) => {
			console.log('res', res)
			if (res && res.data && res.data.respCode) {
				callback(res.data.respMessage, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}
export const createPasswordAction = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('POST', 'auth/changeRecoverPassword', data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data.respMessage, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const getEntityDetails = (entityType, updateUserLoadState, callback) => {
	let userDetails = GetSessionValue('loginCredentials')
	return dispatch => {
		let apiUrl = `${entityType}s/${userDetails ? userDetails['_id'] : ''}`
		return axiosMethodRequest('GET', apiUrl)
			.then(res => {
				dispatch(setEntityDetails(res.data.details))
				if (updateUserLoadState) {
					dispatch(setUserAction({ loadComplete: true }))
				}
				if (res && res.data && res.data.respCode) {
					res.data.details.brandDetails = res.data.brandDetails
					let data = res.data.details
					let userDetails = GetSessionValue('user')
					if (data) {
						data.entityType = 'brand'
						// data.tc_accepted = data.isTAndCAccepted
						data.accessToken = userDetails.accessToken
						SetSessionValue('user', data)
						SetSessionValue('loginCredentials', data)
						if (callback)
							return callback(res.data.details, 'success')
					}
				} else {
					if (callback)
						return callback(res.data.details, 'error')
				}

			})
	}
}

export const languageAction = (callback) => {
	return dispatch => {
		return axiosMethodRequest('GET', 'settings/setLanguage?lang=en')
			.then(async (response) => {
				if (response && response.data && response.data.languages) {
					if (callback)
						callback(response, 'success')
				} else {
					if (callback)
						callback(response.data.errorMessage, 'error')
				}
			})
	}
}

export const loginAction = (data, loader, callback) => {
	data.entityType = config.entityType
	return dispatch => {
		return axiosMethodRequest('POST', 'auth/login', data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					response.data.details.brandDetails = response.data.brandDetails
					let data = response.data.details
					data.accessToken = response.data.accessToken
					data.entityType = 'brand'
					// data.tc_accepted = true;//data.isTAndCAccepted
					await SetSessionValue('loginCredentials', data)
					dispatch(setUserAction(data))
					dispatch(getEntityDetails(config.entityType, true))
					dispatch(settingsAction())
					if (callback)
						callback(data, 'success')
				} else {
					if (callback)
						callback(response.data.errorMessage, 'error')
				}
			})
	}
}
export const loginActionForTrainComponent = (callback, userEmail) => {

	// const query = new URLSearchParams(window.location.search);
	// const pageType = query.get('type');
	// const guestLinkToken = query.get('token');

	let checkurl = `users/checkGuestUser?email=${encodeURIComponent(userEmail)}`
	return dispatch => {
		return axiosMethodRequest('GET', checkurl)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					// 	if (pageType && response.data.details && ((guestLinkToken != response.data.details.guestLinkToken) || (response.data.details.guestLinkExpires < new Date().getTime()))) {
					// 		callback("Token is expired please contact to admin and get updated link.", 'error');
					// 	} else {
					response.data.details.brandDetails = response.data.brandDetails
					let data = response.data.details
					data.accessToken = response.data.accessToken
					data.entityType = 'brand'
					// data.tc_accepted = true;//data.isTAndCAccepted
					await SetSessionValue('loginCredentials', data)
					dispatch(setUserAction(data));
					const query = new URLSearchParams(window.location.search);
					const userFrom = query.get('user');
					if (userFrom == "amex") {
						sessionStorage.setItem('userFrom', "amex");
					}
					dispatch(getEntityDetails(config.entityType, true))
					if (callback)
						callback(data, 'success')
					// }
				} else {
					if (callback)
						callback(response.data.errorMessage, 'error')
				}
			})
	}
}
export const logoutAction = () => {
	return dispatch => {
		return axiosMethodRequest('POST', 'auth/logout', '').then((res) => {
			sessionStorage.clear()
			ClearSessionValue()
			if (res && res.data && res.data.respCode) {
				dispatch(setUserAction(null))
			} else {
				dispatch(setUserAction(null))
			}
		})
	}
}

export const changePasswordAction = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('POST', 'auth/changePassword', data).then((response) => {
			if (response && response.data && response.data.respCode) {
				callback(response.data.respMessage, 'success')
			} else {
				callback(response.data.errorMessage, 'error')
			}
		})
	}
}
export const updateUserProfileActionWithoutBranch = (data, callback) => {
	return dispatch => {
		let userDetails = GetSessionValue('loginCredentials')
		// userDetails = userDetails['details']
		delete data['email']
		delete data['password']
		data['__v'] = userDetails && userDetails['__v'] ? userDetails['__v'] : ''
		axiosMethodRequest('PUT', `users/${userDetails['_id']}?type=profile&response=true&step=${data['step']}`, data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					let data = response.data.details
					let userDetails = GetSessionValue('user')
					if (data) {
						data.entityType = 'brand'
						// data.tc_accepted = data.isTAndCAccepted
						data.accessToken = userDetails.accessToken
						// await SetSessionValue('loginCredentials', data)
						// await sessionStorage.setItem('user', JSON.stringify(data))
						// await sessionStorage.setItem('test', 'test')
					}
					let merchantString = ''
					dispatch(setUserAction(data))

					if (callback) {
						callback(response.data, 'success')
					}
				} else {
					if (callback)
						callback(response.data, 'error')
				}
			})
	}
}
export const updateUserProfileAction = (data, callback) => {
	return dispatch => {
		let userDetails = GetSessionValue('loginCredentials')
		delete data['email']
		delete data['password']
		data['__v'] = userDetails && userDetails['__v'] ? userDetails['__v'] : ''
		axiosMethodRequest('PUT', `${config.entityType}s/${userDetails['_id']}?type=profile&response=true`, data)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					let data = response.data.details
					let userDetails = GetSessionValue('user')
					if (data) {
						data.entityType = 'brand'
						// data.tc_accepted = data.isTAndCAccepted
						data.accessToken = userDetails.accessToken
						await SetSessionValue('loginCredentials', data)
						await SetSessionValue('user', data)
						await SetSessionValue('test', 'test')
					}
					let merchantString = ''
					dispatch(setUserAction(data))

					if (callback) {
						callback(response.data, 'success')
					}
				} else {
					if (callback)
						callback(response.data, 'error')
				}
			})
	}
}

export const getLanguageDataAction = (callback) => {
	return dispatch => {
		axiosMethodRequest('GET', `settings`)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					let settings = response.data.settings[0]
					dispatch(setTrialAction(settings['languages']['en']))
				} else {
					if (callback)
						callback(response.data.errorMessage, 'error')
				}

			})
	}
}

export const getBrandGroupWiseAction = (groupId, callback) => {
	return dispatch => {
		axiosMethodRequest('GET', `groupBrand/getBrandGroupWise/${groupId}`)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					callback(response.data.brandList)
				}
			})
	}
}

export const getGroupAction = (callback) => {
	return dispatch => {
		axiosMethodRequest('GET', `groupBrand?from=brand`)
			.then(async (response) => {
				if (response && response.data && response.data.respCode) {
					callback(response.data.groupBrand);
				}
			})
	}
}

export const getDistributorsAction = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `distributors`).then((response) => {
            if (response && response.data && response.data.respCode) {                  
                    callback(response.data.distributors);
            }
        })
    }
}

export const clearStoreData = (callback) => {
	return dispatch => {
		dispatch(setUserAction(null))
		sessionStorage.clear()
		ClearSessionValue();
	}
}

export const loadGuestUserData = (data) => {
	console.log('--------------data-----------', data)
	return dispatch => {
		dispatch(setUserAction(data))
		// sessionStorage.clear()
	}
}

export const createMandate = (data, callback) => {
	return dispatch => {
		axiosMethodRequest('POST', 'clientList/create', data).catch(error => {
			console.log("clientList/create", error)
		}).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data, 'error')
			}
		})
	}
}

export const getAmexUserAction = (data, callback) => {
	return dispatch => {
		let url = `auth/getAmexUser/${data.amexMerchantID}`;
		let reqParam = {
			amexMerchantID: data.amexMerchantID,
			email: data.email,
			phone: data.phone,
			website: data.website
		};
		return axiosMethodRequest('POST', url, reqParam).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const notifyAdminForAmexAction = (data, callback) => {
	return dispatch => {
		let url = `auth/notifyAdminForAmex`;
		return axiosMethodRequest('POST', url, data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const notifyAdminForAmexBranchAction = (data, callback) => {
	return dispatch => {
		let url = `auth/notifyAdminForAmexBranch`;
		return axiosMethodRequest('POST', url, data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const getGoCardLessDataAction = (callback) => {
	return dispatch => {
		axiosMethodRequest('GET', 'settings/getGoCardLessData').then(async (response) => {
			if (response && response.data && response.data.respCode) {
				if (callback) {
					callback(response.data.settings[0]);
				}
			}
		})
	}
}

export const onBoardAmexBrandAction = (data, callback) => {
	return dispatch => {
		let url = `auth/onBoardAmexBrand`;
		return axiosMethodRequest('POST', url, data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}

export const verifyEmailAction = (data, callback) => {
	return dispatch => {
		let url = "auth/verifyEmail";
		return axiosMethodRequest('POST', url, data).then((res) => {
			if (res && res.data && res.data.respCode) {
				callback(res.data, 'success')
			} else {
				callback(res.data.errorMessage, 'error')
			}
		})
	}
}
