import React, {Component} from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber, date as YupDate } from 'yup';
import moment from 'moment'


class PerformanceFormComponent extends Component {
  handleAddDataForPerformance = (form, arrayAction) => {
    let data = {};
    let values = form.values;
    let keys = [
      'date',
      'reach',
      'conversions',
      'revenue',
    ]
    keys.forEach(key => {
      data[key] = values[`${key}`]
    })
    arrayAction(data)
  }
  getEntriesForPerformance = ({remove, form }) => {
    let data = form.values.performance
    return (
      <React.Fragment>
        {
          data.map((item, i) => {
            return (
              <div className="row" key={i}>
                <div className="col-2 offset-1 col-2 text-center">{moment(item.date).format('DD/MM/YYYY')}</div>
                <div className="col-2 text-center">{item.reach}</div>
                <div className="col-2 text-center">{item.conversions}</div>
                <div className="col-2 text-center">{item.revenue}</div>
                <div className="col-2 text-center">
                  <i 
                    className="fa fa-trash"
                    style={{pointer : 'cursor'}}
                    onClick={() => remove(i)}
                  ></i>
                </div>
              </div>
            )
          })
        }
      </React.Fragment>
    );
  }
  getTotals = (key, performance) => {
    return performance.reduce((result, item) => {
      result += item[key]
      return result
    }, 0)
  }
  render() {
    let { values, status } = this.props;
    return (
      <Form>
        <FieldArray name="performance">
        {({remove, form}) => {
          return (
            <React.Fragment>
              <div style={{minHeight : '200px'}}>
                <div className="row" >
                  <div className="offset-1 col-2 text-center" style={{borderBottom : '1px solid #ddd'}}>Date</div>
                  <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd'}}>Reach</div>
                  <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd'}}>Conversions</div>
                  <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd'}}>Revenue</div>
                  <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd'}}>Actions</div>
                </div>
                {this.getEntriesForPerformance({remove, form})}
              </div>
              <div className="row mt-3">
                <div className="offset-1 col-2 text-center">
                  <Field placeholder="Date" name="date" type="date" className="form-control form-control-sm" min={values.startDate} max={values.endDate}/>
                  <ErrorMessage name="date" component="label" className="error"/>
                  </div>
                <div className="col-2 text-center">
                  <Field placeholder="Reach" name="reach" type="number" className="form-control form-control-sm"/>
                  <ErrorMessage name="reach" component="label" className="error"/>
                  </div>
                <div className="col-2 text-center">
                  <Field placeholder="Conversions" name="conversions" type="number" className="form-control form-control-sm"/>
                  <ErrorMessage name="conversions" component="label" className="error"/>
                </div>
                <div className="col-2 text-center">
                  <Field placeholder="Revenue" name="revenue" type="number" className="form-control form-control-sm"/>
                  <ErrorMessage name="revenue" component="label" className="error"/>
                </div>
                <div className="col-2 text-center">
                  <button 
                    className="btn btn-sm btn-primary"
                    // onClick={(e) => this.handleAddDataForPerformance(form, push)}
                  >
                    Add
                  </button>
                </div>
              </div>
               <div className="row mt-3">
                <div className="offset-1 col-2 text-center" style={{borderBottom : '1px solid #ddd', borderTop: '1px solid #ddd'}}><b>Totals</b></div>
                <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd', borderTop: '1px solid #ddd'}}>{this.getTotals('reach', values.performance)}</div>
                <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd', borderTop: '1px solid #ddd'}}>{this.getTotals('conversions', values.performance)}</div>
                <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd', borderTop: '1px solid #ddd'}}>{this.getTotals('revenue', values.performance)}</div>
                <div className="col-2 text-center" style={{borderBottom : '1px solid #ddd', borderTop: '1px solid #ddd'}}></div>
              </div>
            </React.Fragment>
          )
        }}
      </FieldArray>
      <div className="error text-center mt-3">{status}</div>
      </Form>
    )
  }
}


export class PerformanceForm extends Component {
  initialValues = {
    performance : [],
    date : '',
    reach : '',
    conversions : '',
    revenue : '',
    startDate : '',
    endDate : ''
  }
  validationSchema = YupObject({
    performance : YupString(),
    date : YupDate()
          .required('Date is required'),
    reach : YupNumber()
            .required('Required')
            .min(0, 'Reach must positive')
            .integer('Reach must be an integer'),
    conversions : YupNumber()
            .required('Required')
            .min(0, 'Conversions must positive')
            .integer('Conversions must be an integer'),
    revenue : YupNumber()
            .required('Required')
            .min(0, 'Please enter a non-negative number')
  })
  noop = () => {}
  onSubmitEx = (values, actions) => {
    let { onSubmit } = this.props;
    let data;
    let performance;
    Object.keys(values).forEach(key => {
      if (!data)
        data = {}
      if (key !== 'performance')
        data[key] = values[`${key}`]
    })
    if (data) {
      performance = [...values.performance, data]
      onSubmit({performance}, actions)
    }
  }
  render() {
    let { initialValues, formikRef=this.noop, enableReinitialize, options } = this.props;
    initialValues = {...this.initialValues, ...initialValues}
    if (!initialValues.performance) {
      initialValues.performance = []
    }
    return(
      <Formik
        initialValues={ initialValues || this.initialValues }
        children={ props => <PerformanceFormComponent {...props} options={options} /> }
        onSubmit={this.onSubmitEx}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}
