import React, {Component} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, mixed as YupMixed } from 'yup';
import * as Datetime from 'react-datetime'
import {FileInputComponent} from './FileUpload'


export const INITIAL_TX_UPLOAD_VALUES = {
  'transactions' : '',
  'impressions' : ''
}

export const INITIAL_CO_MANAGER_VALUES = {
  start_date : '',
  end_date : ''
}

export class TxUploadForm extends Component {
  validationSchema = YupObject({
    transactions : YupMixed()
                    .required('Transactions file is required')
                    .test('fileSize', 'File is empty', value => value && value.size > 0),
    impressions : YupMixed()
                    .required('Impressions file is required')
                    .test('fileSize', 'File is empty', value => value && value.size > 0),
  })
  fileUploadRef = {}
  handleFileRef = (name, ref) => {
    this.fileUploadRef[name] = ref;
  }
  handleFormReset = () => {
    Object.keys(this.fileUploadRef).forEach(it => {
      this.fileUploadRef[it].value = ''
    })
  }
  getForm = (props, options={}) => {
    let { status, isSubmitting, setFieldValue, touched, handleBlur } = props;
    return (
      <Form>
        <div className="form-group">
          <label>Transactions</label>
          <Field 
            name="transactions"  
            className="form-control" 
            disabled={isSubmitting} 
            accept="text/csv"
            setFieldValue={setFieldValue}
            component={FileInputComponent}
            touched={touched["transactions"]}
            onBlur={handleBlur}
            handleFileRef={this.handleFileRef}
          />
          <ErrorMessage name="transactions" component="label" className="error"/>
        </div>
        <div className="form-group">
          <label>Impressions</label>
          <Field 
            name="impressions" 
            className="form-control" 
            disabled={isSubmitting} 
            accept="text/csv"
            setFieldValue={setFieldValue}
            component={FileInputComponent}
            touched={touched["impressions"]}
            onBlur={handleBlur}
            handleFileRef={this.handleFileRef}
          />
          <ErrorMessage name="impressions" component="label" className="error"/>
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
  noop = () => {}
  render() {
    let { initialValues, onSubmit, formikRef=this.noop, options } = this.props;
    return(
      <Formik
        initialValues={ initialValues || INITIAL_TX_UPLOAD_VALUES }
        render={ props => this.getForm(props, options) }
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        onReset={this.handleFormReset}
      />
    )
  }
}


export class CampaignOverviewManagerForm extends Component {
  validationSchema = YupObject({})
  getForm = (props, options={}) => {
    let { status, values, setFieldValue } = props;
    return (
      <Form>
        <div className="form-group form-row">
          <div className="col-md-6">
            <label>Start Date</label>
            <Datetime
              viewMode="months" 
              timeFormat={false} 
              dateFormat="MM/YYYY"
              onChange={(date) => setFieldValue('start_date', date)}
              value={values.start_date}
              renderInput={(props, openCalendar) => {
                return (
                  <div className="input-group mb-3">
                    <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                    <div className="input-group-append date-dropdown-btn">
                      <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                    </div>
                  </div>
                )
              }}
            />
            <ErrorMessage name="start_date" component="label" className="error"/>
          </div>
          <div className="col-md-6">
            <label>End Date</label>
            <Datetime
              viewMode="months" 
              timeFormat={false} 
              dateFormat="MM/YYYY"
              onChange={(date) => setFieldValue('end_date', date)}
              value={values.end_date}
              renderInput={(props, openCalendar) => {
                return (
                  <div className="input-group mb-3">
                    <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                    <div className="input-group-append date-dropdown-btn">
                      <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                    </div>
                  </div>
                )
              }}
            />
            <ErrorMessage name="end_date" component="label" className="error"/>
          </div>
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
  noop = () => {}
  render() {
    let { initialValues, onSubmit, formikRef=this.noop, options } = this.props;
    return(
      <Formik
        initialValues={ initialValues || INITIAL_CO_MANAGER_VALUES }
        render={ props => this.getForm(props, options) }
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        onReset={this.handleFormReset}
      />
    )
  }
}

