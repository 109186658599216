import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import Loader from '../Loader';
import { Link } from 'react-router-dom'
import Select from 'react-select';

class InsightsLoginForm extends Component {

  state = {
    loader: true
  }
  initialValues = {
    email: '',
    brandId: '',
  }

  validationSchema = YupObject({
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
    brandId: YupString()
      .required('brandId is required')

  })
  componentDidMount = () => {
    this.setState({ loader: false });
  }

  getForm = (props, { brands, getBrand }) => {
    let { status, isSubmitting, setFieldValue } = props;
    return (

      <Form className="form">
        <Loader loader={this.state.loader} />

        <div className="form-group">
          <label>Email</label>
          <Field
            name="email" className="form-control" disabled={isSubmitting}
            autocomplete="off"
          />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Select Brand</label>
          {/* <Field name="brandId" className="form-control" type="t" disabled={isSubmitting} /> */}
          <Select
            options={brands}
            onChange={value => {
              // this.setState({ selectedBrand: value })
              setFieldValue("brandId", value)
            }}
            onInputChange={(value) => {
              getBrand(value)
            }}
          // value={selectedBrand}
          />
          <ErrorMessage name="brandId" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting}>Login</button>
        </div>
      </Form>
    )
  }

  render() {
    let { initialValues, onSubmit, brands, getBrand } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, { brands, getBrand })}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required



export default InsightsLoginForm










