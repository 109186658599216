const SET_MOBILE_NAV_VISIBILITY = 'LAYOUT/SET_MOBILE_NAV_VISIBILITY';
const SET_SIDEBAR_NAV_VISIBILITY = 'SET_SIDEBAR_NAV_VISIBILITY'

export const setMobileNavVisibility = (visibility) => ({
  type: SET_MOBILE_NAV_VISIBILITY,
  visibility
});

export const toggleMobileNavVisibility = () => (dispatch, getState) => {
  let visibility = getState().Layout.mobileNavVisibility;
  dispatch(setMobileNavVisibility(!visibility));
}

export const setSidebarNavVisibility = (visibility) => ({
  type: SET_SIDEBAR_NAV_VISIBILITY,
  visibility
})

export const toggleSidebarVisibility = () => (dispatch, getState) => {
  
  let visibility = getState().Layout.sidebarNavVisibility;
  dispatch(setSidebarNavVisibility(!visibility))
}

export default function reducer(state = {
  mobileNavVisibility: false,
  sidebarNavVisibility: true
}, action) {
  switch (action.type) {
    case SET_MOBILE_NAV_VISIBILITY:
      return {
        ...state,
        mobileNavVisibility: action.visibility
      };
    case SET_SIDEBAR_NAV_VISIBILITY:
      return {
        ...state,
        sidebarNavVisibility: action.visibility
      }
    default :
      return state
  }
}