import React, { useEffect, useState } from "react";
import Topnavbar from "../../components/InsightsLayout/TopNavBarInsights/topnavbarInsights";
import "../../components/InsightsLayout/app.scss";
import Insights from "../../components/InsightsLayout/insights";
import BottomNavbar from "../../components/InsightsLayout/BottomNavbarTabs/BottomNavbar";
import { connect, useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import KrowdLogo from '../../assets/images/logo-krowd-white.svg';
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from '@mui/icons-material/Logout';
import './insights.css'
import ModalComponent from '../../components/Modal';
import { GetSessionValue } from '../../utils/sessionStorage';
import { RESET_INSIGHTS_DATA } from "../../redux/insights/actionTypes";
import { Container, Navbar, Nav } from "react-bootstrap";
import { logoutAction } from "../../redux/actions";

const imgStyle = {
  'display': 'block',
  'height': "3rem",
  'textAlign': "center",
}
function InsightsPage(props) {
  const dispatch = useDispatch();

  const [displayHeader, setDisplayHeader] = useState(false);
  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' });
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    if (!window.location.pathname.includes("user/socialanalytics")) {
      setDisplayHeader(true);
    }
    let data = GetSessionValue('loginCredentials');
    if (data)
      setUserDetails(data);

    return () => {
      dispatch({
        type: RESET_INSIGHTS_DATA
      })
    }

  }, []);

  const setDisplayLogoutModal = () => {
    setShowLogoutModal(true);
  }
  const onCloseLogoutModal = () => {
    setShowLogoutModal(false);
  }

  const handleLogout = () => {
    onCloseLogoutModal()
    dispatch(logoutAction())
    setTimeout(() => {
      props.history.push(`/login`);
    }, 10);
  }

  return (
    <div>
      {displayHeader &&
        <>
          <div className="d-block d-lg-none d-md-none d-sm-none insights-header">
            <Navbar className="d-lg-none" collapseOnSelect expand="lg">
              <Container className="m-0">
                <Navbar.Brand href="#home">
                  <a href='https://krowd.info' target='_blank'>
                    <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
                  </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav"  >
                  <Nav className="me-auto">
                    <Nav.Link href="#features">
                    </Nav.Link>
                    <Nav.Link href="#features">
                    </Nav.Link>
                    <Nav.Link href="#profile">
                      <AccountCircle className="profile-icon" />
                      <span className="header-text" >
                        {(userDetails.firstName && userDetails.firstName) ? "" : userDetails.email}
                        {userDetails && userDetails.firstName || ''} {userDetails && userDetails.lastName || ''}
                      </span>
                    </Nav.Link>
                    <Nav.Link href="#logout">
                      <LogoutIcon className="logout-icon cursor-pointer" onClick={setDisplayLogoutModal} />
                      <span className="header-text cursor-pointer" onClick={setDisplayLogoutModal}>Logout</span>
                    </Nav.Link>
                  </Nav>

                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <div className="d-lg-block d-none d-md-block d-sm-block insights-header">
            <Grid className="px-3" container >
              <Grid item xs={12} sm={6} md={6} lg={6} className='text-left'>
                <a href='https://krowd.info' target='_blank'>
                  <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" className='img-fluid' />
                </a>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} className='text-right'>

                <AccountCircle className="profile-icon" />
                <span className="header-text" >
                  {(userDetails.firstName && userDetails.firstName) ? "" : userDetails.email}
                  {userDetails && userDetails.firstName || ''} {userDetails && userDetails.lastName || ''}
                </span>
                <LogoutIcon className="logout-icon cursor-pointer" onClick={setDisplayLogoutModal} />
                <span className="header-text cursor-pointer" onClick={setDisplayLogoutModal}>Logout</span>
              </Grid>
            </Grid>
          </div>
        </>
      }
      <ModalComponent
        close={onCloseLogoutModal}
        show={showLogoutModal}
        callback={handleLogout}
        title={"Logout"}
        message={"Are you sure you want to Logout?"}
        action={"Logout"}
      />
      <Topnavbar />
      <Insights />
      <BottomNavbar />
    </div>
  );
}

export default InsightsPage;
