import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutWithSidebar from './LayoutWithSidebar'
import CampaignOverviewPage from '../CampaignOverviewPrintPage';
import CampaignCreate from '../CreateCampaign/index'
import LoginPage from '../PublicPages/LoginPage'
import InsightsPage from '../InsightPageLayout';

// import { Invoice } from '../Invoices/invoice';


const Main = props => {
  let { match } = props;
  return (
    <Switch>
      {/* <Route path={`${match.url}/:user`} component={LoginPage} /> */}
      <Route path={`${match.url}/campaigns/print/:id/:start_date?/:end_date?`} component={CampaignOverviewPage} />
      {/* <Route path={`${match.url}/invoice/print`} component={Invoice} /> */}
      <Route path={`${match.url}`} component={LayoutWithSidebar} />
      <Route path={`${match.url}/user/socialanalytics/overview`} component={InsightsPage} />
    </Switch>
  )
}


export default Main





