
import { reducer as formReducer } from 'redux-form'
import ThemeOptions from './themeoptions';
import Layout from './layout';
import authReducer from './auth'
import campaignReducer from './campaign'
import branchReducer from './branch'
import entityListReducer from './entityList'
import entityDetailReducer from './entityDetail'
import selectEntitiesReducer from './selectEntities'
import campaignOverviewReducer from './campaignoverview'
import LangReducer from './lang'
import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import { insightsReducer } from '../insights';
import { campaignStartPause } from '../campaignStartPause';

export default combineReducers({
  ThemeOptions,
  Layout,
  form: formReducer,
  user: authReducer,
  campaigns: campaignReducer,
  // branches : branchReducer,
  campaignOverview: campaignOverviewReducer,
  banks: entityListReducer('bank'),
  brands: entityListReducer('brand'),
  users: entityListReducer('user'),
  invoices: entityListReducer('invoice'),
  ingestionLogs: entityListReducer('ingestionLog'),
  languages: LangReducer,
  selectEntities: selectEntitiesReducer,
  bank: entityDetailReducer('bank'),
  brand: entityDetailReducer('brand'),
  notifications,
  branches: entityListReducer('branch'),
  documents: entityListReducer('SET_MANAGECREATIVE_DATA'),
  brandDetails: entityListReducer('SET_BRAND_DETAILS_DATA'),
  insights: insightsReducer,
  campaignSP: campaignStartPause
});