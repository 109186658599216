import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import capitalize from 'lodash/capitalize'
import Icon from '@material-ui/core/Icon'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { pluralize } from '../../utils/helpers'
import {
  getEntityAction,
  createEntityAction,
  deleteEntityAction,
  editEntityAction,
  setEntityPageAction,
  setEntitySortAction,
  setEntitySearchAction,
  setEntityFiltersAction,
  uploadCSVForEntityAction,
  getEntityDetails,
  confirmMerchantAction,
  multiAction,
  createMultipleBranchAction,
  getXeroInvoiceAction
} from '../../redux/actions/index'
// import { getBranchListByBrandIdAction, getBranchListByNameAction } from "../../redux/actions";
import { getBranchListFromNodeAction } from "../../redux/actions";
import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';
import config from '../../config/config'
import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import { Redirect } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import ViewModal from '../../components/ViewModal'
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import moment from 'moment'
import { containCrop } from "react-image-crop";
import { GetSessionValue, DeleteSessionValue } from "../../utils/sessionStorage";
import { getUniqueArray } from '../../utils/helpers';
import lambdaAxiosMethodRequest from '../../config/lambdaService';

class EntityList extends Component {
  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    showPasswordResetModal: false,
    showAddNewModal: false,
    showEditModal: false,
    showUploadModal: false,
    showApproveModal: false,
    tableData: [],
    isLoading: false,
    sortCount: 0,
    approvalAction: '',
    columns: this.props.columns,
    searchField: '',
    sessionExpiryModal: false,
    loginRedirect: false,
    first: 0,
    rows: 10,
    languageFields: this.props.language,
    selectedArray: [],
    actions: [
      { label: 'Delete', value: 'DELETE' },
      { label: 'Approve', value: 'APPROVE' },
      { label: 'Reject', value: 'REJECT' },
    ],
    status: [
      { label: 'Status (All)', value: null },
      { label: 'Approved', value: 'Approved' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Updated', value: 'Updated' },
      { label: 'Deleted', value: 'Deleted' },
    ],
    selectedStatus: { label: 'Status', value: null },
    selectedAction: '',
    openViewModal: false,
    branchList: []
  };
  pagination = {
    limit: 10,
    page: 1
  }

  //get Data from sever
  fetchData = (filterType) => {
    let {
      getEntityData,
      filters,
      apiUrl,
      successNotification,
      // items
    } = this.props;
    let { limit, page } = this.pagination

    if(!filters) {
      filters = {};
    }

    let { selectedArray, searchField, selectedStatus } = this.state
    if (searchField) {
      if (!filters) {
        filters = {}
      }
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    if (selectedStatus && selectedStatus.value) {
      if (!filters) {
        filters = {}
      }
      filters.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }
    this.setState({ isLoading: true });
    let userDetails = GetSessionValue('loginCredentials');
    if(apiUrl == "distributors/getInvoiceList/invoice") {      
      filters.brandId = userDetails ? userDetails.brandId : "";
      filters.email = userDetails ? userDetails.email : "";

      // filters.brandId = "KBTA0001E";
      // filters.email = "mail@abuzaad.co.uk";
    }

    if(apiUrl == 'merchants' && (userDetails.role == "groupAdmin" || userDetails.role == "groupUser")) {
      filters.from = "branchList";
      filters.brandId = userDetails ? userDetails.brandId : "";
      filters.role = "groupAdmin";
    }
    getEntityData(page, limit, filters, apiUrl, '', async (res, pagination, type) => {
      let sessionExpiryModal = false
      let sessionExpired = await GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      if (apiUrl == 'merchants' && type == 'success' && filterType == 'pageChanged') {
        this.handleCheckboxforpageChange(res, false)
        this.setState({ tableData: res })

      }

      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (type == 'error') {
        if (sessionExpiryModal) {
          return
        } else {
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        }

      }
    })
  }

  componentDidMount = async () => {
    let { preset, setEntityFilters, page, columns, heading, user } = this.props;
    if (heading == 'user') {
      this.props.getDetails(config.entityType, true, res => { })
    }
    if (heading == 'user' && columns && user && user.role == 'user') {
      let columns = this.props.columns
      columns = columns.splice(1, columns.length)
      this.setState({ columns: columns })
    }
    this.fetchData(page || this.pagination.page)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      // this.fetchData()
    }
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { limit } = this.pagination;
    return parseInt(totalSize / limit) + (totalSize % limit ? 1 : 0)
  }

  //changes in table
  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters, status }) => {
    let {
      setEntitySort,
      setEntitySearch,
      setEntityFilters,
      getEntityData,
      apiUrl,
      setEntityPage
    } = this.props;

    let { limit, page } = this.pagination
    let { searchField, selectedStatus } = this.state
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    console.log('selected status', selectedStatus)
    if (selectedStatus && selectedStatus.value) {
      filterCriteria.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }
    if (type === 'pagination') {
      setEntityPage(page, sizePerPage)
      return;
    }
    if (type === 'sort') {
      let sortCount = this.state.sortCount
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      filterCriteria.sortField = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({ sortCount: sortCount })
      getEntityData(page, limit, filterCriteria, apiUrl, 'sort', (response, pagination, type) => {
        if (type == 'success') {
          this.handleCheckboxforpageChange(response, false)
        }
      })
      return
    }
    if (type === 'search') {
      filterCriteria.globalSearch = {
        value: searchText,
        type: 'user'
      }
      getEntityData(page, limit, filterCriteria, apiUrl, 'globalsearch', (response, pagination, type) => {
        if (type == 'success') {
          this.handleCheckboxforpageChange(response, false)
        }
      })
    }
    if (type === 'dropdown') {
      // filterCriteria = [{
      //   key: 'status',
      //   value: status,
      //   type: 'eq'
      // }]
      getEntityData(page, limit, filterCriteria, apiUrl, 'dropdown', (response, pagination, type) => {
        if (type == 'success') {
          this.handleCheckboxforpageChange(response, false)
        }
      })
    }
    if (type === 'filter') {
      setEntityFilters({ ...filters })
    }
  }

  setShowAddNewModal = () => {
    this.getBranchList()
    this.setState({ showAddNewModal: true })
  }

  onCloseAddNewModal = () => {
    this.setState({ showAddNewModal: false, selectedEntity: null })
  }

  setShowUploadModal = () => {
    this.setState({ showUploadModal: true })
  }

  onCloseUploadModal = () => {
    this.setState({ showUploadModal: false, selectedEntity: null })
  }

  setShowEditModal = (selectedEntity) => {
    if (selectedEntity) {
      this.getBranchList()
      this.setState({ showEditModal: true, selectedEntity })
      return
    }
    this.setState({ showEditModal: true })
  }

  setApproveModal = (selectedEntity, type) => {
    this.setState({ showApproveModal: true, selectedEntity, approvalAction: type })
  }

  onCloseApproveModal = () => {
    this.setState({ showApproveModal: false, selectedEntity: null, approvalAction: null })
  }
  onCloseViewModal = () => {
    this.setState({ openViewModal: false })
    this.setShowEditModal()
  }
  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedEntity: null })
  }

  setShowDeleteModal = (selectedEntity) => {
    this.setState({ showDeleteModal: true, selectedEntity })
  }

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedEntity: null })
  }

  setShowPasswordResetModal = () => {
    this.setState({ showPasswordResetModal: true })
  }

  onClosePasswordResetModal = () => {
    this.setState({ showPasswordResetModal: false, selectedEntity: null })
  }

  //on editing new entitites
  onSubmitEdit = async (values, actions) => {
    let { editEntity, page, successNotification, entityType, apiUrl } = this.props;
    this.setState({ isLoading: true })
    if (apiUrl == 'users') {
      let body = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        brandName: '',
      }
      if (values) {
        body['email'] = values['email'];
        body['firstName'] = values['firstName'];
        body['lastName'] = values['lastName'];
        body['phone'] = values['phone'];
        body['role'] = values['role'];
        body['brandName'] = values['brandName'];
      }
      if (!values.merchantIds) {
        values.merchantIds = []
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      if (values['brandId']) {
        body['brandId'] = values['brandId']
      }
      if (values['brandObjId']) {
        body['brandObjId'] = values['brandObjId']
      }
      values = body;
    }
    if (apiUrl == 'merchants') {
      let body = {};
      if (values) {
        body['name'] = values['name'];
        body['merchantId'] = values['merchantId'];
        body['amexMerchantID'] = values['amexMerchantID'];
        body['contactName'] = values['contactName'];
        body['contactEmail'] = values['contactEmail'];
        body['contactPhone'] = values['contactPhone'];
        body['address'] = values['address'];
        body['tripadvisorUrl'] = values['tripadvisorUrl'];
        body['postcode'] = values['postcode'];
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = Object.assign(values, body);
    }

    let body = {
      "branch_name": values.name || "",
      "entity_url": values.tripadvisorUrl || "",
      "brandname": values.brandName || "",
      "brand_id": values.brandId || "",
      "city": values.city || "",
      "description": values.description || "",
      "address": values.address || "",
      "merchantId": values.merchantId || "",
      "amexMerchantID": values.amexMerchantID || "",
      "postcode": values.postcode || "",
      "tripadvisorUrl": values.tripadvisorUrl || "",
      "sector_id": '16',
      "cuisines_list": [],
      "kmid": values.kmid || ""
    };

    if (apiUrl == 'merchants') {
      // let url = "https://uo1ipaqymb.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
      let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
      // let x_api_key = "52SIj1lUmY4u4B6mhyp9p8zrZL3Ko8PtaJM0FcQ8";
      let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
      let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
      if (response.data) {
        editEntity(values, apiUrl, (res, type) => {
          this.setState({ isLoading: false })
          actions.setSubmitting(false)
          if (type == 'error') {
            actions.setStatus(res)
            return
          }
          // notification
          this.onCloseEditModal()
          this.fetchData(page)
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        })
      }
    } else {         
        editEntity(values, apiUrl, (res, type) => {
          this.setState({ isLoading: false })
          actions.setSubmitting(false)
          if (type == 'error') {
            actions.setStatus(res)
            return
          }
          // notification
          this.onCloseEditModal()
          this.fetchData(page)
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        }) 
    }
  }

  // //on adding new entity
  // onSubmitAddNew = (values, actions) => {
  //   let { createMultipleBranchAction, createEntity, apiUrl } = this.props;
  //   if (values.isNew) {
  //     let userDetails = GetSessionValue('loginCredentials')
  //     let brandId = userDetails ? userDetails.brandId : ""
  //     let brandName = userDetails ? userDetails.brandName : ""
  //     let finalobj = values
  //     finalobj.brandId = brandId;
  //     finalobj.brandName = brandName;
  //     finalobj.brand_id = brandId;
  //     finalobj.created_by = "ui";
  //     finalobj.created_date = new Date();
  //     finalobj.datasource = "ui";
  //     delete finalobj.block;
  //     createMultipleBranchAction([finalobj], async (res, type) => {
  //       console.log(res)
  //       if (res.details.branch[0].updateOne.filter._id) {
  //         let mId = res.details.branch[0].updateOne.filter._id
  //         this.addBranchSuccess(res, mId, actions, type)
  //       }
  //     })
  //   } else {
  //     this.setState({ isLoading: true })
  //     createEntity(values, apiUrl, async (res, type) => {
  //       this.addBranchSuccess(res, res.merchantId, actions, type)
  //     })
  //   }
  //   // this.setState({ isLoading: true })
  //   // createEntity(values, apiUrl, (res, type) => {
  //   //   this.setState({ isLoading: false })
  //   //   actions.setSubmitting(false)
  //   //   if (type == 'error') {
  //   //     actions.setStatus(res)
  //   //     return
  //   //   }
  //   //   // notification
  //   //   this.onCloseAddNewModal()
  //   //   this.fetchData(page)
  //   //   setTimeout(() => {
  //   //     let notification = { ...notificationOpts }
  //   //     notification.message = res
  //   //     successNotification(notification)
  //   //   })
  //   // })
  // }

  //on adding new entity
  onSubmitAddNew = async (values, actions) => {
    let { createMultipleBranchAction,createEntity, apiUrl, page, successNotification } = this.props;
    let userDetails = GetSessionValue('loginCredentials')
    
    let brandId = userDetails ? userDetails.brandId : ""
    let brandName = userDetails ? userDetails.brandName : ""
    let finalobj = values;
    finalobj.brandId = values.brandId || brandId;
    finalobj.brandName = values.brandName || brandName;
    finalobj.brand_id = values.brandId || brandId;
    if(values.role == "brandAdmin" || values.role == "brandUser") {
      finalobj.group_brand_id = userDetails ? userDetails.brandId : "";
      finalobj.brandObjId = values.brandObjId;
    }
    if(!values.brandObjId)
       delete values.brandObjId;
    finalobj.created_by = "ui";
    finalobj.created_date = new Date();
    finalobj.datasource = "ui";
    finalobj.branch_name = finalobj.name;
    finalobj.entity_url = finalobj.tripadvisorUrl;
    finalobj.city = finalobj.city || "";
    finalobj.description = finalobj.description || "";
    finalobj.sector_id = '16';
    finalobj.cuisines_list = [];
    finalobj.kmid = finalobj.kmid || "";
    delete finalobj.block;

    if (apiUrl == 'merchants') {
        // let url = "https://uo1ipaqymb.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
        let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
        // let x_api_key = "52SIj1lUmY4u4B6mhyp9p8zrZL3Ko8PtaJM0FcQ8";
        let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
        let response = await lambdaAxiosMethodRequest('POST', url, finalobj, x_api_key);
        if (response.data && response.data[0] && response.data[0].kmid) {
          finalobj.kmid = response.data[0].kmid;
          createMultipleBranchAction([finalobj], async (res, type) => {
            console.log(res)
            if (res.details.branch[0].updateOne.filter._id) {
              let mId = res.details.branch[0].updateOne.filter._id
              this.addBranchSuccess(res, mId, actions, type)
            }
          })
        }
    } else { 
        this.setState({ isLoading: true })
        finalobj.isFirstLogin = false;
        createEntity(finalobj, apiUrl, (res, type) => {
          this.setState({ isLoading: false })
          actions.setSubmitting(false)
          if (type == 'error') {
            actions.setStatus(res)
            return
          }
          // notification
          this.onCloseAddNewModal()
          this.fetchData(page)
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        })
    }


  }

  addBranchSuccess = async (res, merchantId, actions, type) => {
    let { page, successNotification } = this.props;
    // if (merchantId) {
    //   await this.setCheckBoxData(merchantId);
    //   await this.props.handleTrainComponentMerchantIds(this.state.selectedArray)
    // }
    this.setState({ isLoading: false })
    actions.setSubmitting(false)
    // count = 0;
    // merchantInputFieldCount = 0;
    if (type == 'error') {
      actions.setStatus(res)
      return
    }
    // notification
    this.onCloseAddNewModal()
    this.fetchData(page)
    setTimeout(() => {
      let notification = { ...notificationOpts }
      notification.message = res['respMessage'] ? res['respMessage'] : res
      successNotification(notification)
    })
  }

  //for uplaod csv files
  onSubmitUpload = (values, actions) => {
    let { uploadCSVForEntity, page, successNotification, entityType, apiUrl } = this.props;
    uploadCSVForEntity(values.file, apiUrl, (res, type) => {
      actions.setSubmitting(false)
      if (type == 'error') {
        actions.setStatus(res)
        return
      }
      // notification
      this.onCloseUploadModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }

  //for deeting row
  handleDeleteEntity = () => {
    let { deleteEntity, page, successNotification, entityType, apiUrl } = this.props;
    let { selectedEntity } = this.state
    deleteEntity(selectedEntity, apiUrl, (res, type) => {
      if (type == 'error') {
        return
      }
      // notification
      this.onCloseDeleteModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }

  //handle merchant
  handleConfirmMerchant = (values, actions) => {
    let { confirmMerchant, page, successNotification, entityType } = this.props;
    let { selectedEntity, approvalAction } = this.state
    values.status = `${approvalAction}d`
    values.MId = selectedEntity._id
    let url = approvalAction == 'Approve' ? `users/approveMerchantId` : approvalAction == 'Reject' ? `users/approveMerchantId` : ''
    confirmMerchant(values, url, (res, type) => {
      this.onCloseApproveModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        if (type == 'error') {
          notification.message = res
        } else {
          notification.message = `Merchant ${approvalAction == 'Approve' ? 'approved' : 'rejected'} successfully`
        }
        successNotification(notification)
      })
    })
  }

  submitForm = () => {
    if (this.formik)
      this.formik.submitForm()
  }

  formikRef = async (e) => {
    console.log(e)
    this.formik = e
    if (this.formik) {
      if (this.formik.uploadProps) {
        await this.setState({
          isSubmitDisabled: this.formik.uploadProps.isDisabled
        })
        console.log(this.formik.isDisabled)
      }
    }
  }
  //handleCheckbox for pageChange
  handleCheckboxforpageChange = (data, action) => {
    let { selectedArray, tableData } = this.state
    if (data) {
      data.forEach((item, index) => {
        if (document.getElementById(item._id)) {
          document.getElementById(item._id).checked = action
        }
      })
    }
    // else if(tableData && tableData.length && selectedArray && selectedArray.length){
    //   let matchedMultiSelected=[]
    //   let unmatchedSelcted=[]
    //   for(let i=0;i<tableData.length;i++){
    //     for(let j=0;j<selectedArray.length;j++){
    //       if(tableData[i]._id==selectedArray[j]){
    //         matchedMultiSelected.push(selectedArray[j])
    //       }
    //     }
    //   }
    //   matchedMultiSelected.forEach((item,index)=>{
    //     document.getElementById(item).checked = true
    //   })
    // }

  }
  //for global search
  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  //for multiselect
  multiAction = () => {
    let { multiAction, successNotification } = this.props
    let { selectedArray, selectedAction } = this.state
    let values = {}
    values.selectedIds = selectedArray
    multiAction(values, selectedAction.value, (res, type) => {
      let notification = { ...notificationOpts }
      notification.message = res
      setTimeout(() => {
        successNotification(notification)
      })
      if (type == 'success') {
        selectedArray.forEach((item, index) => {
          document.getElementById(item).checked = false
        })
        selectedArray.forEach((item, index) => {
          return this.handleCheckBox(item, type)
        })
        this.setState({ selectedArray: [] })
        this.fetchData()
      }
    })
  }

  //for multiselect checkbox
  handleCheckBox = (e, type) => {
    // let v = JSON.parse(e.target.value[0])
    let selectedRowData
    if (e.target) {
      selectedRowData = JSON.parse((e.target.value))
    }
    let selectedId
    if (!type) {
      selectedId = selectedRowData._id
    } else {
      selectedId = e
    }
    let selectedArray = this.state.selectedArray
    let selectedIdFound = false
    let selectedIdIndex
    selectedArray.forEach((item, index) => {
      if (item == selectedId) {
        selectedIdFound = true
        selectedIdIndex = index
      }
    })
    if (selectedIdFound) {
      selectedArray.splice(selectedIdIndex, 1)
    } else {
      selectedArray.push(selectedId)
    }
    this.setState({ selectedArray: selectedArray })
    this.getActions()
  }
  getActions = () => {
    let ids = this.state.selectedArray
    let tableData = this.props.items
    let statusArray = []
    let userStatusArray = []

    const allEqual = arr => arr.every(v => v === arr[0])
    if (ids.length) {
      for (let id in ids) {
        for (let item in tableData) {
          if (ids[id] == tableData[item]._id) {
            if (!tableData[item].createdBy.user) {
              statusArray.push(tableData[item].status)
            } else if (tableData[item].createdBy.user) {
              statusArray = []
              userStatusArray.push(tableData[item].status)
            }
          }
        }
      }
    }

    if (statusArray.length && !userStatusArray.length) {
      let alleq = allEqual(statusArray)
      console.log(alleq)
      if (alleq) {
        console.log(statusArray[0])
        if (statusArray[0] == 'Deleted') {
          this.setState({
            actions: [
              { label: 'Approve', value: 'APPROVE' },
              { label: 'Reject', value: 'REJECT' },
            ],
          })
        } else if (statusArray[0] == 'Rejected') {
          this.setState({
            actions: [
              { label: 'Delete', value: 'DELETE' },
              { label: 'Approve', value: 'APPROVE' },
            ],
          })
        } else if (statusArray[0] == 'Approved' || statusArray[0] == 'Active') {
          this.setState({
            actions: [
              { label: 'Delete', value: 'DELETE' },
              { label: 'Reject', value: 'REJECT' },
            ],
          })
        }
      }
    } else if (!statusArray.length && ids.length) {
      this.setState({
        actions: [
          // { label: 'Delete', value: 'DELETE' },
        ],
      })

    }
    if (userStatusArray.length) {
      let alleq = allEqual(userStatusArray)
      console.log(alleq)
      if (alleq) {
        console.log(userStatusArray[0])
        if (userStatusArray[0] == 'Deleted') {
          this.setState({
            actions: [
            ],
          })
        } else {
          this.setState({
            actions: [
              { label: 'Delete', value: 'DELETE' },
            ],
          })
        }
      }
    }
  }

  //view modal
  openViewModal = async (rowData) => {
    let keys = Object.keys(rowData);
    let viewFields = this.props.viewFields ?
      await this.props.viewFields : [];
    if (viewFields) {
      // if (this.props.type == 'Schedule') {
      if (rowData) {
        let values;
        viewFields.forEach((item, index) => {
          if (item.name == 'created') {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          } else if (item.name == 'activatedDate' && rowData[item.name]) {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          } else {
            item.value = rowData[item.name]
          }
        })
      }
      // }
      await this.setState({
        openViewModal: true,
        selectedEntity: rowData
      });
      this.viewModalRef.getRowData(viewFields);
    }
  }
  
  viewInvoice = async (rowData) => {
    console.log("rowData",rowData); 
    let { getXeroInvoice, errorNotification } = this.props;
    if(!rowData.xero_invoice_id) {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = "Invoice is not created."
          errorNotification(notification)
        }) 
    } else {
     
      let data = {
        month : rowData.month,
        year : rowData.year,
        xero_invoice_id : rowData.xero_invoice_id
      }
      getXeroInvoice(data, async (res) => {  
        if (res && res.invoiceURL) {
          window.open(res.invoiceURL, '_blank');
        } else if(res && res.respMessage){
            setTimeout(() => {
              let notification = { ...notificationOpts }
              notification.message = res.respMessage
              errorNotification(notification)
            }) 
        }            
      })
    }
  }

  //for performing actions
  actionsFormatter = (cell, row) => {
    let { user, heading } = this.props
    let userId = user && user._id ? user._id : '';
    let rowId = row && row.createdBy && row.createdBy.user ? row.createdBy.user : ''
    let relatedMerchant = row && row.userId ? row.userId : ''
    console.log(row.merchantId, 'loginid', userId, 'createdd', rowId, 'userId', relatedMerchant)
    return (
      user.role === 'companyAdmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'groupAdmin' || user.role === 'brandAdmin'
      || user.role === 'brandUser' || user.role === 'groupUser'
        ?
        <React.Fragment>
          {heading != 'invoice' && 
            <>
              <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)}  >
                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
              </span>
              <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setShowEditModal(row)} >
                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
              </span>
            </>
          }
          {heading == 'branch' && (row.status == 'Deleted' || row.status == 'Pending' || row.status == 'Rejected') && row && row.createdBy && !row.createdBy.user ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Approve')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">done</Icon>
            </span> : ''}
          {heading == 'branch' && (row.status == 'Deleted' || row.status == 'Pending' || row.status == 'Active' || row.status == 'Approved') && row && row.createdBy && !row.createdBy.user ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Reject')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-danger">clear</Icon>
            </span> : ''}
          {heading == 'branch' ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.openViewModal(row)} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">visibility</Icon>
            </span>
            : ''}

          {heading == 'invoice' &&
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.viewInvoice(row)} >
           <Icon style={{ fontSize: "1.5rem" }} className="text-success" title="View Invoice">visibility</Icon>
            </span>
          }
        </React.Fragment>
        : '')
  }

  //for insert check box in table
  checkBoxFormatter = (cell, row) => {
    let { user, heading } = this.props
    console.log(row)
    return (
      <div className="input-group justify-content-center">
        <input type="checkbox" className="table_select_checkbox" onChange={this.handleCheckBox} value={JSON.stringify(row)} id={row._id} defaultChecked={false} />
      </div>)
  }

  hasEditPermission = () => {
    let { user } = this.props;
    // we may need to do this on resource type
    return user.role === 'companyAdmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'groupAdmin' || user.role === 'brandAdmin'
  }

  sessionExpired = () => {
    DeleteSessionValue('user');
    setTimeout(() => this.setState({ loginRedirect: true }), 1000)
  }

  onPageChange = async (page) => {
    this.pagination.page = page
    await this.fetchData('pageChanged')
    this.setState({ selectedArray: [] })
  }

  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  //when performing multi action , to select action
  changeAction = (selectedAction) => {
    this.setState({ selectedAction })
  }

  //when performing multi action , to select action
  changeStatus = async (selectedStatus) => {
    await this.setState({ selectedStatus })
    let status = selectedStatus.value
    this.handleTableChange('dropdown', { status })
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-2" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content-custom">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)} key={index}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )
  backAction = () => {
    this.props.backAction();
  }

  nextAction = () => {
    this.props.nextAction();
  }

  getBranchList = async () => {
    let userDetails = await GetSessionValue('loginCredentials')
    // let brands = userDetails ? userDetails.brandId : ""
    // if (brands)
    //   this.props.getBranchListByBrandId(brands, (res) => {
    //     console.log(res)
    //     if (res && res.length) {
    //       res.forEach((item, index) => {
    //         item.label = item.name
    //         item.value = item._id
    //       })
    //       this.setState({ branchList: res })
    //     }
    //   })

    let brandName = userDetails ? userDetails.brandName : "";
    const { entityType } = this.props;
    if (brandName && entityType != "user")
      this.props.getBranchListFromNode(brandName, (branchList) => {
        console.log(branchList)
        if (branchList && branchList.length) {
          const uniqueBranch = getUniqueArray(branchList, 'merchant_name');
          uniqueBranch.forEach((item, index) => {
            item.label = item.merchant_name;
            item.value = item.kmid;
            item.name = item.merchant_name;
          })
          this.setState({ branchList: uniqueBranch })
        }
      })
  }

  getBranchData = (value) => {
    // let filterCriteria = {
    //   'criteria': [
    //     {
    //       'key': 'name',
    //       'value': value,
    //       'type': 'regexOr',
    //     },
    //     { "key": "active", "value": null, "type": "eq" },
    //   ]
    // }
    // this.props.getBranchListByNameAction(filterCriteria, (res) => {
    //   console.log(res)
    //   if (res && res.length) {
    //     res.forEach((item, index) => {
    //       item.label = item.name
    //       item.value = item._id
    //     })
    //     this.setState({ branchList: res })
    //   }
    // })

    if (value)
      this.props.getBranchListFromNode(value, (branchList) => {
        console.log(branchList)
        if (branchList && branchList.length) {
          const uniqueBranch = getUniqueArray(branchList, 'merchant_name');
          uniqueBranch.forEach((item, index) => {
            item.label = item.merchant_name;
            item.value = item.kmid;
            item.name = item.merchant_name;
          })
          this.setState({ branchList: uniqueBranch })
        }
      })
  }

  render() {
    const { items, disableSelect, backAction, nextAction, totalCount, heading, entityType, icon, EntityForm, prefix = '', uploadCSV, EntityUploadForm = 'div', CommentsForm, isLoading } = this.props;
    const { approvalAction, columns, searchField, sessionExpired, loginRedirect, selectedArray, actions, status } = this.state
    let index = columns.findIndex(c => c.dataField === 'actions' && c.isDummyField === true)
    if (columns[index]) {
      columns[index].formatter = this.actionsFormatter
    }
    let checkBoxIndex = columns.findIndex(c => c.dataField === 'checkBox' && c.isDummyField === true)
    if (columns[checkBoxIndex]) {

      columns[checkBoxIndex].formatter = this.checkBoxFormatter
    }
    const { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page: page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      // custom:true,
      open: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : null}
        <Loader loader={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0 ">
                    <span>
                      <Icon className="heading-icon ">{icon}</Icon>
                    </span>
                    {heading ? heading == 'branch' ? 
                    `${capitalize(heading.toLowerCase())}es` : 
                    heading == 'ingestionLog' ? 'Ingestion Logs' :
                    `${capitalize(heading.toLowerCase())}s` : ''}
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 container-fluid pr-4">
                {this.props.globalSearchPlaceHolder && <div className="row">
                  <div className="col-md-4 offset-md-8 px-0 text-right col-sm-8 offset-sm-4 col-9 offset-3" style={{ height: 67 }}>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder={this.props.globalSearchPlaceHolder ? this.props.globalSearchPlaceHolder : ''} />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>{searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                  </div>
                </div>}
              </div>
              <div className="content pb-3">
                <div>
                  {
                    this.hasEditPermission()
                      ? (
                        <Row className="m-b-15">
                          <div className={heading == 'user' ? 'pl-4' : 'd-none'}>
                            <button className="btn btn-primary mb-3 table_action_btn" style={{ verticalAlign: 'bottom' }} onClick={this.setShowAddNewModal}>
                              Add New
                              <Icon className="ml-2 checkBoxAlignMent" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">add_circle_outline</Icon>
                            </button>
                          </div>
                          {/* <div className={uploadCSV ? '' : 'd-none'}>
                            <button className="btn btn-primary mb-3 ml-3 table_action_btn" style={{ verticalAlign: 'bottom' }} onClick={this.setShowUploadModal}>
                              Upload CSV
                              <span className="cursor-pointer align-middle ml-2" >
                                <Icon className="ml-2 uploadiconAlignMent" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">cloud_upload</Icon>
                              </span>
                            </button>
                          </div> */}
                          {heading && heading != 'invoice' && heading != 'user' && heading != 'ingestionLog' && !disableSelect ?
                            <div
                              // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                              className={selectedArray && selectedArray.length > 0 ? 'pl-4 selectStatusGo2' : 'pl-4 selectStatus2'}
                            >
                              <Select
                                options={actions}
                                onChange={this.changeAction}
                                value={this.state.selectedAction}
                                className='action_Selecter'
                              />
                            </div> : null}
                          <div className={selectedArray && selectedArray.length > 0 ?
                            'pl-1' : 'd-none'}>
                            <button className="btn btn-primary mb-2 table_action_btn" style={{ verticalAlign: 'bottom', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                              onClick={this.multiAction}
                              disabled={this.state.selectedAction ? false : true}
                            > Go</button>
                          </div>

                          <div className={
                            heading == 'branch' && !disableSelect && selectedArray && selectedArray.length ? 'ml-lg-auto ml-4 mr-4 selectStatusG0' : heading == 'branch' && !disableSelect
                              ? 'ml-lg-auto ml-4 mr-4 selectStatus' : 'd-none'} >
                            <Select
                              options={status}
                              onChange={this.changeStatus}
                              value={this.state.selectedStatus}
                            // className='action_Selecter'
                            />
                          </div>
                          <div className={
                            heading == 'branch' && disableSelect
                              ? 'ml-auto mr-4' : 'd-none'} >
                            <button className="btn btn-primary mb-3 table_action_btn mr-3 px-5" style={{ verticalAlign: 'bottom' }}
                              onClick={this.backAction}>
                              Back

                            </button>
                            <button className="btn btn-primary mb-3 table_action_btn px-5"
                              style={{ verticalAlign: 'bottom' }}
                              onClick={this.nextAction}>
                              Next

                            </button>
                          </div>
                        </Row>
                      ) : ''
                  }
                </div>
                {/* bootstrap table */}
                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={items}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}

                />
                {/* delete modal */}
                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.handleDeleteEntity}
                  isLoading={isLoading}
                  show={this.state.showDeleteModal}
                  title={<span>Delete {heading}</span>}
                  message={<span>Are you sure you want to delete the {heading} ?</span>}
                  action={"Delete"}
                  actionType={'danger'}
                />
                {/* edit modal */}
                <ModalComponent close={this.onCloseEditModal}
                  callback={this.submitForm}
                  show={this.state.showEditModal}
                  isLoading={isLoading}
                  title={`Edit ${heading ? `${capitalize(heading)}` : ''}`}
                  message={<EntityForm
                    initialValues={this.state.selectedEntity}
                    prefix={prefix}
                    onSubmit={this.onSubmitEdit}
                    formikRef={this.formikRef}
                    formType='edit'
                    branchList={this.state.branchList}
                  />}
                  action={"Save"}
                />
                {/* add modal */}
                <ModalComponent close={this.onCloseAddNewModal}
                  callback={this.submitForm}
                  show={this.state.showAddNewModal}
                  isLoading={isLoading}
                  title={`Add New ${heading ? `${capitalize(heading)}` : ''} `}
                  message={<EntityForm
                    formikRef={this.formikRef}
                    onSubmit={this.onSubmitAddNew}
                    prefix={prefix}
                    branchList={this.state.branchList}
                    getBranchData={this.getBranchData}
                  />}
                  action={"Add"}
                />
                {/* uplaod modal */}
                <ModalComponent close={this.onCloseUploadModal}
                  callback={this.submitForm}
                  show={this.state.showUploadModal}
                  title={`Upload CSV file for ${capitalize(heading)}`}
                  message={<EntityUploadForm formikRef={this.formikRef} onSubmit={this.onSubmitUpload} prefix={prefix} />}
                  action={"Upload"}
                  isDisabled={this.state.isSubmitDisabled}
                />
                {/* session expiry modal */}
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />
                {/* show merchant approval modall */}
                <ModalComponent close={this.onCloseApproveModal}
                  callback={this.submitForm}
                  show={this.state.showApproveModal}
                  title={`${approvalAction}`}
                  message={<CommentsForm
                    formikRef={this.formikRef}
                    onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Confirm"}
                  actionType={approvalAction == 'Approve' ? 'success' : 'danger'}
                />
                {/* show view modall */}
                <ModalComponent close={e => this.setState({ openViewModal: false })}
                  callback={this.onCloseViewModal}
                  show={this.state.openViewModal}
                  title={`Branch View`}
                  message={<ViewModal
                    onRef={(ref) => this.viewModalRef = (ref)}
                    // onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Edit"}
                  actionType={'primary'}
                  size='lg'
                  cancelText={'Close'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    user: state.user,
    items: state[pluralize(ownProps.heading)].items,
    sample: state[pluralize(ownProps.heading)],
    isLoading: state[pluralize(ownProps.heading)].isLoading,
    page: state[pluralize(ownProps.heading)].page,
    totalCount: state[pluralize(ownProps.heading)].totalCount,
    sizePerPage: state[pluralize(ownProps.heading)].sizePerPage,
    filters: state[pluralize(ownProps.heading)].filters,
  }),
  (dispatch, ownProps) => ({
    getEntityData: bindActionCreators(getEntityAction(ownProps.heading), dispatch),
    uploadCSVForEntity: bindActionCreators(uploadCSVForEntityAction, dispatch),
    createEntity: bindActionCreators(createEntityAction, dispatch),
    deleteEntity: bindActionCreators(deleteEntityAction, dispatch),
    editEntity: bindActionCreators(editEntityAction(ownProps.heading), dispatch),
    setEntityPage: bindActionCreators(setEntityPageAction(ownProps.heading), dispatch),
    setEntitySort: bindActionCreators(setEntitySortAction(ownProps.heading), dispatch),
    setEntitySearch: bindActionCreators(setEntitySearchAction(ownProps.heading), dispatch),
    setEntityFilters: bindActionCreators(setEntityFiltersAction(ownProps.heading), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    getDetails: bindActionCreators(getEntityDetails, dispatch),
    confirmMerchant: bindActionCreators(confirmMerchantAction, dispatch),
    multiAction: bindActionCreators(multiAction, dispatch),
    // getBranchListByBrandId: bindActionCreators(getBranchListByBrandIdAction, dispatch),
    createMultipleBranchAction: bindActionCreators(createMultipleBranchAction, dispatch),
    // getBranchListByNameAction: bindActionCreators(getBranchListByNameAction, dispatch)
    getBranchListFromNode: bindActionCreators(getBranchListFromNodeAction, dispatch),
    getXeroInvoice: bindActionCreators(getXeroInvoiceAction, dispatch),
  })
)(EntityList);
