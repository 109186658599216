import React, {Component} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';


class SummaryFormComponent extends Component {
  render() {
    let { status, isSubmitting, options={} } = this.props;
    let { isDisabled } = options
    return (
      <Form>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Total Cost</label>
              <Field name="totalCost" type="number" className="form-control" disabled={isSubmitting || isDisabled}/>
              <ErrorMessage name="totalCost" component="label" className="error"/>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Highest Spend</label>
              <Field name="highestSpend" type="number" className="form-control" disabled={isSubmitting || isDisabled}/>
              <ErrorMessage name="highestSpend" component="label" className="error"/>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Lowest Spend</label>
              <Field name="lowestSpend" type="number" className="form-control" disabled={isSubmitting || isDisabled}/>
              <ErrorMessage name="lowestSpend" component="label" className="error"/>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Average Spend</label>
              <Field name="averageSpend" type="number" className="form-control" disabled={isSubmitting || isDisabled}/>
              <ErrorMessage name="averageSpend" component="label" className="error"/>
            </div>
          </div>
        </div>
        {/*<div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>CPA</label>
              <Field name="cpa" type="number" className="form-control" disabled={isSubmitting || isDisabled}/>
              <ErrorMessage name="cpa" component="label" className="error"/>
            </div>
          </div>
        </div>*/}
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

export class SummaryForm extends Component {
  initialValues = {
    totalCost : '',
    highestSpend : '',
    lowestSpend : '',
    averageSpend : '',
  }
  validationSchema = YupObject({
    // reach : YupString()
    //         .required('Reach is required.'),
    // conversions : YupString()
    //         .required('Conversions is required.'),
    // revenue : YupString()
    //         .required('Revenue is required.'),
    totalCost : YupString()
            .required('Total Cost is required.'),
    // cpa : YupString()
    //         .required('CPA is required.'),
    highestSpend : YupString()
            .required('Highest Spend is required.'),
    lowestSpend : YupString()
            .required('Lowest Spend is required.'),
    averageSpend : YupString()
            .required('Average Spend is required.'),
  })
  noop = () => {}
  render() {
    let { initialValues, onSubmit, formikRef=this.noop, enableReinitialize, options } = this.props;
    return(
      <Formik
        initialValues={ initialValues || this.initialValues }
        children={ props => <SummaryFormComponent {...props} options={options} /> }
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}



