import React, { useState, useEffect } from "react";
import Select from "react-select";
import AdjustIcon from "@mui/icons-material/Adjust";
//import './topnavbar.css'
import {
  Container,
  TopLeft,
  BigContainer,
  ClassN,
  ReviewBar,
  TopRight,
} from "./topnavbar.styled";
import Grid from '@mui/material/Grid';
import { Switch } from "react-router-dom";
// import { getReviewDataApiCall } from "../../../services/getInsightsDataApiCall";
import { getFactorsDataAPICall } from "../../../services/getFactorsDataAPICall";
import { getBranchDataAPICall } from "../../../services/getBranchDataAPICall";
import { getScatterChartDataAPICall } from "../../../services/getScatterChartDataAPICall";
import { getTrendsDataAPICall } from "../../../services/getTrendsDataAPICall";
import { getSimilarityDataAPICall } from "../../../services/getSimilarityDataAPICall";
import { useDispatch, useSelector } from "react-redux";
import { SET_INSIGHT_DATE } from "../../../redux/insights/actionTypes";
import { insightsSelectors } from "../../../redux/insights";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Icon from '@material-ui/core/Icon';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import FilterInsightsModalComponent from './../../FilterInsightsModal';

// const Reviewers = [
//   { label: "occassional" },
//    {label: "regular" },
// ]
const year_list = [{ month: 'Jan', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/01/01', end: new Date().getFullYear() + '/01/31' },
{ month: 'Feb', value: '', from: '01', to: '28', start: new Date().getFullYear() + '/02/01', end: new Date().getFullYear() + '/02/28' },
{ month: 'March', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/03/01', end: new Date().getFullYear() + '/03/31' },
{ month: 'April', value: '', from: '01', to: '30', start: new Date().getFullYear() + '/04/01', end: new Date().getFullYear() + '/04/30' },
{ month: 'May', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/05/01', end: new Date().getFullYear() + '/05/31' },
{ month: 'June', value: '', from: '01', to: '30', start: new Date().getFullYear() + '/06/01', end: new Date().getFullYear() + '/06/30' },
{ month: 'July', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/07/01', end: new Date().getFullYear() + '/07/31' },
{ month: 'Aug', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/08/01', end: new Date().getFullYear() + '/08/31' },
{ month: 'Sep', value: '', from: '01', to: '30', start: new Date().getFullYear() + '/09/01', end: new Date().getFullYear() + '/09/30' },
{ month: 'Oct', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/10/01', end: new Date().getFullYear() + '/10/31' },
{ month: 'Nov', value: '', from: '01', to: '30', start: new Date().getFullYear() + '/11/01', end: new Date().getFullYear() + '/11/30' },
{ month: 'Dec', value: '', from: '01', to: '31', start: new Date().getFullYear() + '/12/01', end: new Date().getFullYear() + '/12/31' },];

const monthwise_list = {
  Jan: { month: 'Jan', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/01/01', end: new Date().getFullYear() + '/01/31' },
  Feb: { month: 'Feb', value: '', from: '1', to: '28', start: new Date().getFullYear() + '/02/01', end: new Date().getFullYear() + '/02/28' },
  March: { month: 'March', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/03/01', end: new Date().getFullYear() + '/03/31' },
  April: { month: 'April', value: '', from: '1', to: '30', start: new Date().getFullYear() + '/04/01', end: new Date().getFullYear() + '/04/30' },
  May: { month: 'May', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/05/01', end: new Date().getFullYear() + '/05/31' },
  June: { month: 'June', value: '', from: '1', to: '30', start: new Date().getFullYear() + '/06/01', end: new Date().getFullYear() + '/06/30' },
  July: { month: 'July', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/07/01', end: new Date().getFullYear() + '/07/31' },
  Aug: { month: 'Aug', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/08/01', end: new Date().getFullYear() + '/08/31' },
  Sep: { month: 'Sep', value: '', from: '1', to: '30', start: new Date().getFullYear() + '/09/01', end: new Date().getFullYear() + '/09/30' },
  Oct: { month: 'Oct', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/10/01', end: new Date().getFullYear() + '/10/31' },
  Nov: { month: 'Nov', value: '', from: '1', to: '30', start: new Date().getFullYear() + '/11/01', end: new Date().getFullYear() + '/11/30' },
  Dec: { month: 'Dec', value: '', from: '1', to: '31', start: new Date().getFullYear() + '/12/01', end: new Date().getFullYear() + '/12/31' }
};

const Topnavbar = (props) => {
  let monthIndex = new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1;
  const prevMonth = "{ label: " + year_list[monthIndex].month + "-" + new Date().getFullYear() + ", value: " + year_list[monthIndex].month + "-" + new Date().getFullYear() + " }";
  const [selectedDate, setSelectedDate] = useState({ label: "Last 12 months", value: 'Last-12months' });
  // const [selectedDate, setSelectedDate] = useState({ label: "Last 6 months", value: 'Last-6months' });
  const [allbranches, setAllbranches] = useState([{ kmid: "all", displayname: "All" }]);
  const dispatch = useDispatch();
  const insightsDates = useSelector(insightsSelectors.insightsDates);
  const branchesData = useSelector(insightsSelectors.getBranchesData) ? useSelector(insightsSelectors.getBranchesData) : [{ kmid: "all", displayname: "All" }];
  const { brandId } = useSelector(insightsSelectors.getUserdetailsState);
  const [selectedBranch, setSelectedBranch] = useState({ kmid: "all", displayname: "All" });
  // const [filterInsightsDialog, setFilterInsightsDialog] = useState({
  //   data: {
  //     across_brand: { same_cuisines: false, same_geo_location: false },
  //     allbranches: allbranches,
  //     selectedFilterType: "across_brands",
  //     selectCustomBrand : []
  //   },
  //   isOpen: false
  // });

  useEffect(() => {
    handlePeriodChange(selectedDate, "initial")

  }, []);
  useEffect(() => {
    if (branchesData.data.allbranches.length > 0) {
      let temp = branchesData.data.allbranches;
      temp.splice(0, 0, { kmid: "all", displayname: "All" });
      branchesData.data.allbranches = temp;
      // branchesData.data.allbranches.unshift({ kmid: "all", displayname: "All" });
      setAllbranches(JSON.parse(JSON.stringify(branchesData.data.allbranches)));
    }
    // &kmid=
  }, [branchesData && branchesData.data.allbranches]);

  const handlePeriodChange = (selVal, type) => {
    setSelectedDate(selVal);
    let key = selVal.value;
    let final_obj = {};
    let similar_dates = '';
    let trendFlagValue = false;
    let temp, prev_month, current_month;
    switch (key) {
      case 'Last-3months':
        {
          // let current_month = new Date().getMonth() - 1;
          temp = new Date(new Date().getTime() - (24 * 60 * 60 * 90 * 1000));   // 90 days
          prev_month = new Date(temp).getMonth();
          current_month = new Date().getMonth();
          final_obj = year_list[prev_month];
          final_obj.start = new Date(temp).getFullYear() + '/' + (prev_month + 1) + '/' + year_list[prev_month].from;
          final_obj.end = (new Date().getFullYear()) + '/' + (current_month) + '/' + year_list[current_month - 1].to;
          similar_dates = 'fromdate=' + new Date(temp).getFullYear() + '-' + (prev_month + 1) + '-' + year_list[prev_month].from + '&todate=' + (new Date().getFullYear()) + '-' + (current_month) + '-' + year_list[current_month - 1].to

          trendFlagValue = true;
        }
        break;
      case 'Last-6months':
        {
          temp = new Date(new Date().getTime() - (24 * 60 * 60 * 180 * 1000));   // 180 days
          prev_month = new Date(temp).getMonth();
          current_month = new Date().getMonth();
          // final_obj = year_list[prev_month - 1];
          final_obj = year_list[prev_month];
          final_obj.start = new Date(temp).getFullYear() + '/' + (prev_month + 1) + '/' + year_list[current_month].from;
          final_obj.end = new Date().getFullYear() + '/' + (current_month) + '/' + year_list[current_month - 1].to;
          similar_dates = 'fromdate=' + new Date(temp).getFullYear() + '-' + (prev_month + 1) + '-' + year_list[prev_month].from + '&todate=' + (new Date().getFullYear()) + '-' + (current_month) + '-' + year_list[current_month - 1].to

          trendFlagValue = true;
        }
        break;
      case 'Last-12months':
        {
          temp = new Date(new Date().getTime() - (24 * 60 * 60 * 360 * 1000));   // 360 days
          prev_month = new Date(temp).getMonth() + 1;
          current_month = new Date().getMonth() + 1;
          final_obj = year_list[prev_month - 1];
          final_obj.start = new Date(temp).getFullYear() + '/' + prev_month + '/' + year_list[current_month].from;
          final_obj.end = new Date().getFullYear() + '/' + (current_month) + '/' + year_list[current_month - 1].to;
          similar_dates = 'fromdate=' + new Date(temp).getFullYear() + '-' + prev_month + '-' + year_list[current_month].from + '&todate=' + new Date().getFullYear() + '-' + (current_month) + '-' + year_list[current_month - 1].to;

          trendFlagValue = true;
        }
        break;
      case 'Last-24months':
        {
          temp = new Date(new Date().getTime() - (24 * 60 * 60 * 720 * 1000));   // 360 days
          prev_month = new Date(temp).getMonth() + 1;
          current_month = new Date().getMonth();
          final_obj = year_list[prev_month - 1];
          final_obj.start = new Date(temp).getFullYear() + '/' + prev_month + '/' + year_list[current_month].from;
          final_obj.end = new Date().getFullYear() + '/' + (current_month) + '/' + year_list[current_month - 1].to;
          similar_dates = 'fromdate=' + new Date(temp).getFullYear() + '-' + prev_month + '-' + year_list[current_month].from + '&todate=' + new Date().getFullYear() + '-' + (current_month) + '-' + year_list[current_month - 1].to

          trendFlagValue = true;
        }
        break;
      default: {
        setSelectedDate(selVal);
        let val = selVal.value.split('-');
        final_obj = monthwise_list[val[0]];
        let temp_val_start = final_obj.start.split('/');
        let temp_val_end = final_obj.start.split('/');
        final_obj.start = val[1] + '/' + temp_val_start[1] + '/' + temp_val_start[2];
        final_obj.end = val[1] + '/' + temp_val_end[1] + '/' + final_obj.to;
        similar_dates = 'fromdate=' + val[1] + '-' + temp_val_start[1] + '-' + temp_val_start[2] + '&todate=' + val[1] + '-' + temp_val_end[1] + '-' + final_obj.to

        trendFlagValue = false;
      }
        break;
    }
    // final_obj.trendFlag = trendFlag;
    let final_payload = {
      $gt: new Date(final_obj.start).toISOString(),
      $lt: new Date(final_obj.end).toISOString(),
    }
    dispatch({
      type: SET_INSIGHT_DATE,
      payload: { data: final_payload, cuisine: insightsDates.cuisine, trendFlag: trendFlagValue, selectedKmid: selectedBranch.kmid, kmid: insightsDates.kmid, entityId: insightsDates.entityId, similarDates: similar_dates }
    });
    // useEffect(() => {
    // dispatch(getReviewDataApiCall(brandId, final_payload));
    // dispatch(getFactorsDataAPICall(brandId, final_payload));
    // dispatch(getFactorsDataAPICall(brandId, final_payload, ""));
    if (type != "initial") {
      dispatch(getScatterChartDataAPICall(brandId, final_payload, insightsDates.cuisine, insightsDates.kmid));   // Testing in progress
      if (insightsDates.entityId)
        dispatch(getSimilarityDataAPICall(similar_dates, insightsDates.entityId));
    }
    dispatch(getTrendsDataAPICall(brandId, final_payload, trendFlagValue, insightsDates.kmid, ""));
    dispatch(getBranchDataAPICall(brandId));
    dispatch(getFactorsDataAPICall(brandId, final_payload, ""));

    // }, []);
  };
  const getBranchValue = (ele, val) => {
    console.log(allbranches);
    setSelectedBranch(val);
    // {kmid: "AD", displayname: "Andorra"}
    dispatch({
      type: SET_INSIGHT_DATE,
      payload: { data: insightsDates.date, cuisine: insightsDates.cuisine, trendFlag: insightsDates.trendFlag, selectedKmid: val.kmid, kmid: val.kmid == 'all' ? '' : val.kmid, entityId: val.entityId, similarDates: insightsDates.similarDates }
    });
    dispatch(getScatterChartDataAPICall(brandId, insightsDates.date, insightsDates.cuisine, val.kmid));   // Testing in progress
    dispatch(getTrendsDataAPICall(brandId, insightsDates.date, insightsDates.trendFlag, val.kmid, ""));
    dispatch(getBranchDataAPICall(brandId));
    dispatch(getFactorsDataAPICall(brandId, insightsDates.date, val.kmid));
    if (insightsDates.entityId)
      dispatch(getSimilarityDataAPICall(insightsDates.similarDates, insightsDates.entityId));
  }

  // const filterAdditionalData = (data) => {
  //   setFilterInsightsDialog({ data: data, isOpen: false });
  // }

  // const openFilterInsightsModal = () => {
  //   let data = JSON.parse(JSON.stringify(filterInsightsDialog.data));
  //   if (data && (!data.allbranches || data.allbranches.length == 1)) {
  //     data.allbranches = (JSON.parse(JSON.stringify(allbranches)));
  //     setFilterInsightsDialog(data);
  //   }
  //   setFilterInsightsDialog({ data: data, isOpen: true });
  // }

  return (
    <BigContainer>
      {/* <Container> */}

      {/* {filterInsightsDialog.isOpen ?
        <FilterInsightsModalComponent
          close={() => {
            setFilterInsightsDialog({ data: filterInsightsDialog.data, isOpen: false })
          }}
          callback={filterAdditionalData}
          show={filterInsightsDialog.isOpen}
          actionType='danger'
          data={filterInsightsDialog.data}
        />
        : ""} */}

      <Grid container>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <TopLeft>
            <AdjustIcon
              style={{
                fontWeight: "500",
                fontSize: "35px",
                padding: "5px",
                marginTop: "10px",
                justifyContent: "center",
              }}
            />
            <h3>Social Analytics</h3>
          </TopLeft>
        </Grid>
        <Grid className="py-2 py-lg-2 py-sm-2" item xs={12} sm={9} md={9} lg={9}>
          <TopRight>
            {branchesData.data && branchesData.data.allbranches.length > 1 &&
              <div>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: 300, height: 50, paddingTop: '5px', marginRight: '10px' }}
                  // options={branchData ? branchData : []}
                  options={allbranches}
                  autoHighlight
                  getOptionLabel={(option) => option.displayname}
                  onChange={getBranchValue}
                  size='small'
                  variant="outlined"
                  value={selectedBranch}
                  renderOption={(props, option) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }} {...props}>
                      {option.displayname}
                      <br />
                      {option.address ? <small>
                        ({option.address})
                      </small> : ""}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select branch"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            }

            {/* {allbranches && allbranches.length ?
              <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => openFilterInsightsModal()}>
                <FilterAltOutlinedIcon style={{ fontSize: "2rem" }} className="text-info m-t-10" data-toggle="tool-tip" title="Edit">filter</FilterAltOutlinedIcon>
              </span>
              : ""} */}

            {/* <ReviewBar>
              <ClassN>
                <Select
                  cacheOptions
                  options={[
                    // { label: "March-2022", value: 'March-2022' },
                    { label: "March-2022", value: 'March-2022' },
                    { label: "Feb-2022", value: 'Feb-2022' },
                    // { label: "Jan-2022", value: 'Jan-2022' },
                    { label: "Last 3 months", value: 'Last-3months' },
                    { label: "Last 6 months", value: 'Last-6months' },
                    { label: "Last 12 months", value: 'Last-12months' },
                    { label: "Last 24 months", value: 'Last-24months' },
                  ]}
                  onInputChange={(value, e) => {
                    // if (e.action != "menu-close")
                    //   this.getBrand(value)
                  }}
                  value={selectedDate}
                  classNamePrefix="reactselect"
                  // onChange={handleFilterChange}
                  onChange={(e) => handlePeriodChange(e)}
                />
              </ClassN>
            </ReviewBar> */}
          </TopRight>
        </Grid>
      </Grid>

    </BigContainer>
  );
};
export default Topnavbar;

// <div className='bigcontainer'>
//       <div className='container'>
//         <div className='topleft'>
//         <AdjustIcon style={{
//            fontWeight:'500',
//            fontSize:'35px',
//            padding:'5px',
//            marginTop:'1px',
//            justifyContent:'center',
//                     }}/>
//           <h3>Insights</h3>
//         </div>
//         </div>

//         <div className='container'>
//         <div className='topright'>
//         <h4></h4>
//         <div className='reviewbar'>
//   <div className='nclass'>
//   <select class="classic">
//   <option>3 Months</option>
//   <option>6 Months</option>
//   <option>9 Months</option>
//   <option>12 Months</option>

// </select>
//   </div>
//  </div>
//         </div>
//         </div>
//     </div>
//   );
