import React, { useState, useEffect } from 'react';
import { getSimilarityDataAPICall } from "../../../services/getSimilarityDataAPICall";
import { insightsSelectors } from "../../../redux/insights";
import { useDispatch, useSelector } from "react-redux";
import { SET_INSIGHT_DATE } from "../../../redux/insights/actionTypes";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Radar } from 'react-chartjs-2';
import { Container } from "./../Charts/horizontalbar.styled";
import Card from "react-bootstrap/Card";
import FilterAdditionalData from './FilterAdditionalData';

const temp_chart_data = {
    objectwithjsd: {
        datasets: []
    }

};
const DistanceSimilarityComponent = () => {
    console.log("DistanceSimilarityComponent")
    const [showBranches, setShowBranches] = useState(true);
    const [chartsTotalData, setChartsTotalData] = useState(temp_chart_data);

    const dispatch = useDispatch();
    const branchesData = useSelector(insightsSelectors.getBranchesData) || [];
    const [selectedBranch, setSelectedBranch] = useState({});
    const radarDataState = useSelector(insightsSelectors.getSimilarityData);
    const insightsDates = useSelector(insightsSelectors.insightsDates);
    const loader = useSelector(insightsSelectors.getRadarChartloaderState);

    useEffect(() => {
        if (branchesData.data.allbranches.length > 0) {
            setSelectedBranch(branchesData.data.allbranches[1]);
            branchesData.data.allbranches.splice(0, 1);
            dispatch({
                type: SET_INSIGHT_DATE,
                payload: { data: insightsDates.date, cuisine: insightsDates.cuisine, trendFlag: insightsDates.trendFlag, selectedKmid: insightsDates.selectedKmid, kmid: insightsDates.kmid, entityId: branchesData.data.allbranches[0].entityId, similarDates: insightsDates.similar_dates }
            });
            dispatch(getSimilarityDataAPICall(insightsDates.similarDates, branchesData.data.allbranches[0].entityId));
        }
    }, [branchesData && branchesData.data.allbranches]);
    useEffect(() => {
        if (radarDataState && radarDataState.data.respCode == 200 && radarDataState.data != undefined) {
            if (!insightsDates.selectedKmid || insightsDates.selectedKmid === 'all') {
                setShowBranches(true);
            } else if (insightsDates.kmid !== 'all' && insightsDates.kmid !== '') {
                setShowBranches(false);
            }
            let data1 = radarDataState.data;
            var temp_chart_data1 = {
                objectwithjsd: {
                    datasets: [{
                        "label": "jsd",
                        "data": Object.keys(data1.objectwithjsd).map(res => data1.objectwithjsd[res]),
                        "fill": true,
                        "backgroundColor": "rgb(235, 166, 169, 0.2)",
                        "borderColor": "rgb(235, 166, 169)",
                        "pointBackgroundColor": "rgb(235, 166, 169)",
                        "pointBorderColor": "#fff",
                        "pointHoverBackgroundColor": "#fff",
                        "pointHoverBorderColor": "rgb(235, 166, 169)"
                    }],
                    labels: Object.keys(data1.objectwithjsd)
                }
            }
            setChartsTotalData(temp_chart_data1);
        }
    }, [radarDataState]);

    const getBranchValue = (ele, val) => {
        setSelectedBranch(val);
        dispatch({
            type: SET_INSIGHT_DATE,
            payload: { data: insightsDates.date, cuisine: insightsDates.cuisine, trendFlag: insightsDates.trendFlag, selectedKmid: insightsDates.selectedKmid, kmid: insightsDates.kmid, entityId: val.entityId, similarDates: insightsDates.similar_dates }
        });
        dispatch(getSimilarityDataAPICall(insightsDates.similarDates, val.entityId));
        if (!insightsDates.selectedKmid || insightsDates.selectedKmid === 'all') {
            setShowBranches(true);
        } else if (insightsDates.kmid !== 'all' && insightsDates.kmid !== '') {
            setShowBranches(false);
        }
    }

    return (
        <Container>
            <Card className="chartCard p-r-0">
                <Card.Body className="p-r-0">
                    <div className="row col-12 no-gutters">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="p-l-10">Most Similar Brands</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-right">
                            {/* && insightsDates.kmid != 'all' */}
                            
                                <div className="row col-12 no-gutters">
                                    
                                        <div className="row col-11 no-gutters m-m-t-15">
                                        {showBranches && branchesData.data && branchesData.data.allbranches.length > 1 &&
                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ width: 300, height: 40, paddingTop: '5px', marginRight: '10px' }}
                                                // options={branchData ? branchData : []}
                                                options={branchesData.data.allbranches}
                                                autoHighlight
                                                className='float-right'
                                                getOptionLabel={(option) => option.displayname}
                                                onChange={getBranchValue}
                                                size='small'
                                                variant="outlined"
                                                value={selectedBranch}
                                                renderOption={(props, option) => (
                                                    <div style={{ display: 'flex', flexDirection: 'column' }} {...props}>
                                                        {option.displayname}
                                                        <br />
                                                        <small>
                                                            ({option.address})
                                                        </small>

                                                    </div>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select branch"
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />
                                        }
                                        </div>
                                   
                                    {/* <div className="row col-1 no-gutters m-m-t-15">
                                        <FilterAdditionalData></FilterAdditionalData>
                                    </div> */}
                                </div>
                            
                        </div>

                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-r-0 pt-4">
                            {!loader ?
                                (radarDataState && radarDataState.data && chartsTotalData
                                    ?
                                    <div className='radar-example'>
                                        <div className='col-12 col-lg-12 col-md-12 col-sm-12 pb-3 no-gutters d-flex justify-content-center'>

                                            <Radar
                                                key={chartsTotalData.objectwithjsd}
                                                id="chart"
                                                className='radar-example'
                                                data={chartsTotalData.objectwithjsd}
                                                options={{
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltips: {
                                                        enabled: false
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    elements: {
                                                        line: {
                                                            borderWidth: 2
                                                        },
                                                    },
                                                }} />
                                        </div>
                                        <div className='pt-2'>
                                            Net Positive Reviews Similarity
                                        </div>
                                    </div>
                                    :
                                    <p className="pt-3 text-center" > No data available {chartsTotalData.objectwithjsd.datasets.length}</p>)
                                :
                                <div className="d-flex justify-content-center spinner-block">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>}
                        </div>

                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};


export default DistanceSimilarityComponent;

