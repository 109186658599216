

  import styled from "styled-components";

 export const BigContainer = styled.div`
 display:flex;
  justify-content:space-between;
 @media(max-width:800px){
 flex-direction:column;
 }
 `


 export const Container = styled.div`
 display:flex;
 align-items:center;
justify-content:space-evenly;
 padding:1px;
 position: sticky;
 top: 0;
 z-index: 999;
//background-color:skyblue;
 `
 export const TopLeft = styled.div`
 display:flex;
 justify-content: flex-start;
 & h3{
   padding:5px;
   margin-top:10px;
   justify-content:space-evenly;
 }
 `
 export const TopRight = styled.div`
 display:flex;
 justify-content: flex-end;
 @media(max-width:800px){
   flex-direction:column;
  }

 & h4{
   padding:5px;
   margin-top:10px;
  justify-content:space-evenly;
   font-size:15px;
 }
 `
// // export const SelectDate = styled.div`
// // justify-content: space-evenly;
// // padding: 5px;
// // margin-left: 8px;
// // & input{
// //   margin: .4rem 0;
// //   padding: 5px;
// //   width:13em;
// // }
// // `
  export const ReviewBar = styled.div`
 & select{
   /* styling */
background-color: white;
  display: inline-block;
   font: inherit;
   line-height: 1.5em;
   padding: 0.5em 3.5em 0.5em 1em;
 
   /* reset */

   margin-top: 10px;
   margin-right:5px;
   margin-left:8px;
   width:13em;      
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   -webkit-appearance: none;
   -moz-appearance: none;
 } 
` 
 
  export const ClassN = styled.div`
 & select.classic{
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
     linear-gradient(135deg, white 50%, transparent 50%),
     linear-gradient(to right, black, black);
   background-position:
     calc(100% - 20px) calc(1em + 2px),
     calc(100% - 15px) calc(1em + 2px),
     100% 0;
   background-size:
     5px 5px,
     5px 5px,
    3.5em 2.5em;
   background-repeat: no-repeat;
 }
  `
//   export const TopRight = styled.div`
//  display: flex;
//  align-items: center;
//  justify-content: space-evenly;
//  float:right;
//  `
//  export const TopLeft = styled.div`
//  display: flex;
//  align-items: center;
//  justify-content: space-evenly;
//  float:left

//  `
// // export const Title = styled.h2`
// // margin:;
// //     font-size: 10px;
// // `
 

// .bigcontainer{
//     box-sizing: border-box;
//     margin: 0;
//     background-color: aqua;
// }
// .container{
//     background-color: blueviolet;
// }
//  h4{
//     display: flex;
// justify-content: flex-start;
// background-color: bisque;
// }



 


 /* // export const Wrapper = styled.div`
// // height: 100%;
// //     padding: 0px 20px;
// //     display: flex;
// //     align-items: center;
// //     justify-content: space-between;
// // `
 export const TopRight = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-evenly;
 float:right;
 `
 export const TopLeft = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-evenly;
 float:left

 `
// // export const Period = styled.span`
// // // font-weight: bold;
// // font-size: 15px;
// // `
// // export const SelectDate = styled.div`
// // justify-content: space-evenly;
// // padding: 5px;
// // margin-left: 8px;
// // & input{
// //   margin: .4rem 0;
// //   padding: 5px;
// //   width:10em;
// // }

// // `

// // export const ReviewBar = styled.div`
// // position: relative;
// // display: block;
// // width: 18em;
// // padding: 2.5px;
// // margin-left: 2px;
// // justify-content: space-evenly;

// // & select{
// // outline-width: thick;
// // justify-content: space-evenly;
// // padding: 10px;
// // margin-left: 8px;
// // width:10em;

// // & option{
// //   padding:2px;
// //   color:#000;
// // }
// // }

// // `


// // export const TopbarContainer = styled.div`
// //     position: relative;
// //     cursor: pointer;
// //     margin-right: 10px;
// //     color: green;
// // `




//  */


 /* .topbar {
//     width: 100%;
//     height: 50px;
//     background-color: white;
//     position: sticky;
//     top: 0;
//     z-index: 999;
//   }
//   .topbarWrapper {
//     height: 100%;
//     padding: 0px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
  
//   .topLeft{
//     display: flex;
//     align-items: center;
//    justify-content: space-evenly;

//   }
  
//   .topRight{
//       display: flex;
//       align-items: center;
//   }
  
//   .topbarContainer {
//     position: relative;
//     cursor: pointer;
//     margin-right: 10px;
//     color: green;
//   }
  
//   /*the container must be positioned relative:*/
 /* .reviewbar {
//   position: relative;
//   display: block;
//   width: 12em;
//   padding: 5px;

// }
// .period{
//   border: 1px solid lightgray;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   padding: 5px;
//   margin-left: 15px;
// }
// .period DatePicker{
//   overflow-wrap: hidden;

// } */ 
