import React, { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import LoginPage from './LoginPage'
import SignupPage from './SignupPage'
import ForgotPassword from './ForgotPasswordpage'
import CreatePassword from './Createpassword'
import Notifications from 'react-notification-system-redux';
import CreateCampaign from '../CreateCampaignWithoutBranch/index';
import TrainComponent from '../../components/OnBorading/index'
import InsightsLoginPage from "../../components/insightsLogin/InsightsLoginPage";
import Reviews from '../../pages/InsightPageLayout'

class PublicPage extends Component {

  render() {
    let { notifications } = this.props
    return (
      <div className="container-fluid overflow-auto h-100 trainComponentBg">
        <Switch>
          <Redirect exact path='/' to='/login' />
          <Route path="/login" component={LoginPage} />
          <Route path="/guestuser/:user" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route exact path="/getinsights/6aacdxmr6" component={InsightsLoginPage} />
          <Route path={`/socialanalytics`} component={Reviews} />
          <Route path={`/insights/overview`} component={Reviews} />
          <Route path={`/insights/trends`} component={Reviews} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/changerecoverpassword/:enEmail" component={CreatePassword} />
          <Route path="/setpassword/:enEmail" component={CreatePassword} />
          {/* <Route path="/traincomponent" component={TrainComponent} /> */}
          {/* <Route exact path="/campaigns/create/guestUser/:user"
            render={(props) => <CreateCampaign  {...props} />} /> */}
          {/* <Route path="/guestUser/:user"
            component={CreateCampaign} /> */}
        </Switch>
        <Notifications
          notifications={notifications}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  user: state.user,
  notifications: state.notifications
});

export default connect(mapStateToProp)(PublicPage)

