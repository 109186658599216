import React, { useEffect, useState } from 'react';
import '../../assets/styles/invoice.scss'
import { useDispatch, useSelector } from 'react-redux'
// import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Modal as ModalComponent } from 'react-bootstrap';

export function TransactionList({ close }) {
    const dispatch = useDispatch()
    const invoiceState = useSelector(state => state.campaignOverview.invoiceList || {})
    let monthDetails = `${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}`
    const [isloading, setisloading] = useState(true);
    useEffect(() => {
        if (invoiceState.pdfData && (invoiceState.pdfData.detailedList.length > 0 || invoiceState.pdfData.detailedList.length == 0)) {
            setisloading(false)
        }

    }, [invoiceState]);
    useEffect(() => {
        setisloading(true)
        return () => {
            setisloading(false)
            dispatch({
                type: `SET_INVOICE_DATA`,
                payload: {}
            })
        }
    }, []);

    return (
        <>
            <ModalComponent show={true} onHide={close} size="lg" centered={true} >
                <ModalComponent.Header closeButton>
                    <ModalComponent.Title>Transaction Details - {monthDetails}</ModalComponent.Title>
                </ModalComponent.Header>
                <ModalComponent.Body>
                    <div style={{ height: "calc(100vh - 160px)", overflow: "auto", marginRight: "-5px", minHeight: "1px !important" }}>
                        <div className="invoice overflow-auto">
                            <table cellSpacing="0" cellPadding="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="text-left">DATE</th>
                                        <th class="text-center">POST CODE</th>
                                        <th class="text-center">CARD (LAST 4 DIGITS)</th>
                                        <th class="text-right">AMOUNT (£)</th>
                                        <th class="text-right">CASHBACK (£)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceState.pdfData && invoiceState.pdfData.detailedList.map((res, index) =>
                                    (
                                        <tr class="details">
                                            <td class="no">{index + 1}</td>
                                            <td class="text-left">{res.date} </td>
                                            <td class="postcode">{res.postcode} </td>
                                            <td class="text-center">{res.cardLastNumber} </td>
                                            <td class="qty"> {res.amount} </td>
                                            <td class="total"> {res.cashback} </td>
                                        </tr>
                                    )
                                    )}
                                    {invoiceState.pdfData && invoiceState.pdfData.detailedList.length == 0 &&
                                        <tr class="details">
                                            <th colSpan="6" class="text-center">No Record Found</th>
                                        </tr>
                                    }
                                    {isloading &&
                                        <tr class="details">
                                            <th colSpan="6" class="text-center">Loading...</th>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalComponent.Body>
            </ModalComponent>
        </>
    )
}