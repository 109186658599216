import axiosMethodRequest from '../config/service';
import { GET_REVIEW_DATA, GET_REVIEW_FACTRS_DATA, GET_SCATTER_DATA } from '../redux/insights/actionTypes'
export const getFactorsDataAPICall = (brandId, datePayload, branchId) => {
    return dispatch => {
        console.log('brandId', brandId, datePayload, branchId);
        branchId = branchId == 'all' ? '' : branchId;
        dispatch({
            type: GET_REVIEW_FACTRS_DATA,
            payload: { data:null, loader: true }
        })
        dispatch({
            type: GET_SCATTER_DATA,
            payload: { data:null, loader: true }
        })
        const url = `reviewsdata/factorsAffectingars?brandId=${brandId}&datePayload=${JSON.stringify(datePayload)}&kmid=${branchId}`
        return axiosMethodRequest('GET', url).then((response) => {
            console.log('GET_REVIEW_FACTRS_DATA response', response)
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_REVIEW_FACTRS_DATA,
                    // type: GET_REVIEW_DATA,
                    //payload: { data: response.data.insightsDetails, loader: false }
                    payload: { data: response.data.response, loader: false }

                })
            }
        })
    }
}
