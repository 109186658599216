import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uploadDocsAction, triggerCOCreateAction } from '../../redux/actions'
import { TxUploadForm, CampaignOverviewManagerForm } from '../../components/Forms/CampaignOverviewManagerForm'

import { 
  success as successNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'

class CampaignOverviewManager extends Component {
  onTxSubmit = (values, actions) => {
    let { uploadDocs, successNotification } = this.props;
    uploadDocs(values, err => {
      actions.setSubmitting(false)
      if (err) {
        actions.setStatus(err.response.data.error)
        return;  
      }
      setTimeout(() => {
        let notification = {...notificationOpts}
        notification.message = `Docs uploaded successfully`
        successNotification(notification)
      })
      // console.log(this.formik)
      this.txFormik.handleReset()
      this.setState({isDisabled : true})
    })
  }
  onCOSubmit = (values, actions) => {
    let { triggerCOCreate, successNotification } = this.props;
    triggerCOCreate(values, err => {
      actions.setSubmitting(false)
      if (err) {
        actions.setStatus(err.data.error)
        return;  
      }
      setTimeout(() => {
        let notification = {...notificationOpts}
        notification.message = `Campaign overview create triggered`
        actions.setStatus('')
        successNotification(notification)
      })
      this.setState({isDisabled : true})
    })
  }
  
  txFormikRef = (e) => {
    this.txFormik = e
  }
  coFormikRef = (e) => {
    this.coFormik = e
  }
  txFormikSubmit = () => {
    if(this.txFormik)
      this.txFormik.submitForm()
  }
  coFormikSubmit = () => {
    if(this.coFormik)
      this.coFormik.submitForm()
  }
  render() {
    return (
      <div>
        <div className="row create-header">
          <h3 className="col-md-6 offset-3 text-center mb-5 mt-5">
            Campaign Overview Manager
          </h3>
        </div>
        <div className="row">
          <div className="col-6 offset-3">
            <div className="card">
              <div className="content">
                <CampaignOverviewManagerForm
                  onSubmit={this.onCOSubmit}
                  formikRef={this.coFormikRef}
                />
                <div className="form-group text-center">
                  <button className="btn btn-info btn-fill btn-wd" onClick={this.coFormikSubmit}>Trigger CO Create</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 offset-3">
            <div className="card">
              <div className="content">
                <TxUploadForm
                  onSubmit={this.onTxSubmit}
                  formikRef={this.txFormikRef}
                />
                <div className="form-group text-center">
                  <button className="btn btn-info btn-fill btn-wd" onClick={this.txFormikSubmit}>Upload Docs</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default connect(
  null,
  dispatch => ({
    uploadDocs : bindActionCreators(uploadDocsAction, dispatch),
    triggerCOCreate : bindActionCreators(triggerCOCreateAction, dispatch),
    successNotification : bindActionCreators(successNotificationAction, dispatch),
  })
)(CampaignOverviewManager);




