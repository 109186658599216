import React, { Component } from 'react'
import capitalize from 'lodash/capitalize';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber, addMethod as YupAddMethod } from 'yup';
import { number } from 'prop-types';
import configMessages from './../../config/configMessages'
import { contactNameRegex, postcodeRegex, phoneRegex } from '../../utils/constants/constant';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { GetSessionValue } from '../../utils/sessionStorage';

export class MerchantForm extends Component {
  initialValues = {
    'block': '',
    'address': '',
    'contactName': '',
    'contactPhone': '',
    'contactEmail': '',
    'merchantId': '',
    'amexMerchantID': '',
    'zettleMerchantID': '',
    'name': '',
    'tripadvisorUrl': '',
    "postcode": "",
    isNew: false
  }

  validationSchema = YupObject({
    address: YupString(),
    tripadvisorUrl: YupString(),
    contactName: YupString()
      .matches(contactNameRegex, `Invalid Contact Name`)
      .min(3, `Contact ${configMessages.minName}`)
      .max(100, `Contact ${configMessages.maxName}`),
    // contactPhone: YupNumber()
    //   .required('Contact Number is required')
    //   .typeError("It doesn't contain alphabets")
    //   .positive("Contact Number can't start with a minus"),
    contactPhone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`),
    // .required('Contact Number is required'),
    // .typeError("It doesn't contain alphabets")
    // .positive("Contact Number can't start with a minus"),
    // .min(12, `Contact Number should contain 12 digits`)
    contactEmail: YupString()
      // .required('Email Id is required')
      .email(configMessages.validEmail),
    // merchantId: YupString()
    //   .required(configMessages.branchId),
    // amexMerchantID: YupString()
    //   .required(configMessages.amexMerchantID),
    merchantId: YupString(),
    amexMerchantID: YupString(),
    zettleMerchantID: YupString(),
    name: YupString()
      .required(`Branch ${configMessages.name}`)
      .min(3, `Branch ${configMessages.minName}`)
      .max(100, `Branch ${configMessages.maxName}`),
    postcode: YupString()
      .matches(postcodeRegex, `Invalid Postcode`)
      .required(configMessages.postCode)
  })

  getForm = (props, options = {}, getBranchData, branchList) => {
    let branchList1 = []
    branchList.map(item => {
      if (item.name)
        branchList1.push(item)
    })
    let { status, isSubmitting, values, setFieldValue, selectedBranch } = props;
    let { formType } = options
    console.log('props', props);
    if (props.values && !props.values.contactPhone) {
      props.values.contactPhone = ''
    }
    return (
      <Form>
        <div className="form-group">
          <label>Branch Name </label>
          {/* <Field name="name" className="form-control" value={capitalize(values.name)}
            disabled={isSubmitting} maxLength='30' /> */}
          {/* <Select
            defaultValue={{
              label: props.values.name
            }}
            options={branchList}
            onChange={value => {
              setFieldValue('name', value.name)
              setFieldValue("address", value.address || "")
              setFieldValue("contactEmail", value.email || "")
              setFieldValue("contactPhone", value.phone_no ? value.phone_no.replace(/ /g, "") : "" || "")
              setFieldValue("tripadvisorUrl", "https://www.tripadvisor.com" + value.entity_url || "")
              this.setState({ selectedBranch: value })
            }}
            value={selectedBranch}
          /> */}
          <CreatableSelect
            defaultValue={{
              value: props.values.kmid,
              label: props.values.name
            }}
            value={selectedBranch}
            isDisabled={formType && props.values.kmid}
            key={`branchselect`}
            name="branch"
            options={branchList1}
            className="basic-multi-select"
            classNamePrefix="select"
            onInputChange={(value, actionMeta) => {
              if (actionMeta.action == "input-change") {
                if (value.length >= 3)
                  getBranchData(value, "name")
              }
            }}
            onChange={(value, data) => {
              if (value.__isNew__) {
                let brandId, brandName;
                let userDetails = GetSessionValue('loginCredentials')
                brandId = userDetails ? userDetails.brandId : ""
                brandName = userDetails ? userDetails.brandName : ""

                // let object = {
                //     address: "",
                //     brandId: brandId,
                //     brand_id: brandId,
                //     brandName: brandName,
                //     created_by: "ui",
                //     created_date: new Date(),
                //     datasource: "ui",
                //     email: "",
                //     entity_id: "",
                //     entity_url: "",
                //     latlong: "",
                //     logo_url: "",
                //     merchant_name: "",
                //     name: value.value,
                //     phone_no: "",
                //     website_url: "",
                //     isNew: true
                //     // brandObjId: brandDetails.brandDetails.details.brands._id
                // }
                this.setState({ selectedBranch: value })
                setFieldValue('name', value.value)
                setFieldValue('kmid', "")
                setFieldValue("address", "")
                setFieldValue("contactEmail", "")
                setFieldValue("contactPhone", "")
                setFieldValue("tripadvisorUrl", "")
                setFieldValue("isNew", true)
                // const list = [...selectedBranchList]
                // list.push(object)
                // setSelectedBranchList(list)
              } else {
                // let items = data.option.item
                // items.tripadvisorUrl = items.entity_url;
                // items.amexMerchantID = items.amexMerchantID || "";
                // items.merchantId = items.merchantId || "";
                // items.contactEmail = items.email || "";
                // items.contactPhone = items.phone_no ? items.phone_no.replace(/ /g, "") : "";
                // items.brandName = items.brandname;
                // items.brandId = items.brand_id;
                // let data1 = [...selectedBranchList]
                // data1.push(items)
                // setSelectedBranchList([...data1])
                setFieldValue('name', value.name)
                setFieldValue('kmid', value.kmid)
                setFieldValue("address", value.address || "")
                setFieldValue("contactEmail", value.email || "")
                setFieldValue("contactPhone", value.phone_no ? value.phone_no.replace(/ /g, "") : "" || "")
                setFieldValue("tripadvisorUrl", "https://www.tripadvisor.com" + value.entity_url || "")
                this.setState({ selectedBranch: value })
              }
            }}
          />
          <ErrorMessage name="name" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Visa/MasterCard Merchant ID </label>
          <Field name="merchantId" className="form-control"
          // disabled={isSubmitting || (formType && formType == 'edit')}
          />
          <ErrorMessage name="merchantId" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Amex Merchant ID </label>
          <Field name="amexMerchantID" className="form-control"
          // disabled={isSubmitting || (formType && formType == 'edit')}
          />
          <ErrorMessage name="amexMerchantID" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Zettle/square, etc. Merchant ID </label>
          <Field name="zettleMerchantID" className="form-control"
          // disabled={isSubmitting || (formType && formType == 'edit')}
          />
          <ErrorMessage name="zettleMerchantID" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Tripadvisor Link</label>
          <Field name="tripadvisorUrl" className="form-control" />
          <ErrorMessage name="tripadvisorUrl" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Name</label>
          <Field name="contactName" value={capitalize(values.contactName)} className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contactName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Number</label>
          <Field name="contactPhone"
            // value={values && values.contactPhone &&
            //   (values.contactPhone.toString().length >= 0 && values.contactPhone.toString().length <= 12) && values.contactPhone >= -1
            //   ? values.contactPhone : values.contactPhone <= -1 ? '' : values.contactPhone.toString().substr(0, 12)}
            className="form-control"
            // minLength='12'
            // maxLength='12'
            // pattern="[0-9]"
            type='text'
            disabled={isSubmitting} />
          <ErrorMessage name="contactPhone" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Email</label>
          <Field name="contactEmail" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contactEmail" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Address</label>
          <Field name="address" component='textarea' className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="address" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Post Code</label>
          <Field name="postcode" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="postcode" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, formType, getBranchData, branchList } = this.props;
    let defaultValues;
    if (!initialValues) {
      defaultValues = { ...this.initialValues }
    } else {
      defaultValues = { ...initialValues, password: '', confirmPassword: '' }
    }
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, { formType }, getBranchData, branchList)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}

class Branch extends Component {
  render() {
    return <div>dflkdf</div>
  }
}

export default Branch





