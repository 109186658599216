import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notifyAdminForAmexBranchAction } from '../../redux/actions/authActions';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import AmexContactUsModalComponent from './AmexContactUsModal';
import KrowdLogo from '../../assets/images/k_logo-1.png';
import AMEXLogo from '../../assets/images/american_Express_logo.png';
import AmexDiningBG from '../../assets/images/amex-dining.png';
import configMessages from './../../config/configMessages';
import TalkToUsImg from '../../assets/images/talk-bubble-02.png';
import Icon from '@material-ui/core/Icon';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


const imgStyle = {
    'display': 'block',
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const AMEXImgStyle = {
    'display': 'block',
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const TalkToUsStyle = {
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const AmexDiningBGImgStyle = {
    'width': "100%",
}


class AmexDiningBranch extends Component {

    initialValues = {
        brandName: '',
        legalName: '',
        email: '',
        phone: ''
    }

    state = {
        branchList: [{
            branchName: "",
            merchantId: "",
            amexMerchantID: "",
            zettleMerchantID: "",
            postcode: "",
            postaltown: "",
            address: ""
        }],
        isSubmitting: false,
        showBranchDetail: true,
        selectedFile: {}
    }

    validationSchema = YupObject({
        brandName: YupString()
            .required('Brand Name is required'),
        legalName: YupString()
            .required('Legal Name is required'),
        email: YupString()
            .required(configMessages.email)
            .email(configMessages.validEmail)
    })

    showDocumentUpload = () => {
        this.setState({ showBranchDetail: false });
    }

    onSubmit = () => {
        let { notifyAdminForAmexBranch, errorNotification, successNotification } = this.props;
        // let body = {
        //     branchList: this.state.branchList,
        //     id: this.props.match.params.id,
        //     comments: this.state.comments
        // }

        var formdata = new FormData();
        formdata.append('branchList', JSON.stringify(this.state.branchList));
        formdata.append('id', this.props.match.params.id);
        formdata.append('comments', this.state.comments);
        formdata.append('excelFile', this.state.selectedFile);

        this.setState({ isSubmitting: true });
        notifyAdminForAmexBranch(formdata, (res, type) => {
            if (type == "error") {
                let notification = { ...notificationOpts }
                notification.message = res;
                errorNotification(notification);
                this.setState({ isSubmitting: false });
            } else {
                let notification = { ...notificationOpts }
                notification.message = "Thanks for sharing your details. Your account is now being validated. Please check your email for the next steps."
                successNotification(notification)
                setTimeout(() => {
                    window.open("https://krowd.info", "_self");
                }, 3000);
            }
        })
    }

    clearFile = () => {
        this.setState({ selectedFile: {} });
    }

    handleFileChange = (e) => {
        if (e && e.target && e.target.files) {
            this.setState({ selectedFile: e.target.files[0] });
        }
    }

    openLink = (url) => {
        window.open(url, "_blank");
    }

    addRow = () => {
        let branches = JSON.parse(JSON.stringify(this.state.branchList));
        branches.push({
            branchName: "",
            merchantId: "",
            amexMerchantID: "",
            zettleMerchantID: "",
            postcode: "",
            postaltown: "",
            address: ""
        });
        this.setState({ branchList: branches });
    }

    deleteRow = (index) => {
        let branches = JSON.parse(JSON.stringify(this.state.branchList));
        branches.splice(index, 1)
        this.setState({ branchList: branches });
    }

    handleInputChangeNew = (e, index, filed) => {
        const { value } = e.target;
        let branches = JSON.parse(JSON.stringify(this.state.branchList));
        let data2 = { ...branches[index], [filed]: value };
        branches[index] = data2;
        this.setState({ branchList: branches });
    };

    render() {

        return (


            <div className="row justify-content-center amex-dining-contain">

                <div className="logos-contain">
                    <div className="krowd-logo-container-style">
                        <img src={KrowdLogo} onClick={(e) => { this.openLink("https://krowdit.com/") }} style={imgStyle} alt="krowd-logo" />
                    </div>
                    <div className="amex-logo-container-style">
                        <img src={AMEXLogo} onClick={(e) => { this.openLink("https://amexdining.com/") }} style={AMEXImgStyle} alt="amex-logo" />
                    </div>
                </div>

                <div className="talk-to-us-area">
                    <img src={TalkToUsImg} onClick={(e) => { this.openLink("https://calendly.com/stacey-krowd/30min") }} style={TalkToUsStyle} alt="amex-dining" />
                </div>

                <div className="row col-12 justify-content-center bg-1-amex bg-1-amex-signup">
                    <div className="row col-xl-6 col-lg-6 col-md-6 col-sm-12 form amex-signup-1">
                        <div className="col-12">
                            <div className="card rm-ex-p-m">
                                <div className="header">
                                    <div className="logo-container-style">
                                        <h3 className="text-center">Sign Up</h3>
                                        <h5 className="customer-am-title-signup"> Customer Acquisition Marketplace</h5>
                                    </div>

                                </div>
                                <div className="content form-box">

                                    {this.state.showBranchDetail &&
                                        <>
                                            <div className="row col-12 rm-ex-p-m">

                                                <div className="col-11">
                                                    Is there any other information you wish to share ?
                                                </div>
                                                <div className="col-1 justify-content-end">
                                                    <span className="future-action c-fff" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.addRow()} >
                                                        <Icon style={{ fontSize: "2rem" }} data-toggle="tool-tip" title="Add Branch">add_circle</Icon>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row col-12 rm-ex-p-m">

                                                <table className="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <td className="amex-branch-label"> Branch Name </td>
                                                            <td className="amex-branch-label"> Merchant ID </td>
                                                            <td className="amex-branch-label"> Address Details </td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.branchList.map((row, index) => {
                                                            return (
                                                                <tr key={index + "branches"}>
                                                                    <td>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0 m-t-25">
                                                                            <div className="form-group">
                                                                                {/* <label className="amex-branch-label">Branch Name</label> */}
                                                                                <input type="text" className="form-control"
                                                                                    value={row.branchName}
                                                                                    name={`${index}branchName`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'branchName')}
                                                                                    id={`${index}branchName`} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Visa/MasterCard MID</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.merchantId}
                                                                                    name={`${index}merchantId`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'merchantId')}
                                                                                    id={`${index}merchantId`} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Amex Merchant ID</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.amexMerchantID}
                                                                                    name={`${index}amexMerchantID`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'amexMerchantID')}
                                                                                    id={`${index}amexMerchantID`} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Zettle/square, etc. MID</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.zettleMerchantID}
                                                                                    name={`${index}zettleMerchantID`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'zettleMerchantID')}
                                                                                    id={`${index}zettleMerchantID`} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Post Code</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.postcode}
                                                                                    name={`${index}postcode`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'postcode')}
                                                                                    id={`${index}postcode`} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Postal Town</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.postaltown}
                                                                                    name={`${index}postaltown`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'postaltown')}
                                                                                    id={`${index}postaltown`} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-l-r-0">
                                                                            <div className="form-group">
                                                                                <label className="amex-branch-label">Address</label>
                                                                                <input type="text" className="form-control"
                                                                                    value={row.address}
                                                                                    name={`${index}address`}
                                                                                    onChange={e => this.handleInputChangeNew(e, index, 'address')}
                                                                                    id={`${index}address`} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="future-action c-fff" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.deleteRow(index)}   >
                                                                            <Icon style={{ fontSize: "2rem" }} data-toggle="tool-tip" title="Delete">delete</Icon>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>


                                                {/* <div className="col-12">
                                                    <div className="form-group text-center">
                                                        <button className="btn btn-info btn-fill btn-wd px-5" type="submit" disabled={this.state.isSubmitting} onClick={() => this.showDocumentUpload()} >Continue</button>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </>
                                    }

                                    {this.state.showBranchDetail &&
                                        <>
                                            <div className="row col-12">
                                                <div className="col-12 m-t-5 m-b-5 f-s-11">
                                                    Upload any relevant file using the button below or use the comments section to share additional details
                                                </div>

                                                <div className="col-3">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-primary mb-3">
                                                            Upload
                                                            <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                                                        </button>
                                                        <input
                                                            name="amex-dining-upload"
                                                            id="amex-dining-upload"
                                                            accept=".xls,.xlsx"
                                                            type='file'
                                                            onClick={() => { this.clearFile() }}
                                                            onChange={(event) => { this.handleFileChange(event) }} />
                                                    </div>
                                                </div>

                                                <div className="col-9">
                                                    <div className="form-group">
                                                        {/* <label className="amex-branch-label">Comments</label> */}
                                                        <input type="text" className="form-control"
                                                            value={this.state.comments}
                                                            name="comments"
                                                            placeholder="Comments"
                                                            onChange={e => { this.setState({ comments: e.target.value }); }}
                                                            id="comments" />
                                                    </div>
                                                </div>

                                                <div className="col-12 file-name">
                                                    {this.state.selectedFile && this.state.selectedFile.name &&
                                                        <>
                                                            <div className='file-label'> <span>{this.state.selectedFile.name}</span> </div>
                                                            <div className='file-delete' onClick={() => { this.clearFile() }} >
                                                                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                <div className="col-12 m-t-5">
                                                    <div className="form-group text-center">
                                                        <button className="btn btn-info btn-fill btn-wd px-5" type="submit" disabled={this.state.isSubmitting} onClick={() => this.onSubmit()} >Submit</button>
                                                    </div>
                                                </div>


                                            </div>
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* <div className="row col-12 m-t-10">
                    <div className="border-1"> </div>
                    <div className="border-2"> </div>
                </div> */}
                {/* <div className="row col-12 justify-content-center amex-dining-bg">
                    <img src={AmexDiningBG} style={AmexDiningBGImgStyle} alt="amex-dining" />
                </div> */}


            </div>

        );
    }
}

export default connect(
    null,
    dispatch => ({
        notifyAdminForAmexBranch: bindActionCreators(notifyAdminForAmexBranchAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        errorNotification: bindActionCreators(errorNotificationAction, dispatch)
    })
)(AmexDiningBranch);

