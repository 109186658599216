import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction, loaderAction, loginActionForTrainComponent, languageAction, getLanguageDataAction } from '../../redux/actions/authActions'
import { Link } from 'react-router-dom'
import Login from '../../components/Login/Login.component'
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
import Loader from '../../components/Loader';
import { DeleteSessionValue } from '../../utils/sessionStorage';
//device info
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceDetect,
  browserName,
  osName, osVersion, mobileModel, deviceType, getUA
} from "react-device-detect";
const imgStyle = {
  'display': 'block',
  'height': "6rem",
  'textAlign': "center",
  'margin': 'auto',
  'marginTop': '2em',
  marginBottom: '1em'
}
const logoContainerStyle = {
  marginBottom: '2em',
  textAlign: 'center'
}
const publicIp = require('public-ip');
const macaddress = require('macaddress');
class LoginPage extends Component {
  state = {
    loader: false,
    deviceInfo: {},
    paramsExist: true,
    errorExist: false,
    errorMsgText: '',
  }
  componentDidMount = async () => {
    // await sessionStorage.removeItem('user')
    // await sessionStorage.removeItem('sessionExpired')
    await DeleteSessionValue('user');
    await DeleteSessionValue('sessionExpired');
    // this.props.getLanguageData((res) => {

    // })
    const query = new URLSearchParams(this.props.location.search);
    const pageType = query.get('type');
    const userFrom = query.get('user');
    if (this.props.match.params.user) {
      this.setState({ paramsExist: true })

      this.props.loginTrain((res, type) => {
        if (type === 'success') {
          let { history } = this.props;
          if (pageType == "campaign") {
            if(userFrom == "amex") {
              sessionStorage.setItem('userFrom', "amex");
            }
            history.push(`user/campaigns/create`);
          } else {
            if (res.stepOne || res.isTAndCAccepted) {
              history.push(`user/campaigns/create`)
            } else {
              history.push(`/socialanalytics`)
            }
          }
          this.setState({ errorExist: false, errorMsgText: '' })
        } else if (type === 'error') {
          this.setState({ errorExist: true, errorMsgText: res })

        }
        // this.props.language((res,type)=>{

        // })
        this.setState({ loader: false })
        if (type == 'error') {
          return
        }

        // this.props.history.push('/')  
      }, this.props.match.params.user)
    } else {
      this.setState({ paramsExist: false })
    }
    // sessionStorage.clear()

    let deviceInfo = {}
    publicIp.v4().then((res) => {
      deviceInfo.ipAddress = res
    })
    // macaddress.all().then(async function (all) {
    //  await console.log(JSON.stringify(all, null, 2));
    // });
    deviceInfo.browserName = browserName
    deviceInfo.osName = osName
    deviceInfo.osVersion = osVersion
    deviceInfo.deviceType = deviceType
    this.setState({ deviceInfo: deviceInfo })
    await macaddress.all().then((res) => {
    })

    // macaddress.one(function (err, mac) {
    //   console.log("Mac address for this host: %s", mac);  
    // });
  }
  onSubmit = (values, actions) => {
    let deviceInfo = this.state.deviceInfo
    values.deviceInfo = deviceInfo
    this.setState({ loader: true })
    this.props.login(values, true, (res, type) => {
      this.props.getLanguageData((res) => {

      })
      this.setState({ loader: false })
      if (type == 'error') {
        actions.setSubmitting(false)
        let errorMessage = res
        actions.setStatus(errorMessage)
        return
      }

      // this.props.history.push('/')  
    })
  }

  render() {
    const { paramsExist } = this.state
    return (
      <div>
        {
          this.state.errorExist ?
            <div className='row errorTextDiv' >
              <div className='col-sm-12 text-center'>
                <h4>{this.state.errorMsgText}</h4>
              </div>
            </div>
            : null}
        <Loader loader={this.state.loader} />
        {!paramsExist && <div style={logoContainerStyle}>
          <a href='https://krowd.info' target='_blank'>
            <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          </a>
          <h5> Customer Acquisition Marketplace</h5>
        </div>}
        {!paramsExist && <div className="row form justify-content-center">
          <div className="col-md-4 col-lg-3" >
            <div className="card">
              <div className="header">
                <h3 className="text-center">Login</h3>
              </div>
              <div className="content">
                <Login onSubmit={this.onSubmit} />
              </div>
            </div>
            {/* <div className="text-center mb-4">
              Don't have an account ? <Link to='/signup' className="text-primary">Signup</Link>
            </div> */}
          </div>
        </div>}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginTrain: bindActionCreators(loginActionForTrainComponent, dispatch),
    login: bindActionCreators(loginAction, dispatch),
    loader: bindActionCreators(loaderAction, dispatch),
    language: bindActionCreators(languageAction, dispatch),
    getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(LoginPage);


