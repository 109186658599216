import React, { Component } from 'react';
import { connect } from 'react-redux';
import placeHolderImg from '../../assets/images/placeholder150.png'
import krowdLogo from '../../assets/images/Krowd_logo.jpg'
import mastercardLogo from '../../assets/images/mastercard-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config/config'
// import { Formik } from 'formik'
// import {  DropZone, } from 'react-formik-ui'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { GetSessionValue } from '../../utils/sessionStorage';
const profileImg = {
  display: 'flex',
  justifyContent: 'center',
}
const profileImgStyle = {
  width: '8.33rem',
  height: '8.33rem',
  borderRadius: '50%',
  padding: '0.19rem',
  backgroundColor: '#FFFFFF',
  transition: '1s ease',
  borderStyle: 'solid'

}
class UserInfo extends Component {

  state = {
    isShowingUserMenu: false,
    filesArray: [],
    user: '',
    abc: ''
  };
  //after selcting files append recent files to previous files
  appendFiles = (files) => {
    let filesArray = this.state.filesArray ? this.state.filesArray : []
    for (let fl in files) {
      if (files[fl].type) {
        files[fl]['preview'] = URL.createObjectURL(files[fl]);
        filesArray.push(files[fl])
      }
    }
    this.setState({ filesArray })

  }
  componentDidMount = () => {
    let user = GetSessionValue('user')
    let loginCredentials = GetSessionValue('loginCredentials')
    if (user.brandDetails) {
      user.brandDetails = loginCredentials.brandDetails
    }
    this.setState({ user: user })
  }
  static getDerivedStateFromProps(props, state) {
    let user = GetSessionValue('user')
    return { user: user };
  }
  render() {
    let { screenType } = this.props;
    let { user } = this.state
    let { entity, role, brandDetails } = user
    let logo, entityName, logoClasses = '';
    if (entity && !entity.logo && role === 'systemadmin') {
      logo = krowdLogo
    } else if (role === 'cardprovider') {
      logoClasses = 'hrect-photo'
      logo = mastercardLogo
    }
    // else if (brandDetails && brandDetails.photo) {
    //   logo = brandDetails.photo
    // }
    // else if (entity && entity.logo) {
    //   logo = entity.logo
    // } 
    else {
      logo = placeHolderImg
    }
    if (role === 'systemadmin') {
      entityName = 'Krowd'
    } else {
      // entityName = user && user.displayName ? user.displayName : ''
      entityName = user && user.brandName ? user.brandName : ''
    }
    return (
      <div className="user-wrapper">
        <div className="user">

          <div className={`beta-version-txt`}> Beta </div>

          <div className={`photo-container pb-2`} style={screenType ? profileImg : {}}>
            <img className={`photo ${logoClasses}`}
              src={(brandDetails && brandDetails.photo) ? `${config.imgUrl}brands/${brandDetails.photo}` : logo}
              // src={user && user.photo ? `${config.imgUrl}user/${user.photo}` : user && user.imageLink ? user.imageLink : logo}
              // src={user && user.photo ? `${config.imgUrl}user/${user.photo}` : user && user.imageLink ? user.imageLink : logo}
              alt={entityName} style={screenType ? profileImgStyle : {}}
              onError={(e) => { e.target.onerror = null; e.target.src = logo }}
            />
          </div>

          {/* <Formik
            initialValues={{
              files1: []
            }}
            onSubmit={data => (alert(JSON.stringify(data)))}
          >
            <Form>

              <DropZone
                name='files1'
                label='File upload'
                placeholder='Try dropping some files here, or click to select files to upload.'
              />

            </Form>
          </Formik> */}

          <div className="userinfo">
            <div className="username">
              <div className="company-name" >

                {screenType != 'profile' ? entityName : ''}
              </div>
            </div>
          </div>

        </div>
        {/* <Collapse in={isShowingUserMenu}>
          <ul className="nav user-nav">
            <li><a href="#">My Profile</a></li>
            <li><a href="#">Edit Profile</a></li>
            <li><a href="#">Settings</a></li>
          </ul>
        </Collapse> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.user,

});

export default connect(mapStateToProps)(UserInfo);