import React from 'react';
import cx from 'classnames';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setMobileNavVisibility } from '../../redux/reducers/layout';
import { withRouter } from 'react-router-dom';

import Header from './Header'
import SideBar from '../../components/SideBar/SideBar.component'
import UserProfile from '../UserProfile'
import CurrentCampaign from '../CurrentCampaign'
import CreateCampaignWithoutBranch from '../CreateCampaignWithoutBranch'
import CreateCampaign from '../CreateCampaign'
import CreateCampaignPreview from '../PreviewCampaign'
import FutureCampaign from '../FutureCampaign'
import PastCampaign from '../PastCampaign'
import Home from '../Home';
import CampaignOverviewPage from '../CampaignOverviewPage'
import ReportPage from '../ReportPage'
import PreviewPage from '../PreviewPage'
import Notifications from 'react-notification-system-redux';
import Merchants from '../Merchants'
import ManageCreative from '../Manage_creative'
import Users from '../Users'
import Reviews from '../InsightPageLayout'
import CampaignOverviewManager from '../CampaignOverviewManager'
import CampaignOverviewEdit from '../CampaignOverviewEdit'
import ChangePassword from '../UserProfile/changePassword'
import CreateCampaignPayments from '../../components/CreateCampaign/CreateCampaignPayment';
import PrivateRouteForGuestUser from '../../utils/components/PrivateRouteForGuestUser';
import InvoiceList from '../../../src/pages/Invoices/InvoiceList';
import pageNotFound from './pageNotFound';
import InsightsPage from '.././InsightPageLayout/index'
import ingestionLog from '../IngestionLogs/ingestionLog';
const LayoutWithSidebar = ({
  mobileNavVisibility,
  hideMobileMenu,
  history,
  match,
  user,
  notifications,
  sidebarNavVisibility
}) => {
  history.listen((location) => {
    if (mobileNavVisibility === true) {
      hideMobileMenu(); // listens to make sure if mobile menu is being displayed to hide it
    }
  });
  return (
    <div className={cx({
      'nav-open': mobileNavVisibility === true,
      'sidebar-close': sidebarNavVisibility === false
    })}>

      <div >
        {<div className="close-layer"></div>}
        <SideBar />
        <div className={sidebarNavVisibility ? "main-panel mainPanelHeight" : ' main-panel mainPanelHeightPercentage'}
        >
          <Header />
          <div className="container mobileContainer">
            {
              user.entityType === 'systemadmin'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/campaign-overview/edit/:id`} component={CampaignOverviewEdit} />
                    <Route exact path={`${match.url}/campaign-overview`} component={CampaignOverviewManager} />
                    <Route exact path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route exact path={`${match.url}/campaigns/preview/:id`} component={PreviewPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/merchants`} component={Merchants} />
                    
                    <Route path={`${match.url}/user/socialanalytics`} component={Reviews} />
                    <Route path={`${match.url}/user/socialanalytics/overview`} component={Reviews} />
                    <Route path={`${match.url}/user/socialanalytics/trends`} component={Reviews} />

                    <Redirect exact path="/user" to='/user/banks/list' />
                  </Switch>
                ) : ''
            }
            {
              user.entityType === 'bank'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/dashboard`} component={Home} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/merchants`} component={Merchants} />
                    <Route path={`${match.url}/managecreative`} component={ManageCreative} />
                    <Route path={`${match.url}/ingestionLogs`} component={ingestionLog} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/" to='/user/dashboard' />
                  </Switch>
                ) : ''
            }
            {
              user.role === 'user'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/campaigns/payments`} component={CreateCampaignPayments} />
                    {/* <Route exact path={`${match.url}/campaigns/create/wizard`} component={CreateCampaignWithoutBranch} /> */}
                    <Route exact
                      path={`${match.url}/campaigns/create`}
                      // path={`${match.url}/${user.isFirstLogin ? 'campaigns/create' : 'campaigns/create'}`}
                      component={user.isFirstLogin ? CreateCampaignWithoutBranch : CreateCampaign} />
                    <Route exact path={`${match.url}/campaigns/create/preview`} component={CreateCampaignPreview} />
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/settings/changepassword`} component={ChangePassword} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} />
                    <Route path={`${match.url}/dashboard`} component={Home} />
                    <Route path={`${match.url}/dashboard/:id`} component={Home} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/users`} component={Users} />
                    <Route path={`${match.url}/managecreative`} component={ManageCreative} />
                    <Route path={`${match.url}/ingestionLogs`} component={ingestionLog} />
                    <Route exact path={`${match.url}/campaigns/invoices`} component={InvoiceList} />
                    <Route exact path={`${match.url}/campaign-overview/edit/:id`} component={CampaignOverviewEdit} />
                    <Redirect exact path="/user" to={`/user/${user.isFirstLogin ? 'campaigns/create' : 'campaigns/future'}`} />
                  </Switch>
                ) : ''
            }
            {
              (user.role === 'companyAdmin' || user.role == 'groupAdmin' || user.role == 'brandAdmin')
                ? (
                  <Switch>
                    <PrivateRouteForGuestUser userData={user} exact path={`${match.url}/campaigns/payments`} component={CreateCampaignPayments} to={"/user/PageNotFound"} />
                    {/* <Route exact path={`${match.url}/campaigns/create/wizard`} component={CreateCampaignWithoutBranch} /> */}
                    <Route exact
                      path={`${match.url}/campaigns/create`}
                      // path={`${match.url}/${user.isFirstLogin ? 'campaigns/create/wizard' : 'campaigns/create'}`}
                      component={user.isFirstLogin ? CreateCampaignWithoutBranch : CreateCampaign} />
                    <Route exact path={`${match.url}/campaigns/create/preview`} component={CreateCampaignPreview} />
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/settings/profile`} component={UserProfile} to={"/user/PageNotFound"} />
                    {/* <PrivateRouteForGuestUser path={`${match.url}/settings/campaign`} component={CampaignSettings} to={"/user/PageNotFound"} /> */}
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/settings/changepassword`} component={ChangePassword} to={"/user/PageNotFound"} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} />
                    
                    <Route exact path={`${match.url}/dashboard`} component={Home} />
                    
                    <Route exact path={`${match.url}/socialanalytics/`} component={InsightsPage} />                 

                    <Route exact path={`${match.url}/dashboard/:id`} component={Home} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/branches`} component={Merchants} to={"/user/PageNotFound"} />
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/users`} component={Users} to={"/user/PageNotFound"} />
                    <Route path={`${match.url}/managecreative`} component={ManageCreative} />
                    <Route path={`${match.url}/ingestionLogs`} component={ingestionLog} />
                    <Route exact path={`${match.url}/campaign-overview/edit/:id`} component={CampaignOverviewEdit} />
                    <Route exact path={`${match.url}/campaigns/invoices`} component={InvoiceList} />
                    <Redirect exact path="/user" to={`/user/${user.isFirstLogin ? 'campaigns/create' : 'campaigns/future'}`} />
                    <Route path={`${match.url}/PageNotFound`} component={pageNotFound} />
                  </Switch>
                ) : ''
            }
            {
              (user.role == 'groupUser' || user.role == 'brandUser')
                ? (
                  <Switch>
                    
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/settings/profile`} component={UserProfile} to={"/user/PageNotFound"} />
                    <PrivateRouteForGuestUser userData={user} path={`${match.url}/settings/changepassword`} component={ChangePassword} to={"/user/PageNotFound"} />
                   
                    <Route exact path={`${match.url}/dashboard`} component={Home} /> 
                    <Route exact path={`${match.url}/campaigns/invoices`} component={InvoiceList} />
                    <Redirect exact path="/user" to={`/user/dashboard`} />
                    <Route path={`${match.url}/PageNotFound`} component={pageNotFound} />
                  </Switch>
                ) : ''
            }
          </div>

          {/* <Footer /> */}
        </div>
        <Notifications
          notifications={notifications}
        />
      </div>
    </div>
  )
}


export default withRouter(
  connect(
    state => ({
      user: state.user,
      mobileNavVisibility: state.Layout.mobileNavVisibility,
      sidebarNavVisibility: state.Layout.sidebarNavVisibility,
      notifications: state.notifications
    }),
    (dispatch) => ({
      hideMobileMenu: () => dispatch(setMobileNavVisibility(false))
    })
  )(LayoutWithSidebar)
)
