import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAmexUserAction, getGoCardLessDataAction, onBoardAmexBrandAction, createMandate, verifyEmailAction } from '../../redux/actions/authActions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
import AMEXLogo from '../../assets/images/american_Express_logo.png';
import AmexDiningBG from '../../assets/images/amex-signup-header-dt.jpg';
import AmexDiningBottomBG from '../../assets/images/background-image2_1920x1080.jpg';
import configMessages from './../../config/configMessages';
import TalkToUsImg from '../../assets/images/talk-bubble-02.png';
import TnCComponent from '../CreateCampaignWithoutBranch/TnC';
import ModalComponent from '../../components/Modal';
import loadScript from '../../components/OnBorading/Payments';
import config from '../../../src/config/config';
import {
    success as successNotificationAction,
    error as errorNotificationAction
} from 'react-notification-system-redux';
import { notificationOpts } from './../../config'

const imgStyle = {
    'display': 'block',
    'height': "3rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2.5em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const AMEXImgStyle = {
    'display': 'block',
    'height': "5rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const TalkToUsStyle = {
    'height': "6rem",
    'textAlign': "center",
    'margin': 'auto',
    'marginTop': '2em',
    marginBottom: '1em',
    'cursor': 'pointer'
}
const AmexDiningBGImgStyle = {
    'width': "100%",
}


class AmexDiningSignup extends Component {

    initialValues = {
        name: '',
        brandName: '',
        legalName: '',
        email: '',
        phone: ''
    }

    state = {
        showTCModal: false,
        showSignupForm: true,
        paymentSuccess: false,
        apiLoaded: false,
        settings: {},
        successUser: false
    }

    validationSchema = YupObject({
        name: YupString()
            .required('Brand Name is required'),
        brandName: YupString()
            .required('Brand Name is required'),
        legalName: YupString()
            .required('Legal Name is required'),
        email: YupString()
            .required(configMessages.email)
            .email(configMessages.validEmail),
        // phone: YupString()
        //     .required('Phone number is required'),
    })

    componentDidMount = () => {
        let { getGoCardLessData } = this.props;
        getGoCardLessData(data => {
            this.setState({ settings: data });
        });
        this.loadScript();
    }

    loadScript() {
        loadScript(config.gocardlessJavascript)
            .then((script) => {
                // Grab the script object in case it is ever needed.
                this.script = script;
                this.setState({ apiLoaded: true });
            })
            .catch((err) => {
                console.error(err.message);
            });
    }

    getForm = props => {
        let { status, isSubmitting } = props;
        return (

            <Form className="row col-12 form  p-0 m-0">


                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>NAME</label>
                    <Field name="name" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="name" component="label" className="error" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>RESTAURANT NAME</label>
                    <Field name="brandName" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="brandName" component="label" className="error" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>LEGAL NAME</label>
                    <Field name="legalName" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="legalName" component="label" className="error" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>EMAIL</label>
                    <Field name="email" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="email" component="label" className="error" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>PHONE</label>
                    <Field name="phone" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="phone" component="label" className="error" />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                    <label>WEBSITE</label>
                    <Field name="website" className="form-control" disabled={isSubmitting} autoComplete="off"
                    />
                    <ErrorMessage name="website" component="label" className="error" />
                </div>

                <div className="col-12 form-group text-center">
                    <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting}>CONTINUE</button>
                </div>
            </Form>
        )
    }

    onSubmit = (values, actions) => {
        let { verifyEmail, errorNotification } = this.props;
        verifyEmail({ email: values.email }, (res, type) => {
            if (res.respCode && type == 'success') {
                this.setState({ showTCModal: true, formValues: values, formActions: actions });
            } else {
                let notification = { ...notificationOpts }
                notification.message = res;
                errorNotification(notification);
                actions.setSubmitting(false);
            }
        });
    }

    openLink = (url) => {
        window.open(url, "_blank");
    }

    onCloseTCModal = () => {
        this.setState({ showTCModal: false });
    }

    handleTCResponse = () => {
        this.state.formActions.setSubmitting(true);
        let { onBoardAmexBrand, history, successNotification } = this.props;
        this.state.formValues.from = "amexdiningsignup";

        onBoardAmexBrand(this.state.formValues, (res, type) => {
            // history.push(`/amexdiningbranch/`+ res.savedId)
            this.setState({ showTCModal: false, successUser: true, showSignupForm: false });
            let notification = { ...notificationOpts }
            notification.message = "Registered successfully."
            successNotification(notification)
        });
    }

    gocardlessHandler = () => {
        if (this.state.apiLoaded) {
            let self = this;
            var gocardlessClient = window.GoCardlessClient({
                token: this.state.settings.gocardlessToken,
                // token: "sandbox_RdWPnxnQyowR2yoDNh4in95oalLwC70tuqCChY91"
                // token: "nXThgF8k6woQ9tcRZ4xgW-jsk7CopVIaEPhWzWT-"
                // token: "PAT_BuuFhREvQ27_hChE4VSOai3xu1mOXexi-rhyRlS4"
            });
            gocardlessClient.create({
                onSave: function (payerAuthorisationId, confirmCallback, metadata) {
                    // 1. Optional: store the payerAuthorisationId server-side
                    // 2. Mandatory: call confirmCallback to let the Drop-in flow continue
                    confirmCallback();
                    self.setState({
                        payerAuthorisationId
                    })
                },
                onComplete: function (metadata) {
                    // The customer completed successfully 
                    this.setState({ paymentSuccess: false });
                    setTimeout(async () => {
                        if (self.state.payerAuthorisationId) {
                            await self.props.createMandateapi({
                                payer_authorisations: self.state.payerAuthorisationId,
                                user: {
                                    brandName: "",
                                    brandId: "",
                                    _id: "",
                                    email: ""
                                }
                            }, async (res, type) => {

                            });
                        }
                    }, 1000);
                },
                onExit: function (error, metadata) {
                    if (error === undefined) {
                        // The customer left intentionally the Drop-in flow
                        // (for example they closed the modal interface).
                    } else {
                        // The customer left due to unrecoverable error.
                    }
                }
            });
        }
    }

    openAmexDidingPage = () => {
        const query = new URLSearchParams(window.location.search);
        const redirectTo = query.get('redirectTo');
        if (redirectTo) {
            window.location.href = "https://amexdining.com";
        } else {  
            window.open("https://krowd.info", "_self");
        }
    }
    

    render() {

        return (

            <> 

                <div className="row justify-content-center amex-dining-contain m-0 keep-top-space">

                <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark mb-nav nav-shadow amex-dining-signup-header">
                    <div class="container">
                        <div className="amex-signup-amex-logo">
                            <img src={AMEXLogo} onClick={(e) => { this.openLink("https://amexdining.com/") }} style={AMEXImgStyle} alt="amex-logo" />
                        </div>
                        <div className="divid-logo"></div>
                        <div className="amex-signup-krowd-logo">
                            <img src={KrowdLogo} onClick={(e) => { this.openLink("https://krowdit.com/") }} style={imgStyle} alt="krowd-logo" />
                        </div>
                    </div>
                </nav>

                    {/* <script type="application/javascript" src="https://dropin-sandbox.gocardless.com/script.js"></script> */}

                    <div className="talk-to-us-area">
                        <img src={TalkToUsImg} onClick={(e) => { this.openLink("https://calendly.com/stacey-krowd/30min") }} style={TalkToUsStyle} alt="amex-dining" />
                    </div>

                    <div className="row col-12 justify-content-center amex-dining-signup-bg p-0">
                        <img src={AmexDiningBG} style={AmexDiningBGImgStyle} alt="amex-dining" />
                    </div>

                    <div className="row col-12 justify-content-center m-t-60 m-b-30">

                        {this.state.showSignupForm &&
                            <div className="row col-xl-10 col-lg-10 col-md-10 col-sm-12 form">
                                <div className="col-12">
                                    <div className="card amex-dining-signup-contain">
                                        <div className="header">
                                            <div className="logo-container-style">
                                                <h3 className="amex-singup-title">Use the form below to sign up.</h3>
                                            </div>
                                        </div>
                                        <div className="row col-12 content form-box p-0 m-0">
                                            <Formik
                                                initialValues={this.initialValues}
                                                render={props => this.getForm(props)}
                                                onSubmit={this.onSubmit}
                                                validationSchema={this.validationSchema} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {!this.state.showSignupForm && !this.state.successUser &&
                            <div className="row col-xl-4 col-lg-4 col-md-4 col-sm-12 form">
                                <div className="col-12">
                                    <div className="card justify-content-center amex-dining-signup-contain">

                                        <button className="btn btn-info btn-fill btn-wd"
                                            id="pay-with-gocardless-button"
                                            onClick={() => this.gocardlessHandler()}>
                                            Setup Payment with GoCardless
                                        </button>

                                        <h5 className='pt-4 text-center'>
                                            We will only invoice you for the customer cashback once the campaign
                                            has run successfully and our cardholders have transacted at your store.
                                        </h5>

                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.successUser &&
                            <div className="row col-xl-4 col-lg-4 col-md-4 col-sm-12 form">
                                <div className="col-12">
                                    <div className="card justify-content-center amex-dining-signup-contain">

                                        <h2 className="text-center">Registration Successful</h2>

                                        <h5 className='pt-4 text-center'>
                                            Thank you for registering with Krowd’s Customer Acquisition Marketplace. After validating your details, we'll set up
                                            an account for you on the krowd platform. Please check your email for more information.
                                        </h5>

                                        <div className="col-12 text-center m-t-5">
                                            <button className="btn btn-info btn-fill btn-wd"
                                                onClick={() => this.openAmexDidingPage() }>
                                                OK
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    <div className="row col-12 justify-content-center p-0">
                        <img src={AmexDiningBottomBG} style={AmexDiningBGImgStyle} alt="amex-dining" />
                    </div>

                    <ModalComponent close={this.onCloseTCModal}
                        callback={this.handleTCResponse}
                        show={this.state.showTCModal}
                        title={""}
                        size="lg"
                        message={<TnCComponent />}
                        action={"Accept"}
                    />


                </div>
            </>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        getAmexUser: bindActionCreators(getAmexUserAction, dispatch),
        onBoardAmexBrand: bindActionCreators(onBoardAmexBrandAction, dispatch),
        getGoCardLessData: bindActionCreators(getGoCardLessDataAction, dispatch),
        createMandateapi: bindActionCreators(createMandate, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        errorNotification: bindActionCreators(errorNotificationAction, dispatch),
        verifyEmail: bindActionCreators(verifyEmailAction, dispatch)
    })
)(AmexDiningSignup);

