export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#;$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%;^&*]{8,16}$/
export const onlycharRegex = /^[A-Za-z_-]*$/
export const contactNameRegex = /^[A-Za-z0-9_ '-]*$/
export const brandNameRegex = /^[A-Za-z0-9_ '-]*$/
export const charandnumericRegex = /^[A-Za-z0-9_-]*$/
export const postcodeRegex = /^[A-Za-z0-9]*$/
// export const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const phoneRegex = /^\+?\d*$/
export const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i


export const customerType = {
    "all": "All",
    "new": "New",
    "lapsed": "Lapsed"
}