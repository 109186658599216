import React from 'react';
import styled from 'styled-components'
import Trends from '../Charts/Trends/trends'
import LineChart from '../Charts/LinesChart';

const BigContainer = styled.div`
display:flex;
flex-direction: column;
@media(max-width:800px){
};
width:100%;

`
const Container = styled.div
  `

justify-content:space-between;
padding:1px;
//border:2px solid black;
margin-bottom:10px;

`


const TrendsComponent = () => {
  return (
    <BigContainer>
      <Container>
        <Trends />
      </Container>
      <Container>
        <LineChart />
      </Container>
    </BigContainer>

  );
};

export default TrendsComponent;
