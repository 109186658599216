import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import Loader from '../components/Loader'
import { Link } from 'react-router-dom';
const Modal = ({
  close,
  show,
  title,
  message,
  action,
  callback,
  actionType = "success",
  size = "md",
  isDisabled,
  cancelText = "Cancel",
  overrideCancelText,
  hideCancelBtn = false,
  centered = true,
  isLoading,
  className,
  openingFrom
}) => {
  return (
    <ModalComponent show={show} onHide={close} size={size} centered={centered} className={className}>
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>{title}</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <Loader loader={isLoading} />
        {message}</ModalComponent.Body>
      <ModalComponent.Footer>
        {
          !hideCancelBtn && (
            <Button variant="default" onClick={close}>
              {overrideCancelText ? overrideCancelText : cancelText}
            </Button>
          )
        }
        {action && actionType != null &&
          <Button Button variant={actionType} onClick={callback} disabled={isDisabled} >
            {action}
          </Button>
        }

        {/* {openingFrom == "CreateBranch" &&            
          <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" onClick={callback} disabled={isDisabled}>{action}</button>
        } */}
      </ModalComponent.Footer>
    </ModalComponent >
  )
}

export default Modal

