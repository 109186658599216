import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Card from "react-bootstrap/Card";
//import './horizontalbar.css'
import { Container } from "./horizontalbar.styled";
import { useDispatch, useSelector } from "react-redux";
import { insightsSelectors } from "../../../redux/insights";
import { getFactorsDataAPICall } from "../../../services/getFactorsDataAPICall";

const hdata = {
  series: [],
  options: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      // legend: "top",
      // legend: "none",
    },
    colors: ["#006b1e", "#8e0000"],

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      style: {
        fontSize: "11px",
        fontWidth: "700",
        colors: ["fff"],
        padding: 20
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      // position: 'none',
      // textStyle: { color: 'blue', fontSize: 16 }
    },
    tooltip: {
      trigger: 'none'
    },
    xaxis: {
      categories: ['Food', 'Price', 'Ambiance', 'Service'],
    },
  },
};
const HorizontalBarchart = (props) => {
  const dispatch = useDispatch();
  const [chartData, setchartData] = useState(hdata);
  // const [chartWidth, setchartWidth] = useState(300);
  const chartDataState = useSelector(insightsSelectors.getFactorChartDataState);
  const { brandId } = useSelector(insightsSelectors.getUserdetailsState)
  const loader = useSelector(insightsSelectors.getFactorChartloaderState);
  const [chartloader, setchartloader] = useState(hdata);

  const insightsDates = useSelector(insightsSelectors.insightsDates);

  useEffect(() => {
    if (chartDataState != undefined && chartDataState.chartDetails) {
      let prepareChartData = chartData;
      // let { Positive, Negative, categories } = chartDataState.chartDetails.factorsAffecting;
      let { Negative, Positive, categories } = chartDataState.chartDetails.factorsAffecting || []
      prepareChartData.series = [
        {
          name: "Positive",
          data: Positive, //
        },
        {
          name: "Negative",
          data: Negative, //
        },
      ];
      setchartData(prepareChartData);
      console.log('prepareChartData', prepareChartData);
    }
  }, [chartDataState.chartDetails]);
  useEffect(() => {
    if (insightsDates.date) {
      dispatch(getFactorsDataAPICall(brandId, insightsDates.date, insightsDates.kmid));
    }
  }, [insightsDates.date]);

  useEffect(() => {
    setchartloader(loader);
    console.log("loader",loader);
  }, [loader]);

  return (
    <Container>
      <Card className="chartCard p-r-0">
        <Card.Body className="p-r-0">
          <div id="Factors Affecting chart">
            {/* <h4 className="p-l-10">Factors Affecting Aggregated Review Score</h4> */}
            <h4 className="p-l-10">Sentiment Analysis of Customer Reviews</h4>
            {!chartloader ?
              (
                // chartDataState.chartDetails && chartDataState.chartDetails.factorsAffecting != undefined && (chartDataState.chartDetails.factorsAffecting.Negative != undefined || chartDataState.chartDetails.factorsAffecting.Positive != undefined)
                chartData.series.length > 0
                  ?
                  <React.Fragment>
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="bar"
                      height={395}
                    // width={chartWidth}
                    />
                    <h5>Mention Count in Reviews</h5>
                  </React.Fragment>
                  :
                  <p className="pt-3 text-center" > No data available</p>)
              :
              <div className="d-flex justify-content-center spinner-block">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>}
          </div>
        </Card.Body>
      </Card>
    </Container >
  );
};

export default HorizontalBarchart;
