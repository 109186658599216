import React, { Component } from 'react';
import capitalize from 'lodash/capitalize'
import BarChart from '../Charts/BarChart'


const getCurrency = () => {
  // return $
  return "GBP"
}

class Performance extends Component {
  categories = [
    // { key: 'reach', xLabel: 'Year-Week', yLabel: 'Customers' },
    { key: 'conversions', xLabel: 'Year-Week', yLabel: 'Customers' },
    { key: 'revenue', xLabel: 'Year-Week', yLabel: `Revenue (${getCurrency()})` }
  ]
  state = {
    activeTab: this.categories[0],
    chartData: null
  };

  onClickTabItem = (tab) => {
    let { data } = this.props;
    let chartData = data[tab.key]
    this.setState({ activeTab: tab, chartData });
  }
  componentDidMount() {
    let { data } = this.props;
    if (data) {
      let chartData = data[this.state.activeTab.key]
      this.setState({ chartData });
    }

  }
  render() {
    let { chartData, activeTab } = this.state;
    return (
      <div className="card col performance">
        <div className="card-title">
          <div className="row align-items-baseline">
            <div className="col-md-4">Performance</div>
            <div className="col-md-8">
              <ul className="mt-2 mb-3 nav nav-pills">
                {
                  this.categories.map((category, i) => {
                    return (
                      <li key={i} className="nav-item col pl-1 pr-1" onClick={() => this.onClickTabItem(category)}>
                        <button
                          className={`btn btn-sm nav-link tab-selector w-100 ${activeTab.key === category.key ? 'active btn-primary' : ''}`}
                        >
                          {capitalize(category.key)}
                        </button>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="card-body text-center">
          <div className="performance-charts">
            <div className="chart-content">
              {
                chartData && <BarChart {...activeTab} dontSort={true} data={this.props.data[this.state.activeTab.key]} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Performance