// .bigcontainer{
//     display:flex;
//  justify-content:space-between;

// }
// .container{
//     display:flex;
//      align-items:center;
//      justify-content:space-evenly;
//      padding:1px;
//      flex-basis: 50%;

// }

// .textalign .ul{

// }
// .textalign .li{
//     list-style-type: none;
//      text-align: left;
//      justify-content:center;
//      font-size:12px;
// }
// b{
//     font-size:16px;
// }

// @media screen and (max-width:800px){
// .bigcontainer{
//     flex-direction:column;
// }
// }

import styled from "styled-components";

export const BigContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1px;
  flex-basis: 50%;
`;
export const Textalign = styled.ul`
  & li {
    list-style-type: none;
    text-align: left;
    justify-content: center;
    font-size: 12px;
  }
  & b {
    font-size: 16px;
  }
`;
export const Links = styled.div`
  & p {
    font-size: 12px;
    &a {
      font-size: 12px;
    }
  }
`;
// export const cardstyle = {
//   height: "230px",
//   flex: "1",
//   margin: "20px 20px",
//   paddingTop: "10px",
//   borderRadius: "10px",
//   cursor: "pointer",
//   alignItems: "center",
//   display: "flex",
// };
export const cardbody = {
  textAlign: "center",
  justifyContent: "center",
};

export const cardtitle = {
  textAlign: "center",
  fontSize: "1.6rem",
  padding: "1px",
  fontWeight: "800",
  textAlign: "center",
};
export const cardsubtitle = {
  textAlign: "center",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
  fontWeight: "100",
  lineHeight: "24px",
  justifyContent: "flex-start",
};

export const cardtext = {
  fontSize: "12px",
  alignItems: "center",
  fontWeight: "500",
};

/* .featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .featuredItem {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: rgb(240, 233, 224);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .featuredTitle{
      font-size: 15px;
      display: flex;
      align-items: center;

  }
  
  .featuredMoneyContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
  }
  
  .featuredMoney{
      font-size: 30px;
      font-weight: 600;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      align-items: center;
      color: gray;
  }
   */
