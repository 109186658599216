import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef } from 'yup';
import { onlycharRegex, passwordRegex } from '../../utils/constants/constant';

class SignupForm extends Component {

  // onSubmit = (e) => {
  //   e.preventDefault()
  //   const {email, password} = this.setState
  //   this.props.onSubmit(email, password)
  //   this.setState({email: "", password: ""})
  // }
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  }
  validationSchema = YupObject({
    firstName: YupString()
      .required('Name is required')
      .matches(onlycharRegex, 'Name can contain only alphabets, "-" and/or space')
      .min(3, 'Name should at least contain 3 characters')
      .max(20, 'Name should at most contain 20 characters'),
    lastName: YupString()
      .required('Name is required')
      .matches(onlycharRegex, 'Name can contain only alphabets, "-" and/or space')
      .min(3, 'Name should at least contain 3 characters')
      .max(20, 'Name should at most contain 20 characters'),
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
    password: YupString()
      .required('Password is required')
      .matches(passwordRegex, 'password must contain One special characters, upper case and number.')
      .min(8, 'Password should at least contain 8 characters')
      .max(20, 'Password should at most contain 20 characters'),
    confirmPassword: YupString()
      .required('Confirm password is required')
      .oneOf([YupRef('password'), null], 'Passwords must match'),
  })

  getForm = (props, options = { isDisabled: false, btnLabel: 'SignUp' }) => {
    let { status, isSubmitting } = props;
    let { isDisabled, btnLabel } = options
    return (
      <Form className="form row">
        <div className="col-lg-6 col-sm-12">
          <div className="form-group">
            <label>First Name</label>
            <Field name="firstName" className="form-control" disabled={isSubmitting || isDisabled} />
            <ErrorMessage name="firstName" component="label" className="error" />
          </div>
          <div className="form-group">
            <label>Email</label>
            <Field name="email" className="form-control" disabled={isSubmitting || isDisabled} />
            <ErrorMessage name="email" component="label" className="error" />
          </div>

          <div className="form-group">
            <label>Confirm Password</label>
            <Field name="confirmPassword" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
            <ErrorMessage name="confirmPassword" component="label" className="error" />
          </div>

        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="form-group">
            <label>Last Name</label>
            <Field name="lastName" className="form-control" disabled={isSubmitting || isDisabled} />
            <ErrorMessage name="lastName" component="label" className="error" />
          </div>
          <div className="form-group">
            <label>Password</label>
            <Field name="password" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
            <ErrorMessage name="password" component="label" className="error" />
          </div>
          <div className="form-group text-center pt-5">
            <button className="btn btn-info btn-fill btn-wd px-5 " type="submit" disabled={isSubmitting || isDisabled}>{btnLabel}</button>
          </div>
          {
            status && (
              <div className="form-group text-center">
                <label className="error">{status}</label>
              </div>
            )
          }

        </div>


      </Form>
    )
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, options)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required



export default SignupForm










