import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef, boolean as YupBool, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { Collapse } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import configMessages from './../../config/configMessages'
import ModalComponent from './../Modal';
import { GetSessionValue } from '../../utils/sessionStorage';
import { brandNameRegex, onlycharRegex, passwordRegex, phoneRegex } from '../../utils/constants/constant';
import { getBrandGroupWiseAction } from '../../redux/actions';
import { bindActionCreators } from 'redux'

class UserFormComponent extends Component {
  state = {
    merchants: [],
    isDisplayMerchantId: false,
    merchantList: [],
    groupBrandList: []
  }

  handleShowPassword = () => {
    let { values, setFieldValue } = this.props;
    setFieldValue('editPassword', !values.editPassword)
  }

  componentDidMount = () => {
    let { setFieldValue, options, getBrandGroupWise } = this.props;

    let { prefix } = options
    let userDetails = GetSessionValue('user')
    if (userDetails && userDetails.merchantIds && userDetails.merchantIds.length > 0) {
      let merchantIds = userDetails.merchantIds
      let activeMerchants = []
      merchantIds.forEach((item, index) => {
        if (item) {
          item.label = item.branchName
          item.value = item.merchantObjId
          activeMerchants.push(item)
        }
      })
      this.setState({ merchants: activeMerchants })
    }

    if (userDetails && userDetails.role == "groupAdmin") {
      getBrandGroupWise(userDetails.brandId, (res) => {
        if (res && res.length > 0) {
          this.setState({ groupBrandList: res });
        }
      });
    }
  }

  //Multiselect for merchant ids
  onSelect = (selectedItem) => {
    this.setState({ merchantList: selectedItem })
    this.props.selectMerchantId(selectedItem)
  }

  render() {
    let { status, isSubmitting, values, handleChange, handleBlur, options, selectMerchantId, onSelectBrand } = this.props;

    let { prefix, mode } = options
    let { merchants, isDisplayMerchantId, merchantList } = this.state;
    let merchantListValue = merchantList && merchantList.length ? merchantList : values.merchantIds ? values.merchantIds : [];
    return (
      <Form>
        <div className="form-group">
          <label>Role</label>
          <select
            name="role"
            className="form-control"
            defaultValue={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="" disabled>Select Role</option>
            {/* <option value="companyAdmin">Company Admin</option>
            <option value="user">User</option> */}

            <option value="brandAdmin">Brand Admin</option>
            <option value="brandUser">Brand Finance</option>
            <option value="groupAdmin">Group Admin</option>
            <option value="groupUser">Group Finance</option>

          </select>
          <ErrorMessage name="role" component="label" className="error" />
        </div>

        {this.state.groupBrandList && this.state.groupBrandList.length > 0 && 
            (values.role == "brandAdmin" || values.role == "brandUser") &&
          <div className="form-group">
            <label>Select Brands</label>
            <select
              name="role"
              className="form-control"
              defaultValue={values.brandId}
              onChange={(event) => {
                let filter = this.state.groupBrandList.filter(a => a.value == event.target.value);
                if (filter && filter[0]) {
                  onSelectBrand(filter[0].value, filter[0].label, filter[0]._id, this.props);
                } else {
                  onSelectBrand("", "", "", this.props);
                }
              }}
            >
              <option value="" disabled>Select Brands</option>
              {
                this.state.groupBrandList.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>{option.label}</option>
                  );
                })
              }

            </select>
            <ErrorMessage name="role" component="label" className="error" />
          </div>
        }

        {/* {(values.role == "companyAdmin" || values.role == "brandUser") &&
            <div className="form-group dropdown w-100">
 

              <MultiSelect
                className='multi-select'
                options={this.state.groupBrandList}
                value={values.group_brand_id}
                onChange={(value) => {
                  onSelectBrand(value, this.props);
                }}
                labelledBy={"Select brand"}
                required={true}
                
              />
              <ErrorMessage name="group_brand_id" component="label" className="error" />
            </div>
        } */}


        <div className="form-group">
          <label>Email </label>
          <Field name="email" className="form-control" disabled={mode == 'edit' ? true : isSubmitting} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>First Name  </label>
          <Field name="firstName" value={capitalize(values.firstName)} className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Last Name </label>
          <Field name="lastName" value={capitalize(values.lastName)} className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Phone </label>
          <Field name="phone" type='text' className="form-control"
            // maxLength='12'
            disabled={isSubmitting} />
          <ErrorMessage name="phone" component="label" className="error" />
        </div>

        {/* {isDisplayMerchantId? */}
        {/* {values.role && values.role == 'user' ?
          <div className="form-group">
            <label>{capitalize("Branch")} </label>
            <MultiSelect
              options={this.state.merchants}
              value={merchantList && merchantList.length ? merchantList : values.merchantIds ? [...values.merchantIds] : []}
              onChange={this.onSelect}
              labelledBy={"Select Merchant Id"}
              required={true}
            />
            <ErrorMessage name="merchantId" component="label" className="error" />
          </div> : null} */}
        <input
          value={prefix}
          onChange={handleChange}
          onBlur={handleBlur}
          name="entityType"
          type="hidden"
          className="form-control" />
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

export class FormikForm extends Component {
  state = {
    merchantList: ''
  };

  initialValues = {
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'role': '',
    'merchantId': '',
    'brandId': "",
    "brandName": "",
    "brandObjId": ""
  };

  validationSchemaCreate = YupObject({
    firstName: YupString()
      .required(configMessages.name),
    // .matches(onlycharRegex, 'Name can only contain alphabets')
    // .min(3, configMessages.minName)
    // .max(100, configMessages.maxName),
    lastName: YupString()
      .required(configMessages.name),
    // .matches(onlycharRegex, 'Name can only contain alphabets')
    // .min(3, configMessages.minName)
    // .max(100, configMessages.maxName),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    // phone: YupString()
    //   .matches(phoneRegex, `Enter a valid contact number`)
    //   .required('Contact Number is required'),
    role: YupString()
      .required(configMessages.role),
    // brandId: YupString()
    //   .required("Please select the group"),
  })


  noop = () => { }

  onSubmitValues = (values, actions) => {
    if (this.state.merchantList) {
      values.merchantIds = this.state.merchantList
    }
    let { onSubmit } = this.props;
    onSubmit(values, actions)
  }

  onSelect = (selectedItem) => {
    let merchantList = selectedItem
    this.setState({ merchantList: selectedItem })
  }

  onSelectBrand = (brandId, brandName, brandObjId, props) => {
    props.setFieldValue("brandId", brandId);
    props.setFieldValue("brandName", brandName);
    props.setFieldValue("brandObjId", brandObjId);
  }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, brands, banks, prefix, getBrandGroupWise } = this.props;
    
    let defaultValues;
    if (prefix && !initialValues) {
      defaultValues = { ...this.initialValues }
      defaultValues.entityType = prefix
    } else {
      defaultValues = {
        ...initialValues, password: '', confirmPassword: ''
      }
      if (!defaultValues.phone) defaultValues.phone = "";
      if (!defaultValues.role) defaultValues.role = "";
      if (!defaultValues.firstName) defaultValues.firstName = "";
      if (!defaultValues.lastName) defaultValues.lastName = "";
      if (!defaultValues.email) defaultValues.email = "";
      if (!defaultValues.brandId) defaultValues.brandId = "";
      if (!defaultValues.brandName) defaultValues.brandName = "";
      if (!defaultValues.brandObjId) defaultValues.brandObjId = "";

    }
    let mode = initialValues ? 'edit' : ''
    return (
      <Formik
        initialValues={defaultValues}
        render={props => <UserFormComponent {...props}
          getBrandGroupWise={getBrandGroupWise}
          selectMerchantId={this.onSelect}
          onSelectBrand={this.onSelectBrand}
          options={{ banks, brands, prefix, mode }} /> /*this.getForm(props, {banks, brands, prefix, mode})*/}
        onSubmit={this.onSubmitValues}
        validationSchema={this.validationSchemaCreate}
        ref={formikRef}
      />
    )
  }
}

export const UserForm = connect(
  state => ({
    banks: state.selectEntities.banks,
    brands: state.selectEntities.brands
  }),
  dispatch => ({
    getBrandGroupWise: bindActionCreators(getBrandGroupWiseAction, dispatch)
  })
)(FormikForm)

class SimpleUserFormComponent extends Component {
  state = {
    confirmationModal: false
  }

  //closing confirmation modal
  onCloseConfirmationModal = () => {
    this.setState({ confirmationModal: false })
  }

  //confirmation modal
  handleConfirmationModal = () => {
    let { values, submit } = this.props
    if (submit) {
      values.status = 'deactivate'
      submit(values, null)
    }
  }
  render() {
    let { status, isSubmitting, values, options, languageData } = this.props;
    let { isDisabled } = options
    let { confirmationModal } = this.state
    let user = GetSessionValue('user')
    return (
      <Form className="form">
        <div className="form-group">
          <label>{languageData && languageData.email ? languageData.email : 'Email'}</label>
          <Field name="email" className="form-control" disabled={true} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>{languageData && languageData.firstName ? languageData.firstName : 'First Name'}</label>
          <Field name="firstName" value={capitalize(values.firstName)} className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>{languageData && languageData.lastName ? languageData.lastName : 'Last Name'}</label>
          <Field name="lastName" value={capitalize(values.lastName)} className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>{languageData && languageData.contactPhone ? languageData.contactPhone : 'Contact Phone'}</label>
          <Field name="phone" type='text' className="form-control"
            // maxLength='12'
            disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="phone" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>{languageData && languageData.brand ? languageData.brand : 'Brand'}</label>
          <Field name="brandName" className="form-control" disabled={true} />
          <ErrorMessage name="brandName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>{languageData && languageData.address ? languageData.address : 'Address'}</label>
          <Field name="address" className="form-control" disabled={isSubmitting || isDisabled} component="textarea" />
          <ErrorMessage name="address" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Account Settings</label>
          <div className='account_Settings'>
            Do you want to Deactivate your account ? <span onClick={e => this.setState({ confirmationModal: true })}>click here</span>
          </div>
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <ModalComponent
          close={this.onCloseConfirmationModal}
          show={confirmationModal}
          callback={this.handleConfirmationModal}
          title={"Account Confirmation"}
          message={"Are you sure you want to deactivate Account ?"}
          action={"Yes"}
          cancelText={'No'}
        />
      </Form>
    )
  }
}

export class SimpleUserForm extends Component {

  initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
  }
  validationSchema = YupObject({
    firstName: YupString()
      .required('Name is required')
      .matches(onlycharRegex, 'Name can only contain alphabets')
      .min(3, 'Name should at least contain 3 characters')
      .max(100, 'Name should at most contain 100 characters'),
    lastName: YupString()
      .required('Name is required')
      .matches(onlycharRegex, 'Name can only contain alphabets')
      .min(3, 'Name should at least contain 3 characters')
      .max(100, 'Name should at most contain 100 characters'),
    brandName: YupString()
      .required('Brand is required')
      .matches(brandNameRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, 'Brand should at least contain 3 characters')
      .max(100, 'Brsnd should at most contain 100 characters'),
    phone: YupNumber()
      .required('Contact Number is required')
      .typeError("It doesn't contain alphabets")
      .positive("Contact Number can't start with a minus"),
    editPassword: YupBool(),
    password: YupString()
      .when('editPassword', {
        is: true,
        then: YupString()
          .required('Password is required')
          .matches(passwordRegex, 'password must contain One special characters, upper case and number.')
          .min(8, 'Password should at least contain 8 characters')
          .max(20, 'Password should at most contain 20 characters')
      }),
    confirmPassword: YupString()
      .when('editPassword', {
        is: true,
        then: YupString()
          .required('Confirm password is required')
          .oneOf([YupRef('password'), null], 'Passwords must match'),
      }),
  })

  //onsubmit profile
  onSubmit = (values, actions) => {
    this.props.onSubmit(values, actions)
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options, handleChange, handleBlur } = this.props;
    let defaultValues;
    if (!initialValues) {
      defaultValues = { ...this.initialValues }
    } else {
      defaultValues = { ...initialValues, password: '', confirmPassword: '' }
    }
    return (
      <Formik
        initialValues={defaultValues}
        render={props => <SimpleUserFormComponent
          {...props}
          options={options}
          languageData={this.props.languageData}
          onRef={ref => this.userProfileRef = (ref)}
          submit={this.onSubmit}
        />}
        onSubmit={this.onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}
