import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    createEntityAction,
    editEntityAction,
    deleteEntityAction,
    createCampaignAction,
    getManageCreativeAction,
} from '../../redux/actions'
import {
    success as successNotificationAction,
} from 'react-notification-system-redux';
import {
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { bindActionCreators } from 'redux'
import config from './../../config/config'
import Select from 'react-select';
import BackupIcon from '@material-ui/icons/Backup';
import { Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap'
import CropIcon from '@material-ui/icons/Crop';
import CropModal from './../../components/CropModal'
import ImageCrop from './../Manage_creative/ImageCrop';
import Scrollbar from 'react-smooth-scrollbar';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUpload } from "@fortawesome/free-solid-svg-icons";

class ImageSelection extends React.Component {
    state = {
        documents: [],
        options: [],
        image: '',
        selectedImage: '',
        selectedIndex: 0,
        showCropModal: false,
        selectedEntity: '',
        id: '',
        activeTab: '1',
    }
    file = {}
    componentDidMount = () => {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let { getImageData } = this.props
        let options = this.state.options
        getImageData('', async (res, type) => {
            if (type == 'success') {
                if (res && res.length) {
                    res.forEach((item, index) => {
                        item.value = `${config.imgUrl}${item.filePath}`
                    })
                }
                await this.setState({ documents: res, options: options })
            }
        })
    }
    handleChange = (e) => {
        this.file = e
        let file = e.target.files[0]
        if (file) {
            file.preview = URL.createObjectURL(file)
        }
        this.setState({ image: file, selectedImage: null, selectedIndex: null })
    }
    handleSelect = (item, index) => {
        this.setState({ selectedImage: item.value, image: null, selectedIndex: index })
        let imageFile = {
            name: this.props.name,
            value: item.value
        }
        this.props.handleBlur(null, 'creative', imageFile)
    }
    setShowCropModal = (selectedEntity, id) => {
        this.setState({ showCropModal: true, selectedEntity, id })
    }
    onCloseCropModal = () => {
        if (this.cropImgRef && this.cropImgRef.state && this.cropImgRef.state.croppedImg) {
            this.setState({ image: this.cropImgRef.state.croppedImg })
        }
        this.setState({ showCropModal: false, })
    }
    // Toggling Tabs
    toggle = async (tab) => {
        await this.setState({
            activeTab: tab,
        });
    };

    render() {
        let { options, image, selectedImage, documents, selectedIndex, selectedEntity, id, activeTab } = this.state
        let { name } = this.props
        return <div className='imageSelection'>
            <div className="tabs tabs--justify">
                <div className="tabs__wrap">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => {
                                    this.toggle('1');
                                }}
                            >Add New
                    </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => {
                                    this.toggle('2');
                                }}
                            >Media Library
                    </NavLink>
                        </NavItem>

                    </Nav>
                    <TabContent activeTab={activeTab}  >
                        <TabPane tabId="1" className='template__card'>
                            <div className='row'>
                                <div className='col-sm-12 text-center'>
                                    <div className='mb-4  pt-3'>
                                        <div className='main-file-uploadSelect'>
                                            <div className="upload-btn-wrapper">
                                                <button className="custom-btn custom-btnCustomized">
                                                    {/* <label className='mb-0' htmlFor='image-file123'
                                                    >
                                                       
                                                    </label> */}
                                                    <div className='dotsCircle'>
                                                        <FontAwesomeIcon icon={faUpload} style={{ fontSize: 40 }} />
                                                    </div>
                                                    {/* <h3 className='pt-3 text-white'><b>Choose Images</b></h3> */}
                                                </button>

                                                {/* <input id='file' type="file" className='custom-file-input' multiple onChange={(e) => this.appendFiles(e.target.files)} /> */}
                                                <input
                                                    name={name}
                                                    type='file'
                                                    id='image-file123'
                                                    onChange={this.handleChange}
                                                    className="custom-file-inputs"
                                                    accept='.jpeg,.png,.jpg'
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        name={name}
                                        type='file'
                                        id='image-file123'
                                        onChange={this.handleChange}
                                        className="custom-file-inputs d-none"
                                        accept='.jpeg,.png,.jpg'
                                    />

                                </div>
                                <div className='col-sm-12'>
                                    {image && image.preview ? <div className="dropzone__img" key={'i'} style={{ backgroundImage: `url(${image.preview})` }} >
                                        <img src={image.preview} id={`${image.fileName}__${'i'}`} className='d-none' />
                                        <CropIcon className="heading-icon" color='primary' onClick={() => this.setShowCropModal(image, document.getElementById(`${image.fileName}__${'i'}`))} />
                                    </div> : null}
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2" className='template__card'>
                            <div className='px-3'>
                                <label className='pt-3'>Select Image</label>
                                <Scrollbar>
                                    <Row className='ml-0 mr-0' style={{ height: 300 }}>
                                        {documents && documents.length ? documents.map((item, index) => {
                                            return <Col lg={3} md={3} sm={4} className={`pl-0 pr-0 ${index == selectedIndex ? 'selectedTemplateImage' : ''}`} onClick={e => this.handleSelect(item, index)}>
                                                <div className='center_align'><Card
                                                    className="Img__card"
                                                >
                                                    <img src={item.value} alt={item.fileName} className="list_img" />
                                                    <div className='overlay'>
                                                        <img src={`${config.imgUrl}docs/${item.fileName}`} className="list_img" />
                                                    </div>

                                                </Card>
                                                </div>
                                            </Col>
                                        }) : null}
                                    </Row>
                                </Scrollbar>

                            </div>
                        </TabPane>

                    </TabContent>
                </div>
            </div>


            <CropModal close={this.onCloseCropModal}
                // callback={this.submitForm}
                show={this.state.showCropModal}
                title={"Image Crop"}
                data={selectedEntity}
                message={<ImageCrop
                    selectedImage={selectedEntity}
                    id={id}
                    onRef={(ref) => this.cropImgRef = ref}
                    callback={this.onCloseCropModal}
                    close={this.onCloseCropModal}
                />}
                size={'lg'}
            />
        </div>
    }
}
export default connect(
    state => ({
        documents: state.documents.items,
    }),
    dispatch => ({
        getImageData: bindActionCreators(getManageCreativeAction('manageCreative'), dispatch),
    })
)(ImageSelection)