import React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
// import "./imagecropper.css";
// var finalCropWidth = 640;
// var finalCropHeight = 480;
var finalCropWidth = 1000;
var finalCropHeight = 600;
var finalAspectRatio = finalCropWidth / finalCropHeight;
class ImageCropper extends React.Component {

    constructor() {
        super();
        this.state = {
            imageDestination: "",
            croppedImg: null
        };
        this.imageElement = React.createRef();
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        const cropper = new Cropper(this.imageElement.current, {
            zoomable: true,
            dragMode: 'move',
            scalable: true,
            zoomTo: 2,
            initialAspectRatio: 1,
            viewMode: 1,
            guides: true,
            // minCropBoxHeight: 10,
            // minCropBoxWidth: 10,
            // maxCropBoxHeight: 1000,
            // maxCropBoxWidth: 600,
            minCropBoxHeight: 1000,
            minCropBoxWidth: 600,
            // maxCropBoxHeight: 1000,
            // maxCropBoxWidth: 600,
            background: false,
            responsive: true,
            autoCropArea: 1,
            checkOrientation: false,
            // aspectRatio: 16 / 9,
            aspectRatio: 5 / 3,
            //  cropBoxResizable: false,
            responsive: true,
            height: 400,
            width: "100%",
            crop: () => {
                const canvas = cropper.getCroppedCanvas();
                new Promise((resolve, reject) => {
                    canvas.toBlob(blob => {
                        if (blob) {
                            blob['preview'] = URL.createObjectURL(blob);
                        }
                        this.setState({ croppedImg: blob })
                        resolve(blob);

                    }, 'image/jpeg', 1);
                })

                this.setState({ imageDestination: canvas.toDataURL("image/png") });
            }
        });
    }

    render() {
        return (
            <div>
                <div class="img-container">
                    <img ref={this.imageElement} className='img-fluid' src={this.props.selectedImage.preview} alt="Source" crossOrigin />
                </div>
                <div className='pt-2 img-preview-box'>
                    <img src={this.state.imageDestination} class="img-preview" alt="Destination" />
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn' onClick={e => this.props.close()}>Cancel</button>
                    <button className='btn btn-success' onClick={e => this.props.callback()}>Done</button>
                </div>
            </div>
        );
    }

}

export default ImageCropper;